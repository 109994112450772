<div class="container" style="padding: 0; height: 100%;">

    <div class="row" style="height: 100%; padding-top: 50px; max-height: 100%;">

        <!-- Lijeva kolona -->
        <div class="d-none d-md-block col col-4 col-left">

            <div class="card" style="opacity: 0.9; padding-top: 10px;">
                <app-clanarine-pregled-sidebar></app-clanarine-pregled-sidebar>
            </div>

        </div>

        <!-- Desna kolona -->
        <div class="col card col-right" style="max-height: 100%;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">

                <div>

                    <div data-toggle="modal" data-target="#mdlSidebar">

                        <app-pos-jed-hamburger></app-pos-jed-hamburger>

                    </div>

                    <!--Submit-->
                    <div *ngIf="!srvClanarine.fltClanarinaFilterRez" class="d-block d-md-none" style="padding: 10px;">
                        <button type="submit" class="btn mybtn-cancel w-100" data-dismiss="modal"
                            (click)="prikazi()">Prikaži</button>
                    </div>

                    <!--Pretraga članarina by Ime i Prezime-->
                    <div *ngIf="srvClanarine.fltClanarinaFilterRez">
                        <div class="row">
                            <!-- <div class="col col-1 d-flex align-items-center justify-content-center" style="padding: 5px;">
                                <i class="material-icons" style="text-align:center; vertical-align: middle; font-size: 2rem;">search
                                </i>
                            </div> -->
                            <div class="col pretraga-slim" style="padding: 5px;">
                                <mat-form-field style="width: 100%;">
                                    <input autocomplete="off" name="pretragaClanarine"
                                        [(ngModel)]="srvClanarine.strPretragaClanarine"
                                        (ngModelChange)="srvClanarine.filtrirajClanarine(srvClanarine.strPretragaClanarine)"
                                        matInput placeholder="pretraga.. ime, prezime">
                                    <button mat-button *ngIf="srvClanarine.strPretragaClanarine" matSuffix
                                        aria-label="Clear" (click)="srvClanarine.pretragaClanarinaClear()">X</button>
                                    <button mat-button *ngIf="!srvClanarine.strPretragaClanarine" matSuffix
                                        aria-label="Search" (click)="srvClanarine.pretragaClanarinaClear()"><i
                                            class="material-icons"
                                            style="text-align:center; vertical-align: middle; font-size: 1.5rem;">search
                                        </i></button>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="flex-grow-1" style="overflow-y: scroll; scrollbar-width: none; padding-left: 2px;">
                
                    <div *ngFor="let x of srvClanarine.fltClanarinaFilterRez; let i = index" style="font-size: 1.2rem;">
                        <div class="row" style="padding: 5px;">
                            <div class="col d-flex flex-column justify-content-center" style="padding: 2px;">
                                <div>
                                    {{x.Ime}} {{x.Prezime}}
                                </div>
                                <div *ngIf="x.grpID != '0'" style="font-size: 1rem; color: #8491af;">
                                    {{x.grpOpis}}
                                </div>
                                <div style="font-size: 1rem;">
                                    {{x.claVrijediOD | amDateFormat:'DD.MM.YY.'}} - {{x.claVrijediDO |
                                    amDateFormat:'DD.MM.YY.'}} <span style="margin-left: 10px;">({{x.claIznos}} €)</span>
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-center align-items-center" style="padding: 0;">
                                <!-- <mat-icon *ngIf="x.intPlacena == 0" style="color: #ff7878;">priority_high</mat-icon> -->
                                <mat-icon *ngIf="x.intPlacena == 1" style="color: #56f156;">check</mat-icon>
                            </div>
                            <div class="col col-3 d-flex align-items-center" style="padding: 2px;">
                                <button *ngIf="x.intPlacena == 0" type="button" class="btn mybtn-cancel w-100"
                                    (click)="uplataClStart(x, i)">UPLATA</button>
                            </div>
                        </div>
                    </div>
                
                </div>

            </div>


        </div>

    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header posjed-mod-header d-flex justify-content-center">
                Pregled termina
            </div>

            <div class="modal-body" style="padding: 2px;">

                <app-clanarine-pregled-sidebar></app-clanarine-pregled-sidebar>

            </div>
            <div class="modal-footer bgs-dark" style="padding: 5px;">
                <button type="button" id="btnSidebarClose" class="btn mybtn-cancel" data-dismiss="modal"
                    style="width: 100%; font-size: 1.2rem;">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Članovi -->
<button hidden id="btnMdlClanovi" type="button" data-toggle="modal" data-target="#mdlClanovi">
    Launch demo modal
</button>

<div class="modal fade" id="mdlClanovi" tabindex="-1" role="dialog" aria-labelledby="mdlClanovi" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-block" style="padding: 0px; font-size: 1.2rem;">

                <div class="d-flex" style="padding: 5px;">
                    <div id="mdlClanoviLabel">Odabir člana</div>
                    <button type="button" class="close ml-auto" style="margin: 0; padding: 0;" data-dismiss="modal"
                        aria-label="Zatvori">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="col w-100" style="text-align: center; padding: 5px">
                    <i class="material-icons" style="text-align:center; vertical-align: middle; font-size: 2rem;">search
                    </i>
                    <mat-form-field style="width: 90%;">
                        <input autocomplete="off" type="search" name="pretraga" [(ngModel)]="srvClanarine.strPretraga"
                            (ngModelChange)="srvClanarine.filtrirajClanove(srvClanarine.strPretraga)" matInput
                            placeholder="pretraga..">
                        <button mat-button *ngIf="srvClanarine.strPretraga" matSuffix aria-label="Clear"
                            (click)="srvClanarine.pretragaClear()">X</button>
                    </mat-form-field>
                </div>

            </div>

            <div class="modal-body" style="padding: 5px 10px; font-size: 1.2rem;">

                <div *ngFor="let x of this.srvClanovi.fltClanovi" (click)="odaberiClana(x)"
                    style="padding: 5px 0; border-bottom: solid 0.5px #e4e4e4; cursor: pointer;">
                    <div class="row" style="padding: 0;">

                        <div class="col">
                            <div>
                                {{x.Ime}} {{x.Prezime}}
                            </div>
                            <div style="color: #838383;">
                                {{x.Adresa}}{{x.Adresa && x.Mjesto ? ', ' : ''}}{{x.Mjesto}}
                            </div>

                        </div>

                    </div>
                </div>


            </div>

            <div class="modal-footer">
                <button id="btnMdlClanoviClose" type="button" class="btn mybtn-cancel w-100"
                    data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Uplata članarine -->
<button hidden id="btnMdlUplataCl" type="button" data-toggle="modal" data-target="#mdlUplataCl">
    Launch demo modal
</button>

<div class="modal fade" id="mdlUplataCl" tabindex="-1" role="dialog" aria-labelledby="mdlUplataCl" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-block" style="padding: 0px; font-size: 1.2rem;">

                <div class="d-flex" style="padding: 15px;">
                    <div id="mdlUplataClLabel">Uplata članarine</div>
                    <button type="button" class="close ml-auto" style="margin: 0; padding: 0;" data-dismiss="modal"
                        aria-label="Zatvori">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

            </div>

            <div class="modal-body" style="padding: 10px 10px; font-size: 1.4rem;">

                <div *ngIf="srvClanarine.frmUplataClanarine">
                    <form [formGroup]="srvClanarine.frmUplataClanarine">
                        <!--Datum Od-->
                        <mat-form-field style="width: 100%; text-align: center;">
                            <mat-datepicker-toggle matPrefix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #picker1></mat-datepicker>
                            <input autocomplete="off" matInput formControlName="datum" [matDatepicker]="picker1"
                                placeholder="Datum uplate">
                            <mat-error *ngIf="srvClanarine.frmUplataClanarine.get('datum').errors?.required"
                                style="font-size: 1rem;">
                                Obavezan unos
                            </mat-error>
                        </mat-form-field>
                    </form>
                </div>

            </div>

            <div class="modal-footer d-block" style="padding: 2px;">
                <div class="row">
                    <div class="col" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100" style="font-size: 1.2rem;"
                            (click)="potvrdiUplatu()">Potvrdi</button>
                    </div>
                    <div class="col" style="padding: 2px;">
                        <button id="btnMdlUplataClClose" type="button" class="btn mybtn-cancel w-100"
                            style="font-size: 1.2rem;" data-dismiss="modal">Otkaži</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!--Modal Članarina (new / edit)-->
<button hidden="true" id="btnMdlClanarina" data-toggle="modal" data-target="#mdlClanarina"></button>

<div class="modal fade" id="mdlClanarina" tabindex="-1" role="dialog" aria-labelledby="mdlClanarina" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header justify-content-center" style="font-size: 1.2rem; color: #6c1d1d;">
                <div>
                    Članarina
                </div>
            </div>

            <div class="modal-body" style="padding: 10px 7px; font-size: 1.5rem;">

                <app-clanarine-edit></app-clanarine-edit>

            </div>

            <div class="modal-footer d-block" style="padding: 0;">
                <div class="row">
                    <div class="col" style="padding: 5px;">
                        <button type="button" class="btn mybtn-success w-100"
                            (click)="spremiClanarinu()">Spremi</button>
                    </div>
                    <div class="col" style="padding: 5px;">
                        <button type="button" id="btnMdlClanarinaClose" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>