<div>

    <form *ngIf="srvClanarine.frmClanarineFilter" [formGroup]="srvClanarine.frmClanarineFilter" style="font-size: 1.2rem;">

        <!--Svi članovi-->
        <!-- <div class="row" style="cursor: pointer;"
            (click)="srvClanarine.frmClanarineFilter.get('chkSvi').value == 0 ? srvClanarine.sviClanovi() : modalClanovi()">
            <div class="col col-2 d-flex justify-content-center align-items-center"
                style="padding: 0; color: cadetblue;">
                <mat-icon style="font-size: 2.2rem; width: 40px; height: 40px; text-align: center;"
                    *ngIf="srvClanarine.frmClanarineFilter && srvClanarine.frmClanarineFilter.get('chkSvi').value == 1">
                    check_box</mat-icon>
                <mat-icon style="font-size: 2.2rem; width: 40px; height: 40px; text-align: center;"
                    *ngIf="srvClanarine.frmClanarineFilter && srvClanarine.frmClanarineFilter.get('chkSvi').value == 0">
                    check_box_outline_blank</mat-icon>
            </div>
            <div class="col d-flex align-items-center" style="font-size: 1.3rem;">
                Svi članovi
            </div>
        </div> -->

        <div *ngIf="srvClanarine.frmClanarineFilter.get('clanID').value != 0" style="padding: 10px 20px; text-align: center;">
            <div class="row" style="padding: 0;">
                <div class="col" style="color: #189e6a;">
                    {{srvClanarine.frmClanarineFilter.get('clan').value}}
                </div>
                <div class="col col-2" style="cursor: pointer;" (click)="srvClanarine.sviClanovi()">
                    X
                </div>
            </div>
        </div>

        <!--Neplaćene-->
        <div class="row" style="cursor: pointer;" (click)="srvClanarine.frmClanarineFilter.get('neplacene').value == 0 ? srvClanarine.frmClanarineFilter.get('neplacene').setValue(1) : srvClanarine.frmClanarineFilter.get('neplacene').setValue(0)">
            <div class="col col-2 d-flex justify-content-center align-items-center" style="padding: 0; color: cadetblue;">
                <mat-icon style="font-size: 2.2rem; width: 40px; height: 40px; text-align: center;" *ngIf="srvClanarine.frmClanarineFilter && srvClanarine.frmClanarineFilter.get('neplacene').value == 1">
                    check_box</mat-icon>
                <mat-icon style="font-size: 2.2rem; width: 40px; height: 40px; text-align: center;" *ngIf="srvClanarine.frmClanarineFilter && srvClanarine.frmClanarineFilter.get('neplacene').value == 0">
                    check_box_outline_blank</mat-icon>
            </div>
            <div class="col d-flex align-items-center" style="font-size: 1.3rem;">
                Samo neplaćene
            </div>
        </div>

        <!--Razdoblje-->
        <div class="row" style="cursor: pointer;" (click)="srvClanarine.frmClanarineFilter.get('razdoblje').value == 0 ? srvClanarine.frmClanarineFilter.get('razdoblje').setValue(1) : srvClanarine.frmClanarineFilter.get('razdoblje').setValue(0)">
            <div class="col col-2 d-flex justify-content-center align-items-center" style="padding: 0; color: cadetblue;">
                <mat-icon style="font-size: 2.2rem; width: 40px; height: 40px; text-align: center;" *ngIf="srvClanarine.frmClanarineFilter && srvClanarine.frmClanarineFilter.get('razdoblje').value == 1">
                    check_box</mat-icon>
                <mat-icon style="font-size: 2.2rem; width: 40px; height: 40px; text-align: center;" *ngIf="srvClanarine.frmClanarineFilter && srvClanarine.frmClanarineFilter.get('razdoblje').value == 0">
                    check_box_outline_blank</mat-icon>
            </div>
            <div class="col d-flex align-items-center" style="font-size: 1.3rem;">
                Određeno razdoblje
            </div>
        </div>

        <div *ngIf="srvClanarine.frmClanarineFilter.get('razdoblje').value == 1" style="padding: 20px;">

            <!--Datum Od-->
            <mat-form-field style="width: 100%; text-align: center;">
                <input autocomplete="off" matInput formControlName="datOd" [matDatepicker]="picker1" placeholder="Datum od">
                <mat-error *ngIf="srvClanarine.frmClanarineFilter.get('datOd').errors?.required" style="font-size: 1rem;">
                    Obavezan unos
                </mat-error>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker1></mat-datepicker>
            </mat-form-field>

            <!--Datum Do-->
            <mat-form-field style="width: 100%; text-align: center;">
                <input autocomplete="off" matInput formControlName="datDo" [matDatepicker]="picker2" placeholder="Datum do">
                <mat-error *ngIf="srvClanarine.frmClanarineFilter.get('datDo').errors?.required" style="font-size: 1rem;">
                    Obavezan unos
                </mat-error>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker2></mat-datepicker>
            </mat-form-field>

        </div>

        <!--Submit-->
        <div style="padding: 10px;">
            <button type="submit" class="btn mybtn-cancel w-100" data-dismiss="modal" (click)="prikazi()">Prikaži</button>
        </div>


    </form>
</div>

<div style="padding: 10px;">
    <button class="btn btns-info w-100" (click)="unosClanarine()">Unos članarine</button>
</div>

<div *ngIf="srvClanarine.clanarinaStat">

    <div *ngIf="srvClanarine.clanarinaStat.neplaceneCount != 0" class="card card-stat">
        <div class="naslov">
            Neplaćene članarine
        </div>
        <div class="row">
            <div class="col">
                <div>
                    Neplaćenih članarina: {{srvClanarine.clanarinaStat.neplaceneCount}}
                </div>
                <div>
                    Iznos: {{srvClanarine.clanarinaStat.neplaceneIznos | number:'1.2-2':'en-HR'}}
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="srvClanarine.clanarinaStat.placeneCount != 0" class="card card-stat">
        <div class="naslov">
            Plaćene članarine
        </div>
        <div class="row">
            <div class="col">
                <div>
                    Plaćenih članarina: {{srvClanarine.clanarinaStat.placeneCount}}
                </div>
                <div>
                    Iznos: {{srvClanarine.clanarinaStat.placeneIznos | number:'1.2-2':'en-HR'}}
                </div>
            </div>
        </div>
    </div>
    
</div>