import { Component, OnInit } from '@angular/core';
import { MjestaRH, ModulService } from '../modul.service';
import { KorisniciService } from '../korisnici.service';
import { MjestaService } from '../mjesta.service';

@Component({
  selector: 'app-korisnik-edit',
  templateUrl: './korisnik-edit.component.html',
  styleUrl: './korisnik-edit.component.scss'
})
export class KorisnikEditComponent implements OnInit {

  constructor(
    public mod: ModulService,
    public srvKorisnici: KorisniciService,
    public srvMjesta: MjestaService
  ) { }


  ngOnInit(): void {

    

  }

  


}
