import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Clanarina, ClanarinaFilterRez, ClanarinaVrsta, ClanarineFilter, ClanarineService, UplataClanarine, VclanarinaGrupa } from '../clanarine.service';
import { Clan, ClanoviService } from '../clanovi.service';
import { Grupa, GrupeService } from '../grupe.service';
import { ModulService } from '../modul.service';

@Component({
  selector: 'app-clanarine-pregled',
  templateUrl: './clanarine-pregled.component.html',
  styleUrls: ['./clanarine-pregled.component.scss']
})
export class ClanarinePregledComponent implements OnInit, OnDestroy {

  constructor(
    private mod: ModulService,
    public srvClanovi: ClanoviService,
    public srvClanarine: ClanarineService,
    private srvGrupe: GrupeService
  ) { }

  ngOnInit(): void {

    this.srvClanarine.objClanarinaFilterRez = null;
    this.srvClanarine.fltClanarinaFilterRez = null;
    this.srvClanarine.strPretraga = '';
    this.srvClanarine.strPretragaClanarine = '';

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.mod.objKor.token;
    }

    this.mod.provjeraTokena(tok, () => {

      this.mod.provjeraPrijaveTrenOper(rez9 => {

        this.mod.ucitajPosJed(() => {

          this.srvClanarine.objClanarinaFilterRez = null;
          this.srvClanarine.fltClanarinaFilterRez = null;

          this.srvClanarine.napraviFrmClanarineFilter(null, (rez: ClanarineFilter) => {
            this.prikazi();
          });

        });

      });

    });


  }



  //Odabir člana
  odaberiClana(x: Clan) {

    this.srvGrupe.grupeByClan(1, x.clanID, (rez: Grupa[]) => {
      this.srvGrupe.objGrupe = rez;
    });

    this.srvClanarine.frmClanarineFilter.get('chkSvi').setValue(0);
    this.srvClanarine.frmClanarineFilter.get('clanID').setValue(x.clanID);
    this.srvClanarine.frmClanarineFilter.get('clan').setValue(x.Ime + ' ' + x.Prezime);

    document.getElementById('btnMdlClanoviClose').click();

    if (this.srvClanarine.intUnosTmp == 1) {

      document.getElementById('btnMdlClanarina').click();

      this.srvClanarine.clanarineVrsteByPosJedID(this.mod.objPosJedSelected.PoslJedID, (rez: ClanarinaVrsta[]) => {
        this.srvClanarine.objVrsteClanarina = rez;



        this.srvClanarine.napraviFrmClanarina(null, () => {

          this.srvClanarine.frmClanarina.get('claClanID').setValue(x.clanID);

          this.srvClanarine.istekClanarine(this.srvClanarine.frmClanarineFilter.get('clanID').value, (rez: Clanarina) => {

            if (rez) {

              let dTmp: moment.Moment = rez.claVrijediDO;

              this.srvClanarine.frmClanarina.get('claVrijediOD').setValue(moment(dTmp).add(1, 'days').utc(true));
              this.srvClanarine.frmClanarina.get('claVrijediDO').setValue(moment(this.srvClanarine.frmClanarina.get('claVrijediOD').value).add(1, 'month').subtract(1, 'days').utc(true));
              this.srvClanarine.frmClanarina.get('claIznos').setValue(rez.claIznos);
              this.srvClanarine.frmClanarina.get('claVrstaID').setValue(rez.claVrstaID);

              if (rez.claVrstaID != 0) {
                this.srvClanarine.objVrsteClanarina.forEach(val => {
                  if (val.cvID == rez.claVrstaID) {
                    this.srvClanarine.frmClanarina.get('claIznos').setValue(val.cvIznos);
                  }
                });
              }

              this.srvClanarine.frmClanarina.get('claGrupaID').setValue(rez.claGrupaID);

              if (rez.claGrupaID != 0) {
                this.srvGrupe.objGrupe.forEach(cal => {
                  if (cal.grpID == rez.claGrupaID) {
                    this.srvClanarine.frmClanarina.get('claIznos').setValue(cal.grpClanarina);
                  }
                });
              }

              if (this.srvClanarine.frmClanarina.get('claVrijediOD').value.format('D') == '1') {
                let dLastTmp: string = moment(this.srvClanarine.frmClanarina.get('claVrijediOD').value).endOf('month').format('DD');
                this.srvClanarine.frmClanarina.get('claVrijediDO').setValue(moment(moment(this.srvClanarine.frmClanarina.get('claVrijediOD').value.format('YYYY') + '-' + this.srvClanarine.frmClanarina.get('claVrijediOD').value.format('MM') + '-' + dLastTmp)).utc(true));
              }

            }

            this.srvClanarine.intUnosTmp = 0;

          });

        });

      });

    }

  }

  //Unos zaduženja članainre
  //Spremanje nove članarine
  spremiClanarinu() {

    if (this.srvClanarine.frmClanarina.valid) {

      this.srvClanarine.spremiClanarinu(this.srvClanarine.frmClanarina.value, (rez: VclanarinaGrupa) => {

        if (rez) {

          document.getElementById('btnMdlClanarinaClose').click();

          this.mod.msgBox(1, "Spremljeno", 1000);
        } else {
          this.mod.msgBox(2, "Greška prilikom spremanja!", 3000);
        }
      });

    } else {
      this.mod.msgBox(2, "Popunite ispravno sva polja!", 3000);
    }

  }

  //Uplata članarine
  uplataClStart(x, i) {

    this.srvClanarine.iTmp = i;

    let uTmp: UplataClanarine = { clanarinaID: Number(x.claID), datum: moment() };

    this.srvClanarine.napraviFrmUplataClanarine(uTmp, () => {
      document.getElementById('btnMdlUplataCl').click();
    });

  }

  //Potvrdi uplatu članarine
  potvrdiUplatu() {

    this.srvClanarine.uplataClanarine(this.srvClanarine.frmUplataClanarine.value, (rez: string) => {

      if (rez == 'ok') {

        this.srvClanarine.fltClanarinaFilterRez[this.srvClanarine.iTmp].intPlacena = 1;
        this.srvClanarine.fltClanarinaFilterRez[this.srvClanarine.iTmp].claDatumUplate = moment(this.srvClanarine.frmUplataClanarine.get('datum').value);

        let clIDtmp: number = this.srvClanarine.frmUplataClanarine.get('clanarinaID').value;

        this.srvClanarine.objClanarinaFilterRez.forEach(val => {
          if (val.claID == clIDtmp) {
            val.intPlacena = 1;
            val.claDatumUplate = moment(this.srvClanarine.frmUplataClanarine.get('datum').value);
          }
        });

        this.srvClanarine.iTmp = 0;

        document.getElementById('btnMdlUplataClClose').click();
        this.mod.msgBox(1, "SPREMLJENO", 2000);

      } else {
        this.mod.msgBox(2, "Greška prilikom spremanja", 3000);
      }

    });

  }

  //Prikaz podataka
  prikazi() {
    this.srvClanarine.filterClanarine(this.srvClanarine.frmClanarineFilter.value, (rez: ClanarinaFilterRez[]) => {

      this.srvClanarine.objClanarinaFilterRez = rez;
      this.srvClanarine.fltClanarinaFilterRez = rez;
      this.srvClanarine.clanarinaStat = this.srvClanarine.clanarineStat(this.srvClanarine.fltClanarinaFilterRez);
    });
  }


  ngOnDestroy(): void {
    document.getElementById('btnSidebarClose').click();
    document.getElementById('btnMdlClanoviClose').click();
    document.getElementById('btnMdlUplataClClose').click();
    this.srvClanarine.clanarinaStat = null;
  }

}
