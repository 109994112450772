<div class="container" style="opacity: 0.9; padding: 0; font-size: 1.2rem; height: 100%;">

    <div class="row" style="padding-top: 50px; height: 100%; max-height: 100%;">

        <!-- Lijeva kolona -->
        <div class="d-none d-md-block col col-4 col-left" style="padding: 0;">

            <app-pn-pregled-sidebar></app-pn-pregled-sidebar>

        </div>

        <!-- Desna kolona -->
        <div class="col card col-right" style="padding: 0; padding-bottom: 30px; height: 100%; max-height: 100%;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">

                <div>
                    <app-pos-jed-hamburger></app-pos-jed-hamburger>
                </div>

                <div class="flex-grow-1" style="overflow-y: auto; scrollbar-width: none; background-color: white;">

                    <div style="padding: 5px;">

                        <div *ngIf="!srvNapredak.objPnStat" class="alert alert-success" style="text-align: center;">
                            Nema podataka za prikaz
                        </div>

                        <div
                            *ngIf="mod.objKor?.vrstaID == 1 && !mod.session && !srvNapredak?.objVePnVezaByClanID && srvNapredak.intAkt == 1">
                            <div class="alert alert-warning">
                                <div>
                                    Trenutno ne pratite napredak po ni jednom programu.
                                </div>
                                <div>
                                    Kako biste počeli praćenje klinite "Dodaj program za praćenje" i odaberite predložak
                                    koji vam najviše odgovara."
                                </div>
                                <div>
                                    Na linku "Predlošci" možete pogledati parametre svih dostupnih programa te, ako vam
                                    niti jedan ne odgovara možete napraviti novi prilagođen baš vama.
                                </div>

                            </div>
                        </div>

                        <div *ngIf="!srvNapredak.bolPnStatDetalji">

                            <div *ngFor="let x of srvNapredak.objPnStat">

                                <div class="row-stav">

                                    <div class="row">

                                        <div class="col mycol">
                                            <div class="naslov">
                                                {{x.naziv}}
                                            </div>
                                            <div class="d-flex">
                                                <div style="margin-right: 10px;">
                                                    jed. mjere:
                                                </div>
                                                <div style="font-weight: bold;">
                                                    {{x.jedMj}}
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="x.cilj" class="col col-2 mycol">
                                            <div class="d-flex justify-content-center"
                                                style="font-weight: bold; color: #024a7f;">
                                                <div style="margin-right: 10px;">
                                                    <i class="fa fa-flag-checkered" aria-hidden="true"></i>
                                                </div>
                                                <div>
                                                    {{x.cilj | number}}
                                                </div>
                                            </div>
                                            <div style="color: #024a7f; text-align: center;">
                                                {{srvNapredak.calcNapredPosto(x.start, x.end, x.cilj).ukPoj + '/' +
                                                srvNapredak.calcNapredPosto(x.start, x.end, x.cilj).ukCilj}}
                                            </div>
                                        </div>

                                        <div *ngIf="x.cilj" class="col col-4 mycol"
                                            style="text-align: center; font-size: 1rem;">
                                            <div>
                                                Ostvareno:
                                            </div>
                                            <div style="font-size: 1.5rem; color: green;">
                                                {{srvNapredak.calcNapredPosto(x.start, x.end, x.cilj).postotak + '%'}}
                                            </div>
                                        </div>

                                    </div>

                                    <div class="d-flex align-items-center" style="font-size: 1rem;">
                                        <div style="margin-right: 10px;">
                                            Prvo mjerenje:
                                        </div>
                                        <div style="font-weight: bold; margin-right: 10px;">
                                            {{(x.start | number) + ' ' + x.jedMj}}
                                        </div>
                                        <div>
                                            {{x.startDat | amDateFormat: 'DD.MM.YYYY.'}}
                                        </div>
                                    </div>

                                    <div *ngIf="x.startDat != x.endDat" class="d-flex align-items-center" style="font-size: 1rem;">
                                        <div style="margin-right: 10px;">
                                            Zadnje mjerenje:
                                        </div>
                                        <div style="font-weight: bold; margin-right: 10px;">
                                            {{(x.end | number) + ' ' + x.jedMj}}
                                        </div>
                                        <div>
                                            {{x.endDat | amDateFormat: 'DD.MM.YYYY.'}}
                                        </div>
                                    </div>

                                    <div class="d-flex" style="font-size: 1rem;">
                                        <div style="margin-right: 10px; color: green;">
                                            Napredak:
                                        </div>
                                        <div style="color: green; margin-right: 15px;">
                                            {{napredak(x.start, x.end)}}
                                        </div>
                                        <div style="color: green;">
                                            {{srvNapredak.dateDiffDani(x.startDat, x.endDat) | sklanjanjeDani}}
                                        </div>
                                    </div>



                                    <!--  <div *ngFor="let y of x.mjerenja"> -->

                                    <!-- <div>
                                            {{y.pnmDatum | amDateFormat: 'DD.MM.YYYY.'}}
                                        </div> -->

                                    <div *ngIf="x.chartDdData"
                                        style="overflow-x: auto; overflow-y: hidden; scrollbar-width: thin;">
                                        <div #lnChOkvir>
                                            <google-chart [type]="'LineChart'" [width]="x.cddWidth"
                                                [height]="lnChOkvir.offsetHeight" [data]="x.chartDdData"
                                                [columns]="lineChartdatacolumn" [options]="lineChartoptions"
                                                (select)="onSelect($event)"></google-chart>
                                        </div>
                                    </div>

                                    <!--  </div>   -->
                                </div>

                            </div>

                        </div>

                        <div *ngIf="srvNapredak.bolPnStatDetalji">

                            <div *ngFor="let x of srvNapredak.objPnStatDetalji">

                                <div style="padding: 5px; background-color: #ebebeb;">

                                    <div class="row">
                                        <div class="col col-10 mycol">
                                            <div>
                                                {{x.datum | amDateFormat:'DD.MM.YYYY.'}}
                                            </div>
                                        </div>

                                        <div class="col col-2 mycol d-flex align-items-center justify-content-center"
                                            style="cursor: pointer;" (click)="spremiPnMjDatStart(x)">
                                            <i class="material-icons">create</i>
                                        </div>

                                    </div>

                                </div>

                                <div *ngFor="let y of x.stavke">

                                    <div class="row" style="padding: 5px;">

                                        <div class="col mycol">
                                            <div>
                                                {{y.ppsNaziv}}
                                            </div>
                                        </div>

                                        <div class="col col-4 mycol" style="text-align: center;">
                                            <div>
                                                {{(y.pnmVrijednost | number) + ' ' + y.ppsJedMj}}
                                            </div>
                                        </div>

                                        <div class="col col-2 mycol d-flex align-items-center justify-content-center"
                                            style="cursor: pointer;" (click)="spremiPnMjStavStart(y)">
                                            <i class="material-icons">create</i>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header posjed-mod-header d-flex justify-content-center">
                Obavijesti
            </div>

            <div class="modal-body" style="padding: 2px;">

                <app-pn-pregled-sidebar></app-pn-pregled-sidebar>

            </div>

            <div class="modal-footer bgs-dark">
                <button type="button" id="btnSidebarClose" class="btn btn-outline-secondary" data-dismiss="modal"
                    style="width: 100%;">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal - Odabir člana -->
<button hidden="true" id="btnMdlOdabirClana" class="btn btn-info" data-toggle="modal"
    data-target="#mdlOdabirClana"></button>

<div class="modal fade" id="mdlOdabirClana" tabindex="-1" role="dialog" aria-labelledby="mdlOdabirClanaLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlOdabirClanaLabel">Odabir člana</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="text-align: center; padding: 5px;">

                <div>

                    <div *ngFor="let x of srvNapredak.objClanovi" (click)="odabirClana(x)" class="row-clan"
                        style="padding: 5px; cursor: pointer;">

                        <div style="font-size: 1.2rem; font-weight: bold;">
                            {{x.Ime + ' ' + x.Prezime}}
                        </div>
                        <div>
                            {{x.Adresa && x.Mjesto ? x.Adresa + ', ' + x.Mjesto : x.Adresa ? x.Adresa : x.Mjesto}}
                        </div>

                    </div>

                </div>

            </div>

            <div class="modal-footer d-block" style="padding: 5px;">
                <div class="row">
                    <!-- <div class="col" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100">SPREMI</button>
                    </div> -->
                    <div class="col" style="padding: 2px;">
                        <button id="btnMdlOdabirClanaClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">ZATVORI</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal - Novo praćenje (privatni korisnici / samo članovi) -->
<button hidden="true" id="btnMdlNovoPracenje" class="btn btn-info" data-toggle="modal"
    data-target="#mdlNovoPracenje"></button>

<div class="modal fade" id="mdlNovoPracenje" tabindex="-1" role="dialog" aria-labelledby="mdlNovoPracenjeLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlNovoPracenjeLabel">{{srvNapredak.frmPnVeza?.get('pnvID').value == 0 ? 'Odabir programa' : 'Uređivanje'}}</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="text-align: center; padding: 5px;">

                <app-pn-veza-edit></app-pn-veza-edit>

            </div>

            <div class="modal-footer d-block" style="padding: 5px;">
                <div class="row">
                    <div class="col" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100"
                            (click)="spremiNovoPracenje()">SPREMI</button>
                    </div>
                    <div class="col" style="padding: 2px;">
                        <button id="btnMdlNovoPracenjeClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">ZATVORI</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!--MODAL novo mjerenje-->
<button hidden="true" id="btnMdlPnMjerEdit" class="btn btn-info" data-toggle="modal" data-target="#mdlPnMjerEdit">Modal
    poslan e-mail</button>

<div class="modal fade" id="mdlPnMjerEdit" tabindex="-1" role="dialog" aria-labelledby="mdlMjerEditLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-block">

                <!-- <div class="d-flex" style="margin-bottom: 10px;">
                    <h6 class="modal-title" id="mdlPnStavEditLabel">Mjerenje</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div> -->

                <div class="input-slim">
                    <form *ngIf="srvNapredak.frmPnMjerenje" [formGroup]="srvNapredak.frmPnMjerenje">

                        <mat-form-field style="width: 100%; text-align: center;">
                            <mat-label>Datum</mat-label>
                            <input matInput name="datum" formControlName="datum" style="text-align: center;"
                                [matDatepicker]="picker1" placeholder="Datum">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #picker1></mat-datepicker>
                        </mat-form-field>

                    </form>
                </div>

            </div>

            <div class="modal-body" style="padding: 5px;">

                <app-pn-mjerenje-edit></app-pn-mjerenje-edit>

            </div>
            <div class="modal-footer" style="display: block; padding: 5px;">
                <div class="row">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100" (click)="spremiMjerenje()">Spremi</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button id="btnMdlPnMjerEditClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--MODAL zadani ciljevi-->
<button hidden="true" id="btnMdlCiljeviEdit" class="btn btn-info" data-toggle="modal" data-target="#mdlCiljevi">Modal
    poslan e-mail</button>

<div class="modal fade" id="mdlCiljevi" tabindex="-1" role="dialog" aria-labelledby="mdlCiljeviLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-block" style="padding: 15px 15px 5px;">

                <div class="d-flex" style="margin-bottom: 10px;">
                    <h6 class="modal-title" id="mdlPnStavEditLabel">Zadani ciljevi</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="d-flex" style="color: blue;">
                    <div style="margin-right: 10px;">
                        <i class="fa fa-square" aria-hidden="true"></i>
                    </div>
                    <div>
                        Prvo mjerenje
                    </div>
                </div>

            </div>

            <div class="modal-body" style="padding: 5px;">

                <app-pn-cilj-edit></app-pn-cilj-edit>

            </div>
            <div class="modal-footer" style="display: block; padding: 5px;">
                <div class="row">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100" (click)="spremiCiljeve()">Spremi</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button id="btnMdlCiljeviClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--MODAL uređivanje datuma na mjerenjima-->
<button hidden="true" id="btnMdlPnMjDatEdit" class="btn btn-info" data-toggle="modal"
    data-target="#mdlPnMjDatEdit">Modal
    poslan e-mail</button>

<div class="modal fade" id="mdlPnMjDatEdit" tabindex="-1" role="dialog" aria-labelledby="mdlPnMjDatEditLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-block" style="padding: 15px 15px 5px;">

                <div class="d-flex" style="margin-bottom: 10px;">
                    <h6 class="modal-title" id="mdlPnStavEditLabel">Uređivanje datuma</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

            </div>

            <div class="modal-body" style="padding: 5px;">

                <div *ngIf="srvNapredak.frmPnMjDatEdit">

                    <form [formGroup]="srvNapredak.frmPnMjDatEdit">


                        <mat-form-field style="width: 100%; text-align: center;">
                            <mat-label>Datum</mat-label>
                            <input matInput name="datum" formControlName="datum" style="text-align: center;"
                                [matDatepicker]="picker10" placeholder="Datum">
                            <mat-datepicker-toggle matSuffix [for]="picker10"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #picker10></mat-datepicker>
                        </mat-form-field>

                    </form>

                </div>

            </div>
            <div class="modal-footer" style="display: block; padding: 5px;">
                <div class="row">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100" (click)="spremiPnMjDat()">Spremi</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button id="btnMdlPnMjDatEditClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--MODAL uređivanje stavke / vrijednosti na mjerenjima-->
<button hidden="true" id="btnMdlPnMjStavEdit" class="btn btn-info" data-toggle="modal"
    data-target="#mdlPnMjStavEdit">Modal
    poslan e-mail</button>

<div class="modal fade" id="mdlPnMjStavEdit" tabindex="-1" role="dialog" aria-labelledby="mdlPnMjStavEditLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-block" style="padding: 15px 15px 5px;">

                <div class="d-flex" style="margin-bottom: 10px;">
                    <h6 class="modal-title" id="mdlPnStavEditLabel">Uređivanje mjerenja</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

            </div>

            <div class="modal-body" style="padding: 5px;">

                <div *ngIf="!srvNapredak.bolDelPnMjStav">

                    <div style="padding: 10px;">
                        <button type="button" class="btn mybtn-delete w-100" (click)="srvNapredak.bolDelPnMjStav = true">Obriši mjerenje</button>
                    </div>
    
                    <div *ngIf="srvNapredak.frmPnMjStavEdit">
    
                        <form [formGroup]="srvNapredak.frmPnMjStavEdit">
    
    
                            <mat-form-field class="w-100" style="text-align: center;">
                                <mat-label>{{srvNapredak.frmPnMjStavEdit.get('ppsJedMj').value}}</mat-label>
                                <input matInput type="tel" formControlName="pnmVrijednost" ariaLabel="pnmVrijednost"
                                    (blur)="srvNapredak.decFormatStav()"
                                    placeholder="{{srvNapredak.frmPnMjStavEdit.get('ppsJedMj').value}}"
                                    style="text-align: center;">
                            </mat-form-field>
    
                        </form>
    
                    </div>
                </div>

                <div *ngIf="srvNapredak.bolDelPnMjStav">

                    <div class="alert alert-danger" style="text-align: center;">
                        Želite li nepovratno izbrisati mjerenje iz evidencije?
                    </div>

                    <div class="row">
                        <div class="col mycol">
                            <button type="button" class="btn mybtn-danger w-100" (click)="obrisiPnMjStav()">Obriši</button>
                        </div>
                        <div class="col mycol">
                            <button type="button" class="btn mybtn-cancel w-100" (click)="srvNapredak.bolDelPnMjStav = false">Odustani</button>
                        </div>
                    </div>

                </div>
                

            </div>
            <button id="btnMdlPnMjStavEditClose2" hidden type="button" class="btn mybtn-cancel w-100" data-dismiss="modal">Zatvori</button>
            <div *ngIf="!srvNapredak.bolDelPnMjStav" class="modal-footer" style="display: block; padding: 5px;">
                <div class="row">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100" (click)="spremiPnMjStav()">Spremi</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button id="btnMdlPnMjStavEditClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>