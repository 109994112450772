import { Component } from '@angular/core';
import { ModulService } from '../modul.service';
import { NapredakService, PnPrgStavka, PnProgram, PnStatPojedinacno, PnVeza, VePnVezaByClanID } from '../napredak.service';
import { Clan, ClanoviService } from '../clanovi.service';
import * as moment from 'moment';

@Component({
  selector: 'app-pn-pregled-sidebar',
  templateUrl: './pn-pregled-sidebar.component.html',
  styleUrl: './pn-pregled-sidebar.component.scss'
})
export class PnPregledSidebarComponent {

  constructor(
    public mod: ModulService,
    public srvNapredak: NapredakService,
    public srvClanovi: ClanoviService
  ) { }

  //Program select
  programSelect(x: VePnVezaByClanID) {

    this.srvNapredak.bolPnStatDetalji = false;
    
    this.srvNapredak.objVePnVezaByClanIDsel = x;
    document.getElementById('btnSidebarClose').click();

    //Učitavanje mjerenja
    let pnvID: number = this.srvNapredak.objVePnVezaByClanIDsel.pnvID;
    let clanID: number = this.srvNapredak.objVePnVezaByClanIDsel.pnvClanID;

    this.srvNapredak.pnStatistikaByClan(clanID, pnvID, null, null, (rez) => {
      this.srvNapredak.objPnStat = rez;
      console.log(this.srvNapredak.objPnStat);
      this.srvNapredak.napuniChartDd();
    });

    //Učitavanje mjerenja - pojedinačno (za uređivanje)
    if (this.srvNapredak.bolPnStatDetalji) {
      this.srvNapredak.pnStatDetalji(pnvID, null, null, (rez: PnStatPojedinacno[]) => {
        this.srvNapredak.objPnStatDetalji = rez;
        console.log(rez);
      });
    }

  }

  //Odabir člana - start
  odabirClanaStart() {
    document.getElementById('btnMdlOdabirClana').click();

    this.srvClanovi.clanoviByKorisnik(this.mod.objPosJedSelected.PoslJedID, this.mod.session, moment(), (rez: Clan[]) => {
      this.srvNapredak.objClanovi = rez;
    });

  }

  //Novo praćenje - privatni korisnik (samo članovi)
  novoPracenje() {

    //Učitavanje predložaka programa
    this.srvNapredak.pnFillByDopustenje((rez: PnProgram[]) => {
      this.srvNapredak.objProgrami = rez;
      this.srvNapredak.napraviFrmPnVeza(null, () => {

        let clanID: number;
        if (this.srvNapredak.objClanSel) {
          clanID = this.srvNapredak.objClanSel.clanID;
        } else {
          clanID = 0;
        }

        this.srvNapredak.frmPnVeza.get('pnvClanID').setValue(clanID);
        document.getElementById('btnMdlNovoPracenje').click();

      });
    });

  }

  //Uredi praćenje
  urediPracenjeStart(x: VePnVezaByClanID) {
    //Učitavanje predložaka programa
    this.srvNapredak.pnFillByDopustenje((rez: PnProgram[]) => {
      this.srvNapredak.objProgrami = rez;
      this.srvNapredak.napraviFrmPnVeza(x, () => {
        /* this.srvNapredak.frmPnVeza.get('pnvClanID').setValue(0); */
        document.getElementById('btnMdlNovoPracenje').click();
      });
    });
  }

  //Novo mjerenje (praćenje napretka)
  novoMjerenjeStart(x: PnVeza) {

    //Učitavanje parametara za praćenje za odabrani program
    this.srvNapredak.pnStavkeByPnID(x.pnvTemplID, (rez: PnPrgStavka[]) => {

      document.getElementById('btnSidebarClose').click();

      this.srvNapredak.napraviFrmPnMjerenje(x.pnvID, rez, (val) => {

        this.srvNapredak.zadnjeMjerenjeHandle(x.pnvID);

        document.getElementById('btnMdlPnMjerEdit').click();
      });

    });

  }

  //Učitavanje ciljeva po aktivnom programu
  ciljeviByPnvID(pnvID: number) {

    this.srvNapredak.ciljeviByPnvID(pnvID, (rez) => {
      this.srvNapredak.napraviFrmVePnCilj(pnvID, rez, () => {
        document.getElementById('btnMdlCiljeviEdit').click();
      });
    });

  }

  //intAkt switch
  intAktSwitch() {

    this.srvNapredak.objVePnVezaByClanID = null;
    this.srvNapredak.objVePnVezaByClanIDsel = null;
    this.srvNapredak.objPnStat = null;

    this.srvNapredak.intAkt == 0 ? this.srvNapredak.intAkt = 1 : this.srvNapredak.intAkt = 0;

    if (this.mod.objKor.vrstaID == 2 || this.mod.session) {
      //Programi by član ID
      this.srvNapredak.vePnVezaByClanID(this.srvNapredak.objClanSel.clanID, this.srvNapredak.intAkt, (rez: VePnVezaByClanID[]) => {
        this.srvNapredak.objVePnVezaByClanID = rez;

        if (rez) {

          this.srvNapredak.objVePnVezaByClanIDsel = rez[0];

          //Učitavanje mjerenja
          let pnvID: number = this.srvNapredak.objVePnVezaByClanIDsel.pnvID;
          this.srvNapredak.pnStatistikaByClan(this.srvNapredak.objClanSel.clanID, pnvID, null, null, (rez) => {
            this.srvNapredak.objPnStat = rez;
            this.srvNapredak.napuniChartDd();
          });

          //Učitavanje mjerenja - pojedinačno (za uređivanje)
          if (this.srvNapredak.bolPnStatDetalji) {
            this.srvNapredak.pnStatDetalji(pnvID, null, null, (rez: PnStatPojedinacno[]) => {
              this.srvNapredak.objPnStatDetalji = rez;
              console.log(rez);
            });
          }


        }

      });
    }

    if (this.mod.objKor.vrstaID == 1 && !this.mod.session) {
      this.srvNapredak.vePnVezaByKorisnikID(this.srvNapredak.intAkt, (rez: VePnVezaByClanID[]) => {
        this.srvNapredak.objVePnVezaByClanID = rez;

        if (rez) {

          this.srvNapredak.objVePnVezaByClanIDsel = rez[0];

          //Učitavanje mjerenja
          let pnvID: number = this.srvNapredak.objVePnVezaByClanIDsel.pnvID;
          let clanID: number = 0
          this.srvNapredak.pnStatistikaByClan(clanID, pnvID, null, null, (rez) => {
            this.srvNapredak.objPnStat = rez;
            this.srvNapredak.napuniChartDd();
          });

          //Učitavanje mjerenja - pojedinačno (za uređivanje)
          if (this.srvNapredak.bolPnStatDetalji) {
            this.srvNapredak.pnStatDetalji(pnvID, null, null, (rez: PnStatPojedinacno[]) => {
              this.srvNapredak.objPnStatDetalji = rez;
              console.log(rez);
            });
          }


        }

      })
    }

  }

  //Detalji / Uređivanje
  bolDetaljiSwitch() {

    document.getElementById('btnSidebarClose').click();

    this.srvNapredak.bolPnStatDetalji = !this.srvNapredak.bolPnStatDetalji;

    if (this.srvNapredak.bolPnStatDetalji) {

      //Učitavanje mjerenja - pojedinačno (za uređivanje)
      this.srvNapredak.pnStatDetalji(this.srvNapredak.objVePnVezaByClanIDsel.pnvID, null, null, (rez: PnStatPojedinacno[]) => {
        this.srvNapredak.objPnStatDetalji = rez;
        console.log(rez);
      });

    } else {
      this.srvNapredak.objPnStatDetalji = null;
      //Učitavanje mjerenja
      let pnvID: number = this.srvNapredak.objVePnVezaByClanIDsel.pnvID;
      let clanID: number = 0
      this.srvNapredak.pnStatistikaByClan(clanID, pnvID, null, null, (rez) => {
        this.srvNapredak.objPnStat = rez;
        this.srvNapredak.napuniChartDd();
      });

    }

  }

}
