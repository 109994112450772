<div>
    <form *ngIf="srvkorisnici.frmPromjenaLozinke" [formGroup]="srvkorisnici.frmPromjenaLozinke">

        <!--stara-->
        <mat-form-field class="w-100">
            <mat-label>Trenutna lozinka</mat-label>
            <input autocomplete="off" matInput type="password" formControlName="stara" required>
            <mat-error *ngIf="srvkorisnici.frmPromjenaLozinke.controls['stara'].errors?.required">Polje je obavezno za unos
            </mat-error>
            <mat-error *ngIf="srvkorisnici.frmPromjenaLozinke.controls['stara'].errors?.minlength">Minimalno 6 znakova
            </mat-error>
        </mat-form-field>
    
    
        <!--nova-->
        <mat-form-field class="w-100">
            <mat-label>Nova lozinka</mat-label>
            <input autocomplete="off" matInput type="password" formControlName="nova" required>
            <mat-error *ngIf="srvkorisnici.frmPromjenaLozinke.controls['nova'].errors?.required">Polje je obavezno za unos
            </mat-error>
            <mat-error *ngIf="srvkorisnici.frmPromjenaLozinke.controls['nova'].errors?.minlength">Minimalno 6 znakova
            </mat-error>
            <mat-error *ngIf="srvkorisnici.frmPromjenaLozinke.controls['nova'].errors?.mustMatch">Morate upisati identičnu
                vrijednost kao u polju 'Lozinka'</mat-error>
        </mat-form-field>
    
         <!--nova2-->
         <mat-form-field class="w-100">
            <mat-label>Potvrdite novu lozinku</mat-label>
            <input autocomplete="off" matInput type="password" formControlName="nova2" required>
            <mat-error *ngIf="srvkorisnici.frmPromjenaLozinke.controls['nova2'].errors?.required">Polje je obavezno za unos
            </mat-error>
            <mat-error *ngIf="srvkorisnici.frmPromjenaLozinke.controls['nova2'].errors?.minlength">Minimalno 6 znakova
            </mat-error>
            <mat-error *ngIf="srvkorisnici.frmPromjenaLozinke.controls['nova2'].errors?.mustMatch">Morate upisati identičnu
                vrijednost kao u polju 'Lozinka'</mat-error>
        </mat-form-field>
    
    </form>
</div>

<div>

</div>
