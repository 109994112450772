import { Injectable, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControlOptions } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from './dateformats';
import { formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Kor, MjestaRH, ModulService, PosJedinice } from './modul.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as validatori from './validatori';
import { Clanstvo } from './clanstva/clanstva.component';
import { AktivnostiGrp } from './aktivnosti.service';
import { Grupa } from './grupe.service';
import { Dvorana } from './dvorane.service';

//Lista Članstava
export interface ClanstvaList {
  vClansID: number,
  vrstaIDcl: number,
  ClanIDcl: number,
  KlijentIDcl: number,
  pjedIDcl: number,
  PocetakDatum: string,
  intAkt: number
}

//Interface Članovi
export interface Clan {
  clanID: number,
  KlijentIDcl: number,
  Ime: string,
  Prezime: string,
  Adresa: string,
  MjestoID: 0,
  Mjesto: string,
  OIB: string,
  tel: string,
  e_mail: string,
  Napomena: string,
  fitNetID: number
}

export interface VrstaClanstva {
  vID: number,
  opis_vrsta_cl: string
}

export interface VclnMjesto extends Clan, MjestaRH {

}

//Članstvo / Član / Mjesto
export interface VClanstvo extends Clanstvo, Clan, MjestaRH {

}

//Interface Trenutno prisutni
export interface TrenutnoPrisutni {
  trenID: number,
  KlijentIDtren: number,
  PosJedIDtren: number,
  KoriniskIDtren: number,
  clanVrstaIDtren: number,
  GrupaIDtren: number,
  vrijemePrijava: number,
  vrijemeOdjava: number,
  dvoranaIDtren: number
}

//Interface zahtjev za povezivanje član - fitNet
export interface ZahtjevClanFitnet {
  vfcID: number,
  klijentIDvfc: number,
  naziv: string,
  korIDvfc: number,
  clanIDvfc: number,
  statusVfc: number,
  DatumVfc: Moment
}

//vZahtjev Klijent
export interface VzahtjevKorisnik extends ZahtjevClanFitnet, PoslovniKorisnik {

}

//vZahtjev Klijent
export interface VzahtjevKlijent extends ZahtjevClanFitnet, PrivatniKorisnik {

}

//Lista poslovnih korisnika - fitness centri za odabir kod povezivanja
export interface PoslovniKorisnik {
  korisnikID: number,
  Ime: string,
  Adresa: string,
  Broj_poste: number,
  Mjesto: string
}

//Lista privatnih korisnika - za odabir kod povezivanja
export interface PrivatniKorisnik {
  korisnikID: number,
  Ime: string,
  Prezime: string;
  Adresa: string,
  Broj_poste: number,
  Mjesto: string
}

//Filtriranje posjeta by Član
export interface FrmPreglPosjetaClan {
  clanID: number,
  datOd: Moment,
  datDo: Moment,
  fingerprint: string
}

export interface PregledPosjetaClan extends TrenutnoPrisutni, AktivnostiGrp, Grupa, Dvorana {
  treneri: Clan[]
}

//Frm Posjeta Edit
export interface FrmPosjetaEdit {
  trenID: number,
  dOd: Moment,
  hOd: string,
  mOd: string,
  dDo: Moment,
  hDo: string,
  mDo: string,
  GrupaIDtren: number,
  dvoID: number,
  fingerprint: string
}

//Validator - maxH
export function maxH(v: FormControl) {

  if (!isNaN(parseInt(v.value))) {

    if (parseInt(v.value) > 24 || parseInt(v.value) < 0) {
      return { maxMin: true };
    } else {
      return null;
    }

  } else {
    return { notNumb: true };
  }

}

//Validator - maxMin
export function maxMin(v: FormControl) {

  if (!isNaN(parseInt(v.value))) {

    if (parseInt(v.value) > 59 || parseInt(v.value) < 0) {
      return { maxMin: true };
    } else {
      return null;
    }

  } else {
    return { notNumb: true };
  }

}

//Validator - Provjera OIB-a
export function oibChk(v: FormControl) {

  let oib: string = v.value.toString();

  if (oib.length == 0) return null;

  if (oib.length != 11) return { err: 'OIB mora sadržavati 11 znamenki' };

  let b: number = parseInt(oib, 10);
  if (isNaN(b)) return { err: 'Svi znakovi moraju biti numerički' };

  let a: number = 10;
  for (let i = 0; i < 10; i++) {
    a = a + parseInt(oib.substr(i, 1), 10);
    a = a % 10;
    if (a == 0) a = 10;
    a *= 2;
    a = a % 11;
  }

  let kontrolni: number = 11 - a;
  if (kontrolni == 10) kontrolni = 0;

  if (kontrolni == parseInt(oib.substr(10, 1))) {
    return null;
  } else {
    return { err: 'Neispravan OIB' };
  }

}

@Injectable({
  providedIn: 'root',

})
export class ClanoviService {

  constructor(
    private router: Router,
    public mod: ModulService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private frmBuilder: FormBuilder
  ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  //Globalne varijable (popuniti iz modula)
  /* webUrl: string;
  token: string;
  korisnikID: number; */

  //lista članstava
  lstClanstva: ClanstvaList[];

  //Neprijavljeni članovi list
  objNeprijavljeni;
  fltNeprijavljeni;
  strPretraga2: string = '';

  //Članstva / član
  objClanstva;

  //Postavljanje datuma
  minDate; // = new Date(2018, 0, 1);
  //dat1: string = formatDate(new Date(), 'yyyy-MM-dd', 'en-US'); //'2019-07-31';

  //frmPrijava
  frmPrijava: FormGroup;
  frmClan: FormGroup;
  frmClanstvo: FormGroup;

  //Odabrani član - novi / uređivanje
  clanID: number = 0;

  //Prijava člana helpers
  aktZaClana = null;
  tjPosjeta = 0;
  clanarineByClan = null;

  displayedColumns: string[] = ['Ime', 'Prezime', 'Adresa', 'Mjesto'];
  dataSource: MatTableDataSource<Clan>;

  //Član/mjesto by član ID
  clanByClanId(clanID: number, callback) {

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=clanByClanId';
    let obj: string = JSON.stringify({ clanID: clanID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VclnMjesto) => {
      callback(rez);
    });

  }

  //Članstva by fitNet ID / klijent ID
  objVClanstva: VClanstvo[];

  //Članstva by fitNet ID
  clanstvaByFitnetId(fitNetID: number, KlijentIDcl: number, callback) {

    let now: string = moment().format('YYYY-MM-DD HH:mm:ss');

    let url: string = this.mod.webUrl + '/php/clanstva.php?token=' + this.mod.objKor.token + '&funk=clanstvaByFitnetId';
    let obj: string = JSON.stringify({ now: now, fitNetID: fitNetID, KlijentIDcl: KlijentIDcl, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez) => {
      callback(rez);
    });

  }

  //Članstva by Klijent ID
  clanstvaByKlijentId(KlijentIDcl: number, ClanIDcl: number, callback) {

    let now: string = moment().format('YYYY-MM-DD HH:mm:ss');

    let url: string = this.mod.webUrl + '/php/clanstva.php?token=' + this.mod.objKor.token + '&funk=clanstvaByKlijentId';
    let obj: string = JSON.stringify({ now: now, KlijentIDcl: KlijentIDcl, ClanIDcl: ClanIDcl, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez) => {
      callback(rez);
    });

  }

  //Material dataSource Članovi
  ucitajMatDataSource(pJed: number) {

    let urlTmp: string = this.mod.webUrl + '/php/prisutni.php?token=' + this.mod.objKor.token + '&pjedID=' + pJed + '&funk=odabirClana';
    let obj: string = JSON.stringify({ fingerprint: this.mod.fingerprint() });

    this.http.post(urlTmp, obj).subscribe((rez: Clan[]) => {

      this.dataSource = new MatTableDataSource<Clan>(rez);

    });

  }

  //Članovi fillByKorisnikID
  clanoviByKorisnikID(callback) {

    let urlTmp: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=byKorisnikID';

    let session = this.mod.session;

    let obj: string = JSON.stringify({ session: session, fingerprint: this.mod.fingerprint() });

    this.http.post(urlTmp, obj).subscribe(rez => {

      callback(rez);

    });

  }

  //poslovne jedinice ba trener / operater
  posjedByTrenOper(klijentID: number, ClanIDcl: number, vrstaID: number, callback) {

    let url: string = this.mod.webUrl + '/php/posl_jed.php?token=' + this.mod.objKor.token + '&funk=posjedByTrenOper';
    let obj: string = JSON.stringify({ KlijentIDpos: klijentID, vrstaIDcl: vrstaID, ClanIDcl: ClanIDcl, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: PosJedinice[]) => {
      callback(rez);
    });

  }

  //poslovne jedinice by fizička osoba - općenito (član, trener, operater)
  posjedByFizickaOsoba(aktivna: number, callback) {

    let url: string = this.mod.webUrl + '/php/posl_jed.php?token=' + this.mod.objKor.token + '&funk=posjedByFizickaOsoba';
    let obj: string = JSON.stringify({ aktivna: aktivna, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: PosJedinice[]) => {
      callback(rez);
    });

  }

  //frmClan
  napraviFrmClan() {

    this.frmClan = this.formBuilder.group({

      clanID: [0],
      KlijentIDcl: [this.mod.objKor.korisnikID],
      Ime: ['', [Validators.required]],
      Prezime: [''],
      Adresa: [''],
      MjestoID: [0],
      Mjesto: [''],
      OIB: ['', oibChk],
      tel: [''],
      e_mail: [''],
      Napomena: [''],
      fitNetID: [0]

    });

    return this.frmClan;

  }

  //Postavi frmClanovi
  postaviFrmClanovi(clID: number, callback) {

    //this.clanID = clID;

    this.napraviFrmClan();


    if (clID > 0) {

      //Uređivanje
      let urlTmp: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=odabirID&clID=' + clID;
      let obj: string = JSON.stringify({ fingerprint: this.mod.fingerprint() })

      this.http.post(urlTmp, obj).subscribe(rez => {
        this.frmClan.patchValue(rez);

      });

    } else {

      //Novi unos
      //alert('frm korID = ' + this.frmClan.controls['KlijentIDcl'].value);
      //this.frmClan.controls['KlijentIDcl'].setValue(this.mod.objKor.korisnikID);

    }

    callback(this.frmClan.value);

  }

  //frmClanstvo
  napraviFrmClanstvo(callback) {

    let kl: number = Number(this.mod.objPosJedSelected.KlijentIDpos);
    this.frmClanstvo = this.formBuilder.group({

      vClansID: [0],
      vrstaIDcl: [, Validators.required],
      ClanIDcl: [this.clanID],
      KlijentIDcl: [kl],
      pjedIDcl: [this.mod.objPosJedSelected.PoslJedID, Validators.required],
      PocetakDatum: [, Validators.required],
      KrajDatum: [],
      intAkt: [1]

    }, {
      validator: validatori.datumClanstva
    });

    if (!this.clanID) {
      this.frmClanstvo.get('vrstaIDcl').setValue('1');
      this.frmClanstvo.get('PocetakDatum').setValue(moment());
      //this.frmClanstvo.get('PocetakDatum').setValue(moment());
    }

    callback();

  }

  //Član - dostupne poslovne jedinice po vrsti članstva (član, trener, operater)
  clDostupnePj(ClanIDcl: number, KlijentIDcl: number, clanVrstaIDtren: number, callback) {

    let url: string = this.mod.webUrl + '/php/clanstva.php?token=' + this.mod.objKor.token + '&funk=clDostupnePj';
    let obj: string = JSON.stringify({ ClanIDcl: ClanIDcl, KlijentIDcl: KlijentIDcl, clanVrstaIDtren, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe(rez => {
      callback(rez);
    });

  }

  ucitajClanstvaList() {

    let urlTmp: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=clanstva';
    let obj: string = JSON.stringify({ fingerprint: this.mod.fingerprint() });

    this.http.post(urlTmp, obj).subscribe((rez: ClanstvaList[]) => {
      this.lstClanstva = rez;
    });

  }

  //UČITAJ NEPRIJAVLJENE
  ucitajNeprijavljene(bolTreneri: boolean, pJed: number, callback) {

    let now: string = moment().format('YYYY-MM-DD HH:mm:ss');
    let urlTmp: string = this.mod.webUrl + '/php/prisutni.php?token=' + this.mod.objKor.token + '&pjedID=' + pJed + '&funk=odabirClana';
    let obj: string = JSON.stringify({ now: now, fingerprint: this.mod.fingerprint() });

    this.http.post(urlTmp, obj).subscribe((rez: Clan[]) => {

      this.objNeprijavljeni = rez;
      this.fltNeprijavljeni = rez;
      this.filtrirajNeprijavljene(bolTreneri, this.strPretraga2);

      callback(rez);

    });

  }

  //FILTRIRANJE NEPRIJAVLJENI
  filtrirajNeprijavljene(bolTreneri: boolean, strP: string) {
    if (this.objNeprijavljeni) {
      this.fltNeprijavljeni = this.objNeprijavljeni.filter(s =>
        (s.Ime.toLowerCase().includes(strP.toLowerCase()) || s.Prezime.toLowerCase().includes(strP.toLowerCase())) && (bolTreneri ? true : s.vrstaIDcl == 1)
        /* if (!bolTreneri) {
          s.vrstaIDcl == '1';
        } */
      );
    } else {
      this.fltNeprijavljeni = null;
    }

  }

  //frm Prijava
  napraviFrmPrijava(callback) {

    this.frmPrijava = this.formBuilder.group({

      KlijentIDtren: [0],
      PosJedIDtren: [0],
      KoriniskIDtren: [0],
      vrstaIDcl: [1],
      ime: ['', [Validators.required]],
      priDat: [moment(), [Validators.required]],
      priH: [0, [Validators.required, maxH]],
      priMin: [0, [Validators.required, maxMin]],
      dvoranaIDtren: ['0']

    });

    callback();

    return this.frmPrijava;

  }

  //Postavi početne vrijednosti frmPrijava
  postaviFrmPrijava(pjedID: number, clanID: number, strTren: string, vrstaIDcl: number, callback) {

    //document.getElementById('btnModalClanLogin').click();

    let klID: number;
    if (this.mod.session) {
      klID = this.mod.session.KlijentIDtren;
    } else {
      klID = this.mod.objKor.korisnikID;
    }

    this.frmPrijava.controls['KlijentIDtren'].setValue(klID);
    this.frmPrijava.controls['PosJedIDtren'].setValue(pjedID);
    this.frmPrijava.controls['KoriniskIDtren'].setValue(clanID);
    this.frmPrijava.controls['vrstaIDcl'].setValue(vrstaIDcl);
    this.frmPrijava.controls['ime'].setValue(strTren);
    this.frmPrijava.controls['priDat'].setValue(moment());
    this.frmPrijava.controls['priH'].setValue(formatDate(new Date(), 'HH', 'en-US'));
    this.frmPrijava.controls['priMin'].setValue(formatDate(new Date(), 'mm', 'en-US'));

    callback(this.frmPrijava.value);

  }

  //PRIJAVA ČLANA
  prijaviClana() {

    if (this.frmPrijava.valid) {

      //Formatiranje Datum/Vrijeme za slanje http
      let d1Tmp: Date = new Date(this.frmPrijava.controls['priDat'].value);

      let godTmp: number = d1Tmp.getFullYear();
      let mjTmp: number = d1Tmp.getMonth();
      let danTmp: number = d1Tmp.getDate();
      let hTmp: number = this.frmPrijava.controls['priH'].value;
      let minTmp: number = this.frmPrijava.controls['priMin'].value;

      let dTmp: string = formatDate(new Date(godTmp, mjTmp, danTmp, hTmp, minTmp, 0), 'yyyy-MM-dd HH:mm:ss', 'en-US');

      //JSON body
      let phpBody = {

        KlijentIDtren: this.frmPrijava.controls['KlijentIDtren'].value,
        PosJedIDtren: this.frmPrijava.controls['PosJedIDtren'].value,
        KoriniskIDtren: this.frmPrijava.controls['KoriniskIDtren'].value,
        vrstaIDcl: this.frmPrijava.controls['vrstaIDcl'].value,
        vrijemePrijava: dTmp,
        dvoranaIDtren: this.frmPrijava.controls['dvoranaIDtren'].value,
        fingerprint: this.mod.fingerprint()
      }

      //www. url
      let urlTmp: string = this.mod.webUrl + '/php/prisutni.php?token=' + this.mod.objKor.token + '&funk=prijava';

      //http.get
      this.http.post(urlTmp, phpBody).subscribe(rez => {

        if (rez && rez['rez'] == 'ok') {

          if (rez['session']) {
            localStorage.setItem('session', rez['session']);
          }

          document.getElementById('btnPrijavaClose').click();

          this.mod.msgBox(1, "Član je uspješno prijavljen!", 2000);

        } else {

          this.mod.msgBox(2, "Greška prilikom prijave!", 3000);

        }

      });

    }

  }

  //Prijava u grupu (glavni modal za prijavu)
  prijavaGrupa(obj0, callback) {

    obj0['fingerprint'] = this.mod.fingerprint();

    let urlTmp: string = this.mod.webUrl + '/php/prisutni.php?token=' + this.mod.objKor.token + '&funk=prijavaGrupa';
    let obj: string = JSON.stringify(obj0);

    this.http.post(urlTmp, obj).subscribe(rez => {
      callback(rez);
    });

  }

  //Članstva - član
  ucitajClanstva(clID: number) {

    let urlTmp = this.mod.webUrl + "/php/clanovi.php?token=" + this.mod.objKor.token + '&funk=clanstvaClan&clID=' + clID + '&pjedID=' + this.mod.objPosJedSelected.PoslJedID;
    let obj: string = JSON.stringify({ fingerprint: this.mod.fingerprint() });

    this.http.post(urlTmp, obj).subscribe(rez => {
      this.objClanstva = rez;
    });

  }

  //Format Datuma
  formatirajDatum(datum) {
    return formatDate(new Date(datum), 'yyyy-MM-dd', 'en-US'); //'2019-07-31';
  }

  //Snackbar Alert
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  //Spremi promjene - Član
  spremiClan(clID: number, callback) {

    this.frmClan.markAllAsTouched();
    this.frmClanstvo.markAllAsTouched();

    if (this.frmClan.valid && (this.frmClanstvo.valid || this.clanID != 0)) {

      let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=update';

      let objTmp = this.frmClan.value;

      this.frmClanstvo.controls['PocetakDatum'].setValue(moment(this.frmClanstvo.controls['PocetakDatum'].value).format('YYYY-MM-DD HH:mm:ss'));

      objTmp.clanstvo = this.frmClanstvo.value;

      objTmp['fingerprint'] = this.mod.fingerprint();

      let objTmpStr = JSON.stringify(objTmp);

      this.http.post(url, objTmpStr).subscribe((rez: Clan) => {

        if (rez) {
          this.frmClan.patchValue(rez);
          localStorage.clanID = this.frmClan.controls['clanID'].value;
          this.clanID = this.frmClan.controls['clanID'].value;

          this.mod.msgBox(1, "Podaci su uspješno pohranjeni!", 2000);

          this.ucitajClanstva(this.clanID);

        } else {
          this.mod.msgBox(2, "Greška prilikom spremanja!", 3000);
        }

        callback(rez);

      })
    } else {
      this.mod.msgBox(2, "Popunite ispravno polja obavezna za unos!", 3000);
    }

  }

  //Spremi clanstvo
  spremiClanstvo(obj0, callback) {

    if (obj0.PocetakDatum) {
      obj0.PocetakDatum = moment(obj0.PocetakDatum).format('YYYY-MM-DD');
    }

    if (obj0.KrajDatum) {
      obj0.KrajDatum = moment(obj0.KrajDatum).format('YYYY-MM-DD');
    }

    let url: string = this.mod.webUrl + '/php/clanstva.php?token=' + this.mod.objKor.token + '&funk=spremiClanstvo';

    obj0['fingerprint'] = this.mod.fingerprint();

    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez: Clanstvo) => {
      callback(rez);
    });

  }

  //Povjera odobrenja za spremanje (trener - operater)
  korOdobrenje: number;
  provjeriOdobrenje(): boolean {

    if (localStorage.getItem('korOdobrenje')) {
      this.korOdobrenje = parseInt(localStorage.getItem('korOdobrenje'));

      if (this.korOdobrenje > 0) {
        let bTmp: boolean
        if (this.objClanstva) {
          this.objClanstva.forEach((val: Clanstvo) => {
            if (val.pjedIDcl == this.korOdobrenje || val.pjedIDcl == 0) {
              bTmp = true;
            }

          });
        } else {
          bTmp = false;
        }

        return bTmp;

      } else {
        return true; //sve poslovne jedinice
      }

    } else {
      return false;
    }

  }

  //frmOdjava trener / operater
  frmOdjava: FormGroup;

  napraviFrmOdjava(callback) {

    /* let session: TrenutnoPrisutni;
    session = JSON.parse(localStorage.getItem('session')); */

    let tNow: Moment = moment();

    this.frmOdjava = this.frmBuilder.group({
      trenID: [this.mod.session.trenID],
      ime: [this.mod.objKor.Ime + ' ' + this.mod.objKor.Prezime,],
      vrijemePrijava: [this.mod.session.vrijemePrijava],
      odjDat: [tNow.format('YYYY-MM-DD'), [Validators.required]],
      odjH: [tNow.format('HH'), [Validators.required, validatori.maxH, Validators.minLength(2), Validators.maxLength(2)]],
      odjMin: [tNow.format('mm'), [Validators.required, validatori.maxMin, Validators.minLength(2), Validators.maxLength(2)]]
    }, {
      validator: validatori.VrijemeOdjava
    });

    callback(this.frmOdjava.value);

  }

  //frm Odjava - član  QR
  napraviFrmOdjavaQr(trenID: number, imePrezime: string, vrijemePrijava: Moment, callback) {

    /* let session: TrenutnoPrisutni;
    session = JSON.parse(localStorage.getItem('session')); */

    let tNow: Moment = moment();

    this.frmOdjava = this.frmBuilder.group({
      trenID: [trenID],
      ime: [imePrezime],
      vrijemePrijava: [vrijemePrijava],
      odjDat: [tNow.toDate(), [Validators.required]],
      odjH: [tNow.format('HH'), [Validators.required, validatori.maxH, Validators.minLength(2), Validators.maxLength(2)]],
      odjMin: [tNow.format('mm'), [Validators.required, validatori.maxMin, Validators.minLength(2), Validators.maxLength(2)]]
    }, {
      validator: validatori.VrijemeOdjava
    });

    callback(this.frmOdjava.value);

  }

  //Odjava End Treenr / Operater
  odjavaTrenOperEnd() {

    this.frmOdjava.markAllAsTouched();

    if (this.frmOdjava.valid) {

      let datum: string = moment(this.frmOdjava.get('odjDat').value).format('YYYY-MM-DD');
      let sati: string = moment(this.frmOdjava.get('odjH').value, 'HH').format('HH');
      let min: string = moment(this.frmOdjava.get('odjMin').value, 'mm').format('mm');

      let trenID: number = this.frmOdjava.get('trenID').value;
      let vrijemeOdj: string = datum + ' ' + sati + ':' + min + ':00';

      this.odjavaTrenOper(trenID, vrijemeOdj, (rez) => {

        if (rez == 'ok') {

          this.mod.objPosJedSelected = null;
          this.mod.session = null;
          localStorage.removeItem('objPosJedSelected');
          localStorage.removeItem('session');

          document.getElementById('btnMdlOdjavaTrenOper').click();

          this.router.navigate['cpanel1'];

        } else {
          //error
        }

      });

    } else {
      //invalid form

    }



  }

  //Odjava trenera / operatera
  odjavaTrenOper(trenID: number, vrijemeOdjava: string, callback) {

    if (!this.mod.objPosJed && this.mod.objPosJedSelected) {

      let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=odjavaTrenOper';

      let obj0 = {
        trenID: trenID,
        vrijemeOdjava: vrijemeOdjava,
        fingerprint: this.mod.fingerprint()
      }

      let obj = JSON.stringify(obj0);

      this.http.post(url, obj).subscribe((rez) => {
        callback(rez);
      });

    }

  }

  trajanjePosjeta() {

    /* //Angular / Javascript
     let d1 = +new Date(this.frmOdjava.controls['vrijemePrijava'].value);
     let d2 = +new Date(this.frmOdjava.controls['odjDat'].value + 'T' + this.frmOdjava.controls['odjH'].value + ':' + this.frmOdjava.controls['odjMin'].value + ':00');
     let diff: number = -(d1 - d2) / 1000 / 60; //Ukupno min
    */

    //Moment.js
    let dd1 = moment(this.frmOdjava.controls['vrijemePrijava'].value);
    let d012 = moment(this.frmOdjava.controls['odjDat'].value).format('YYYY-MM-DD');
    let dd2 = moment(d012 + 'T' + this.frmOdjava.controls['odjH'].value + ':' + this.frmOdjava.controls['odjMin'].value + ':00');

    //alert(dd2);

    let diff: number = -dd1.diff(dd2, 'minutes');

    let rezDana: number = Math.floor(diff / 1440); //Dana

    let rezH: number = Math.floor((diff - (rezDana * 1440)) / 60); //Sati

    let rezMin: number = diff - (rezDana * 1440) - (rezH * 60); //Minuta

    if (isNaN(rezDana) || isNaN(rezH) || isNaN(rezMin)) {
      return

    } else {

      if (rezDana <= 0) {

        if (rezH <= 0) {
          return rezMin + 'min';
        } else {
          return rezH + 'h ' + rezMin + 'min';
        }

      } else {

        if (rezDana == 1) {
          return rezDana + 'dan ' + rezH + 'h ' + rezMin + 'min';
        } else {
          return rezDana + 'dana ' + rezH + 'h ' + rezMin + 'min';
        }

      }

    }
  }

  //Zahtjev za povezivanje član - fitnet
  zahtjevClanFitnet(obj0: ZahtjevClanFitnet, callback) {

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=zahtjevClanFitnet';
    obj0['fingerprint'] = this.mod.fingerprint();
    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez) => {
      callback(rez);
    });

  }

  frmZahtjevClFit: FormGroup

  napraviFrmZahtjevClFit(val: ZahtjevClanFitnet, callback) {

    this.frmZahtjevClFit = this.frmBuilder.group({
      vfcID: [val ? val.vfcID : 0],
      klijentIDvfc: [val ? val.klijentIDvfc : 0],
      naziv: [val ? val.naziv : null, Validators.required],
      nazivChk: [val ? val.naziv : null],
      korIDvfc: [val ? val.korIDvfc : 0],
      clanIDvfc: [val ? val.clanIDvfc : 0],
      statusVfc: [val ? val.statusVfc : 0],
      DatumVfc: [val ? val.DatumVfc : null],
      fingerprint: [this.mod.fingerprint()]

    }, { validator: validatori.MustMatch('naziv', 'nazivChk') } as AbstractControlOptions);

    //Listener za naziv centra autocomplete
    /* this.frmZahtjevClFit.get('naziv').valueChanges.subscribe(value => {

      if (value.length >= 1) {
        this.filtrirajPosKor(value);
      } else {
        this.fltPoslovniKor = this.objPoslovniKor;
      }

    }); */

    callback(this.frmZahtjevClFit)

  }

  //FILTRIRANJE poslovnih korisnika
  strPretragaPosl: string;
  filtrirajPosKor(strP: string) {
    this.fltPoslovniKor = [];

    if (strP) {
      this.objPoslovniKor.forEach((val: PoslovniKorisnik) => {
        if (val.Ime.toLowerCase().includes(strP?.toLowerCase()) || val.Adresa?.toLowerCase().includes(strP?.toLowerCase()) || val.Mjesto?.toLowerCase().includes(strP?.toLowerCase())) {
          this.fltPoslovniKor.push(val);
        }
      });
    } else {
      this.fltPoslovniKor = this.objPoslovniKor;
    }


  }

  objPoslovniKor: PoslovniKorisnik[];
  fltPoslovniKor: PoslovniKorisnik[];

  //Lista poslovnih korisnika
  lstPoslovniKorisnici(callback) {

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=lstPoslovniKorisnici';
    let obj: string = JSON.stringify({ fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: PoslovniKorisnik[]) => {
      /* this.fltPoslovniKor = rez; */
      callback(rez);
    });

  }

  //Odabir fitness centra za slanje zahtjeva
  postaviZahtjevCentar(x: PoslovniKorisnik) {

    this.frmZahtjevClFit.get('klijentIDvfc').setValue(x.korisnikID);
    this.frmZahtjevClFit.get('naziv').setValue(x.Ime);
    this.frmZahtjevClFit.get('nazivChk').setValue(x.Ime);

  }

  //Slanje zahtjeva za povezivanje
  posaljiZahtjev() {

    this.frmZahtjevClFit.markAllAsTouched();

    if (this.frmZahtjevClFit.valid) {

      let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=zahtjevClanFitnet';
      let obj: string = JSON.stringify(this.frmZahtjevClFit.value);

      this.http.post(url, obj).subscribe((rez: VzahtjevKorisnik) => {

        //Update objekta nepotvrđeni zahtjevi
        if (!this.objNepotZahtjevi) {
          this.objNepotZahtjevi = [];
        }
        this.objNepotZahtjevi.push(rez);

        //učitavanje svega
        this.lstPoslovniKorisnici((rezPk) => {
          if (rezPk) {

            this.objPoslovniKor = rezPk;
            this.filtrirajPosKor(this.strPretragaPosl);

          } else {
            this.objPoslovniKor = null;
            this.fltPoslovniKor = null;
            this.mod.msgBox(2, "Greška prilikom učitavanja fitness centara!", 3000);
          }

        });

        document.getElementById('btnMdlZahtjevClFit').click();

        this.mod.msgBox(1, "Zahtjev je uspješno poslan!", 3000);
      });

    } else {
      this.mod.msgBox(2, "Popunite ispravno sva polja", 3000);
    }

  }

  //Lista nepotvrđenih zahtjeva by korisnikID (privatni)
  objNepotZahtjevi: VzahtjevKorisnik[];

  //Lista nepotvrđenih zahtjeva by klijentID (poslovni)
  objNepotZahtjeviKl: VzahtjevKlijent[];

  nepZahtjevByKorId(korIDvfc: number, callback) {

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=nepZahtjevByKorId';
    let obj: string = JSON.stringify({ korIDvfc: korIDvfc, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VzahtjevKorisnik[]) => {
      callback(rez);
    });

  }

  //Lista nepotvrđenih zahtjeva by Klijent ID (poslovni)
  nepZahtjevByKlijentId(klijentIDvfc: number, callback) {

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=nepZahtjevByKlijentId';
    let obj: string = JSON.stringify({ klijentIDvfc: klijentIDvfc, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VzahtjevKlijent[]) => {
      callback(rez);
    });

  }

  trajanjePosjetaParams(d1: Moment) {

    //Moment.js
    let dd1 = moment(d1);
    let dd2 = moment();

    let diff: number = -dd1.diff(dd2, 'minutes');


    let rezDana: number = Math.floor(diff / 1440); //Dana

    let rezH: number = Math.floor((diff - (rezDana * 1440)) / 60); //Sati

    let rezMin: number = diff - (rezDana * 1440) - (rezH * 60); //Minuta

    if (isNaN(rezDana) || isNaN(rezH) || isNaN(rezMin)) {
      return

    } else {

      if (rezDana <= 0) {

        if (rezH <= 0) {
          return rezMin + ' min';
        } else {
          return rezH + ' h ' + rezMin + ' min';
        }

      } else {

        if (rezDana == 1) {
          return rezDana + ' dan ' + rezH + ' h ' + rezMin + ' min';
        } else {
          return rezDana + ' dana ' + rezH + ' h ' + rezMin + ' min';
        }

      }

    }
  }

  //Razlika u vremenu
  trajanjePosjetaParamsDo(d1: Moment) {

    //Moment.js
    let dd1 = moment();
    let dd2 = moment(d1);

    let diff: number = -dd1.diff(dd2, 'minutes');


    let rezDana: number = Math.floor(diff / 1440); //Dana

    let rezH: number = Math.floor((diff - (rezDana * 1440)) / 60); //Sati

    let rezMin: number = diff - (rezDana * 1440) - (rezH * 60) + 1; //Minuta

    if (isNaN(rezDana) || isNaN(rezH) || isNaN(rezMin)) {
      return

    } else {

      if (rezDana <= 0) {

        if (rezH <= 0) {
          return rezMin + ' min';
        } else {
          return rezH + ' h ' + rezMin + ' min';
        }

      } else {

        if (rezDana == 1) {
          return rezDana + ' dan ' + rezH + ' h ' + rezMin + ' min';
        } else {
          return rezDana + ' dana ' + rezH + ' h ' + rezMin + ' min';
        }

      }

    }
  }

  //trajanje posjeta HH mm
  trajanjePosjetaHmm(d1: Moment, d2: Moment) {

    d1 = moment(d1);
    d2 = moment(d2);

    let diff: number = -d1.diff(d2, 'minutes');

    let rezH: number;
    let rezMin: number;

    if (diff > 60) {
      rezH = Math.floor(diff / 60);
      rezMin = diff - (rezH * 60);
      return rezH + 'h ' + rezMin + 'min';
    } else {
      rezMin = diff;
      return rezMin + 'min';
    }

  }

  objClanovi;
  fltClanovi;

  objClanoviZahtjev: Clan[];
  fltClanoviZahtjev: Clan[];

  //Svi članovi by Klijent ID
  clanoviByKlID(KlijentIDcl: number, callback) {

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=clanoviByKlID';
    let obj: string = JSON.stringify({ KlijentIDcl: KlijentIDcl, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez) => {
      callback(rez);
    });

  }

  //frm Povezivanje člana
  frmPovez: FormGroup;
  napraviFrmPovez(vfcID: number, clanIDvfc: number, callback) {

    this.frmPovez = this.frmBuilder.group({
      vfcID: [vfcID],
      clanIDvfc: [clanIDvfc]
    });

    callback(this.frmPovez.value);

  }

  //Poveži zahtjev - član frmPovez [vfcID, clanIDvfc] 
  poveziZahtjev(obj0, callback) {

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=poveziZahtjev';
    obj0['fingerprint'] = this.mod.fingerprint();
    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez) => {
      callback(rez);
    });

  }

  //Treneri u poslovnoj jedinici
  treneriByPosJed(pjedIDcl: number, KlijentIDcl: number, callback) {

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=treneriByPosJed';
    let now: string = moment().format('YYYY-MM-DD HH:mm:ss')
    let obj: string = JSON.stringify({ pjedIDcl: pjedIDcl, KlijentIDcl: KlijentIDcl, now: now, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: Clan[]) => {
      callback(rez);
    });

  }


  //Dnevni termini za člana radi eventualne prijave u grupu
  terminiZaClana(clanID: number, pjedID: number, datVrijeme: Moment, callback) {

    let datVrijeme2: string = moment(datVrijeme).format('YYYY-MM-DD HH:mm:ss');

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=terminiZaClana';
    let obj: string = JSON.stringify({ clanID: clanID, pjedID: pjedID, datVrijeme: datVrijeme2, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez) => {
      callback(rez);
    });

  }


  //Broj tjednih posjeta člana
  tjednihPosjeta(KoriniskIDtren: number, clanVrstaIDtren: number, PosJedIDtren: number, datum: Moment, callback) {

    let datum2: string = moment(datum).format('YYYY-MM-DD');

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=tjednihPosjeta';
    let obj: string = JSON.stringify({ KoriniskIDtren: KoriniskIDtren, clanVrstaIDtren: clanVrstaIDtren, PosJedIDtren: PosJedIDtren, datum: datum2, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: number) => {
      callback(Number(rez));
    });

  }

  //Uređivanje posjeta
  bolUredPosjeta: boolean = false;

  //Frm pregled posjeta by član
  frmPreglPosjetaClan: FormGroup;
  napraviFrmPreglPosjetaClan(val: FrmPreglPosjetaClan, callback) {

    this.frmPreglPosjetaClan = this.frmBuilder.group({
      clanID: [0],
      datOd: [moment()],
      datDo: [moment()],
      fingerprint: this.mod.fingerprint()
    });

    if (val) {
      this.frmPreglPosjetaClan.patchValue(val);
    }

    callback(this.frmPreglPosjetaClan.value);

  }

  //Pregled posjeta by Član
  objPregldPosjetaClan: PregledPosjetaClan[];
  rekapitulacija;
  rekapSum = { count: 0, vrijeme: 0 };

  pregledPosjetaByClan(obj0: FrmPreglPosjetaClan, callback) {

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=pregledPosjetaByClan';
    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez: PregledPosjetaClan[]) => {
      callback(rez);
    });

  }

  //Pregled posjeta by fitNet ID
  bolPojedinacno: boolean = false;
  pregledPosjetaByFitNetID(obj0, callback) {

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=pregledPosjetaByFitNetID';
    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez: PregledPosjetaClan[]) => {
      callback(rez);
    });

  }

  //Uređivanje posjete (trenutno prisutni)
  frmPosjetaEdit: FormGroup;
  objDvorane: Dvorana[];
  bolBrisanje: boolean = false;
  napraviFrmPosjetaEdit(val: FrmPosjetaEdit, callback) {

    this.frmPosjetaEdit = this.frmBuilder.group({
      trenID: [0],
      dOd: [, [Validators.required]],
      hOd: [, [Validators.required, validatori.maxH]],
      mOd: [, [Validators.required, validatori.maxMin]],
      dDo: [, [Validators.required]],
      hDo: [, [Validators.required, validatori.maxH]],
      mDo: [, [Validators.required, validatori.maxMin]],
      GrupaIDtren: [0],
      dvoID: [0],
      fingerprint: [this.mod.fingerprint()]
    }, {
      validator: validatori.VrijemePrijavaOdjava
    });

    if (val) {
      this.frmPosjetaEdit.patchValue(val);
    }

    callback(this.frmPosjetaEdit.value);

  }

  //Spremi posjetu
  spremiPosjetu(obj0: FrmPosjetaEdit, callback) {

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=spremiPosjetu';
    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez: TrenutnoPrisutni) => {
      callback(rez);
    });

  }

  //Članovi by korisnik
  clanoviByKorisnik(PoslJedID: number, session, datum: Moment, callback) {

    let obj0 = {
      PoslJedID: PoslJedID,
      session: session,
      datum: datum,
      fingerprint: this.mod.fingerprint()
    }

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=clanoviByKorisnik';
    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez: Clan[]) => {
      callback(rez);
    });

  }

}
