<form *ngIf="srvClanovi.frmPreglPosjetaClan" [formGroup]="srvClanovi.frmPreglPosjetaClan">


    <!--Datum Od-->
    <mat-form-field style="width: 100%; text-align: center;">
        <input autocomplete="off" matInput formControlName="datOd" [matDatepicker]="picker1" placeholder="Datum od">
        <mat-error *ngIf="srvClanovi.frmPreglPosjetaClan.get('datOd').errors?.required"
            style="font-size: 1rem;">Obavezan unos
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker1></mat-datepicker>
    </mat-form-field>

    <!--Datum Do-->
    <mat-form-field style="width: 100%; text-align: center;">
        <input autocomplete="off" matInput formControlName="datDo" [matDatepicker]="picker2" placeholder="Datum do">
        <mat-error *ngIf="srvClanovi.frmPreglPosjetaClan.get('datDo').errors?.required"
            style="font-size: 1rem;">Obavezan unos
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker2></mat-datepicker>
    </mat-form-field>

    <div style="margin-top: 10px;">
        <button type="button" class="btn mybtn-cancel w-100" (click)="pregledPosjetaByClan()">Prikaži</button>
    </div>

</form>

<div style="padding: 5px; margin-top: 15px;">

    <div *ngFor="let x of this.srvClanovi.rekapitulacija" style="border-bottom: solid 0.5px lightgray;">

        <div style="font-weight: bold;">
            {{x.grpOpis}}
        </div>
        <div>
            broj posjeta: {{x.count}}
        </div>
        <div>
            trajanje: {{x.vrijeme | minToHHHmm}}
        </div>

    </div>

    <div *ngIf="this.srvClanovi.rekapitulacija?.length > 1" style="margin-top: 10px;">
        <div>
            Ukupno posjeta: {{this.srvClanovi.rekapSum.count}}
        </div>
        <div>
            Ukupno trajanje: {{this.srvClanovi.rekapSum.vrijeme | minToHHHmm}}
        </div>
    </div>

</div>

<div *ngIf="srvClanovi.objPregldPosjetaClan" style="margin-top: 20px;">
    <button *ngIf="!srvClanovi.bolUredPosjeta" type="button" class="btn mybtn-success w-100" (click)="srvClanovi.bolUredPosjeta = !srvClanovi.bolUredPosjeta"><i class="fa fa-lock" aria-hidden="true" style="margin-right: 10px;"></i>Otkučaj uređivanje</button>
    <button *ngIf="srvClanovi.bolUredPosjeta" type="button" class="btn mybtn-danger w-100" (click)="srvClanovi.bolUredPosjeta = !srvClanovi.bolUredPosjeta"><i class="fa fa-unlock" aria-hidden="true" style="margin-right: 10px;"></i>Zaključaj uređivanje</button>
</div>

<div *ngIf="srvClanovi.bolUredPosjeta" style="margin-top: 10px; padding: 2px;">
    <div class="alert alert-warning">
        Sve promjene koje ovdje napravite su vidljive članu u njegovim statistikama. Ukoliko obrišete posjetu, ona će biti trajno obrisana iz evidencije, stoga budite oprezni prilikom korištenja opcije i koristite ju samo, ako trebate ispraviti pogrešan unos.
    </div>
</div>