import { Component } from '@angular/core';
import { KalendarService } from '../kalendar.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { AktivnostiService } from '../aktivnosti.service';


@Component({
  selector: 'app-aktivnost-edit',
  standalone: true,
  imports: [ FormsModule, ReactiveFormsModule, MatFormField, MatOption, NgIf, NgFor, MatSelect, MatInput, MatLabel],
  templateUrl: './aktivnost-edit.component.html',
  styleUrl: './aktivnost-edit.component.scss'
})
export class AktivnostEditComponent {

  constructor(
    public srvKalendar: KalendarService,
    public srvAktivnosti: AktivnostiService
  ){}

  //Dodavanje trenera u termin
  dodajTreneraUakt(aktGrpID: number, clanID: number) {

    this.srvAktivnosti.dodajTreneraUakt(aktGrpID, clanID, () => {
      this.srvAktivnosti.treneriByAktIdEdit(aktGrpID, (rez) => {
        this.srvAktivnosti.objTreneriByAktID = rez;
      });
    });

  }

  //Brisanje trenera iz aktivnosti
  obrisiTreneraAkt(vtaID: number, aktGrpID: number) {

    this.srvAktivnosti.obrisiTreneraAkt(vtaID, () => {
      this.srvAktivnosti.treneriByAktIdEdit(aktGrpID, (rez) => {
        this.srvAktivnosti.objTreneriByAktID = rez;
      });
    });

  }



}
