import { trigger, state, style, animate, transition, query, stagger } from '@angular/animations';

//list stagger Fade in
export const listFadeIn = trigger('listFadeIn', [
  transition('* <=> *', [
    query(':enter',
      [
        style({
          opacity: 0,
          color: 'green'
          /* transform: 'translateX(-200%)' */
        }),
        stagger('50ms', animate('800ms ease-out', style({
          opacity: 1,
          color: 'black'
          /* transform: 'translateX(0)' */
        })))
      ],
      { optional: true }
    )
  ])
]);

//Fade in
export const fadeIn = trigger('fadeIn', [
  transition(':enter', [
    style({ opacity: 0 }), animate('900ms', style({ opacity: 1 }))]
  )
]);

//Fade slide In
export const slideIn = trigger('slideIn', [
  transition(':enter', [
    style({ transform: 'translateY(-50%)', opacity: 0}), animate('400ms', style({ transform: 'translateY(0)', opacity: 1}))]
  )
]);

// navMsg msg_anim
export const NavMsg = trigger('navMsg', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)' }), animate('400ms ease-out', style({ transform: 'translateY(0)' }))]
  ),
  transition(':leave', [
    style({ transform: 'translateY(0)' }), animate('300ms ease-in', style({ transform: 'translateY(-100%)' }))]
  )
]);