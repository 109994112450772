<div>
    <div *ngIf="mod.session || mod.objKor?.vrstaID == 2">
        <button type="button" class="btn mybtn-success" style="width: 100%;" (click)="novaObavijestStart()">Nova
            obavijest</button>
        <hr>
    </div>

    <div *ngIf="mod.objKor && mod.objKor.vrstaID == 1" style="padding: 2px 0;">
        <button type="button" class="btn mybtn-cancel" style="width: 100%;" (click)="primljene()"><i
                *ngIf="srvObavijesti.intVrsta == 1" class="fa fa-circle"
                style="font-size: 1.2rem; color: #00cd00; margin-right: 5px;" aria-hidden="true"></i>Primljene</button>
    </div>
    <div *ngIf="mod.objKor && (mod.objKor.vrstaID == 2 ||mod.session)" style="padding: 2px 0;">
        <button type="button" class="btn mybtn-cancel" style="width: 100%;" (click)="poslane()"><i
                *ngIf="srvObavijesti.intVrsta == 2" class="fa fa-circle"
                style="font-size: 1.2rem; color: #00cd00; margin-right: 5px;" aria-hidden="true"></i>Poslane</button>
    </div>
    <hr>
    <!-- <div>
        <button type="button" class="btn mybtn-cancel" style="width: 100%;" (click)="novaPozivnica()"><i *ngIf="srvObavijesti.intVrsta == 3" class="fa fa-circle" style="font-size: 1.2rem; color: #00cd00; margin-right: 5px;" aria-hidden="true"></i>Pozivnice u grupu</button>
    </div> -->

    <div>

        <!-- <div *ngIf="!srvObavijesti.bolOznacavanje">
            <div class="alert alert-secondary">
                Obavijesti će se automatski učitati kada odaberete željeno razdoblje
            </div>
        </div> -->

        <form *ngIf="srvObavijesti.frmFilter" [formGroup]="srvObavijesti.frmFilter">

            <!--Datum Od-->
            <mat-form-field style="width: 100%; text-align: center;">
                <input autocomplete="off" matInput formControlName="datOd" [min]="srvObavijesti.minDat"
                    [max]="srvObavijesti.maxDat" [matDatepicker]="picker1" placeholder="Datum od">
                <mat-error *ngIf="srvObavijesti.frmFilter.get('datOd').errors?.required"
                    style="font-size: 1rem;">Obavezan unos
                </mat-error>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker1></mat-datepicker>
            </mat-form-field>

            <!--Datum Do-->
            <mat-form-field style="width: 100%; text-align: center;">
                <input autocomplete="off" matInput formControlName="datDo" [min]="srvObavijesti.minDat"
                    [max]="srvObavijesti.maxDat" [matDatepicker]="picker2" placeholder="Datum do">
                <mat-error *ngIf="srvObavijesti.frmFilter.get('datDo').errors?.required"
                    style="font-size: 1rem;">Obavezan unos
                </mat-error>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker2></mat-datepicker>
            </mat-form-field>

            <div>
                <button type="submit" class="btn mybtn-info w-100" (click)="srvObavijesti.prikazi()">Prikaži</button>
            </div>

        </form>

    </div>

</div>