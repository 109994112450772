import { Component } from '@angular/core';
import { ModulService } from '../modul.service';
import { NapredakService, PnPrgStavka, PnProgram } from '../napredak.service';

@Component({
  selector: 'app-pn-prg-template-sidebar',
  templateUrl: './pn-prg-template-sidebar.component.html',
  styleUrl: './pn-prg-template-sidebar.component.scss'
})
export class PnPrgTemplateSidebarComponent {

  constructor(
    public mod: ModulService,
    public srvNapredak: NapredakService
  ) { }

  //Program select
  programSelect(x: PnProgram) {

    document.getElementById('btnSidebarClose').click();
    
    this.srvNapredak.objPrgSel = x;

    //Učitavanje stavki
    this.srvNapredak.pnStavkeByPnID(x.pnID, (rez: PnPrgStavka[]) => {
      console.log(rez);
      this.srvNapredak.objPnPrgStavke = rez;
    });

  }

  //Novi program - start
  noviPnStart() {

    this.srvNapredak.napraviFrmProgram(null, (rez: PnProgram) => {

      if (this.mod.session) {
        this.srvNapredak.frmPnProgram.get('pnKlijentID').setValue(this.mod.session.KlijentIDtren);
      } else if (this.mod.objKor.vrstaID == 2) {
        this.srvNapredak.frmPnProgram.get('pnKlijentID').setValue(this.mod.objKor.korisnikID);
      }

      console.log(this.srvNapredak.frmPnProgram.get('pnKlijentID').value);

      document.getElementById('btnMdlPnEdit').click();

      setTimeout(() => {
        document.getElementById('pnNaziv').focus();
      }, 500);

    });
    
  }

  //Uređivanje programa - start
  editPnStart(x: PnProgram) {

    this.srvNapredak.napraviFrmProgram(x, () => {
      document.getElementById('btnMdlPnEdit').click();
    });
  
  }

}
