import { Component } from '@angular/core';
import { ModulService } from '../modul.service';
import { DvoraneService } from '../dvorane.service';

@Component({
  selector: 'app-dvorana-edit',
  templateUrl: './dvorana-edit.component.html',
  styleUrl: './dvorana-edit.component.scss'
})
export class DvoranaEditComponent {

  constructor (
    public mod: ModulService,
    public srvDvorane: DvoraneService
  ) {}

  //aktivna check click
  aktivnaCheck() {
    if (this.srvDvorane.frmDvorana.controls['aktivnaDvo'].value == 0) {
      this.srvDvorane.frmDvorana.controls['aktivnaDvo'].setValue(1);
    } else {
      this.srvDvorane.frmDvorana.controls['aktivnaDvo'].setValue(0);
    }
  }

}
