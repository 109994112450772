<div>

    <!-- Programi -->
    <div class="card bgs-dark" style="padding: 5px;">

        <div>
            Programi
        </div>

        <!--Programi - predlošci-->
        <mat-form-field class="w-100" style="text-align: center;">
            <mat-label>Odaberite program</mat-label>
            <mat-select ([ngModel])="srvNapredak.pnTempID">
                <mat-option *ngFor="let x of srvNapredak.objProgrami" [value]="x.pnID">{{x.pnNaziv}}</mat-option>
            </mat-select>
        </mat-form-field>


        <div style="margin-top: 15px;">
            <button type="button" class="btn mybtn-info w-100">+ Dodaj na listu za
                praćenje</button>
        </div>

        <div style="margin-top: 15px;">
            <button type="button" class="btn mybtn-cancel w-100" routerLink="/pn-template" data-dismiss="modal" data-target="modalPrisutni"><i class="material-icons" style="font-size: 1.5rem; margin-right: 10px;">
                create
            </i>Unos / uređivanje programa</button>
        </div>


    </div>

</div>