import { Injectable } from '@angular/core';
import { ModulService, PosJedinice } from './modul.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as validatori from './validatori';
import { Aktivnost, Vaktivnost } from './kalendar.service';
import { Clan, TrenutnoPrisutni } from './clanovi.service';

export interface Grupa {
  grpID: number,
  grpKorID: number,
  grpOpis: string,
  grpDatumOD: string,
  grpDatumDO: string,
  grpAkt: number,
  grpPosJedID: number,
  grpClanarina: number,
  maxCl: number
}

export interface ClanGrupa {
  vcgID: number,
  GrupaIDvcg: number,
  ClanIDvcg: number,
  DatumODvcg: string,
  DatumDOvcg: string,
  AktivanVcg: number,
  autoCl: number,
  Ime: string,
  Prezime: string,
  Mjesto: string
}

export interface VezGrupaClan {
  vcgID: number,
  GrupaIDvcg: number,
  ClanIDvcg: number,
  DatumODvcg: string,
  DatumDOvcg: string,
  AktivanVcg: number
  autoCl: number
}

export interface VclanZaPrijavu extends VezGrupaClan, Clan, TrenutnoPrisutni {

}

//Brzi unos člana u grupu
export interface ClGrpUnos {
  ClanIDvcg: number,
  GrupaIDvcg: number,
  DatumODvcg: Moment
}

@Injectable({
  providedIn: 'root'
})
export class GrupeService {

  constructor(
    private frmBuilder: FormBuilder,
    public mod: ModulService,
    private http: HttpClient
  ) { }

  objGrupe: Grupa[];
  objClanoviGrupa: ClanGrupa[];
  objSlobodniCl: Clan[];
  fltSlobodniCl: Clan[];

  /* objGrupa = {
    grpID: 0,
    NazivGrp: ''
  } */

  objGrupa: Grupa;
  objPosJedTmp: PosJedinice;

  intUnosTermina: number // Samo za link s componente termini na grupe

  intClanSelID: number;

  //Instanca forme Nova Grupa
  fnNovaGrupa(): FormGroup {

    //this.mod.objPosJedSelected = JSON.parse(localStorage.objPosJedSelected);

    let frm = this.frmBuilder.group({
      grpID: [0],
      grpKorID: [this.mod.objPosJedSelected.KlijentIDpos],
      grpOpis: [, [Validators.required]],
      grpDatumOD: [moment(), Validators.required],
      grpDatumDO: [],
      grpAkt: [1],
      grpPosJedID: [this.mod.objPosJedSelected.PoslJedID],
      grpClanarina: [0, [Validators.required, validatori.decimalCheck]],
      maxCl: [0, [Validators.required]]
    });

    return frm;

  }

  //Učitaj Grupe -> select all
  ucitajGrupe(callback: Function) {

    //let token: string = localStorage.getItem('token');

    let urlTmp: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=select&pjedID=' + this.mod.objPosJedSelected.PoslJedID;

    let obj0;
    if (this.mod.session) {
      obj0 = { vClansID: this.mod.session.vClansID, fingerprint: this.mod.fingerprint() };
    } else {
      obj0 = { vClansID: 0, fingerprint: this.mod.fingerprint() };
    }

    let obj: string = JSON.stringify(obj0);

    this.http.post(urlTmp, obj).subscribe((rez: Grupa[]) => {
      callback(rez);
    });

  }

  //Grupe by poslovna jedinica
  grupeByPosJed(PoslJedID: number, grpAkt: number, callback) {

    let url: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=grupeByPosJed';
    let obj: string = JSON.stringify({ PoslJedID: PoslJedID, grpAkt: grpAkt, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: Grupa[]) => {
      callback(rez);
    });

  }

  //Pipe Naziv grupe by ID
  grupaById(id: number): string {

    let rez: string;

    if (id == 0) {
      rez = '';
    } else {
      this.objGrupe.forEach(val => {
        if (val.grpID == id) {
          rez = val.grpOpis;
        }
      });
    }

    return rez;

  }

  //Učitaj Grupe -> select all by  KorisnikID
  fillByKorisnikID(callback: Function) {

    let url: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=byKorisnikID';

    let obj0;

    if (this.mod.session) {
      obj0 = { session: this.mod.session, fingerprint: this.mod.fingerprint() };
    } else {
      obj0 = { session: null, fingerprint: this.mod.fingerprint() };
    }

    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez: Grupa[]) => {
      callback(rez);
    });

  }

  //Grupe by Trener - offline
  grupeByTrenerOffline(callback) {

    let url: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=grupeByTrenerOffline';

    let obj: string = JSON.stringify({ fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: Grupa[]) => {
      callback(rez);
    });

  }

  //Grupe by član ID
  grupeByClanId(session, intAkt: number, ClanIDvcg: number, callback) {

    let url: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=grupeByClanId';

    let obj: string = JSON.stringify({ session: session, intAkt: intAkt, ClanIDvcg: ClanIDvcg, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: Grupa[]) => {
      callback(rez);
    });

  }

  //Nova Grupa
  novaGrupa(obj: Grupa, callback) {

    if (obj.grpDatumOD) {
      obj.grpDatumOD = moment(obj.grpDatumOD).format('YYYY-MM-DD');
    }

    if (obj.grpDatumDO) {
      obj.grpDatumDO = moment(obj.grpDatumDO).format('YYYY-MM-DD');
    }

    if (this.mod.session) {
      obj['vClansID'] = this.mod.session.vClansID;
    }

    obj['fingerprint'] = this.mod.fingerprint();

    let urlTmp: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=novaGrupa';
    let obj0: string = JSON.stringify(obj);

    this.http.post(urlTmp, obj0).subscribe((rez: Grupa[]) => {

      if (rez) {
        this.objGrupe = rez;
      }

      callback(rez);

    });

  }

  //Grupa edit - save
  spremiGrupaEdit(x: Grupa, callback) {

    let urlTmp: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=spremiGrupaEdit';
    
    x['fingerprint'] = this.mod.fingerprint();

    if (x.grpDatumOD) {
      x.grpDatumOD = moment(x.grpDatumOD).format('YYYY-MM-DD');
    }

    if (x.grpDatumDO) {
      x.grpDatumDO = moment(x.grpDatumDO).format('YYYY-MM-DD');
    }

    let obj0: string = JSON.stringify(x);

    this.http.post(urlTmp, obj0).subscribe((rez: Grupa) => {
      callback(rez);
    });

  }

  //Učitaj Trenere

  //Učitaj pridružene Članove

  //Lista članova koji nisu u grupi
  ucitajSlobodneClanove(grpID: number, vrstCl: number, pjedID: number) {

    this.objSlobodniCl = null;
    this.fltSlobodniCl = null;

    //let token: string = localStorage.getItem('token');

    let urlTmp: string = this.mod.webUrl + "/php/grupe.php?token=" + this.mod.objKor.token + "&funk=grupaSlobodni";

    let objTmp = {
      grpID: grpID,
      vrstCl: vrstCl,
      pjedID: pjedID,
      fingerprint: this.mod.fingerprint()
    }

    let obj: string = JSON.stringify(objTmp);

    this.http.post(urlTmp, obj).subscribe((rez: Clan[]) => {
      this.objSlobodniCl = rez;
      this.fltSlobodniCl = rez;
    });

  }

  //Učlanjeni 
  ucitajUclanjene(grpID: number, callback) {

    //let token: string = localStorage.getItem('token');

    let urlTmp: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=uclanjeni&grpID=' + grpID;
    let obj: string = JSON.stringify({ fingerprint: this.mod.fingerprint() });
    this.http.post(urlTmp, obj).subscribe((rez: ClanGrupa[]) => {
      this.objClanoviGrupa = rez;
      callback(rez);
    });

  }

  //Unos novog člana u grupu - VezGrupaClan
  unesiClana(obj: VezGrupaClan) {

  }

  //Obriši člana iz grupe
  obrisiClana(vcgID: number, callback) {

    let url: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=obrisiClana';
    let obj: string = JSON.stringify({ vcgID: vcgID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez) => {
      callback(rez);
    });

  }

  //Lista aktivnosti za određeni datum
  ucitajDnevneAktivnosti(dat: Moment, callback: Function) {

    //let token: string = localStorage.getItem('token');
    let strDat: string = dat.format('YYYY-MM-DD');
    let urlTmp: string = this.mod.webUrl + '/php/aktivnosti.php?token=' + this.mod.objKor.token + '&funk=aktivnostNaDan&dat=' + strDat + '&grpID=' + this.objGrupa.grpID;
    let obj: string = JSON.stringify({ fingerprint: this.mod.fingerprint() });

    this.http.post(urlTmp, obj).subscribe((rez: Vaktivnost[]) => {
      callback(rez);
    });

  }

  //Lista članova u grupi (prijavljeni / neprijavljeni checkbox)
  objClanoviZaPrijavu: VclanZaPrijavu[];

  ucitajClanoveZaPrijavu(aktGrpID: number, callback) {

    //let token: string = localStorage.getItem('token');
    let url: string = this.mod.webUrl + "/php/grupe.php?token=" + this.mod.objKor.token + "&funk=ucitajClanoveZaPrijavu";
    let obj: string = JSON.stringify({ aktGrpID: aktGrpID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VclanZaPrijavu[]) => {
      callback(rez);
    });

  }

  //Članovi by grupa ID
  clanoviByGrupaID(GrupaIDvcg: number, AktivanVcg: number, callback) {

    let url: string = this.mod.webUrl + "/php/grupe.php?token=" + this.mod.objKor.token + "&funk=clanoviByGrupaID";
    let obj: string = JSON.stringify({ GrupaIDvcg: GrupaIDvcg, AktivanVcg: AktivanVcg, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VclanZaPrijavu[]) => {
      callback(rez);
    });

  }

  //Označavanje / Odznačavanje člana za prijavu na aktivnost
  clanChk(x: VclanZaPrijavu, akt: Aktivnost, callback) {

    let url: string;

    if (x.vcgID) {
      url = this.mod.webUrl + "/php/grupe.php?token=" + this.mod.objKor.token + "&funk=clanChk";
    } else {
      url = this.mod.webUrl + "/php/grupe.php?token=" + this.mod.objKor.token + "&funk=odjaviGosta";
    }

    x['aktGrpID'] = akt.aktGrpID;
    x['PoslJedID'] = this.mod.objPosJedSelected.PoslJedID;
    x['PocetakAkt'] = akt.PocetakAkt;
    x['KrajAkt'] = akt.KrajAkt;
    x['fingerprint'] = this.mod.fingerprint();

    let obj: string = JSON.stringify(x);

    this.http.post(url, obj).subscribe((rez: VclanZaPrijavu) => {
      callback(rez);
    })

  }

  //Treneri By Grupa ID
  treneriByGrupaID(tgGrupaID: number, callback) {

    let url: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=treneriByGrupaID';
    let obj: string = JSON.stringify({ tgGrupaID: tgGrupaID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: Clan[]) => {
      callback(rez);
    });

  }

  //Lista dostupnih trenera za dodavanje u grupu
  treneriZaGrupu(grpID: number, KlijentIDcl: number, PoslJedID: number, callback) {

    let url: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=treneriZaGrupu';
    let obj: string = JSON.stringify({ grpID: grpID, KlijentIDcl: KlijentIDcl, PoslJedID: PoslJedID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: Clan[]) => {
      if (rez) {
        rez.forEach((val, i) => {
          val['sel'] = false;
        })
      }
      callback(rez);
    });

  }

  //Dodavanje trenera - send request
  dodajTreneraEnd(obj0: number[], grpID: number, klID: number, pjID: number, callback) {

    interface Obj {
      treneri: number[],
      grpID: number,
      KlijentIDcl: number,
      PoslJedID: number,
      fingerprint: string
    }

    let obj01: Obj = { treneri: obj0, grpID: grpID, KlijentIDcl: klID, PoslJedID: pjID, fingerprint: this.mod.fingerprint() };

    let url: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=dodajTreneraEnd';
    let obj: string = JSON.stringify(obj01);

    this.http.post(url, obj).subscribe((rez: Clan[]) => {
      callback(rez);
    });

  }

  //Brisanje trenera iz grupe
  obrisiTrenera(vtgID: number, callback) {

    let url: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=obrisiTrenera';
    let obj: string = JSON.stringify({ vtgID: vtgID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: Clan[]) => {
      callback(rez);
    });

  }

  //Grupa by Grupa ID
  grupaByGrpId(grpID: number, callback) {

    let url: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=grupaByGrpId';
    let obj: string = JSON.stringify({ grpID: grpID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: Grupa[]) => {
      callback(rez);
    });

  }

  //Grupe by Član ID (aktivne => -1 = sve, 0 = neaktivne, 1 = aktivne)
  grupeByClan(intAkt: number, clanID: number, callback) {

    let url: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=grupeByClan';
    let obj: string = JSON.stringify({ intAkt: intAkt, clanID: clanID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: Grupa[]) => {
      callback(rez);
    });

  }

  //Dostupne Grupe by Član ID 
  dostupneGrpByClan(trener: boolean, ClanIDvcg: number, grpPosJedID: number, callback) {

    let url: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=dostupneGrpByClan';
    let obj: string = JSON.stringify({ trener: trener, ClanIDvcg: ClanIDvcg, grpPosJedID: grpPosJedID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: Grupa[]) => {
      callback(rez);
    });

  }

  //Prijava gosta u grupu
  prijavaGosta(clanID: number, akt: Aktivnost, callback) {

    let url: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=prijavaGosta';
    
    let obj0 = {
      KlijentIDtren: this.mod.objPosJedSelected.KlijentIDpos, 
      PosJedIDtren: this.mod.objPosJedSelected.PoslJedID, 
      KoriniskIDtren: clanID, 
      clanVrstaIDtren: 1, 
      GrupaIDtren: akt.aktGrpID, 
      vrijemePrijava: akt.PocetakAkt, 
      vrijemeOdjava: akt.KrajAkt,
      fingerprint: this.mod.fingerprint()
    }

    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez) => {
      callback(rez);
    });

  }

  //Brzi unos člana u grupu

  //frm Unos
  frmClanGrupaUnos: FormGroup;
  napraviFrmClGrpUnos(obj: ClGrpUnos, callback) {

    this.frmClanGrupaUnos = this.frmBuilder.group({
      ClanIDvcg: [0],
      GrupaIDvcg: [0, Validators.min(1)],
      DatumODvcg: [moment(), Validators.required]
    });

    if (obj) {
      this.frmClanGrupaUnos.patchValue(obj);
    }

    callback(this.frmClanGrupaUnos.value);

  }

  //Unos
  clanGrupaUnos(obj0: ClGrpUnos, callback) {

    obj0.DatumODvcg = obj0.DatumODvcg.utc(true);

    let url: string = this.mod.webUrl + '/php/grupe.php?token=' + this.mod.objKor.token + '&funk=clanGrupaUnos';
    let obj: string = JSON.stringify({ obj: obj0, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez) => {
      callback(rez);
    });

  }


}
