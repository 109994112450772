<div class="container" style="padding: 0; height: 100%;">

    <div class="row" style="height: 100%; padding-top: 50px; max-height: 100%; opacity: 0.9;">

        <!-- Lijeva kolona -->
        <div class="d-none d-md-block col col-4 card" style="padding: 5px;">

            <div style="opacity: 0.9; padding-top: 10px;">
                <app-stat-klijent-sidebar></app-stat-klijent-sidebar>
            </div>

        </div>

        <!-- Desna kolona -->
        <div class="col card" style="max-height: 100%; padding: 0;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">

                <div>
                    <app-pos-jed-hamburger></app-pos-jed-hamburger>
                </div>

                <div class="flex-grow-1" style="overflow-y: scroll; scrollbar-width: none;">

                    <div *ngIf="srvStatKlijent.objStatByFrm?.posjeta == 0">
                        <div class="alert alert-success" style="padding: 10px; text-align: center;">
                            Nema podataka za prikaz
                        </div>

                    </div>

                    <div *ngIf="!srvClanovi.bolPojedinacno && srvStatKlijent.objStatByFrm?.posjeta > 0">


                        <!-- Poslovne jedinice -->
                        <div class="card card2" @navMsg>

                            <div *ngIf="srvStatKlijent.objStatByFrm.pJed?.length > 1" class="naslov">
                                {{'Ukupno posjeta: ' + srvStatKlijent.objStatByFrm.posjeta +
                                (srvStatKlijent.objStatByFrm.posjetaMin ? ' (' + (srvStatKlijent.objStatByFrm.posjetaMin
                                | minToHHHmm) + ')' : '')}}
                            </div>

                            <div *ngIf="srvStatKlijent.objStatByFrm.vrste?.length > 1" style="padding: 10px;">
                                <div *ngFor="let x of srvStatKlijent.objStatByFrm.vrste">
                                    <div>
                                        <span style="font-weight: bold;">{{x.opis_vrsta_cl}}</span> {{' posjeta: ' +
                                        x.posjeta + ' (' + (x.min | minToHHHmm) + ')'}}
                                    </div>
                                </div>

                            </div>



                            <div class="row">

                                <div *ngFor="let x of srvStatKlijent.objStatByFrm.pJed"
                                    class="col col-12 col-sm-6 col-md-6 d-flex" style="padding: 10px;">

                                    <div class="flex-grow-1 justify-content-center" style="min-width: 150px;">
                                        <div class="podnaslov">
                                            {{x.podaci.NazivPos}}
                                        </div>
                                        <div>
                                            {{'broj posjeta: ' + x.posjeta}}
                                        </div>
                                        <div *ngIf="srvStatKlijent.objStatByFrm.pJed?.length > 1"
                                            style="font-size: 1.5rem; text-align: center;">
                                            {{postotak(x.posjeta, srvStatKlijent.objStatByFrm.posjeta) + ' %'}}
                                        </div>


                                        <div *ngFor="let y of x.vrste">
                                            <div>
                                                <span style="font-weight: bold;">{{y.opis_vrsta_cl}}</span> <span
                                                    *ngIf="x.vrste?.length > 1">{{' posjeta: ' + y.posjeta}}</span>
                                                <span *ngIf="srvStatKlijent.objStatByFrm.pJed?.length > 1">{{' ('+
                                                    (y.min | minToHHHmm) + ')'}}</span>
                                            </div>
                                        </div>


                                    </div>

                                    <div *ngIf="srvStatKlijent.objStatByFrm.pJed?.length > 1">
                                        <google-chart style="max-width: 80px; max-height: 80px;" #chart
                                            [type]="'PieChart'" [data]="x.data" [options]="x.options">
                                        </google-chart>
                                    </div>


                                </div>

                            </div>


                        </div>

                        <!-- Grupe -->
                        <div *ngIf="srvStatKlijent.objStatByFrm?.grupe.length > 0" class="card card2 grp" @navMsg>

                            <div class="naslov">
                                Posjete po grupama
                            </div>

                            <div style="padding: 10px;">

                                <div class="row">

                                    <div class="col">

                                    </div>

                                    <div class="col col-2">
                                        posjeta
                                    </div>

                                    <div *ngIf="srvStatKlijent.objStatByFrm.grupe?.length > 1" class="col col-2">
                                        udio %
                                    </div>

                                    <div class="col col-2">
                                        odaziv %
                                    </div>

                                </div>

                                <div *ngFor="let x of srvStatKlijent.objStatByFrm.grupe" class="row">

                                    <div class="col">
                                        <div>
                                            {{x.naziv}}
                                        </div>
                                    </div>

                                    <div class="col col-2">
                                        <div>
                                            {{x.count}}
                                        </div>
                                    </div>

                                    <div *ngIf="srvStatKlijent.objStatByFrm.grupe?.length > 1" class="col col-2">
                                        {{postotak(x.count, srvStatKlijent.objStatByFrm.posjeta)}}
                                    </div>

                                    <div class="col col-2">
                                        <div *ngIf="x.odaziv != -1"
                                            [ngStyle]="{'color': x.odaziv >= 90 ? 'green' : ''}">
                                            {{x.odaziv}}
                                        </div>
                                        <div *ngIf="x.odaziv == -1">
                                            -
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <!-- Dani -->
                        <div *ngIf="daniCountAsBool()" class="card card2" @navMsg>

                            <div class="naslov">
                                Posjete po danima
                            </div>

                            <div #colChOkvir>
                                <google-chart [type]="'ColumnChart'" [width]="colChOkvir.offsetWidth"
                                    [height]="colChOkvir.offsetHeight" [data]="srvStatKlijent.chartDaniData"
                                    [columns]="columnsChartdatacolumn" [options]="columnsChartoptions"
                                    (select)="onSelect($event)"></google-chart>
                            </div>

                        </div>

                        <!-- Doba dana -->
                        <div *ngIf="dobaDanaAsBool()" class="car card2" @navMsg>

                            <div class="naslov">
                                Doba dana
                            </div>

                            <div style="overflow-x: scroll; overflow-y: hidden; scrollbar-width: thin;">
                                <div #lnChOkvir>
                                    <google-chart [type]="'LineChart'" [width]="srvStatKlijent.cddWidth"
                                        [height]="lnChOkvir.offsetHeight" [data]="srvStatKlijent.chartDdData"
                                        [columns]="columnsChartdatacolumn" [options]="lineChartoptions"
                                        (select)="onSelect($event)"></google-chart>
                                </div>
                            </div>


                        </div>

                        <!-- Top članovi -->
                        <div *ngIf="srvStatKlijent.objStatByFrm.topClanovi" class="card card2" @navMsg>

                            <div class="naslov">
                                Top članovi
                            </div>

                            <div style="padding: 10px;">

                                <div class="row row-top-cl">

                                    <div class="col">

                                    </div>

                                    <div class="col col-2">
                                        posjeta
                                    </div>

                                    <div class="col col-3">
                                        trajanje
                                    </div>

                                </div>

                                <div *ngFor="let x of srvStatKlijent.objTopClanoviPaginator">

                                    <div class="row row-top-cl">

                                        <div class="col">
                                            <div>
                                                {{x.Ime + ' ' + x. Prezime}}
                                            </div>
                                        </div>

                                        <div class="col col-2">
                                            {{x.count}}
                                        </div>

                                        <div *ngIf="x.Ime != '' || x.Prezime != ''" class="col col-3">
                                            {{x.min | minToHHHmm}}
                                        </div>
                                        <div *ngIf="x.Ime == '' && x.Prezime == ''" style="color: transparent;">
                                            nevidljivi place holder
                                        </div>

                                    </div>

                                </div>

                                <div *ngIf="srvStatKlijent.objStatByFrm.topClanovi?.length > srvStatKlijent.tcCntPerPage"
                                    class="row paginator">

                                    <div *ngIf="!ifFirst()" class="col col-3" (click)="srvStatKlijent.pgFirst()">
                                        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                                    </div>
                                    <div *ngIf="ifFirst()" class="col col-3">
                                        <i class="fa fa-angle-double-left" style="color: #d9d8d8;"
                                            aria-hidden="true"></i>
                                    </div>

                                    <div *ngIf="!ifFirst()" class="col col-3" (click)="srvStatKlijent.pgPrev()">
                                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                                    </div>
                                    <div *ngIf="ifFirst()" class="col col-3">
                                        <i class="fa fa-angle-left" style="color: #d9d8d8;" aria-hidden="true"></i>
                                    </div>

                                    <div *ngIf="!ifLast()" class="col col-3" (click)="srvStatKlijent.pgNext()">
                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                    </div>
                                    <div *ngIf="ifLast()" class="col col-3">
                                        <i class="fa fa-angle-right" style="color: #d9d8d8;" aria-hidden="true"></i>
                                    </div>

                                    <div *ngIf="!ifLast()" class="col col-3" (click)="srvStatKlijent.pgLast()">
                                        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                    </div>
                                    <div *ngIf="ifLast()" class="col col-3">
                                        <i class="fa fa-angle-double-right" style="color: #d9d8d8;"
                                            aria-hidden="true"></i>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <!-- Razmak - donja margina-->
                        <div style="margin-bottom: 30px;">

                        </div>

                    </div>

                    <div *ngIf="srvClanovi.bolPojedinacno && srvClanovi.objPregldPosjetaClan">

                        <div *ngFor="let x of srvClanovi.objPregldPosjetaClan">
                            <div style="margin: 2px; padding: 2px 5px; border-bottom: solid 0.5px lightgrey;">
                                <div class="row">

                                    <div class="col" style="padding: 0;">

                                        <div style="font-weight: bold;">
                                            {{x.NazivPos}}
                                        </div>

                                        <div class="d-flex">
                                            <div>
                                                {{x.vrijemePrijava | amDateFormat:'DD.MM.YYYY.'}}
                                            </div>
                                            <div style="margin-left: 10px;">
                                                {{x.vrijemePrijava | amDateFormat:'HH:mm'}}h
                                            </div>
                                            <div style="margin-left: 5px;">
                                                - {{x.vrijemeOdjava | amDateFormat:'HH:mm'}}h
                                            </div>
                                            
                                        </div>

                                        <div [ngStyle]="{'color': x.clanVrstaIDtren == '2' ? 'blue' : x.clanVrstaIDtren == '3' ? 'green' : 'black'}">
                                                {{x.clanVrstaIDtren == '2' ? 'trener' : x.clanVrstaIDtren == '3' ?
                                                'operater' : x.grpOpis}}
                                            </div>

                                        <div class="d-flex">
                                            <!-- <div style="margin-right: 5px;">
                                                {{x.vrijemePrijava | amDateFormat:'HH:mm'}}h
                                            </div>
                                            <div>
                                                - {{x.vrijemeOdjava | amDateFormat:'HH:mm'}}h
                                            </div> -->
                                            <!-- <div style="margin-left: 15px;">
                                                ({{srvClanovi.trajanjePosjetaHmm(x.vrijemePrijava, x.vrijemeOdjava)}})
                                            </div> -->
                                        </div>

                                        <div class="d-flex flex-wrap" style="color: grey;">
                                            <div *ngIf="x.opisDvo" style="margin-right: 10px;">
                                                {{x.opisDvo}}
                                            </div>
                                            <div *ngFor="let y of x.treneri" style="margin-right: 10px;">
                                                {{y.Ime + ' ' + y.Prezime}}
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col col-4 d-flex justify-content-center align-items-center">
                                        <div>
                                            {{srvClanovi.trajanjePosjetaHmm(x.vrijemePrijava, x.vrijemeOdjava)}}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>



                </div>

            </div>

        </div>

    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header posjed-mod-header d-flex justify-content-center">
                Statistike
            </div>

            <div class="modal-body posjed-mod-body">

                <app-stat-klijent-sidebar></app-stat-klijent-sidebar>

            </div>
            <div class="modal-footer bgs-dark" style="padding: 5px;">
                <button type="button" id="btnSidebarClose" class="btn mybtn-cancel" data-dismiss="modal"
                    style="width: 100%; font-size: 1.2rem;">Zatvori</button>
            </div>
        </div>
    </div>
</div>