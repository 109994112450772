<!--SIDEBAR HAMBURGER-->
<div class="hamb-card" *ngIf="mod.objKor" (click)="hamburgerClick()">

    <div class="row">

        <div class="d-flex align-items-center justify-content-center d-md-none col-3 hamb-col-2" data-toggle="modal" data-target="#modalPrisutni">
            <i class="material-icons hamb-btn">menu</i>
        </div>

        <div *ngIf="mod.objPosJedSelected" class="col d-flex justify-content-center hamb-col-10">
            <span>{{mod.objPosJedSelected.NazivPos}}</span>
        </div>

    </div>

</div>