import { Component } from '@angular/core';
import { ApiRez, ModulService } from '../modul.service';
import { NapredakService } from '../napredak.service';

@Component({
  selector: 'app-pn-stavka-edit',
  templateUrl: './pn-stavka-edit.component.html',
  styleUrl: './pn-stavka-edit.component.scss'
})
export class PnStavkaEditComponent {

  constructor(
    public mod: ModulService,
    public srvNapredak: NapredakService
  ) { }

  //Brisanje stavke / parametra
  obrisiPnPrgStavku(ppsID: number) {

    this.srvNapredak.bolBrisanje = false;

    this.srvNapredak.obrisiPnPrgStavku(ppsID, (rez: ApiRez) => {

      if (rez && rez.rez == 'ok') {

        document.getElementById('btnMdlPnStavEditClose').click();

        this.srvNapredak.objPnPrgStavke.forEach((val, ind) => {
          if (val.ppsID == ppsID) {
            this.srvNapredak.objPnPrgStavke.splice(ind, 1);
          }
        });

        this.mod.msgBox(1, "OBRISANO!", 800);

      } else {
        this.mod.msgBox(2, "Greška prilikom brisanja", 800);
      }

    });

  }

  //Otkazivanje brisanja
  otkaziBrisanje() {
    
    this.srvNapredak.bolBrisanje = false;

   /*  document.getElementById('btnMdlPnStavEditClose').click(); */

  }

  //Aktivan chk
  aktClick() {

    let aktTmp: number = this.srvNapredak.frmPnPrgStavka.get('ppsAkt').value;

    if (aktTmp == 1) {
      this.srvNapredak.frmPnPrgStavka.get('ppsAkt').setValue(0);
    } else {
      this.srvNapredak.frmPnPrgStavka.get('ppsAkt').setValue(1);
    }

  }

}
