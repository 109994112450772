import { Component, OnInit } from '@angular/core';
import { StatKlijentService } from '../stat-klijent.service';

@Component({
  selector: 'app-stat-kl-posjete',
  templateUrl: './stat-kl-posjete.component.html',
  styleUrls: ['./stat-kl-posjete.component.scss']
})
export class StatKlPosjeteComponent implements OnInit {

  constructor(
    public srvStatKlijent: StatKlijentService
  ) { }

  ngOnInit(): void {

    this.srvStatKlijent.napraviFrmFilter(null, () => {
      this.srvStatKlijent.napraviLcPosjete();
    });

  }

  datumSelect(event) {
    
  }





}
