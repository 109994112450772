<form *ngIf="srvClanarine.frmClanarinaVrsta" [formGroup]="srvClanarine.frmClanarinaVrsta" #frmClanarinaVrsta>

    <div class="row">
        <div class="col col-6">

        </div>
        <div class="col d-flex justify-content-end" style="padding: 5px;" *ngIf="this.srvClanarine.frmClanarinaVrsta.controls['cvID'].value != 0">
            <button type="button" class="btn mybtn-delete" (click)="brisanjeVrsteClanarine()">Obriši <mat-icon style="margin-left: 10px;">delete_forever</mat-icon></button>
        </div>
    </div>

    <mat-form-field class="w-100">
        <mat-label>Naziv</mat-label>
        <input autocomplete="off" autocomplete="off" matInput type="text" formControlName="cvOpis" placeholder="opis" required>
        <mat-error *ngIf="srvClanarine.frmClanarinaVrsta.controls['cvOpis'].errors?.required">Polje je obavezno za unos</mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Napomena</mat-label>
        <textarea autocomplete="off" matInput type="text" formControlName="cvNapomena" placeholder="napomena"></textarea>
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Iznos članarine</mat-label>
        <input autocomplete="off" autocomplete="off" matInput type="tel" formControlName="cvIznos" #cvIznos placeholder="iznos" aria-label="cvIznos" required (blur)="srvClanarine.decFormatVrsteCla($event)">
        <mat-error *ngIf="srvClanarine.frmClanarinaVrsta.controls['cvIznos'].errors?.required">Polje je obavezno za unos</mat-error>
        <mat-error *ngIf="srvClanarine.frmClanarinaVrsta.controls['cvIznos']?.errors">Polje mora biti broj</mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Tjednih posjeta (0 = neograničeno)</mat-label>
        <input autocomplete="off" autocomplete="off" matInput type="tel" formControlName="cvTjedno" placeholder="tjednih posjeta" required>
    </mat-form-field>    

</form>