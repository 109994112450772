<div class="container" style="padding: 0; height: 100%; opacity: 0.9;">

    <div class="row" style="height: 100%; padding-top: 50px; max-height: 100%;">

        <!-- Lijeva kolona -->
        <div class="d-none d-md-block col-md-4" style="padding: 0;">
            <div class="card" style="width: 100%; padding: 15px 2px;">
                <app-clanarine-sidebar></app-clanarine-sidebar>
            </div>
        </div>

        <!-- Desna kolona -->
        <div class="col" style="padding: 0; max-height: 100%;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">

                <div>

                    <div class="bgs-light">
                        <app-pos-jed-hamburger (hamburgerClickEmitt)="openSidebar()"></app-pos-jed-hamburger>
                    </div>

                    <!-- <div *ngIf="srvClanarine.objClanarine" class="card">

                        <div class="row zaglavlje">

                            <div class="col col-5">
                                Razdoblje
                            </div>

                            <div class="col col-4 d-flex justify-content-center">
                                plaćena
                            </div>
  
                            <div class="col col-3" style="text-align: right;">
                                iznos
                            </div>

                        </div>

                    </div> -->

                </div>

                <div class="flex-grow-1 card" style="overflow-y: scroll; margin-right: -15px; padding-bottom: 20px;">

                    <div *ngIf="srvClanarine.objClanarine" style="padding-right: 15px;">

                        <!-- Tablica-->
                        <div *ngFor="let x of srvClanarine.objClanarine" class="row tablica"
                            (click)="urediClanarinu(x)">

                            <div class="col col-9" style="font-size: 1rem;">

                                <div style="padding: 0 5px;">
                                    <div style="color: #010f30; font-size: 1.2rem;">
                                        {{x.claVrijediOD | amDateFormat:'MMMM'}} / {{x.claVrijediDO
                                        | amDateFormat:'MMMM'}}
                                    </div>
                                </div>

                                <div style="padding: 0 5px; font-size: 1rem;">

                                    <div>
                                        {{x.claVrijediOD | amDateFormat:'DD.MM.YYYY.'}} - {{x.claVrijediDO
                                        | amDateFormat:'DD.MM.YYYY.'}}
                                    </div>

                                </div>

                                <div *ngIf="x?.grpOpis != '-'" style="padding: 0px 5px; font-size: 1rem; color: #002679;">
                                    {{x.grpOpis + (x.cvID ? '  - ' + x.cvOpis2 : '')}}
                                </div>

                                <div class="d-flex align-items-center">
                                    <!-- <i *ngIf="x.intPlacena == 0" class="fa fa-minus" aria-hidden="true"></i> -->

                                    <div *ngIf="x.intPlacena == 0" class="d-flex align-items-center">
                                        <i class="fa fa-times" style="color: #c70b0b;" aria-hidden="true"></i> <span
                                            style="margin-left: 5px; color: #c70b0b;">nije plaćena</span>
                                    </div>

                                    <div *ngIf="x.intPlacena == 1" class="d-flex align-items-center">
                                        <i class="fa fa-check" style="color: #ade9ad;" aria-hidden="true"></i> <span
                                            style="margin-left: 5px; color: #717171;">{{x.claDatumUplate
                                            | amDateFormat:'DD.MM.YYYY.'}}</span>
                                    </div>

                                </div>

                            </div>

                            <div class="col col-3 d-flex justify-content-end align-items-center"
                                style="color: #717171;">
                                {{x.claIznos | number:'1.2-2':'en-HR'}} €
                            </div>

                        </div>
                    </div>

                    <div *ngIf="!srvClanarine.objClanarine" class="alert alert-success"
                        style="text-align: center; padding-right: 15px; margin-bottom: 0;">
                        Nema podataka za prikaz
                    </div>

                </div>

            </div>

        </div>
    </div>

</div>

<!--SIDEBAR -->
<button hidden="true" #btnSidebar data-toggle="modal" data-target="#mdlSidebar"></button>
<div class="modal left fade" id="mdlSidebar" tabindex="-1" role="dialog" aria-labelledby="mdlSidebarLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="background-color: #c4cbca; border-radius: 10px;">

            <div class="modal-header" style="border: none; padding: 0px; background-color: #c4cbca;">
                <!-- <i class="material-icons">people</i>
                <span style="height: 20px; margin-left: 5px;">Članovi</span> -->
                <div class="w-100 naslov-m">
                    <span>Unos članarine</span>
                </div>
            </div>

            <div class="modal-body" style="padding: 0px;">

                <app-clanarine-sidebar></app-clanarine-sidebar>

            </div>

            <div class="modal-footer" style="padding: 5px;">
                <button type="button" id="btnSidebarClose" class="btn btn-outline-secondary" style="width: 100%;"
                    data-dismiss="modal">Zatvori</button>
            </div>

        </div>
    </div>
</div>

<!--Modal Članarina (new / edit)-->
<button hidden="true" id="btnMdlClanarina" data-toggle="modal" data-target="#mdlClanarina"
    (click)="bolBrisanje = false"></button>

<div class="modal fade" id="mdlClanarina" tabindex="-1" role="dialog" aria-labelledby="mdlClanarina" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header justify-content-center" style="font-size: 1.2rem; color: #6c1d1d;">
                <div>
                    Članarina
                </div>
            </div>

            <div class="modal-body" style="padding: 10px 7px; font-size: 1.5rem;">

                <app-clanarine-edit></app-clanarine-edit>

                <div *ngIf="srvClanarine.frmClanarina && srvClanarine.frmClanarina.get('claID').value" class="row">
                    <!-- <div class="col-6">

                    </div> -->
                    <div class="col" style="padding: 5px 5px 15px;">
                        <button *ngIf="!bolBrisanje" type="button" class="btn mybtn-delete w-100"
                            (click)="bolBrisanje = true"><i class="material-icons" style="margin-right: 15px;">delete_sweep</i>obriši
                            članarinu</button>
                        <!-- obriši članarinu X -->
                    </div>
                </div>

            </div>

            <div class="modal-footer d-block" style="padding: 0;">

                <div *ngIf="!bolBrisanje" class="row">
                    <div class="col" style="padding: 5px;">
                        <button type="button" class="btn mybtn-success w-100" (click)="spremiClanarinu()"><i
                                class="material-icons">save</i> Spremi</button>
                    </div>
                    <div class="col" style="padding: 5px;">
                        <button type="button" class="btn mybtn-cancel w-100" data-dismiss="modal"><i
                                class="material-icons">close</i> Zatvori</button>
                    </div>
                </div>

                <button hidden id="btnMdlClanarinaClose" data-dismiss="modal"></button>

                <div *ngIf="bolBrisanje" class="d-flex" style="color: #6c1d1d; text-align: center; padding: 5px;">
                    <div class="d-flex">
                        <i class="material-icons">error_outline</i>
                    </div>
                    <div class="d-flex">
                        Upozorenje! Potvrdom brisanja nepovratno ćete ukloniti odabranu članarinu iz evidencije
                    </div>
                </div>

                <div *ngIf="bolBrisanje" class="row">
                    <div class="col" style="padding: 5px;">
                        <button type="button" class="btn mybtn-danger w-100" (click)="obrisiClanarinu()"><i
                                class="material-icons">delete_sweep</i> Potvrdi brisanje</button>
                    </div>
                    <div class="col" style="padding: 5px;">
                        <button type="button" class="btn mybtn-cancel w-100" (click)="bolBrisanje = false"><i
                                class="material-icons">close</i> Odustani</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>