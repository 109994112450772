import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClanoviService } from '../clanovi.service';
import { Grupa, GrupeService } from '../grupe.service';
import { ModulService, PosJedinice } from '../modul.service';
import { TerminiRezGrp, TerminiService } from '../termini.service';
import { Aktivnost, KalendarService } from '../kalendar.service';
import * as moment from 'moment';
import { Dvorana, DvoraneService } from '../dvorane.service';
import { AktivnostiService, TreneriByAktID } from '../aktivnosti.service';

@Component({
  selector: 'app-termini-pregled',
  templateUrl: './termini-pregled.component.html',
  styleUrls: ['./termini-pregled.component.scss']
})
export class TerminiPregledComponent implements OnInit, OnDestroy {

  constructor(
    public srvTermini: TerminiService,
    public mod: ModulService,
    public srvClan: ClanoviService,
    public srvGrupe: GrupeService,
    public srvkalendar: KalendarService,
    public srvDvorane: DvoraneService,
    public srvAktivnosti: AktivnostiService
  ) { }

  ngOnInit(): void {

    this.srvTermini.objTermini = null;

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.mod.objKor.token;
    }

    /* if (!this.srvClan.clanID && localStorage.getItem('clanID')) {
      this.srvClan.clanID = Number(localStorage.getItem('clanID'));
      localStorage.removeItem('clanID');
    } */

    this.mod.provjeraTokena(tok, () => {

      this.mod.provjeraPrijaveTrenOper(rez9 => {

        /* this.mod.ucitajPosJed(() => { */

          if (!this.srvTermini.frmTerminFilter) {
            this.srvTermini.napraviFrmTerminFilter(() => {});
          }

          //Poslovne jedinice
          if (this.mod.objKor.vrstaID == 2) {
            this.srvTermini.objPosJed = this.mod.objPosJed;
            this.srvTermini.frmTerminFilter.get('pjedID').setValue('0');
          } else {
            this.srvClan.posjedByFizickaOsoba(1, (rez: PosJedinice[]) => {
              this.srvTermini.objPosJed = rez;
              this.srvTermini.frmTerminFilter.get('pjedID').setValue(rez[0].PoslJedID);
              this.srvGrupe.grupeByPosJed(rez[0].PoslJedID, 1, (rez: Grupa[]) => {
                this.srvGrupe.objGrupe = rez;
              });
            });
          }

          //Grupe - depreced
          /* if (this.mod.objKor.vrstaID == 2) {
            this.srvGrupe.ucitajGrupe((rez: Grupa[]) => {
              this.srvGrupe.objGrupe = rez;
            });
          } else {
            this.srvGrupe.fillByKorisnikID((rez: Grupa[]) => {
              this.srvGrupe.objGrupe = rez;

              if (this.mod.session.clanVrstaIDtren == 2) {
                this.srvTermini.frmTerminFilter.get('grpID').setValue(rez[0].grpID);
              }
            })
          } */

        /* }); */
      });
    });

  }

  //Uređivanje termina
  urediAktivnost(x) {

    this.srvkalendar.fnKalendarUnos(0, () => {

      //Treneri by aktivnost ID
      this.srvAktivnosti.treneriByAktIdEdit(x.aktGrpID, (rez: TreneriByAktID[]) => {
        this.srvAktivnosti.objTreneriByAktID = rez;
      });

      //Dvorane
      this.srvDvorane.dvoraneByPosJedId(x.grpPosJedID, -1, (rez: Dvorana[]) => {

        this.srvkalendar.objDvorane = rez;

        x.statusAkt = Number(x.statusAkt);
        x['datumOD'] = moment(x.PocetakAkt).format('YYYY-MM-DD');
        x['hOD'] = moment(x.PocetakAkt).format('HH');
        x['mOD'] = moment(x.PocetakAkt).format('mm');

        let duration = moment.duration(moment(x.KrajAkt).diff(moment(x.PocetakAkt)));

        let hTmp, mTmp: number;
        mTmp = duration.asMinutes();
        /* hTmp = Math.trunc(mTmp / 60);
        mTmp = mTmp - (hTmp * 60); - depreced */

        /* x['hDO'] = hTmp; - depreced */
        x['mDO'] = mTmp;

        this.srvkalendar.frmKalendarUnos.patchValue(x);

        document.getElementById('btnAktEdit').click();

      });

    });

  }

  //Spremamnje promjena u terminu
  spremiAktivnost() {

    this.srvkalendar.aktivnostQuickUpdate((rez) => {

      rez.edit = true;
      this.srvTermini.objTermini.forEach((val, ind) => {
        val.termini.forEach((val2, ind2) => {
          if (val2.aktGrpID == rez.aktGrpID) {
            this.srvTermini.objTermini[ind].termini[ind2] = rez;
          }
        });
      });

      document.getElementById('btnMdlAktEditClose').click();
    });

  }

  prikaziSession() {
    
  }

  ngOnDestroy(): void {
    document.getElementById('btnSidebarClose').click();
  }

}
