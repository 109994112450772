import { Component } from '@angular/core';
import { ApiRez, ModulService } from '../modul.service';
import { ClanarinaVrsta, ClanarineService } from '../clanarine.service';

@Component({
  selector: 'app-clanarina-vrsta-edit',
  templateUrl: './clanarina-vrsta-edit.component.html',
  styleUrl: './clanarina-vrsta-edit.component.scss'
})
export class ClanarinaVrstaEditComponent {

  constructor(
    public mod: ModulService,
    public srvClanarine: ClanarineService
  ) { }


  //Brisanje
  brisanjeVrsteClanarine() {

    let cvID: number = this.srvClanarine.frmClanarinaVrsta.controls['cvID'].value;

    this.srvClanarine.brisanjeVrsteClanarine(cvID, (rez: ApiRez) => {

      if (rez.rez == 'ok') {

        //Učitavanje vrsta članarina
        this.srvClanarine.clanarineVrsteByPosJedID(this.mod.objPosJedSelected.PoslJedID, (rez: ClanarinaVrsta[]) => {
          this.srvClanarine.objVrsteClanarina = rez;
        });

        this.mod.msgBox(1, "OBRISANO!", 700);

      } else {
        this.mod.msgBox(2, rez.errMsg, 700);
      }

    });

    document.getElementById('btnMdlClanarinaVrstaClose').click();

  }

}
