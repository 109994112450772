import { Component, OnInit, ViewChild, ElementRef, DoCheck, OnDestroy, HostListener, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Kor, ModulService, SocialUser } from './modul.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Clan, ClanoviService } from './clanovi.service';
import { NavMsg } from './animacije';
import { SwPush } from '@angular/service-worker';
import { Location } from '@angular/common';
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { Subscription, fromEvent, map, merge, of } from 'rxjs';
import { GoogleSigninComponent } from './google-signin/google-signin.component';
import { NgxScannerQrcodeComponent, NgxScannerQrcodeService, ScannerQRCodeConfig, ScannerQRCodeResult, ScannerQRCodeSelectedFiles } from 'ngx-scanner-qrcode';
import { Dvorana, DvoraneService } from './dvorane.service';
import { MatDatepickerInput } from '@angular/material/datepicker';
import * as moment from 'moment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [NavMsg]
})

export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    public mod: ModulService,
    private formBuilder: FormBuilder,
    private router: Router,
    private actRoute: ActivatedRoute,
    private http: HttpClient,
    public srvClanovi: ClanoviService,
    private swPush: SwPush,
    private location: Location,
    private authService: SocialAuthService,
    public srvDvorane: DvoraneService
    /* private compGoogleSignIn: GoogleSigninComponent */
    //private newsletterService: NewsletterService
  ) { }


  @ViewChild('btnLogin') btnLogin: ElementRef;
  @ViewChild('btnModal') btnModal: ElementRef;
  @ViewChild('mdlQrScan') mdlQrScan: ElementRef;
  

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (document.hidden) {
      localStorage.setItem('token', this.mod.objKor.token);
      localStorage.setItem('firebasePushToken', this.mod.firebasePushToken);
      localStorage.setItem('pjSel', this.mod.objPosJedSelected?.PoslJedID.toString());
      if (this.mod.session) {
        localStorage.setItem('session', JSON.stringify(this.mod.session));
      }
      if (this.mod.objClanstvo) {
        localStorage.setItem('objClanstvo', JSON.stringify(this.mod.objClanstvo));
      }
    }
    /* if (!document.hidden) {
      this.authSubscription = this.authService.authState.subscribe((user) => {
        console.log('user', user);
        this.socialLogin(user);
      });
    } */
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

  }

  loginForm: FormGroup;

  kor: string;
  token: string;
  clTmp: Clan;

  readonly VAPID_PUBLIC_KEY = "BHbI1YwfFNgpRZL8DQxVnYGedRSU8WCoU20dNQlriygcIPeqo9NlTWUZIt4a5xiJxyBV8iU_R-niLJkhkFizDug";

  /* subscribeToNotifications() {
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    })
      .then(sub => this.newsletterService.addPushSubscriber(sub).subscribe())
      .catch(err => console.error("Could not subscribe to notifications", err));
  } */

  ngAfterViewInit(): void {

    /* setTimeout(() => {

      this.mdlQrScan.nativeElement.addEventListener('hidden.bs.modal', function (event) { 
        console.log("show.bs.modal event 2") 
      });
  
      let modal2 = document.getElementById('mdlQrScan');
      modal2.addEventListener('hidden.bs.modal', function (event) { 
        console.log("show.bs.modal event 2") 
      });
    }, 3000); */






    /* this.action?.data.subscribe(rez => {

      if (rez[0]) {

        this.action.stop();

        try {

          let rez2 = JSON.parse(rez[0].value);

          if (rez2.fitnet || rez2.klijent) {
            //prijava
            console.log(rez2.fitnet);
          } else {
            this.mod.msgBox(3, "Nepoznat format", 2000);
            setTimeout(rez => {
              this.action.start();
            }, 1000);
          }

        } catch (error) {
          this.mod.msgBox(1, "Nepoznat format", 2000);
          setTimeout(rez => {
            this.action.start();
          }, 1000);
        }

      }

    }); */

  }



  ngOnInit() {

    this.mod.checkNetworkStatus();


    //Učitavanje objKor
    if (!localStorage.getItem('token')) {

      this.mod.btnLoginClass = 'Prijava';


    } else {

      //this.mod.objKor = JSON.parse(localStorage.objKor);

      this.mod.btnLoginClass = 'Odjava';
      this.mod.firebasePushToken = localStorage.getItem('firebasePushToken');

      /* this.mod.korByToken(localStorage.getItem('token'), (rez: Kor) => {
        this.mod.objKor = rez;
        console.log(rez);
        this.mod.ucitajPosJed((rez) => {
          console.log(rez);
         });

      }); */

    }

    //LOGIN FRM
    this.loginForm = this.formBuilder.group({
      kor: ['', [Validators.required, Validators.email]],
      pass: ['', [Validators.required, Validators.minLength(4)]]
    });

    this.loginForm.setValue(this.mod.objLogin);

    if (!this.mod.objKor) {
      this.mod.btnLoginClass = "Prijava";
    } else {
      this.mod.btnLoginClass = "Odjava";
    }

    //this.signInWithGoogle();
    //this.signInWithFB();

    this.refreshToken();

    /* this.authService.authState.subscribe((user) => {
      console.log(user);
      this.user = user;
      this.loggedIn = (user != null);
    }); */

  }


  signInWithGoogle(): void { //depreced

    /* this.compGoogleSignIn.handleGoogleLogin(); */

    /* this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((rez) => {
      this.socialLogin(rez);
    }); */
    /* this.authService.authState.subscribe((user1: SocialUser) => {
      console.log(user1);
    }); */
  }

  googleSignin(googleWrapper: any) {

    //this.authSubscription?.unsubscribe();

    googleWrapper.click();

    /* this.authSubscription = this.authService.authState.subscribe((user) => {
      console.log('user', user);
      this.socialLogin(user);
      
    }); */




  }

  signInWithFB(): void {
    this.authService?.signIn(FacebookLoginProvider.PROVIDER_ID).then((rez) => {
      this.socialLogin(rez);
    });
  }

  socialLogin(user: SocialUser) {
    if (user && user.email) {
      this.mod.socialLogin(user, (rez0: Kor) => {
        if (rez0) {
          this.mod.objKor = rez0;
          document.getElementById('btnLoginModalClose').click();
          this.router.navigate(['cpanel1']);
          /* this.authSubscription.unsubscribe(); */
          this.mod.msgBox(1, "Uspješno ste prijavljeni", 2000);
        } else {
          this.mod.msgBox(2, "Greška prilikom prijave", 2000);
        }
      });
    } else {
      this.mod.msgBox(2, "Greška prilikom prijave", 2000);
    }
  }

  //Refresh social login token
  refreshToken(): void {
    this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID).then(rez => {
    });
  }

  //check full screen
  checkFullScreen() {
    if (window.innerHeight == screen.height) {
      return true;
    } else {
      return false;
    }
  }

  //Enter full screenauthService
  enterFullScreen() {
    document.documentElement.requestFullscreen();
  }

  //Exit full screen
  exitFullScreen() {
    document.exitFullscreen();
  }

  //LOGIN MAIN
  loginMain() {

    if (!this.mod.objKor) {


      //Prijava
      //this.kor = this.mod.objLogin.kor;
      this.loginForm.setValue(this.mod.objLogin);

      /* document.getElementById('btnLogin').click(); */
      this.btnLogin.nativeElement.click();
    } else {
      //Odjava
      this.mod.odjava();
      /* this.authService.authState.subscribe(rez => {
        if (rez) {
          this.authService.signOut();
          this.authService.signOut(true);
          this.authSubscription.unsubscribe();
        }
      }); */
    }

  }

  //LOGIN CHK - Modal btnPotvrdi
  loginChk() {

    if (this.loginForm.valid) {

      this.mod.login(this.loginForm.value, () => {

        if (this.mod.objKor) {
          //this.btnLogin.nativeElement.click();
          document.getElementById('btnLoginModalClose').click();
          this.router.navigate(['cpanel1']);
        } else {
          this.loginForm.setErrors({ 'loginFail': 'Netočno korisničko ime ili lozinka' });
        }

      });
    }

  }

  //Povezivanje člana Step 2
  poveziClanaStep2(x: Clan) {

    this.srvClanovi.frmPovez.get('clanIDvfc').setValue(x.clanID);

  }

  //Povezivanje clana End .. 
  poveziClanaEnd() {

    this.srvClanovi.poveziZahtjev(this.srvClanovi.frmPovez.value, (rez) => {
      if (rez == 'ok') {
        document.getElementById('btnMdlClZahtjevClose').click();
        this.srvClanovi.nepZahtjevByKorId(this.mod.objKor.korisnikID, (rez) => {
          this.srvClanovi.objNepotZahtjevi = rez;
        });
        this.mod.msgBox(1, "Član je uspješno povezan!", 2000);
      } else {
        this.mod.msgBox(2, "Greška! pokušajte ponovno!", 3500);
      }
    });

  }

  //BACK button
  back() {
    this.location.back();
  }




  //QR scanner start
  qrScanStart() {

    /* console.log(this.action.devices);
    console.log(this.action.deviceIndexActive);
    this.handle(this.action, 'start'); */
    /* this.action.start(); */

    this.srvDvorane.dvoraneByPosJedId(this.mod.objPosJedSelected.PoslJedID, 1, (rez: Dvorana[]) => {
      this.mod.dvoranePjed = rez;
    });


    this.mod.bolQrScaning = true;
    this.srvClanovi.objVClanstva = null;

    document.getElementById('btnMdlQrScan').click();



  }

  //test
  logInfo() {
    console.log(this.mod.objKor);
    console.log(this.mod.objPosJed);
    console.log(this.mod.objPosJedSelected);
    console.log(this.mod.session);
    console.log(this.mod.objClanstvo);
  }

  ngOnDestroy(): void {
    //this.authSubscription.unsubscribe();
    if (this.mod.networkStatus$) {
      this.mod.networkStatus$.unsubscribe();
    }
    document.getElementById('btnLoginModalClose').click();
    document.getElementById('btnRegClose').click();
    document.getElementById('btnOdjavaClose').click();
    document.getElementById('btnMdlClZahtjevClose').click();
  }

}
