<div class="container" style="padding: 0px; height: 100%; padding-bottom: 5px;">

    <div class="row" style="padding-top: 50px; height: 100%; max-height: 100%;">

        <!-- Lijeva kolona -->
        <div class="d-none d-md-block col col-4" style="padding: 0;">

            <app-napredak-sidebar></app-napredak-sidebar>

        </div>

        <!-- Desna kolona -->
        <div class="col col-right" style="max-height: 100%; padding: 0;">
        
            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">
            
                <div class="bgs-dark" data-toggle="modal" data-target="#mdlSidebar">

                    <app-pos-jed-hamburger></app-pos-jed-hamburger>

                </div>

                <div class="flex-grow-1" style="overflow-y: scroll; scrollbar-width: none; padding-left: 2px;">
                
                
                </div>
            
            </div>
        
        </div>
    
    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header posjed-mod-header d-flex justify-content-center">
                Praćenje napretka
            </div>

            <div class="modal-body posjed-mod-body" style="padding: 0;">

                <app-napredak-sidebar></app-napredak-sidebar>

            </div>

            <div class="modal-footer bgs-dark">
                <button type="button" id="btnSidebarClose" class="btn btn-outline-secondary" data-dismiss="modal"
                    style="width: 100%;">Zatvori</button>
            </div>
        </div>
    </div>
</div>