// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyD3U-9zqXCMvC4WR67aYfQLNnLVv5FmcVM",
    authDomain: "fitnet-5061d.firebaseapp.com",
    projectId: "fitnet-5061d",
    storageBucket: "fitnet-5061d.appspot.com",
    messagingSenderId: "229743405443",
    appId: "1:229743405443:web:5cfffeec594ac45a322eb6",
    vapidKey: "BHbI1YwfFNgpRZL8DQxVnYGedRSU8WCoU20dNQlriygcIPeqo9NlTWUZIt4a5xiJxyBV8iU_R-niLJkhkFizDug"
  },
};


