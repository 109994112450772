import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ClanarinaVrsta, ClanarineService, VclanarinaGrupa } from '../clanarine.service';
import { ClanoviService } from '../clanovi.service';
import { Grupa, GrupeService } from '../grupe.service';
import { ModulService } from '../modul.service';

@Component({
  selector: 'app-clanarine-edit',
  templateUrl: './clanarine-edit.component.html',
  styleUrls: ['./clanarine-edit.component.scss']
})
export class ClanarineEditComponent implements OnInit {

  constructor(
    public mod: ModulService,
    public srvClanarine: ClanarineService,
    public srvGrupe: GrupeService,
  ) { }

  ngOnInit(): void {

    this.srvClanarine.clanarineVrsteByPosJedID(this.mod.objPosJedSelected?.PoslJedID, (rez: ClanarinaVrsta[]) => {
      this.srvClanarine.objVrsteClanarina = rez;
    });

  }

  //checkbox plaćanje
  placanjeChk() {

    if (this.srvClanarine.frmClanarina.get('intPlacena').value == 0) {
      this.srvClanarine.frmClanarina.get('intPlacena').setValue(1);
      if (!this.srvClanarine.frmClanarina.get('claDatumUplate').value) {
        this.srvClanarine.frmClanarina.get('claDatumUplate').setValue(moment().toDate());
      }
    } else {
      this.srvClanarine.frmClanarina.get('intPlacena').setValue(0);
    }
  }

  //Odabir grupe
  odabirGrupe(event) {

    if (this.srvClanarine.frmClanarina.get('claGrupaID').value != 0) {
      this.srvClanarine.frmClanarina.get('claVrstaID').setValue(0);
    }

    this.srvGrupe.objGrupe.forEach((val, ind) => {
      if (val.grpID == event) {
        this.srvClanarine.frmClanarina.get('claIznos').setValue(val.grpClanarina);
      }
    });
  }

  //Odabir vrste članarine
  odabirVrsteClanarine(event) {
    this.srvClanarine.objVrsteClanarina.forEach((val, ind) => {
      if (val.cvID == event) {
        this.srvClanarine.frmClanarina.get('claIznos').setValue(val.cvIznos);
      }
    });
  }

  //Odabir datuma OD
  dat1Select() {
    let dTmp: moment.Moment = moment(this.srvClanarine.frmClanarina.get('claVrijediOD').value);
    this.srvClanarine.frmClanarina.get('claVrijediDO').setValue(dTmp.add(1, 'M').add(-1, 'd'));
  }

}
