import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModulService } from '../modul.service';
import { FrmFilter, ObavijestiService } from '../obavijesti.service';
import { PozivniceService } from '../pozivnice.service';

@Component({
  selector: 'app-obavijesti-sidebar',
  templateUrl: './obavijesti-sidebar.component.html',
  styleUrls: ['./obavijesti-sidebar.component.scss']
})
export class ObavijestiSidebarComponent implements OnInit, OnDestroy {

  constructor(
    public mod: ModulService,
    public srvObavijesti: ObavijestiService,
    private srvPozivnice: PozivniceService
  ) { }

  ngOnInit(): void {

  }

  //Nova obavijest START
  novaObavijestStart() {

    this.srvObavijesti.novaObavijest();
    document.getElementById('btnSidebarClose').click();
    document.getElementById('btnMdlNovaObavijest').click();

  }

  //Primljene obavijesti
  primljene() {
    this.srvObavijesti.bolOznacavanje = false;
    this.srvObavijesti.oznCount = 0;
    this.srvObavijesti.infoSent = 0;
    this.srvObavijesti.objSent = null;
    this.srvObavijesti.fltSent = null;
    this.srvObavijesti.intVrsta = 1;
    this.srvObavijesti.prikazi();
  }

  //Poslane obacijesti
  poslane() {
    this.srvObavijesti.bolOznacavanje = false;
    this.srvObavijesti.oznCount = 0;
    this.srvObavijesti.infoSent = 0;
    this.srvObavijesti.objVobavRez = null;
    this.srvObavijesti.fltVobavRez = null;
    this.srvObavijesti.intVrsta = 2;
    this.srvObavijesti.prikazi();
  }

  //DEPRECATED
  novaPozivnica() {

    this.srvPozivnice.napraviFrmPozivnica(null, (rez) => {

    });
    document.getElementById('btnMdlNovaPozivnica').click();
  }


  ngOnDestroy(): void {
    document.getElementById('btnSidebarClose').click();
  }

}
