import { Component, OnDestroy, OnInit } from '@angular/core';
import { Kor, ModulService } from '../modul.service';
import { ClanarinaClan, ClanarineClanService, FrmFilter } from '../clanarine-clan.service';
import { Router } from '@angular/router';
import { ClanoviService } from '../clanovi.service';

@Component({
  selector: 'app-clanarine-clan',
  templateUrl: './clanarine-clan.component.html',
  styleUrl: './clanarine-clan.component.scss'
})
export class ClanarineClanComponent implements OnInit, OnDestroy {

  constructor(
    public mod: ModulService,
    public srvClanClan: ClanarineClanService,
    private router: Router,
    public srvClanovi: ClanoviService
  ) { }


  ngOnInit(): void {

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.mod.objKor.token;
    }

    if (tok) {
      this.mod.provjeraTokena(tok, () => {

        this.mod.provjeraPrijaveTrenOper(rez9 => {

          //Klijenti select
          this.srvClanClan.klijentiByClan((rez: Kor[]) => {
            console.log(rez);
            this.srvClanClan.objKlijenti = rez;
          });

          //Učitavanje članarina
          this.srvClanClan.napraviFrmFilter(null, (rez: FrmFilter) => {
            console.log(rez);
            this.srvClanClan.clanarineByFilter(rez, (rez1: ClanarinaClan[]) => {
              console.log(rez1);
              this.srvClanClan.objClanarine = rez1;
            });
          });

        });

      });

    } else {
      this.mod.quickClean();
      this.router.navigate(['/naslovnica']);
    }

  }

  //Image source
  imgSrc(img, imgSize) {
    return 'fitNet/assets/clanovi/' + img + '.jpg?timestamp=' + imgSize;
  }

  //Boja članarine
  clanColor(x: number) {

    x = Number(x);

    if (x > 5) {
      return '#27ad77'; //zelena
    } else if (x <= 5 && x > 3) {
      return '#ff6000'; //narančasta
    } else if (x <= 3 && x > 0) {
      return 'red'; //crvena
    } else if (x <= 0) {
      return '#b1aeaa'; //siva
    }

  }

  ngOnDestroy(): void {
    document.getElementById('btnSidebarClose').click();
  }

}
