<div class="container" style="padding: 0px; scrollbar-width: none;">

    <div class="sticky d-flex justify-content-end" *ngIf="!standAlone" style="padding-top: 60px; opacity: 1;">
        <div class="alert alert-info d-flex" style="cursor: pointer; margin: 0; background-color: #efefef; opacity: 0.9;">

            <div (click)="standAlone = true" data-toggle="modal" data-target="#mdlHomeScreen">
                <i class="fa fa-download" style="margin-right: 10px;" aria-hidden="true"></i> preuzmi aplikaciju
            </div>

            <div style="margin-left: 20px;" (click)="standAlone = true">
                X
            </div>

            <!-- <div class="row d-flex">
                <div class="col col-1" style="padding: 0; text-align: left;" (click)="standAlone = true">
                    X
                </div>
                <div class="col" style="padding: 0; text-align: end;" (click)="standAlone = true" data-toggle="modal" data-target="#mdlHomeScreen">
                    <div>
                        <i class="fa fa-download" style="margin-right: 10px;" aria-hidden="true"></i> preuzmi aplikaciju
                    </div>
                </div>
            </div> -->
    
        </div>
    </div>

    <div class="row" style="opacity:0.8;"
        [ngStyle]="{'padding-top': standAlone ? '0px' : '0px', 'margin-top': standAlone ? '50px' : '-60px'}">

        <!-- Što je fitNET-->
        <div class="card col-md-4" style="padding-top:4px">
            <img class="card-img-top" src="fitNet/assets/main7.jpg" alt="Card image cap">
            <div class="card-img-overlay text-light">
                <h1 class="card-title">fitNET</h1>
            </div>
            <div class="card-body">
                <h5 class="card-title">Što je fitNET?!</h5>
                <p class="card-text text-justify">
                    fitNET je mreža namijenjena fitness ili sličnim centrima pomoću koje možete jednostavno, bilo kada i
                    s bilo kojeg mjesta pratiti stanje u centru (evidencije članova / članarina, jednostavna
                    autorizacija članova putem RF čip kartice ili mobilne aplikacije,
                    trenutni broj posjetitelja, izdavanje opreme...).
                </p>
                <a href="#" class="btn btn-outline-dark">Više...</a>
            </div>
        </div>

        <!-- Poslovni korisnici -->
        <div class="col-md-4 card" style="padding-top:4px">
            <img class="card-img-top" src="fitNet/assets/main6.jpg" alt="Card image cap">
            <div class="card-img-overlay text-light">
                <h1 class="card-title">fitNET</h1>
            </div>
            <div class="card-body">
                <h5 class="card-title">Poslovni korisnici</h5>
                <p class="card-text text-justify">
                    fitNET je mreža namijenjena fitness ili sličnim centrima pomoću koje možete jednostavno, bilo kada i
                    s bilo kojeg mjesta pratiti stanje u centru (evidencije članova / članarina, jednostavna
                    autorizacija članova putem RF čip kartice ili mobilne aplikacije,
                    trenutni broj posjetitelja, izdavanje opreme...).
                </p>
                <a href="#" class="btn btn-outline-dark">Više...</a>
            </div>
        </div>

        <!-- Privatni korisnici -->
        <div class="col-md-4 card" style="padding-top:4px">
            <img class="card-img-top" src="fitNet/assets/main5.jpg" alt="Card image cap">
            <div class="card-img-overlay text-light">
                <h1 class="card-title">fitNET</h1>
            </div>
            <div class="card-body">
                <h5 class="card-title">Privatni korisnici</h5>
                <p class="card-text text-justify">
                    fitNET je mreža namijenjena fitness ili sličnim centrima pomoću koje možete jednostavno, bilo kada i
                    s bilo kojeg mjesta pratiti stanje u centru (evidencije članova / članarina, jednostavna
                    autorizacija članova putem RF čip kartice ili mobilne aplikacije,
                    trenutni broj posjetitelja, izdavanje opreme...).
                </p>
                <a href="#" class="btn btn-outline-dark">Više...</a>
            </div>
        </div>

    </div>


</div>

<button hidden="true" id="btnPoslano" class="btn btn-info" data-toggle="modal" data-target="#modalPoslanEmail">Modal
    poslan e-mail</button>

<!--MODAL POSLAN e-mail-->
<div class="modal fade" id="modalPoslanEmail" tabindex="-1" role="dialog" aria-labelledby="Modal Poslan email"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="modalPoslanEmailLabel">Provjerite e-mail!</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    Na e-mail adresu {{mod.objLogin.kor}} vam je poslan link za aktivaciju računa. Nakon što aktivirate
                    račun možete početi s korištenjem aplikacije!
                </p>
                <span>Ugodan rad Vam želimo!</span>
            </div>
            <div class="modal-footer">
                <button id="btnPolsanMailClose" type="button" class="btn btn-outline-success btn-sm btn-block"
                    data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!--Prvi login-->
<button hidden="true" id="btnMdlPrviLogin" class="btn btn-info" data-toggle="modal" data-target="#mdlPrviLogin">Modal
    poslan e-mail</button>
<div class="modal fade" id="mdlPrviLogin" tabindex="-1" role="dialog" aria-labelledby="mdlPrviLoginLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlPrviLoginLabel">Aktivacija!</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    Vaš račun je aktiviran!
                    Možete preuzeti aplikaciju ili se izravno na starnici prijaviti svojim korisničkim imenom i lozinkom.
                    Zahvaljujemo što ste odabrali fitNet!
                </p>
                <span>Ugodan rad Vam želimo!</span>
            </div>
            <div class="modal-footer">
                <button id="btnMdlPrviLoginClose" type="button" class="btn btn-outline-success btn-sm btn-block"
                    data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!--MODAL ADD TO HOME SCREEN INSTRUKCIJE-->
<button hidden="true" id="btnMdlHomeScreen" class="btn btn-info" data-toggle="modal" data-target="#mdlHomeScreen">Modal
    poslan e-mail</button>

<div class="modal fade" id="mdlHomeScreen" tabindex="-1" role="dialog" aria-labelledby="mdlHomeScreenLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header">
                <h6 class="modal-title" id="modalPoslanEmailLabel">Instaliranje aplikacije</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <div *ngIf="os == 'Windows'">
                    
                    <div class="d-flex">
                        <div style="margin-right: 15px;">
                            1. Kliknite gumb "install"
                        </div>
                        <div>
                            <img src="/assets/winAddHomeScreen.png" width="30px" height="25px" alt="">
                        </div>
                    </div>

                    <div style="margin-top: 15px;">
                        <div>
                            2. Iz skočnog prozora odaberite opciju "instaliraj"
                        </div>
                        <div style="text-align: center;">
                            <img src="/assets/winInstall1.jpg" width="400px" height="150px" alt="">
                        </div>
                    </div>

                </div>

                <div *ngIf="os == 'iOS' || os == 'Mac OS'">

                    <div class="d-flex">
                        <div style="margin-right: 15px;">
                            1. Kliknite "share button"
                        </div>
                        <div>
                            <img src="/assets/iosShare.png" width="30px" height="25px" alt="">
                        </div>
                    </div>

                    <div style="margin-top: 15px;">
                        <div>
                            2. Iz izbornika odaberite opciju "Add to home screen"
                        </div>
                        <div style="text-align: center;">
                            <img src="/assets/iosAddHomeScreen.png" width="300px" height="40px" alt="">
                        </div>
                    </div>

                    <div style="margin-top: 15px;">
                        <div>
                            3. Potvrdite instalaciju klikom na "Add"
                        </div>
                        <div style="text-align: center;">
                            <img src="/assets/iosAddEnd.jpg" width="300px" height="150px" alt="">
                        </div>
                    </div>

                </div>

                <div *ngIf="os == 'Android'">

                    <div class="d-flex">
                        <div style="margin-right: 15px;">
                            1. Kliknite ikonu za dodatni izbornik
                        </div>
                        <div>
                            <mat-icon>more_vert</mat-icon>
                        </div>
                    </div>

                    <div style="margin-top: 15px;">
                        <div style="margin-right: 15px;">
                            2. Kliknite "Dodaj na početni zaslon / Add to home screen"
                        </div>
                        <div style="text-align: center;">
                            <img src="/assets/androidAddHomeScreen.jpg" width="300px" height="50px" alt="">
                        </div>
                    </div>

                    <div style="margin-top: 15px;">
                        <div style="margin-right: 15px;">
                            3. Odaberite opciju "Instaliraj"
                        </div>
                        <div style="text-align: center;">
                            <img src="/assets/androidIstall1.jpg" width="300px" height="200px" alt="">
                        </div>
                    </div>

                    <div style="margin-top: 15px;">
                        <div style="margin-right: 15px;">
                            4. Potvrdite instalaciju klikom na "Instaliraj"
                        </div>
                        <div style="text-align: center;">
                            <img src="/assets/androidInstall2.jpg" width="250px" height="150px" alt="">
                        </div>
                    </div>
                    
                </div>

            </div>

            <div class="modal-footer">
                <button id="btnMdlHomeScreenClose" type="button" class="btn mybtn-cancel w-100"
                    data-dismiss="modal">Zatvori</button>
            </div>

        </div>
    </div>
</div>