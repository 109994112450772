import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModulService } from '../modul.service';

@Component({
  selector: 'app-pos-jed-hamburger',
  templateUrl: './pos-jed-hamburger.component.html',
  styleUrls: ['./pos-jed-hamburger.component.scss']
})
export class PosJedHamburgerComponent implements OnInit {

  @Output() hamburgerClickEmitt = new EventEmitter<boolean>();

  constructor(
    public mod: ModulService
  ) { }

  ngOnInit() {
  }

  hamburgerClick() {
    this.hamburgerClickEmitt.emit(true);
  }

}
