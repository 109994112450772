<div style="text-align: center;">

    <p style="font-size: 1.3rem; margin: 0; padding-left: 5px;">
        {{srvClanovi.frmPrijava.controls['ime'].value}}</p>

        <div style="font-weight: bold; text-align: left; padding-left: 5px;">
            Individualno vježbanje
        </div>

    <div style="padding-left: 5px;">

        <div *ngIf="srvClanovi.clanarineByClan && srvClanovi.frmPrijava.get('vrstaIDcl').value == 1">
            <div *ngFor="let x of srvClanovi.clanarineByClan" class="d-flex justify-content-center"
                [ngStyle]="{'color': claColor(x)}">

                <div>
                    {{x.cvOpis2}}
                </div>
                <!-- <div *ngIf="x.intPlacena == 1" style="margin-left: 20px;">
                    [{{x.claIznos}}]
                </div> -->
                <div class="d-flex justify-content-start" style="justify-content: start !important;">
                    <div style="margin-left: 10px;">
                        <!-- {{'[ ' + (x.cvTjedno ? x.cvTjedno + 'x tj.' : 'neograničeno') + ' ]'}}  --><span *ngIf="x.intPlacena == 0" style="color: red; font-weight: bold;">!</span>
                    </div>
                    <div style="margin-left: 20px;">
                        {{' do ' + (x.claVrijediDO | amDateFormat: 'DD.MM.YYYY.')}}
                    </div>
                </div>

            </div>
        </div>

        <div *ngIf="!srvClanovi.clanarineByClan && srvClanovi.frmPrijava.get('vrstaIDcl').value == 1"
            style="color: #971111" Style="padding: 0 10px">
            nema važeću članarinu
        </div>

        <div *ngIf="srvClanovi.frmPrijava.get('vrstaIDcl').value == 2" style="color: blue;">
            Trener
        </div>

        <div *ngIf="srvClanovi.frmPrijava.get('vrstaIDcl').value == 3" style="color: green;">
            Operater
        </div>

    </div>

</div>


<form [formGroup]="srvClanovi.frmPrijava" class="input-slim" style="text-align: center;">

    <div style="margin: 10px 0;">
        <div class="row">
            <div class="col col-6" style="padding: 5px;">
                <!--Datepicker Datum OD-->
                <mat-form-field style="width: 100%; text-align: center;">
                    <mat-label>Datum</mat-label>
                    <input matInput name="dat2" formControlName="priDat" 
                        style="text-align: center;" [matDatepicker]="picker1" placeholder="Datum">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker1></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col col-3" style="padding: 5px;">

                <mat-form-field style="width: 100%; text-align: center;">
                    <mat-label>Sati</mat-label>
                    <input matInput type="tel" formControlName="priH" placeholder="Sati" style="text-align: center;"
                        required>
                </mat-form-field>

            </div>
            <div class="col col-3" style="padding: 5px;">
                <mat-form-field style="width: 100%; text-align: center;">
                    <mat-label>Min.</mat-label>
                    <input matInput type="tel" formControlName="priMin" placeholder="Min." style="text-align: center;"
                        required>
                </mat-form-field>
            </div>


        </div>

    </div>

    <div *ngIf="mod.dvoranePjed" style="margin: 10px 0;">
        <mat-form-field style="width: 100%;">
            <mat-label>Dvorana</mat-label>
            <mat-select formControlName="dvoranaIDtren">
                <mat-option [value]="'0'">-</mat-option>
                <mat-option *ngFor="let x of mod.dvoranePjed" [value]="x.dvoID">
                    {{x.opisDvo}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div>
        <mat-error
            *ngIf="srvClanovi.frmPrijava.controls['priDat'].errors || srvClanovi.frmPrijava.controls['priH'].errors || srvClanovi.frmPrijava.controls['priMin'].errors">
            Sva polja moraju biti ispravno popunjena</mat-error>
    </div>

</form>

<div class="row" style="width: 100%; margin-top: 2px;">
    <div class="col" style="padding: 5px;">
        <button type="submit" class="btn {{srvClanovi.frmPrijava.valid ? 'mybtn-success' : 'mybtn-danger'}}"
            (click)="prijaviClana()" style="width: 100%;">PRIJAVI</button>
    </div>

</div>


<div *ngIf="srvClanovi.aktZaClana">

    <div style="margin: 15px 0; text-align: center; background-color: #dfdfdf; padding: 5px;">
        Prijava u grupu:
    </div>

    <div *ngFor="let x of srvClanovi.aktZaClana" style="padding: 3px 0;">

        <div class="card" style="padding: 8px;">

            <div style="font-weight: bold;">
                {{x.grpOpis + ' ' + (x.PocetakAkt | amDateFormat: 'HH:mm') + ' - '
                + (x.KrajAkt | amDateFormat: 'HH:mm') + 'h'}}
            </div>

            <div *ngFor="let y of x.clanarine" class="d-flex">
                <div [ngStyle]="{'color': claColor(x)}" style="margin-right: 5px;">
                    {{'Članarina do ' + (y.claVrijediDO | amDateFormat: 'DD.MM.YYYY.')}}
                </div>
                <div *ngIf="y.intPlacena == 0" style="color: red; font-weight: bold;">
                    !
                </div>
            </div>

            <div *ngIf="!x.clanarine" style="color: #971111">
                nema važeću članarinu
            </div>

            <div>
                <button type="button" class="btn mybtn-success" (click)="prijavaGrupa(x)"
                    style="width: 100%;">PRIJAVI</button>
            </div>

        </div>

    </div>

</div>