import { Component, OnInit } from '@angular/core';
import { ModulService } from '../modul.service';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../validatori';
import { HttpClient } from '@angular/common/http';
import { KorisniciService } from '../korisnici.service';

@Component({
  selector: 'app-promjena-lozinke',
  templateUrl: './promjena-lozinke.component.html',
  styleUrl: './promjena-lozinke.component.scss'
})
export class PromjenaLozinkeComponent implements OnInit {

  constructor(
    public mod: ModulService,
    public srvkorisnici: KorisniciService
  ) { }

  ngOnInit(): void {
    this.srvkorisnici.napraviFrmPromjenaLozinke();
  }


 

}
