<form *ngIf="srvDvorane.frmDvorana" [formGroup]="srvDvorane.frmDvorana">

    <mat-form-field class="w-100">
        <mat-label>Naziv dvorane</mat-label>
        <input autocomplete="off" autocomplete="off" matInput type="text" formControlName="opisDvo" placeholder="naziv" required>
        <mat-error *ngIf="srvDvorane.frmDvorana.controls['opisDvo'].errors?.required">Polje je obavezno za unos</mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Napomena</mat-label>
        <textarea autocomplete="off" matInput type="text" formControlName="napomenaDvo" placeholder="napomena"></textarea>
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Kapacitet (broj osoba)</mat-label>
        <input autocomplete="off" matInput type="number" formControlName="kapacitetDvo" placeholder="broj osoba" required>
        <mat-error *ngIf="srvDvorane.frmDvorana.controls['kapacitetDvo'].errors?.required">Polje je obavezno za unos</mat-error>
        <mat-error *ngIf="srvDvorane.frmDvorana.controls['kapacitetDvo'].errors?.min">Broj mora biti veći od 0</mat-error>
        <mat-error *ngIf="srvDvorane.frmDvorana.controls['kapacitetDvo'].errors?.max">Broj mora biti manji od 1000</mat-error>
    </mat-form-field>

    <div class="d-flex align-items-center" style="padding: 0px 10px; cursor: pointer;"
            (click)="aktivnaCheck()">
            <div style="font-size: 1.5rem;">
                <div *ngIf="srvDvorane.frmDvorana.controls['aktivnaDvo'].value == '0'" class="fa fa-square-o"
                    aria-hidden="true"></div>
                <div *ngIf="srvDvorane.frmDvorana.controls['aktivnaDvo'].value == '1'" class="fa fa-check-square-o"
                    aria-hidden="true"></div>
            </div>
            <div style="margin-left: 10px;">
                Aktivna
            </div>
        </div>

</form>