import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiRez, MjestaRH, ModulService, PosJedinice } from '../modul.service';
import { Dvorana, DvoraneService } from '../dvorane.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UploadService } from '../upload.service';
import { PostavkeService } from '../postavke.service';
import { KorEdit, KorisniciService } from '../korisnici.service';
import { MjestaService } from '../mjesta.service';
import { PosJediniceService } from '../pos-jedinice.service';
import { ClanarinaVrsta, ClanarineService } from '../clanarine.service';

@Component({
  selector: 'app-postavke',
  templateUrl: './postavke.component.html',
  styleUrl: './postavke.component.scss'
})

export class PostavkeComponent implements OnInit {

  constructor(
    public mod: ModulService,
    public srvDvorane: DvoraneService,
    public frmBuilder: FormBuilder,
    private uploadService: UploadService,
    public srvPostavke: PostavkeService,
    public srvKorisnici: KorisniciService,
    public srvMjesta: MjestaService,
    public srvPosJed: PosJediniceService,
    public srvClanarine: ClanarineService
  ) { }

  @ViewChild('pimage') pimage!: ElementRef;

  posJedTmp: PosJedinice;
  dvoTmp: Dvorana[];

  form: FormGroup;
  uploadResponse;

  ngOnInit(): void {

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.mod.objKor.token;
    }

    this.mod.provjeraTokena(tok, () => {

      this.mod.provjeraPrijaveTrenOper(rez9 => {

        this.mod.ucitajPosJed(() => {

          this.form = this.frmBuilder.group({
            avatar: ['']
          });

          //Poslovne jedinice
          this.posJedTmp = this.mod.objPosJedSelected;

          //Dvorane
          this.srvDvorane.dvoraneByPosJedId(this.posJedTmp?.PoslJedID, -1, (rez: Dvorana[]) => {
            this.dvoTmp = rez;
          });

          //Vrste članarina
          this.srvClanarine.clanarineVrsteByPosJedID(this.posJedTmp?.PoslJedID, (rez: ClanarinaVrsta[]) => {
            this.srvClanarine.objVrsteClanarina = rez;
          });


        });

      });

    });

  }

  //Image source
  imgSrc(korID, imgSize) {
    return 'fitNet/assets/clanovi/' + korID + '.jpg?timestamp=' + imgSize;
  }

  //Odabir poslovne jedinice
  odabirPosJed(x: PosJedinice) {
    this.posJedTmp = x;
    //Dvorane
    this.srvDvorane.dvoraneByPosJedId(this.posJedTmp.PoslJedID, -1, (rez: Dvorana[]) => {
      this.dvoTmp = rez;
    });
    //Vrste članarina
    this.srvClanarine.clanarineVrsteByPosJedID(this.posJedTmp.PoslJedID, (rez: ClanarinaVrsta[]) => {
      this.srvClanarine.objVrsteClanarina = rez;
    });
  }

  file;
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.form.get('avatar').setValue(this.file);
      /* document.getElementById('pimage').setAttribute('src', URL.createObjectURL(file)); */
      this.pimage.nativeElement.src = URL.createObjectURL(this.file);
    }
  }

  imgLoading: boolean = false;
  onSubmit() {
    this.imgLoading = true;
    const formData = new FormData();
    formData.append('avatar', this.form.get('avatar').value);

    this.uploadService.uploadFile(formData).subscribe(
      (res) => {
        this.uploadResponse = res;
        this.form.get('avatar').setValue(null);
        this.file = null;
        this.imgLoading = false;
        this.mod.objKor.img = this.mod.objKor.korisnikID;
        this.mod.objKor.imgSize = res.imgSize;
        this.mod.msgBox(1, "Spremljeno!", 1500);
      },
      (err) => {
        this.imgLoading = false;
        this.mod.msgBox(2, "Greška!", 1500);
      }
    );
  }

  //Brisanje profilne slike
  obrisiProfilnuStart() {
    document.getElementById('btnMdlObrisiSliku').click();
  }

  obrisiProfilnu() {

    this.srvPostavke.obrisiProfilnu(this.mod.objKor.korisnikID, (rez: string) => {

      if (rez == 'ok') {
        document.getElementById('btnMdlObrisiSlikuClose').click();
        this.mod.objKor.img = 0;
        this.mod.objKor.imgSize = 0;
        this.mod.msgBox(1, "OBRISANO!", 1000);
      } else {
        this.mod.msgBox(2, "Greška! Pokušajte ponovno!", 1500);
      }

    });

  }

  //Ureživanje općih podataka
  urediOpce() {
    this.srvKorisnici.napraviFrmKorEdit(this.mod.objKor, (rez: KorEdit) => {
      if (!this.srvMjesta.objMjestaRH) {

        this.srvMjesta.ucitajMjestaRH((rez: MjestaRH[]) => {

          this.srvMjesta.objMjestaRH = rez;

          this.mjestaListenerKorisnik();

        });

      } else {
        this.mjestaListenerKorisnik();
      }
      document.getElementById('btnMdlKorEdit').click();
    });
  }

  //listener za mjesta - korisnik
  mjestaListenerKorisnik() {
    //Listener za Mjesta 2 (posl. jed.) autocomplete
    this.srvKorisnici.frmKorEdit.get('Mjesto').valueChanges.subscribe(value => {
      if (value.length >= 3) {
        this.srvKorisnici.filtrirajMjestaRH2(value);
      } else {
        this.srvKorisnici.fltMjesta2 = [];
      }
    });
  }

  //Spremanje općih podataka
  spremiOpce() {

    if (this.srvKorisnici.frmKorEdit.get('Mjesto').value == '') {
      this.srvKorisnici.frmKorEdit.get('MjestoID').setValue(0);
    }

    this.srvKorisnici.frmKorEdit.markAllAsTouched();

    if (this.srvKorisnici.frmKorEdit.valid) {

      this.srvKorisnici.spremiOpce(this.srvKorisnici.frmKorEdit.value, (rez: string) => {

        if (rez == 'ok') {

          document.getElementById('btnMdlKorEditClose').click();

          this.mod.objKor.Ime = this.srvKorisnici.frmKorEdit.get('Ime').value;
          this.mod.objKor.Prezime = this.srvKorisnici.frmKorEdit.get('Prezime').value;
          this.mod.objKor.Adresa = this.srvKorisnici.frmKorEdit.get('Adresa').value;
          this.mod.objKor.MjestoID = this.srvKorisnici.frmKorEdit.get('MjestoID').value;
          this.mod.objKor.Mjesto = this.srvKorisnici.frmKorEdit.get('Mjesto').value;
          this.mod.objKor.OIB = this.srvKorisnici.frmKorEdit.get('OIB').value;

          this.mod.msgBox(1, "SPREMLJENO", 1000);

        } else {
          this.mod.msgBox(2, "Greška! Promjene nisu spremljene", 1500);
        }

      });


    } else {
      this.mod.msgBox(2, "Greška! Provjerite ispravnost polja", 1500);
    }

  }

  //INSERT / UPDATE poslovna jedinica - START
  unosUpdatePosJed(val: PosJedinice) {

    this.srvPosJed.napraviFrmPosJed(val, (rez: PosJedinice) => {

      this.srvPosJed.frmPosJed.get('KlijentIDpos').setValue(this.mod.objKor.korisnikID);

      if (!val) {
        this.srvPosJed.frmPosJed.get('intIsto').setValue(0);
      }

      if (!this.srvMjesta.objMjestaRH) {

        this.srvMjesta.ucitajMjestaRH((rez: MjestaRH[]) => {

          this.srvMjesta.objMjestaRH = rez;

          this.mjestaListenerPosJed();

        });

      } else {
        this.mjestaListenerPosJed();
      }
      document.getElementById('btnMdlPosJedEdit').click();

    });

  }

  //listener za mjesta - poslovna jedinica
  mjestaListenerPosJed() {
    //Listener za Mjesta 2 (posl. jed.) autocomplete
    this.srvPosJed.frmPosJed.get('Mjesto').valueChanges.subscribe(value => {
      if (value.length >= 3) {
        this.srvKorisnici.filtrirajMjestaRH2(value);
      } else {
        this.srvKorisnici.fltMjesta2 = [];
      }
    });
  }


  //Potvrda spremanja - posloven jedinice
  spremiPosJed() {

    if (this.srvPosJed.frmPosJed.get('intIsto').value == 1) {
      this.srvPosJed.frmPosJed.get('NazivPos').setValue(this.mod.objKor.Ime);
      this.srvPosJed.frmPosJed.get('AdresaPos').setValue(this.mod.objKor.Adresa);
      this.srvPosJed.frmPosJed.get('MjestoIDpos').setValue(this.mod.objKor.MjestoID);
      this.srvPosJed.frmPosJed.get('Mjesto').setValue(this.mod.objKor.Mjesto);
    }

    this.srvPosJed.frmPosJed.markAllAsTouched();

    if (this.srvPosJed.frmPosJed.valid) {

      if (this.srvPosJed.frmPosJed.get('Mjesto').value == '') {
        this.srvPosJed.frmPosJed.get('MjestoIDpos').setValue(0);
      }

      this.srvPosJed.unosUpdatePosJed(this.srvPosJed.frmPosJed.value, (rez: ApiRez) => {

        if (rez && rez.rez == 'ok') {

          document.getElementById('btnMdlPosJedEditClose').click();

          this.posJedTmp = rez.obj;

          if (this.srvPosJed.frmPosJed.get('PoslJedID').value == 0) {
            this.mod.objPosJed.push(this.posJedTmp);
          } else {
            this.mod.objPosJed.forEach((val, ind) => {
              if (val.PoslJedID == this.posJedTmp.PoslJedID) {
                this.mod.objPosJed[ind] = this.posJedTmp;
              }
            });
          }

          if (this.mod.objPosJedSelected && this.mod.objPosJedSelected.PoslJedID == this.posJedTmp.PoslJedID) {
            this.mod.objPosJedSelected = this.posJedTmp;
          }

          this.mod.msgBox(1, "SPREMLJENO!", 1000);

        } else {
          this.mod.msgBox(2, "Greška priliom spremanja!", 1500);
        }

      });

    } else {
      this.mod.msgBox(2, "Greška! Provjerite ispravnost polja", 1500);
    }

  }


  //Dvorana insert / edit - start
  dvoInsertEditStart(dvo: Dvorana) {

    this.srvDvorane.napraviFrmDvorana(dvo, (rez: Dvorana) => {
      this.srvDvorane.frmDvorana.get('pjedIDdvo').setValue(this.posJedTmp.PoslJedID);
      document.getElementById('btnMdlDvoranaEdit').click();
    });

  }

  //Spremi dvoranu
  spremiDvoranu() {

    this.srvDvorane.frmDvorana.markAllAsTouched();

    if (this.srvDvorane.frmDvorana.valid) {

      this.srvDvorane.dvoInsertEdit(this.srvDvorane.frmDvorana.value, (rez: ApiRez) => {

        if (rez && rez.rez == 'ok') {

          document.getElementById('btnMdlDvoranaEdit').click();

          if (!this.srvDvorane.frmDvorana.get('dvoID').value) {
            if (!this.dvoTmp) {
              this.dvoTmp = [];
            }
            this.dvoTmp.push(rez.obj);
          } else {
            this.dvoTmp.forEach((val, ind) => {
              if (val.dvoID == rez.obj.dvoID) {
                this.dvoTmp[ind] = rez.obj;
              }
            });
          }

          this.mod.msgBox(1, "SPREMLJENO!", 1000);

        } else {
          this.mod.msgBox(2, "Greška! Provjerite ispravnost polja", 1500);
        }

      });

    } else {
      this.mod.msgBox(2, "Greška! Provjerite ispravnost polja", 1500);
    }

  }

  //Unos / Edit članarine vrtsta
  clanarineVrstaInsertEditStart(val: ClanarinaVrsta) {

    this.srvClanarine.napraviFrmClanarinaVrsta(val, (rez) => {
      document.getElementById('btnMdlClanarinaVrsta').click();
    });

  }

  //Spremi vrstu članarine
  spremiVrstuClanarine() {

    this.srvClanarine.frmClanarinaVrsta.markAllAsTouched();

    if (this.srvClanarine.frmClanarinaVrsta.valid) {

      this.srvClanarine.vrstaClanrineInsertUpdate(this.srvClanarine.frmClanarinaVrsta.value, (rez: ApiRez) => {

        if (rez && rez.rez == 'ok') {

          document.getElementById('btnMdlClanarinaVrstaClose').click();

          if (this.srvClanarine.frmClanarinaVrsta.get('cvID').value == 0) {

            if (!this.srvClanarine.objVrsteClanarina) {
              this.srvClanarine.objVrsteClanarina = []
            }

            this.srvClanarine.objVrsteClanarina.push(rez.obj);

          } else {

            this.srvClanarine.objVrsteClanarina.forEach((val, ind) => {
              if (val.cvID == rez.obj.cvID) {
                this.srvClanarine.objVrsteClanarina[ind] = rez.obj;
              }
            });

          }

          this.mod.msgBox(1, "SPREMLJENO!", 700);

        } else {
          this.mod.msgBox(2, rez.errMsg, 1500);
        }

      });

    } else {
      this.mod.msgBox(2, "Greška! Provjerite ispravnost polja", 1500);
    }

  }

  //Info poruka - vrste članarina
  msgInfoVrsteClanarina() {

    let msg: string = 'Vrste članarina se odnose samo na individualno vježbanje. Za grupe iznos članarine upisujete prilikom kreiranja grupe.'

    this.mod.msgInfo(msg);

  }

  //Promjena lozinke
  promijeniLozinkuStart() {
    document.getElementById('btnMdlPromjenaLozinke').click()
  }

  //promijeni loziku - end
  promijeniLozinku() {

    this.srvKorisnici.frmPromjenaLozinke.markAllAsTouched();

    if (this.srvKorisnici.frmPromjenaLozinke.valid) {

      this.srvKorisnici.promijeniLozinku(this.srvKorisnici.frmPromjenaLozinke.value, (rez: ApiRez) => {

        if (rez && rez.rez == 'ok') {

          document.getElementById('btnMdlPromjenaLozinkeClose').click();

          this.mod.objKor.token = rez.obj;

          this.mod.msgBox(1, "SPREMLJENO", 1000);

        } else {
          this.mod.msgBox(2, rez.errMsg, 1000);
        }

      });

    } else {
      this.mod.msgBox(2, "Popunite ispravno sva polja", 1000);
    }    

  }

}
