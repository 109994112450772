<div *ngIf="srvKalendar.frmKalendarUnos">
    <form [formGroup]="srvKalendar.frmKalendarUnos" style="font-size: 1.5rem;">

        <!-- <div class="row" style="font-size: 1rem;">
            Odabrani termin: {{srvKalendar.frmKalendarUnos.controls['hOD'].value}} : {{srvKalendar.frmKalendarUnos.controls['mOD'].value}}
        </div> -->


        <div class="row" style="font-size: 1rem;">
            <div class="col col-6">
                Početak
            </div>
            <div class="col col-6">
                Trajanje
            </div>
        </div>

        <div class="row row-vrijeme input-slim" style="padding-top: 15px;">

            <div class="col col-6 d-flex justify-content-between">
                <mat-form-field style="width: 45%;">
                    <mat-label>Sati</mat-label>
                    <input matInput type="tel" formControlName="hOD" placeholder="Sati" required>
                </mat-form-field> :

                <mat-form-field style="width: 45%;">
                    <mat-label>Min.</mat-label>
                    <input matInput type="tel" formControlName="mOD" placeholder="Min." required>
                </mat-form-field>
            </div>


            <div class="col col-6">
                <!-- <mat-form-field style="width: 45%;">
                    <mat-label>Sati</mat-label>
                    <input matInput type="tel" formControlName="hDO" placeholder="Sati" required>
                </mat-form-field> : -->

                <mat-form-field style="width: 100%;">
                    <mat-label>Minuta</mat-label>
                    <input matInput type="tel" formControlName="mDO" placeholder="Min." required>
                </mat-form-field>
            </div>
        </div>

        <div style="font-size: 1.2rem;">
            <div>
                Status:
            </div>
            <div class="mat-sel-termin">
                <mat-form-field style="text-align: center; width: 100%;">
                    <!-- <mat-label>Izbornik:</mat-label> -->
                    <mat-select formControlName="statusAkt">
                        <mat-option [value]="0">Na rasporedu</mat-option>
                        <mat-option [value]="1">Otkazano</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="srvKalendar.objDvorane" style="font-size: 1.2rem;">
            <div>
                Dvorana
            </div>
            <div>
                <mat-form-field style="font-size: 1.5rem; width: 100%;">
                    <mat-select formControlName="dvoranaIdAkt">
                        <mat-option [value]="'0'">Nije određeno</mat-option>
                        <mat-option *ngFor="let x of srvKalendar.objDvorane" [value]="x.dvoID">{{x.opisDvo}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>

        <div *ngIf="srvAktivnosti.objTreneriByAktID" class="card akt-treneri">
            <div>
                Treneri
            </div>
            <div class="d-flex flex-wrap">
                <div *ngFor="let x of srvAktivnosti.objTreneriByAktID" style="margin-left: 5px; min-width: 150px;">

                    <div class="row" style="cursor: pointer;" (click)="!x.vtaID ? dodajTreneraUakt(srvKalendar.frmKalendarUnos.get('aktGrpID').value, x.clanID) : obrisiTreneraAkt(x.vtaID, srvKalendar.frmKalendarUnos.get('aktGrpID').value)">
                        <div class="col col-2">
                            <i *ngIf="!x.vtaID" class="fa fa-square-o" style="align-items: center;"
                                aria-hidden="true"></i>
                            <i *ngIf="x.vtaID" class="fa fa-check-square-o" aria-hidden="true"></i>
                        </div>
                        <div class="col d-flex flex-column align-items-start" style="margin-left: 5px;">
                            <div>
                                {{x.Ime + ' ' + x.Prezime}} <!-- <span *ngIf="!x.vtgID" style="margin-left: 5px; color: orange;">! trener više nije u grupi</span> -->
                            </div>
                            <div *ngIf="!x.vtgID" style="color: #d53503;">
                                ! trener više nije u grupi
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </form>
</div>