import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModulService } from '../modul.service';
import { ClanoviService, FrmPosjetaEdit, TrenutnoPrisutni } from '../clanovi.service';
import * as moment from 'moment';
import { Dvorana, DvoraneService } from '../dvorane.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pregled-posjeta',
  templateUrl: './pregled-posjeta.component.html',
  styleUrl: './pregled-posjeta.component.scss'
})
export class PregledPosjetaComponent implements OnInit, OnDestroy {

  constructor(
    public mod: ModulService,
    public srvClanovi: ClanoviService,
    public srvDvorane: DvoraneService,
    private router: Router
  ) { }

  ngOnInit(): void {

    if (localStorage.getItem("clanID")) {
      this.srvClanovi.clanID = JSON.parse(localStorage.getItem("clanID"));
    }

    console.log(this.mod.objKor);
    
    if (this.mod.objKor.vrstaID != 2) {
      this.router.navigate(['/naslovnica']);
    }

  }

  // //Spremanje promjena

  //Start
  posjetaEditStart(val: TrenutnoPrisutni) {

    let obj: FrmPosjetaEdit = {
      trenID: val.trenID,
      dOd: moment(val.vrijemePrijava),
      hOd: moment(val.vrijemePrijava).format('HH'),
      mOd: moment(val.vrijemePrijava).format('mm'),
      dDo: moment(val.vrijemeOdjava),
      hDo: moment(val.vrijemeOdjava).format('HH'),
      mDo: moment(val.vrijemeOdjava).format('mm'),
      GrupaIDtren: val.GrupaIDtren,
      dvoID: val.dvoranaIDtren,
      fingerprint: this.mod.fingerprint()
    }


    //Učitavanje dvorana
    this.srvDvorane.dvoraneByPosJedId(this.mod.objPosJedSelected.PoslJedID, -1, (rez: Dvorana[]) => {
      this.srvClanovi.objDvorane = rez;
      //Frm Pregled posjeta edit
      this.srvClanovi.napraviFrmPosjetaEdit(obj, (rez) => {
        document.getElementById('btnMdlPosjetaEdit').click();
      });
    });

  }

  //Spremi
  spremiPosjetu() {

    this.srvClanovi.spremiPosjetu(this.srvClanovi.frmPosjetaEdit.value, (rez) => {

      if (rez) {

        document.getElementById('btnMdlPosjetaEditClose').click();

        this.srvClanovi.objPregldPosjetaClan.forEach((val, ind) => {
          if (val.trenID == rez.trenID) {
            this.srvClanovi.objPregldPosjetaClan.splice(ind, 1, rez);
          }
        });

        this.mod.msgBox(1, "SPREMLJENO", 700);

      } else {
        this.mod.msgBox(2, "Greška prilikom spremanja", 900);
      }

    });

  }

  ngOnDestroy(): void {
    localStorage.setItem("clanID", JSON.stringify(this.srvClanovi.clanID));
    this.srvClanovi.bolUredPosjeta = false;
  }

}
