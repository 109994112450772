import { Component } from '@angular/core';
import { ModulService } from '../modul.service';
import { NapredakService } from '../napredak.service';

@Component({
  selector: 'app-pn-mjerenje-edit',
  templateUrl: './pn-mjerenje-edit.component.html',
  styleUrl: './pn-mjerenje-edit.component.scss'
})
export class PnMjerenjeEditComponent {

  constructor(
    public mod: ModulService,
    public srvNapredak: NapredakService
  ) {}

  intFocus: number;

}
