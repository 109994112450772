import { Component, OnInit } from '@angular/core';
import { ModulService } from '../modul.service';
import { NapredakService, PnProgram } from '../napredak.service';

@Component({
  selector: 'app-napredak',
  templateUrl: './napredak.component.html',
  styleUrl: './napredak.component.scss'
})
export class NapredakComponent implements OnInit {

  constructor(
    public mod: ModulService,
    public srvNapredak: NapredakService,
  ) { }

  ngOnInit(): void {
    
    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.mod.objKor.token;
    }

    this.mod.provjeraTokena(tok, () => {

      this.mod.provjeraPrijaveTrenOper(() => {

        this.mod.ucitajPosJed(() => {
          

          //Učitavanje predložaka programa
          this.srvNapredak.pnFillByDopustenje((rez: PnProgram[]) => {
            console.log(rez);
            this.srvNapredak.objProgrami = rez;
          });


        });

      });

    });

  }

}
