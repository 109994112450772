import { Component } from '@angular/core';
import { NapredakService, PnProgram } from '../napredak.service';

@Component({
  selector: 'app-napredak-sidebar',
  templateUrl: './napredak-sidebar.component.html',
  styleUrl: './napredak-sidebar.component.scss'
})
export class NapredakSidebarComponent {

  constructor(
    public srvNapredak: NapredakService
  ) {}

  

}
