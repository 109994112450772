import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NaslovnicaComponent } from './naslovnica/naslovnica.component';
import { CpanelComponent } from './cpanel/cpanel.component';
import { AktivacijaComponent } from './aktivacija/aktivacija.component';
import { RegistracijaComponent } from './registracija/registracija.component';
import { PrisutniComponent } from './prisutni/prisutni.component';
import { ClanoviComponent } from './clanovi/clanovi.component';
import { ClanEditComponent } from './clan-edit/clan-edit.component';
import { ObavijestiPanelComponent } from './obavijesti-panel/obavijesti-panel.component';
import { GrupeComponent } from './grupe/grupe.component';
import { GrupaClanoviComponent } from './grupa-clanovi/grupa-clanovi.component';
import { ClanarineComponent } from './clanarine/clanarine.component';
import { TerminiPregledComponent } from './termini-pregled/termini-pregled.component';
import { ClanarinePregledComponent } from './clanarine-pregled/clanarine-pregled.component';
import { StatKlijentComponent } from './stat-klijent/stat-klijent.component';
import { StatKlPosjeteComponent } from './stat-kl-posjete/stat-kl-posjete.component';
import { ZahtjeviZpComponent } from './zahtjevi-zp/zahtjevi-zp.component';
import { PostavkeComponent } from './postavke/postavke.component';
import { VjezbeComponent } from './vjezbe/vjezbe.component';
import { FbVideoComponent } from './fb-video/fb-video.component';
import { PregledPosjetaComponent } from './pregled-posjeta/pregled-posjeta.component';
import { NapredakComponent } from './napredak/napredak.component';
import { PnPrgTemplateComponent } from './pn-prg-template/pn-prg-template.component';
import { PnPregledComponent } from './pn-pregled/pn-pregled.component';
import { ClanarineClanComponent } from './clanarine-clan/clanarine-clan.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/naslovnica',
    pathMatch: 'full'
  },
  {
    path: 'naslovnica',
    component: NaslovnicaComponent
  },
  {
    path: 'cpanel1',
    component: CpanelComponent
  },
  {
    path: 'aktivacija',
    component: AktivacijaComponent
  },
  {
    path: 'registracija',
    component: RegistracijaComponent
  },
  {
    path: 'prisutni',
    component: PrisutniComponent
  },
  {
    path: 'clanovi',
    component: ClanoviComponent
  },
  {
    path: 'clan',
    component: ClanEditComponent
  },
  {
    path: 'clanarine',
    component: ClanarineComponent
  },
  {
    path: 'obavijesti-panel',
    component: ObavijestiPanelComponent
  },
  {
    path: 'grupe',
    component: GrupeComponent
  },
  {
    path: 'grupa-clanovi',
    component: GrupaClanoviComponent
  },
  {
    path: 'termini-pregled',
    component: TerminiPregledComponent
  },
  {
    path: 'clanarine-pregled',
    component: ClanarinePregledComponent
  },
  {
    path: 'stat-klijent',
    component: StatKlijentComponent
  },
  {
    path: 'stat-kl-posjete',
    component: StatKlPosjeteComponent
  },
  {
    path: 'zahtjevi-zp',
    component: ZahtjeviZpComponent
  },
  {
    path: 'postavke',
    component: PostavkeComponent
  },
  {
    path: 'vjezbe',
    component: VjezbeComponent
  },
  {
    path: 'fbvideo',
    component: FbVideoComponent
  },
  {
    path: 'pregled-posjeta',
    component: PregledPosjetaComponent
  },
  {
    path: 'napredak',
    component: NapredakComponent
  },
  {
    path: 'pn-template',
    component: PnPrgTemplateComponent
  },
  {
    path: 'pn-pregled',
    component: PnPregledComponent
  },
  {
    path: 'clanarine-clan',
    component: ClanarineClanComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
