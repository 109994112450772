<div>

    <!-- Članarine -->
    <div class="card" style="padding: 5px;">

        <div *ngIf="srvClanEdit.objVazeceClanarine">

            <div style="color: #898989;">
                Članarina vrijedi do
            </div>

            <div *ngFor="let x of srvClanEdit.objVazeceClanarine" [ngStyle]="{'border-bottom': srvClanEdit.objVazeceClanarine.length > 1 ? 'solid 0.5px #cbc3c3' : 'none'}">
                <div class="d-flex justify-content-center"
                    style="font-size: 1.3rem; padding: 5px 0px; font-weight: bold;">
                    <div [ngStyle]="{'color': srvClanEdit.clrVazeca(x.claVrijediDO)}">
                        {{ (x.claVrijediDO | amDateFormat:'DD.MM.YYYY.')}}
                    </div>
                    <div style="margin-left: 15px; color: gray; font-weight: normal;">
                        <!-- {{x.grpOpis ? '' : '   ' + x.claIznos + '€'}} -->
                        {{x.claIznos + '€'}}
                    </div>
                    <div *ngIf="x.intPlacena == 0" style="margin-left: 5px; font-weight: bold; color: red;">
                        !
                    </div>
                </div>
                <div *ngIf="x.cvID" style="text-align: center;">
                    {{x.cvOpis2}}
                </div>
                <div class="d-flex justify-content-center">
                    <div style="color: #00003f;">
                        {{x.grpOpis}}
                    </div>
                    <div>
                        
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="!srvClanEdit.objVazeceClanarine" class="alert alert-warning" style="margin: 0;">
            Nema važeću članarniu
        </div>

        <div style="margin-top: 5px;">
            <button type="button" class="btn mybtn-cancel w-100" routerLink="/clanarine">Pregled članarina</button>
        </div>

    </div>

    <!-- Grupe -->
    <div style="margin-top: 10px; padding: 5px;">

        <div *ngIf="this.srvClanEdit.objGrupe">
            <div style="color: #898989;">
                Grupe
            </div>
    
            <div *ngFor="let x of this.srvClanEdit.objGrupe">
                <div style="font-weight: bold;">
                    {{x.grpOpis}}
                </div>
                <div>
                    {{'Član od ' + (x.DatumODvcg | amDateFormat:'DD.MM.YYYY.')}}
                </div>
                <div class="d-flex" style="cursor: pointer;" (click)="autoClanarine(x)">
                    <div class="d-flex align-items-center" style="font-size: 1.5rem;">
                        <div *ngIf="x.autoCl == 0" class="fa fa-square-o" aria-hidden="true"></div>
                        <div *ngIf="x.autoCl == 1" class="fa fa-check-square-o" aria-hidden="true"></div>
                    </div>
                    <div class="d-flex align-items-center" style="margin-left: 10px;" [ngStyle]="{'color': x.autoCl == 1 ? '#008b5c' : 'grey'}">
                        Automatsko obnavljanje članarine
                    </div>    
                </div>
            </div>
        </div>

        <div *ngIf="!this.srvClanEdit.objGrupe" class="alert alert-success" style="margin: 0;">
            Nema upisanih grupa
        </div>

        <!-- Upis člana u grupu -->
        <div style="margin-top: 10px; border-bottom: solid 0.5px grey; padding-bottom: 10px;">
            <button type="button" class="btn mybtn-success w-100" (click)="upisGrpStart()">Upiši člana u grupu</button>
        </div>

        <!-- Praćenje napretka -->
         <div style="margin-top: 15px; border-bottom: solid 0.5px lightgrey;">
            <div>
                Praćenje napretka
            </div>
            <div>
                <div *ngFor="let x of srvNapredak.objVePnVezaByClanID">
                    <div style="font-weight: bold;">
                        {{x.pnNaziv}}
                    </div>
                    <div>
                        {{'Početak praćenja: ' + (x.pnvDatum | amDateFormat: 'DD.MM.YYYY.')}}
                    </div>
                    <div>
                        <div class="row">
                            <div class="col col-12" style="padding: 5px;">
                                <button type="button" class="btn mybtn-blank w-100 h-100" (click)="novoMjerenjeStart(x)"><i class="fa fa-clock-o" style="margin-right: 5px; font-size: 1.5rem;" aria-hidden="true"></i>Novo mjerenje</button>
                            </div>
                            <div class="col col-12" style="padding: 5px;">
                                <button type="button" class="btn mybtn-blank w-100 h-100" (click)="ciljeviByPnvID(x.pnvID)"><i class="fa fa-flag-checkered" style="margin-right: 5px; font-size: 1.5rem;" aria-hidden="true"></i>Zadani ciljevi</button>
                            </div>
                            <div class="col col-12" style="padding: 5px;">
                                <button type="button" class="btn mybtn-blank w-100 h-100" (click)="pnPregled(x)"><i class="fa fa-line-chart" style="margin-right: 5px; font-size: 1.5rem;" aria-hidden="true"></i>Statistika</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div style="padding: 10px 0;">
                <button type="button" class="btn mybtn-success w-100" (click)="dodajProgramStart()">Dodaj program</button>    
            </div>
         </div>

        <!-- Pregled posjeta -->
        <div *ngIf="mod.objKor?.vrstaID == 2" style="margin-top: 10px;">
            <button type="button" class="btn mybtn-cancel w-100" routerLink="/pregled-posjeta">Pregled posjeta</button>
        </div>

        <!-- QR koda -->
        <div *ngIf="mod.objKor">
            <qrcode #parent [width]="250" (qrCodeURL)="onChangeURL($event)"  [qrdata]="fitNetID()" style="text-align: center;"></qrcode>
        </div>
        <div>
            <!-- <button routerLink="qrCodeDownloadLink" type="button" class="btn mybtn-cancel"><i class="fa fa-download" aria-hidden="true"></i> Preuzmi</button> -->
            <a type="button" class="btn mybtn-cancel" [href]="qrCodeDownloadLink" download="qrcode"><i class="fa fa-download" style="margin-right: 10px;" aria-hidden="true"></i> Preuzmi QR kod</a>
        </div>

    </div>

</div>