<form *ngIf="srvGrupe.frmClanGrupaUnos" [formGroup]="srvGrupe.frmClanGrupaUnos">

    <div *ngIf="srvGrupe.objGrupe">
        <!-- Grupa -->
        <mat-form-field style="width: 100%;">
            <mat-label>Grupe</mat-label>
            <mat-select formControlName="GrupaIDvcg">
                <mat-option *ngFor="let x of srvGrupe.objGrupe" [value]="x.grpID">
                    {{x.grpOpis}}</mat-option>
            </mat-select>
            <mat-error>morate odabrati grupu</mat-error>
        </mat-form-field>

        <!--Datepicker Datum OD-->
        <mat-form-field style="width: 170px; margin-right: 15px;">
            <mat-label>Datum upisa</mat-label>
            <input matInput name="dat3" formControlName="DatumODvcg" style="text-align: center;"
                [matDatepicker]="picker3" placeholder="Datum upisa">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker3></mat-datepicker>
            <mat-error>odaberite datum upisa</mat-error>
        </mat-form-field>
    </div>

    <div *ngIf="!srvGrupe.objGrupe">
        <div class="aler alert-info" style="text-align: center; padding: 30px 5px;">
            Nema dostupnih grupa
        </div>
    </div>

</form>