<div class="container" style="padding: 0px; height: 100%; max-height: 100%;">

    <div class="row" style="padding-top: 50px; height: 100%; max-height: 100%; opacity: 0.9;">

        <!--Lijeva kolona-->
        <div class="d-none d-md-block col-md-4 card" style="padding: 0px;">

            <app-postavke-sidebar></app-postavke-sidebar>

        </div>

        <!-- Desna kolona -->
        <div class="col card" style="padding: 0px; max-height: 100%;">

            <div class="d-flex flex-column"
                style="height: 100%; max-height: 100%; overflow-x: hidden; padding-bottom: 20px;">

                <div>
                    <app-pos-jed-hamburger></app-pos-jed-hamburger>
                </div>

                <div class="flex-grow-1" style="overflow-y: scroll; scrollbar-width: none;">

                    <!-- Korisnik -->

                    <!-- Profilna slika -->

                    <div *ngIf="mod.objKor" class="card card-2">

                        <div class="naslov">
                            Profilna slika
                        </div>

                        <div>
                            <div class="row">
                                <div class="col" style="padding: 5px;">
                                    <img id="pimage" #pimage name="pimage"
                                        style="border-radius: 50%; height: 150px; width: 150px;"
                                        src="{{imgSrc(mod.objKor.img, mod.objKor.imgSize)}}"
                                        onerror="this.onerror=null; this.src='fitNet/assets/clanovi/0.png'"
                                        class="img-fluid p-slika" alt="{Slika nije dostupna}">
                                </div>

                                <div>

                                </div>
                                <div class="col d-flex align-items-center">

                                    <div class="w-100">
                                        <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">

                                            <div style="padding: 5px;">
                                                <button type="button" class="btn mybtn-cancel w-100"
                                                    (click)="fileInput.click()">
                                                    <span>Učitaj sliku</span>
                                                    <input #fileInput type="file" (change)="onFileSelect($event)"
                                                        style="display:none;" />
                                                </button>
                                            </div>

                                            <div *ngIf="imgLoading" class="text-center" style="padding: 20px;">
                                                <div class="spinner-border" role="status">
                                                    <!-- <span class="visually-hidden">Loading...</span> -->
                                                </div>
                                                <div>
                                                    učitavam...
                                                </div>
                                            </div>

                                            <div *ngIf="file && !imgLoading" style="padding: 5px;">
                                                <button type="submit" class="btn mybtn-success w-100">Spremi
                                                    promjene</button>
                                            </div>

                                            <div *ngIf="!file && !imgLoading && mod.objKor.img" style="padding: 5px;">
                                                <button type="button" class="btn mybtn-danger w-100"
                                                    (click)="obrisiProfilnuStart()">Obriši profilnu
                                                    sliku</button>
                                            </div>

                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Opći podaci -->
                    <div *ngIf="mod.objKor" class="card card2">
                        <div class="naslov">
                            Opći podaci
                        </div>

                        <div class="row">

                            <div class="col" style="padding: 0;">

                                <div style="padding: 5px;">
                                    <div class="podnaslov">
                                        {{mod.objKor.Ime + ' ' + (mod.objKor.Prezime ? mod.objKor.Prezime : '')}}
                                    </div>
                                    <div>
                                        {{mod.objKor.Adresa && mod.objKor.Mjesto ? mod.objKor.Adresa + ', ' +
                                        mod.objKor.Mjesto
                                        : mod.objKor.Adresa ? mod.objKor.Adresa : mod.objKor.Mjesto
                                        ?
                                        mod.objKor.Mjesto : ''}}
                                    </div>
                                    <div>
                                        korisničko ime:
                                    </div>
                                    <div>
                                        {{mod.objKor.korIme}}
                                    </div>

                                </div>

                            </div>

                            <div class="col col-2 d-flex justify-content-center align-items-center"
                                style="cursor: pointer; padding: 0px;" (click)="urediOpce()">
                                <i class="material-icons" style="font-size: 2.5rem;">
                                    create
                                </i>
                            </div>

                        </div>

                        <div *ngIf="mod.objKor.social == 'false'" style="padding: 10px 5px;" (click)="promijeniLozinkuStart()">
                            <button type="button" class="btn mybtn-cancel w-100"><i class="fa fa-unlock-alt"
                                    style="margin-right: 10px;" aria-hidden="true"></i>Promijeni lozinku</button>
                        </div>

                    </div>

                    <!-- Poslovne jedinice -->
                    <div *ngIf="mod.objPosJed" class="card card2">
                        <div class="naslov">
                            {{mod.objPosJed.length == 1 ? 'Poslovna jedinica' : 'Poslovne jedinice'}}
                        </div>

                        <div *ngFor="let x of mod.objPosJed" style="padding: 5px; cursor: pointer;"
                            [ngStyle]="{'background-color': x?.PoslJedID == posJedTmp?.PoslJedID && mod.objPosJed?.length > 1 ? '#d3f3e0' : 'transparent'}"
                            (click)="odabirPosJed(x)">

                            <div class="row">

                                <div class="col" style="padding: 0px;">
                                    <div class="podnaslov">
                                        {{x.NazivPos}}
                                    </div>
                                    <div>
                                        {{x.AdresaPos}}
                                    </div>
                                    <div>
                                        {{x.Mjesto}}
                                    </div>
                                    <div>
                                        {{'kapacitet: ' + x.kapacitet}}
                                    </div>

                                </div>

                                <div *ngIf="x?.PoslJedID == posJedTmp?.PoslJedID"
                                    class="col col-2 d-flex justify-content-center align-items-center"
                                    style="padding: 0px;" (click)="unosUpdatePosJed(x)">
                                    <i class="material-icons" style="font-size: 2.5rem;">
                                        create
                                    </i>
                                </div>

                            </div>

                        </div>

                        <div style="padding: 10px 5px;">
                            <button type="button" class="btn mybtn-cancel w-100" (click)="unosUpdatePosJed(null)">Dodaj
                                poslovnu jedinicu</button>
                        </div>

                    </div>

                    <!-- Dvorane -->
                    <div *ngIf="mod.objKor.vrstaID == 2" class="card card2">

                        <div class="naslov">
                            {{'Dvorane [ ' + posJedTmp?.NazivPos + ' ]'}}
                        </div>

                        <div *ngFor="let x of dvoTmp" style="padding: 5px;">

                            <div class="row">

                                <div class="col" style="padding: 0px;">
                                    <div class="podnaslov">
                                        {{x.opisDvo}}
                                    </div>
                                    <div>
                                        {{'kapacitet: ' + x.kapacitetDvo}}
                                    </div>
                                </div>

                                <div class="col col-2 d-flex justify-content-center align-items-center"
                                    style="padding: 0px; cursor: pointer;" (click)="dvoInsertEditStart(x)">
                                    <i class="material-icons" style="font-size: 2.5rem;">
                                        create
                                    </i>
                                </div>

                            </div>

                        </div>

                        <div *ngIf="!dvoTmp" style="padding: 5px;">
                            Poslovna jedinica nema dvorane
                        </div>

                        <div style="padding: 10px 5px;">
                            <button type="button" class="btn mybtn-cancel w-100"
                                (click)="dvoInsertEditStart(null)">Dodaj dvoranu</button>
                        </div>

                    </div>

                    <!-- Vrste članarina -->
                    <div *ngIf="mod.objKor.vrstaID == 2" class="card card2">

                        <div class="naslov d-flex">
                            <div>
                                {{'Vrste članarina [ ' + posJedTmp?.NazivPos + ' ]'}}
                            </div>
                            <div class="ml-auto"
                                style="font-size: 1.2rem; padding: 0 5px; color: blue; cursor: pointer;"
                                (click)="msgInfoVrsteClanarina()">
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </div>
                        </div>

                        <div style="padding: 5px;">

                            <div *ngIf="srvClanarine.objVrsteClanarina" class="row cl-vrste-head">
                                <div class="col">
                                    Naziv
                                </div>
                                <div class="col col-2 justify-content-center">
                                    iznos
                                </div>
                                <div class="col col-2 justify-content-center text-center">
                                    tj. posjeta
                                </div>
                                <div class="col col-2">

                                </div>
                            </div>

                            <div *ngFor="let x of srvClanarine.objVrsteClanarina">

                                <div class="row cl-vrste-row">
                                    <div class="col" style="font-weight: bold;">
                                        {{x.cvOpis}}
                                    </div>
                                    <div class="col col-2 justify-content-center">
                                        {{x.cvIznos}} €
                                    </div>
                                    <div class="col col-2 justify-content-center">
                                        <div *ngIf="x.cvTjedno != 0">
                                            {{x.cvTjedno}}
                                        </div>
                                        <div *ngIf="x.cvTjedno == 0" style="writing-mode: vertical-rl; color: green;">
                                            8
                                        </div>
                                    </div>
                                    <div class="col col-2 justify-content-center" style="cursor: pointer;"
                                        (click)="clanarineVrstaInsertEditStart(x)">
                                        <i class="material-icons" style="font-size: 2rem;">
                                            create
                                        </i>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="!srvClanarine.objVrsteClanarina" style="padding: 5px;">
                                Nemate upisanih vrsta članarina
                            </div>
                        </div>

                        <div style="padding: 10px 5px;">
                            <button type="button" class="btn mybtn-cancel w-100"
                                (click)="clanarineVrstaInsertEditStart(null)">Dodaj vrstu članarine</button>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header posjed-mod-header d-flex justify-content-center">
                Statistike
            </div>

            <div class="modal-body posjed-mod-body">

                <app-postavke-sidebar></app-postavke-sidebar>

            </div>
            <div class="modal-footer bgs-dark" style="padding: 5px;">
                <button type="button" id="btnSidebarClose" class="btn mybtn-cancel" data-dismiss="modal"
                    style="width: 100%; font-size: 1.2rem;">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal - brisnje profilne - potvrda -->
<button hidden="true" id="btnMdlObrisiSliku" class="btn btn-info" data-toggle="modal"
    data-target="#mdlObrisiSliku"></button>

<div class="modal fade" id="mdlObrisiSliku" tabindex="-1" role="dialog" aria-labelledby="mdlObrisiSlikuLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlObrisiSlikutLabel">Brisanje profilne slike</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center; padding: 5px;">

                <div style="padding: 15px 5px; font-size: 1.2rem; text-align: center;">
                    Želite li obrisati profilnu sliku?
                </div>

            </div>
            <div class="modal-footer d-block" style="padding: 5px;">
                <div class="row">
                    <div class="col" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100" (click)="obrisiProfilnu()">POTVRDI
                            PRISANJE</button>
                    </div>
                    <div class="col" style="padding: 2px;">
                        <button id="btnMdlObrisiSlikuClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">OTKAŽI</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal - Uređivanje općih podataka -->
<button hidden="true" id="btnMdlKorEdit" class="btn btn-info" data-toggle="modal" data-target="#mdlKorEdit"></button>

<div class="modal fade" id="mdlKorEdit" tabindex="-1" role="dialog" aria-labelledby="mdlKorEditLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlKorEditLabel">Uređivanje podataka</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center; padding: 5px;">

                <app-korisnik-edit></app-korisnik-edit>

            </div>
            <div class="modal-footer d-block" style="padding: 5px;">
                <div class="row">
                    <div class="col" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100" (click)="spremiOpce()">SPREMI</button>
                    </div>
                    <div class="col" style="padding: 2px;">
                        <button id="btnMdlKorEditClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">OTKAŽI</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal - Unos / Uređivanje poslovne jedinice -->
<button hidden="true" id="btnMdlPosJedEdit" class="btn btn-info" data-toggle="modal"
    data-target="#mdlPosJedEdit"></button>

<div class="modal fade" id="mdlPosJedEdit" tabindex="-1" role="dialog" aria-labelledby="mdlPosJedEditLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlPosJedEditLabel">Uređivanje podataka</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center; padding: 5px;">

                <app-pos-jed-edit></app-pos-jed-edit>

            </div>
            <div class="modal-footer d-block" style="padding: 5px;">
                <div class="row">
                    <div class="col" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100" (click)="spremiPosJed()">SPREMI</button>
                    </div>
                    <div class="col" style="padding: 2px;">
                        <button id="btnMdlPosJedEditClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">OTKAŽI</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal - Unos / Uređivanje poslovne dvorane -->
<button hidden="true" id="btnMdlDvoranaEdit" class="btn btn-info" data-toggle="modal"
    data-target="#mdlDvoranaEdit"></button>

<div class="modal fade" id="mdlDvoranaEdit" tabindex="-1" role="dialog" aria-labelledby="mdlDvoranaEditLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlDvoranaEditLabel">Uređivanje podataka</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center; padding: 5px;">

                <app-dvorana-edit></app-dvorana-edit>

            </div>
            <div class="modal-footer d-block" style="padding: 5px;">
                <div class="row">
                    <div class="col" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100" (click)="spremiDvoranu()">SPREMI</button>
                    </div>
                    <div class="col" style="padding: 2px;">
                        <button id="btnMdlDvoranaEditClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">OTKAŽI</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal - Unos / Uređivanje vrste članarina -->
<button hidden="true" id="btnMdlClanarinaVrsta" class="btn btn-info" data-toggle="modal"
    data-target="#mdlClanarinaVrsta"></button>

<div class="modal fade" id="mdlClanarinaVrsta" tabindex="-1" role="dialog" aria-labelledby="mdlClanarinaVrstaLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlClanarinaVrstaLabel">Uređivanje podataka</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center; padding: 5px;">

                <app-clanarina-vrsta-edit></app-clanarina-vrsta-edit>

            </div>
            <div class="modal-footer d-block" style="padding: 5px;">
                <div class="row">
                    <div class="col" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100"
                            (click)="spremiVrstuClanarine()">SPREMI</button>
                    </div>
                    <div class="col" style="padding: 2px;">
                        <button id="btnMdlClanarinaVrstaClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">OTKAŽI</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal - Unos / Uređivanje vježbe -->
<button hidden="true" id="btnMdlVjezba" class="btn btn-info" data-toggle="modal" data-target="#mdlVjezba"></button>

<div class="modal fade" id="mdlVjezba" tabindex="-1" role="dialog" aria-labelledby="mdlVjezbaLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlVjezbaLabel">Uređivanje podataka</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center; padding: 5px;">

                <app-clanarina-vrsta-edit></app-clanarina-vrsta-edit>

            </div>
            <div class="modal-footer d-block" style="padding: 5px;">
                <div class="row">
                    <div class="col" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100">SPREMI</button>
                    </div>
                    <div class="col" style="padding: 2px;">
                        <button id="btnMdlVjezbaClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">OTKAŽI</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal - Promjena lozinke -->
<button hidden="true" id="btnMdlPromjenaLozinke" class="btn btn-info" data-toggle="modal"
    data-target="#mdlPromjenaLozinke"></button>

<div class="modal fade" id="mdlPromjenaLozinke" tabindex="-1" role="dialog" aria-labelledby="mdlPromjenaLozinkeLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlPromjenaLozinkeLabel">Promjena lozinke</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center; padding: 5px;">

                <app-promjena-lozinke></app-promjena-lozinke>

            </div>
            <div class="modal-footer d-block" style="padding: 5px;">
                <div class="row">
                    <div class="col" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100" (click)="promijeniLozinku()">SPREMI</button>
                    </div>
                    <div class="col" style="padding: 2px;">
                        <button id="btnMdlPromjenaLozinkeClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">OTKAŽI</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>