import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Clan, ClanoviService } from './clanovi.service';
import { ClanGrupa, Grupa, GrupeService } from './grupe.service';
import { ModulService } from './modul.service';
import * as validatori from './validatori';

export interface Clanarina {
  claID: number,
  claClanID: number,
  claVrstaID: number,
  claGrupaID: number,
  intPlacena: number,
  claDatumUplate: Moment,
  claVrijediOD: Moment,
  claVrijediDO: Moment,
  claIznos: number,
  claKorClID: number
}

export interface VezClanstva {
  vClansID: number,
  vrstaIDcl: number,
  ClanIDcl: number,
  KlijentIDcl: number,
  pjedIDcl: number,
  PocetakDatum: Moment,
  KrajDatum: Moment,
  intAkt: number
}

export interface ClanarineFilter {
  kliID: number,
  chkSvi: number,
  clanID: number,
  clan: string,
  razdoblje: number,
  datOd: Moment,
  datDo: Moment,
  neplacene: number
}

export interface UplataClanarine {
  clanarinaID: number,
  datum: Moment
}

export interface VclanarinaClan extends Clanarina, Clan {

}

export interface VclanarinaGrupa extends Clanarina, Grupa {

}

export interface Vclanarina extends Clanarina, Clan, Grupa {

}

export interface ClanarinaFilterRez extends Clanarina, Clan, Grupa {

}

export interface ClanarinaStat {
  neplaceneCount: number,
  neplaceneIznos: number,
  placeneCount: number,
  placeneIznos: number
}

//Clanarine Vrsta
export interface ClanarinaVrsta {
  cvID: number,
  cvPosJedID: number,
  cvOpis: string, 
  cvNapomena: string, 
  cvIznos: number, 
  cvTjedno: number,
  intDel: number
}

@Injectable({
  providedIn: 'root'
})
export class ClanarineService {

  constructor(
    private mod: ModulService,
    private http: HttpClient,
    private frmBuilder: FormBuilder,
    private srvClanovi: ClanoviService,
    private srvGrupe: GrupeService
  ) { }

  objClanarine: VclanarinaGrupa[];
  objClanarinaLast: VclanarinaGrupa;

  frmClanarina: FormGroup;
  frmClanarineFilter: FormGroup;
  objClanarinaFilterRez: ClanarinaFilterRez[];
  fltClanarinaFilterRez: ClanarinaFilterRez[];
  frmUplataClanarine: FormGroup;

  intUnosTmp: number = 0;

  strPretraga: string;
  strPretragaClanarine: string;
  iTmp: number = 0;

  clanarinaStat: ClanarinaStat;

  vcgID: number;

  //Napravi “rina
  napraviFrmClanarina(val: Clanarina, callback) {

    this.frmClanarina = this.frmBuilder.group({

      claID: [0],
      claClanID: [this.srvClanovi.clanID],
      claVrstaID: [0],
      claGrupaID: [0],
      intPlacena: [0],
      claDatumUplate: [moment().toDate()],
      claVrijediOD: [moment().toDate(), [Validators.required]],
      claVrijediDO: [moment().add(1, 'month').subtract(1, 'day').toDate(), [Validators.required]],
      claIznos: [0, [Validators.required, validatori.decimalCheck]],
      claKorClID: [this.mod.objPosJedSelected.KlijentIDpos]

    }, { validator: [validatori.datumClanarine] });

    this.frmClanarina.get('claGrupaID').enable();

    if (val) {
      this.frmClanarina.patchValue(val);
    } else {
      /* this.frmClanarina.get('claGrupaID').setValue(Number(this.srvGrupe.objGrupe[0].grpID)); */
    }

    callback(this.frmClanarina.value);

  }

  //Članarine by Član ID - sellect all
  clanarineByClanID(clanID: number, callback) {

    let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=clanarineByClanID';
    let obj: string = JSON.stringify({ clanID: clanID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VclanarinaGrupa[]) => {
      callback(rez);
    });

  }

  //Spremi članarinu
  spremiClanarinu(val: Clanarina, callback) {

    this.frmClanarina.markAllAsTouched();

    if (this.frmClanarina.valid) {
      let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=spremiClanarinu';
      val['fingerprint'] = this.mod.fingerprint();
      let obj: string = JSON.stringify(val);
  
      this.http.post(url, obj).subscribe((rez: VclanarinaGrupa) => {
        callback(rez);
      });
    } else {
      this.mod.msgBox(2, "Greška! Promjene nisu prihvaćene.", 3000);
    }

    

  }

  //Formatiranje decimalnog broja
  decFormat(event) {
    let strNaziv: string = event.srcElement.ariaLabel;
    if (this.frmClanarina.get(strNaziv).value) {
      this.frmClanarina.get(strNaziv).setValue(this.frmClanarina.get(strNaziv).value.replace(',', '.'));
    }
  }

  decFormatVrsteCla(event) {
    let strNaziv: string = event.srcElement.ariaLabel;
    if (this.frmClanarinaVrsta.get(strNaziv).value) {
      this.frmClanarinaVrsta.get(strNaziv).setValue(this.frmClanarinaVrsta.get(strNaziv).value.replace(',', '.'));
    }
  }

  //frm Filter Članarine
  napraviFrmClanarineFilter(val: ClanarineFilter, callback) {

    let kTmp: number;
    if (this.mod.session) {
      kTmp = this.mod.session.KlijentIDtren;
    } else {
      kTmp = this.mod.objKor.korisnikID;
    }

    this.frmClanarineFilter = this.frmBuilder.group({
      kliID: [kTmp],
      chkSvi: [1],
      clanID: [0],
      clan: [],
      razdoblje: [0],
      datOd: [moment()],
      datDo: [moment()],
      neplacene: [1],
    });

    if (val) {
      this.frmClanarineFilter.patchValue(val);
    }

    callback(this.frmClanarineFilter.value);

  }

  //Poništavanje odabranog člana
  sviClanovi() {
    this.frmClanarineFilter.get('chkSvi').setValue(1);
    this.frmClanarineFilter.get('clanID').setValue(0);
    this.frmClanarineFilter.get('clan').setValue(null);
  }

  //Filtriranje članarina
  filterClanarine(val: ClanarineFilter, callback) {

    let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=filterClanarine';
    val['fingerprint'] = this.mod.fingerprint();
    let obj: string = JSON.stringify(val);
    val['session'] = this.mod.session;

    this.http.post(url, obj).subscribe((rez: ClanarinaFilterRez[]) => {
      callback(rez);
    });

  }

  //Filtriranje članova
  filtrirajClanove(strP: string) {
    //alert(strP);
    this.srvClanovi.fltClanovi = this.srvClanovi.objClanovi.filter(s => s.Ime.toLowerCase().includes(strP.toLowerCase()) || s.Prezime.toLowerCase().includes(strP.toLowerCase()));
  }

  //Očisti pretragu
  pretragaClear() {
    this.strPretraga = '';
    this.filtrirajClanove('');
  }

  //Filtriranje članarina
  filtrirajClanarine(strP: string) {
    
    if (this.objClanarinaFilterRez) {
      this.fltClanarinaFilterRez = this.objClanarinaFilterRez.filter(s => s.Ime.toLowerCase().includes(strP.toLowerCase()) || s.Prezime.toLowerCase().includes(strP.toLowerCase()));
      this.clanarinaStat = this.clanarineStat(this.fltClanarinaFilterRez);
    } else {
      this.fltClanarinaFilterRez = null;
      this.clanarinaStat = null;
    }
  
    

  }

  //Očisti pretragu članarina
  pretragaClanarinaClear() {
    this.strPretragaClanarine = '';
    this.filtrirajClanarine('');
  }

  //Napravi frm Uplata članarine
  napraviFrmUplataClanarine(val: UplataClanarine, callback) {

    this.frmUplataClanarine = this.frmBuilder.group({
      clanarinaID: [0],
      datum: [moment()]
    });

    if (val) {
      this.frmUplataClanarine.patchValue(val);
    }

    callback(val);

  }

  //Uplata članarine
  uplataClanarine(val: UplataClanarine, callback) {

    let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=uplataClanarine';
    val['fingerprint'] = this.mod.fingerprint();
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez: ClanarinaFilterRez[]) => {
      callback(rez);
    });

  }

  //Istek članarine by Član ID
  istekClanarine(clanID: number, callback) {

    let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=istekClanarine';
    let obj: string = JSON.stringify({ clanID: clanID, fingerprint: this.mod.fingerprint });

    this.http.post(url, obj).subscribe((rez: Clanarina) => {
      callback(rez);
    });

  }

  //Auto obnavljanje članarina on / off
  autoClanarine(x: ClanGrupa, callback) {
    
    let oTmp = {
      vcgID: x.vcgID,
      ClanIDvcg: x.ClanIDvcg,
      GrupaIDvcg: x.GrupaIDvcg,
      DatumODvcg: x.DatumODvcg,
      autoCl: x.autoCl,
      fingerprint: this.mod.fingerprint()
    }

    let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=autoClanarine';
    let obj: string = JSON.stringify(oTmp);

    this.http.post(url, obj).subscribe((rez: string) => {
      callback(rez);
    });

  }

  //Brisanje članarine
  obrisiClanarinu(x: Clanarina, callback) {

    let claID: number = Number(x.claID);

    let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=obrisiClanarinu';
    let obj: string = JSON.stringify({ claID: claID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: string) => {
      callback(rez);
    });

  }

  //Statistka (neplaćene / plaćene članarine)
  clanarineStat(objClanarina: ClanarinaFilterRez[]): ClanarinaStat {

    let rez: ClanarinaStat = {
      neplaceneCount: 0,
      neplaceneIznos: 0,
      placeneCount: 0,
      placeneIznos: 0
    }

    objClanarina.forEach((val, ind) => {

      if (val.intPlacena == 0) {
        rez.neplaceneCount += 1;
        rez.neplaceneIznos += Number(val.claIznos);
      } else {
        rez.placeneCount += 1;
        rez.placeneIznos += Number(val.claIznos);
      }

    });

    return rez;

  }

  //Važeće by član ID / grupa
  vazeceByClan(claID: number, datum: Moment, callback) {

    let dat2: string = moment(datum).format('YYYY-MM-DD');

    let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=vazeceByClan';
    let obj: string = JSON.stringify({ claID: claID, datum: dat2, fingerprint: this.mod.fingerprint()});

    this.http.post(url, obj).subscribe((rez: Clan[]) => {
      callback(rez);
    });

  }

  //Važeće by član ID
  vazeceByClanArr(claID: number, datum: Moment, callback) {

    let dat2: string = moment(datum).format('YYYY-MM-DD');

    let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=vazeceByClanArr';
    let obj: string = JSON.stringify({ claID: claID, datum: dat2, fingerprint: this.mod.fingerprint()});

    this.http.post(url, obj).subscribe((rez: Clan[]) => {
      callback(rez);
    });

  }

  //Članarine vrste
  objVrsteClanarina: ClanarinaVrsta[];
  frmClanarinaVrsta: FormGroup;
  napraviFrmClanarinaVrsta(val: ClanarinaVrsta, callback) {

    this.frmClanarinaVrsta = this.frmBuilder.group({
      cvID: [0],
      cvPosJedID: [this.mod.objPosJedSelected.PoslJedID], 
      cvOpis: [, Validators.required], 
      cvNapomena: [], 
      cvIznos: [0, [Validators.required, validatori.decimalCheck, validatori.isNum]], 
      cvTjedno: [0]
    });

    if (val) {
      this.frmClanarinaVrsta.patchValue(val);
    }

    callback(this.frmClanarinaVrsta.value);

  }

  //Članarine vrste by Klijent ID - DEPRECATED
  /* clanarineVrsteByKlijentID(cvKlijentID: number, callback) {

    let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=clanarineVrsteByKlijentID';
    let obj: string = JSON.stringify({ cvKlijentID: cvKlijentID, fingerprint: this.mod.fingerprint()});

    this.http.post(url, obj).subscribe((rez: ClanarinaVrsta[]) => {
      callback(rez);
    });

  } */

   //Članarine vrste by Pos jed
   clanarineVrsteByPosJedID(cvPosJedID: number, callback) {

    let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=clanarineVrsteByPosJedID';
    let obj: string = JSON.stringify({ cvPosJedID: cvPosJedID, fingerprint: this.mod.fingerprint()});

    this.http.post(url, obj).subscribe((rez: ClanarinaVrsta[]) => {
      callback(rez);
    });

  }

  //Spremi vrstu članarine Insert / Update
  vrstaClanrineInsertUpdate(obj0: ClanarinaVrsta, callback) {

    let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=vrstaClanrineInsertUpdate';
    obj0['fingerprint'] = this.mod.fingerprint();
    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez: ClanarinaVrsta) => {
      callback(rez);
    });

  }

  //Brisanje vrste članarine
  brisanjeVrsteClanarine(cvID: number, callback) {

    let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=brisanjeVrsteClanarine';
    let obj: string = JSON.stringify({ cvID: cvID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: string) => {
      callback(rez);
    });
  }


}
