import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { VjezbeService } from '../vjezbe.service';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-fb-video',
  templateUrl: './fb-video.component.html',
  styleUrl: './fb-video.component.scss'
})
export class FbVideoComponent implements OnInit, AfterViewInit, OnChanges {

  constructor(
    public srvVjezbe: VjezbeService,
    private router: Router,
    public sanitizer: DomSanitizer
  ) { }

  

  ngOnInit(): void {
    console.log('fb video init');
    
  }

  ngAfterViewInit(): void {
    
  }


ngOnChanges(changes: SimpleChanges): void {
  
}

refresh(){
  console.log('fb video refresh');
  
  location.reload();
}


}
