import { Component, OnInit } from '@angular/core';
import { FrmFilter, FrmFilterRez, StatByFrm, StatKlijentService } from '../stat-klijent.service';
import { ModulService } from '../modul.service';
import { StatClanService } from '../stat-clan.service';
import { ClanoviService, PregledPosjetaClan } from '../clanovi.service';
import { Moment } from 'moment';

@Component({
  selector: 'app-stat-klijent-sidebar',
  templateUrl: './stat-klijent-sidebar.component.html',
  styleUrls: ['./stat-klijent-sidebar.component.scss']
})
export class StatKlijentSidebarComponent implements OnInit {

  constructor(
    public mod: ModulService,
    public srvStatKlijent: StatKlijentService,
    public srvStatClan: StatClanService,
    public srvClanovi: ClanoviService
  ) { }

  ngOnInit(): void {

  }


  //switch chk srvClanovi.bolPojedinacno 
  chkPojedinacnoClick() {

    this.srvClanovi.bolPojedinacno = !this.srvClanovi.bolPojedinacno;

    document.getElementById('btnSidebarClose').click();

  }

  //Učitavanje pojedinačnih posjeta
  ucitajPojedinacno() {
    interface Obj0 {
      datOd: Moment,
      datDo: Moment,
      fingerprint: string
    }

    let obj0: Obj0 = {
      datOd: this.srvStatKlijent.frmFilter.get('datOd').value,
      datDo: this.srvStatKlijent.frmFilter.get('datDo').value,
      fingerprint: this.mod.fingerprint()
    }

    this.srvClanovi.pregledPosjetaByFitNetID(obj0, (rez) => {
      if (rez) {
        this.srvClanovi.objPregldPosjetaClan = rez.objPregldPosjetaClan;
      } else {
        this.srvClanovi.objPregldPosjetaClan = null;
      }

    });
  }

}
