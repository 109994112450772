<div>

    <!-- Član -->
    <div *ngIf="srvNapredak.objClanSel" class="card">
        <div class="naslov">
            Odabrani član
        </div>
        <div style="padding: 5px;">
            <div style="font-weight: bold;">
                {{srvNapredak.objClanSel.Ime + ' ' + srvNapredak.objClanSel.Prezime}}
            </div>
            <div>
                {{srvNapredak.objClanSel.Adresa}}
            </div>
            <div>
                {{srvNapredak.objClanSel.Mjesto}}
            </div>
        </div>
    </div>

    <!-- button Odabir člana -->
    <div *ngIf="mod.objKor?.vrstaID == 2 || mod.session" class="card" style="padding: 5px;">
        <div style="margin: 10px 0;">
            <button type="button" class="btn mybtn-blank w-100" (click)="odabirClanaStart()"><i class="material-icons"
                    style="margin-right: 10px;">people</i> Odabir člana</button>
        </div>
    </div>

    <!-- chk Aktivni -->
    <div *ngIf="((mod.objKor?.vrstaID == 2 || mod.session) && srvNapredak.objClanSel) || (mod.objKor?.vrstaID == 1 && !mod.session)" class="card">
        <div class="d-flex align-items-center" style="padding: 10px; cursor: pointer;" (click)="intAktSwitch()">
            <div style="margin-right: 10px; font-size: 1.5rem;">
                <i *ngIf="srvNapredak.intAkt" class="fa fa-check-square-o" aria-hidden="true"></i>
                <i *ngIf="!srvNapredak.intAkt" class="fa fa-square-o" aria-hidden="true"></i>
            </div>
            <div>
                aktivni programi
            </div>
        </div>
    </div>

    <!-- Programi by član ID -->
    <div *ngIf="srvNapredak.objVePnVezaByClanID" class="card">

        <div class="naslov">
            {{srvNapredak.objVePnVezaByClanID?.length > 1 ? 'Programi' : 'Program'}}
        </div>

        <div>
            <div *ngFor="let x of srvNapredak.objVePnVezaByClanID"
                [ngClass]="{'prg-sel': srvNapredak.objVePnVezaByClanIDsel?.pnvID == x.pnvID}">

                <div class="row" (click)="programSelect(x)">
                    <div class="col mycol">
                        <div style="padding: 5px; cursor: pointer;">
                            <div style="font-weight: bold;">
                                {{x.pnNaziv}}
                            </div>
                            <div style="color: grey;">
                                {{'by ' + x.Ime + ' ' + x.Prezime}}
                            </div>
                            <div *ngIf="x.pnvKorID != mod.objKor.korisnikID">
                                {{'voditelj: ' + x.Ime2 + ' ' + x.Prezime2}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="mod.objKor.korisnikID == x.pnvKorID" class="col col-2 mycol d-flex justify-content-center align-items-center"
                        (click)="urediPracenjeStart(x)" style="cursor: pointer;">
                        <i class="material-icons" style="margin-right: 10px; font-size: 1.5rem;">create</i>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <!-- chk Uređivanje -->
    <div *ngIf="srvNapredak.objVePnVezaByClanIDsel?.pnvKorID == mod.objKor?.korisnikID" class="card">
        <div class="d-flex align-items-center" style="padding: 10px; cursor: pointer;" (click)="bolDetaljiSwitch()">
            <div style="margin-right: 10px; font-size: 1.5rem;">
                <i *ngIf="srvNapredak.bolPnStatDetalji" class="fa fa-check-square-o" aria-hidden="true"></i>
                <i *ngIf="!srvNapredak.bolPnStatDetalji" class="fa fa-square-o" aria-hidden="true"></i>
            </div>
            <div>
                Uređivanje
            </div>
        </div>
    </div>

    <div *ngIf="srvNapredak.objVePnVezaByClanIDsel?.pnvKorID == mod.objKor?.korisnikID" class="card"
        style="padding: 10px 5px 20px;">
        <div style="margin-top: 10px;">
            <button type="button" class="btn mybtn-info w-100"
                (click)="novoMjerenjeStart(srvNapredak.objVePnVezaByClanIDsel)"><i class="material-icons"
                    style="margin-right: 10px;">create</i>Novo mjerenje</button>
        </div>
        <div style="margin-top: 10px;">
            <button type="button" class="btn mybtn-blank w-100"
                (click)="ciljeviByPnvID(srvNapredak.objVePnVezaByClanIDsel.pnvID)"><i class="fa fa-flag-checkered"
                    aria-hidden="true" style="margin-right: 10px;"></i>Zadani ciljevi</button>
        </div>
    </div>

    <div class="card" style="padding: 5px;">

        <div *ngIf="((mod.objKor?.vrstaID==1 && !mod.session) || srvNapredak.objClanSel) && srvNapredak.intAkt" style="margin-top: 10px;">
            <button type="button" class="btn mybtn-success w-100" (click)="novoPracenje()"><i class="material-icons"
                    style="margin-right: 10px;">timer</i>Dodaj program za praćenje</button>
        </div>

        <div style="margin: 10px 0;">
            <button type="button" class="btn mybtn-info w-100" routerLink="/pn-template"><i class="material-icons"
                    style="margin-right: 10px;">create</i>Predlošci</button>
        </div>

    </div>

</div>