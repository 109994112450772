import { Component, OnInit, Output, EventEmitter, DoCheck, AfterViewInit } from '@angular/core';
import { Clan, ClanoviService, ZahtjevClanFitnet } from '../clanovi.service';
import { ModulService, PosJedinice } from '../modul.service';
import { Dvorana, DvoraneService } from '../dvorane.service';

@Component({
  selector: 'app-pos-jedinice',
  templateUrl: './pos-jedinice.component.html',
  styleUrls: ['./pos-jedinice.component.scss']
})
export class PosJediniceComponent implements OnInit, AfterViewInit {

  constructor(
    public mod: ModulService,
    public srvClanovi: ClanoviService,
    public srvDvorane: DvoraneService
  ) { }

  @Output() odabranaPjed = new EventEmitter<PosJedinice>();

  objPosJed: PosJedinice[];

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    /* this.objPosJed = [];
    this.mod.objPosJed?.forEach(val => {
      if (val.aktivna == 1) {
        this.objPosJed.push(val);
      }
    }); */
  }

  //btnPosJed click
  btnPosJedClick(obj: PosJedinice) {

    this.mod.objPosJedSelected = obj;
    //localStorage.objPosJedSelected = JSON.stringify(obj);

    this.odabranaPjed.emit(obj);

    this.srvDvorane.dvoraneByPosJedId(this.mod.objPosJedSelected.PoslJedID, 1, (rez: Dvorana[]) => {
      this.mod.dvoranePjed= rez;
    });

  }

  //Modal - lista članova / odabir člana za povezivanje
  poveziClana(x: ZahtjevClanFitnet) {

    this.srvClanovi.clanoviByKlID(this.mod.objKor.korisnikID, (rez: Clan[]) => {
      this.srvClanovi.objClanoviZahtjev = rez;
      this.srvClanovi.fltClanoviZahtjev = rez;
      this.srvClanovi.napraviFrmPovez(x.vfcID, 0, (rez) => {
        document.getElementById('btnMdlClZahtjev').click();
      })

    });

  }

}


