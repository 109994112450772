import { Component, OnInit, OnDestroy, Pipe, PipeTransform, HostListener } from '@angular/core';
//import { ModulService } from '../modul.service';
import { MjestaService } from '../mjesta.service';
import { ClanoviService } from '../clanovi.service';
import { formatDate } from '@angular/common';
import { ApiRez, ModulService, PosJedinice } from '../modul.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Clanstvo } from '../clanstva/clanstva.component';
import { Validators } from '@angular/forms';
import { ClanarineService, Vclanarina } from '../clanarine.service';
import { ClanEditService } from '../clan-edit.service';
import { ClanGrupa, Grupa, GrupeService } from '../grupe.service';
import { NapredakService, VePnVezaByClanID } from '../napredak.service';

export interface ClPostavka {
  vID: number,
  opis_vrsta_cl: string,
  posJed: PosJed[]
}

export interface PosJed {
  PoslJedID: number,
  NazivPos: string
}

@Component({
  selector: 'app-clan-edit',
  templateUrl: './clan-edit.component.html',
  styleUrls: ['./clan-edit.component.scss'],
})

export class ClanEditComponent implements OnInit, OnDestroy {

  constructor(
    public mod: ModulService,
    public srvMjesta: MjestaService,
    public srvClan: ClanoviService,
    public srvClanarine: ClanarineService,
    public srvClanEdit: ClanEditService,
    public srvGrupe: GrupeService,
    public srvNapredak: NapredakService
  ) { }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    localStorage.setItem('clanID', this.srvClan.clanID.toString());
  }

  objPosJed: PosJedinice[];


  objClPostavke: ClPostavka[];
  objClPosJedSel: PosJed[];

  danas = moment();

  ngOnInit() {

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.mod.objKor.token;
    }

    if (!this.srvClan.clanID && localStorage.getItem('clanID')) {
      this.srvClan.clanID = Number(localStorage.getItem('clanID'));
      localStorage.removeItem('clanID');
    }


    this.mod.provjeraTokena(tok, () => {

      if (!this.srvMjesta.objMjestaRH) {
        this.srvMjesta.ucitajMjestaRH(() => {
        });
      }

      this.mod.ucitajPosJed(() => {

        this.objPosJed = [];

        if (this.mod.objPosJed) {
          this.mod.objPosJed?.forEach(val => {
            if (val.aktivna == 1) {
              this.objPosJed.push(val);
            }
          });
          this.pocetnePostavke();
        } else {

          this.mod.session = null;
          this.mod.provjeraPrijaveTrenOper((rez) => {

            this.srvClan.posjedByTrenOper(this.mod.session.KlijentIDtren, this.mod.session.KoriniskIDtren, this.mod.session.clanVrstaIDtren, (rez: PosJedinice[]) => {
              rez?.forEach(val => {
                if (val.aktivna == 1) {
                  this.objPosJed.push(val);
                }
              });
            });

            this.pocetnePostavke();

          });

        }

        localStorage.clear();

      });

    });

  }

  //Pocetne postavke
  pocetnePostavke() {

    //Frm Članovi
    this.srvClan.postaviFrmClanovi(this.srvClan.clanID, () => {
      this.mjestaListener();
    });

    //frm članstvo
    this.srvClan.napraviFrmClanstvo(() => {

    });

    //Članstva
    this.srvClan.ucitajClanstvaList();
    this.srvClan.ucitajClanstva(this.srvClan.clanID);

    //važeće članarine
    this.srvClanarine.vazeceByClanArr(this.srvClan.clanID, moment(), (rez: Vclanarina[]) => {
      this.srvClanEdit.objVazeceClanarine = rez;
    });

    //Upisan u grupe:
    let session = this.mod.session ? this.mod.session : null;
    this.srvGrupe.grupeByClanId(session, 1, this.srvClan.clanID, (rez: Grupa[]) => {
      this.srvClanEdit.objGrupe = rez;
    });

    //Programi za praćenje napretka
    this.srvNapredak.vePnVezaByClanID(this.srvClan.clanID, 1, (rez: VePnVezaByClanID[]) => {
      this.srvNapredak.objVePnVezaByClanID = rez;
    });

  }

  //Listener Mjesto ComboBox
  mjestaListener() {

    this.srvMjesta.fltMjesta = [];

    this.srvClan.frmClan.get('Mjesto').valueChanges.subscribe(value => {

      if (value && value.length >= 3) {
        this.srvMjesta.filtrirajMjestaRH(value);
      } else {
        this.srvMjesta.fltMjesta = [];
      }

    });

  }

  //SET MjestoID (mod.objKor.MjestoID)
  postaviMjestoID(mjestoID: number) {
    this.srvClan.frmClan.patchValue({ MjestoID: mjestoID });
  }

  //Članstvo cmb 
  postaviClanstvoIDfrm(clID: string) {
    this.srvClan.frmClanstvo.controls['vrstaIDcl'].setValue(clID);
    //alert(JSON.stringify(this.srvClan.frmClanstvo.value));
  }

  //Postavi poslovnu jedinicu
  postaviPosJed(pjedID) {
    this.srvClan.frmClanstvo.controls['pjedIDcl'].setValue(pjedID);
  }

  //Spremi
  btnSpremi(clID: number) {
    this.srvClan.spremiClan(clID, () => {

      //važeće članarine
      this.srvClanarine.vazeceByClanArr(this.srvClan.clanID, moment(), (rez: Vclanarina[]) => {
        this.srvClanEdit.objVazeceClanarine = rez;
      });

      //Upisan u grupe:
      let session = this.mod.session ? this.mod.session : null;
      this.srvGrupe.grupeByClanId(session, 1, this.srvClan.clanID, (rez: Grupa[]) => {
        this.srvClanEdit.objGrupe = rez;
      });

    });


  }

  //Spremi članstvo
  spremiClanstvo() {

    this.srvClan.frmClanstvo.markAllAsTouched();

    if (this.srvClan.frmClanstvo.valid) {

      let vClansID: number = this.srvClan.frmClanstvo.get('vClansID').value;

      this.srvClan.spremiClanstvo(this.srvClan.frmClanstvo.value, (rez: Clanstvo) => {
        if (rez) {

          if (vClansID) {
            this.srvClan.objClanstva.forEach((val, ind) => {
              if (val.vClansID == rez.vClansID) {
                this.srvClan.objClanstva.splice(ind, 1, rez);
              }
            });
          } else {
            this.srvClan.objClanstva.push(rez);
          }

          document.getElementById('btnMdlClanstEditClose').click();

          this.mod.msgBox(1, "Spremljeno!", 2000);

        } else {
          this.mod.msgBox(2, "Greška prilikom spremanja", 3000);
        }
      });

    } else {
      this.mod.msgBox(2, "Popunite ispravno sva polja!", 3000);
    }

  }

  //Ikona - članstva
  iconClanstva(clID: string) {

    switch (clID) {

      case '1':
        return 'people';

      case '2':
        return 'emoji_people';

      case '3':
        return 'transfer_within_a_station';

    }

  }

  //Novo članstvo
  novoClanstvo() {

    this.objClPosJedSel = null;

    let clID: number = this.srvClan.clanID;
    let klID: number = this.mod.objPosJedSelected.KlijentIDpos;
    let cvID: number;

    if (this.mod.session) {
      cvID = this.mod.session.clanVrstaIDtren;
    } else {
      cvID = 0;
    }

    this.srvClan.clDostupnePj(clID, klID, cvID, (rez) => {

      this.srvClan.napraviFrmClanstvo(() => {
        this.srvClan.frmClanstvo.get('vrstaIDcl').addValidators(Validators.required);
      });

      if (rez && rez.length) {
        this.objClPostavke = rez;
      } else {
        this.objClPostavke = null;
      }

    });

    document.getElementById('btnMdlClanstEdit').click();
  }

  //Novo članstvo - odabir članstva i postavljanje dostupnih poslovnih jedinica
  ncPosJedOdabir(x) {
    this.objClPosJedSel = x;
  }

  //Uređivanje članstva
  clanstEditStart(x: Clanstvo) {

    this.srvClan.napraviFrmClanstvo(() => {
      this.srvClan.frmClanstvo.get('vrstaIDcl').removeValidators(Validators.required);
      this.srvClan.frmClanstvo.patchValue(x);
    });

    document.getElementById('btnMdlClanstEdit').click();

  }

  //poslovne jedinice - učitavanje ovisno o vrsti korisnika (vlasnik / trener, operater)


  //Chk aktivno / neaktivno
  clAktChk() {

    let chk: number = this.srvClan.frmClanstvo.get('intAkt').value;

    if (chk == 0) {
      this.srvClan.frmClanstvo.get('intAkt').setValue(1);
    } else {
      this.srvClan.frmClanstvo.get('intAkt').setValue(0);
    }

  }

  //Potvrda unosa člana u grupu
  potvrdiUnosGrp() {

    this.srvGrupe.frmClanGrupaUnos.markAllAsTouched();

    if (this.srvGrupe.frmClanGrupaUnos.valid) {

      this.srvGrupe.clanGrupaUnos(this.srvGrupe.frmClanGrupaUnos.value, (rez) => {

        if (rez) {

          //Upisan u grupe:
          if (!this.srvClanEdit.objGrupe) {
            this.srvClanEdit.objGrupe = [];
          }
          this.srvClanEdit.objGrupe.push(rez);

          document.getElementById('btnMdlClGrpUnosClose').click();

          this.mod.msgBox(1, "Uspješno dodano!", 1500);

        } else {
          this.mod.msgBox(2, "Greška prilikom unosa!", 1500);
        }

      });

    } else {
      this.mod.msgBox(2, "Popunite sva potrebna polja", 1500);
    }

  }

  //Spremanje prve članarine
  spremiClanarinu() {

    this.srvClanarine.frmClanarina.get('claGrupaID').enable();

    let rTmp = this.srvClanarine.frmClanarina.value;
    rTmp.vcgID = this.srvClanarine.vcgID;

    this.srvClanarine.spremiClanarinu(rTmp, (rez: ClanGrupa) => {

      if (rez) {

        this.srvClanEdit.objGrupe.forEach((val, ind) => {
          if (val['vcgID'] == this.srvClanarine.vcgID) {
            val['autoCl'] = 1;
          }
        });

        //važeće članarine
        this.srvClanarine.vazeceByClanArr(this.srvClan.clanID, moment(), (rez: Vclanarina[]) => {
          this.srvClanEdit.objVazeceClanarine = rez;
        });

        this.srvClanarine.vcgID = 0;
        document.getElementById('btnMdlClanarinaClose').click();
        this.mod.msgBox(1, "Spremljeno!", 2000);
      } else {
        this.mod.msgBox(2, "Greška! promjene nisu prihvaćene.", 3000);
        this.srvClanarine.frmClanarina.get('claGrupaID').disable();
      }

    });

  }

  //spremanje veze - program za praćenje napretka
  spremiPnVeza() {

    this.srvNapredak.frmPnVeza.markAllAsTouched();

    if (this.srvNapredak.frmPnVeza.valid) {

      this.srvNapredak.spremiPnVezu(this.srvNapredak.frmPnVeza.value, (rez: ApiRez) => {

        if (rez && rez.rez == 'ok') {

          document.getElementById('btnMdlPnVezaEditClose').click();

          let pnvID: number = this.srvNapredak.frmPnVeza.get('pnvID').value;

          if (!pnvID) {

            if (!this.srvNapredak.objVePnVezaByClanID) {
              this.srvNapredak.objVePnVezaByClanID = [];
            }

            this.srvNapredak.objVePnVezaByClanID.push(rez.obj);

          } else {

            this.srvNapredak.objVePnVezaByClanID.forEach((val, ind) => {

              if (val.pnvID == rez.obj.pnvID) {
                this.srvNapredak.objVePnVezaByClanID.splice(ind, 1, rez.obj);
              }

            });

          }

          this.mod.msgBox(1, "SPREMLJNO!", 800);

        } else {
          this.mod.msgBox(2, "Greška priliom spremanja!", 900);
        }

      });

    } else {
      this.mod.msgBox(2, "Popunite obavezna polja!", 800);
    }

  }

  //Spremanje pn mjerenja
  spremiMjerenje() {

    this.srvNapredak.frmPnMjerenje.get('datum').markAsTouched();

    if (this.srvNapredak.frmPnMjerenje.valid) {

      this.srvNapredak.spremiMjerenje(this.srvNapredak.frmPnMjerenje.value, (rez: ApiRez) => {

        if (rez && rez.rez == 'ok') {

          document.getElementById('btnMdlPnMjerEditClose').click();

          this.mod.msgBox(1, "SPREMLJENO", 800);


        } else if (rez && rez.rez == 'empty') {
          this.mod.msgBox(1, "Nema podataka za spremanje!", 1000);
        } else {
          this.mod.msgBox(2, "Greška prilkom spremanja", 1000);
        }

      });


    } else {
      this.mod.msgBox(2, "Popunite ispravno sva polja", 800);
    }

  }

  //Spremanje zadanih ciljeva
  spremiCiljeve() {
    let obj0 = this.srvNapredak.frmVePnCilj.value;
    this.srvNapredak.spremiCiljeve(obj0, (rez) => {

      if (rez && rez == 'ok') {
        document.getElementById('btnMdlCiljeviClose').click();
        this.mod.msgBox(1, "SPREMLJENO", 800);
      } else {
        this.mod.msgBox(2, "Greška!", 800);
      }

    });
  }

  ngOnDestroy() {
    localStorage.setItem('clanID', this.srvClan.clanID.toString());
    document.getElementById('btnSidebarClose').click();
  }

}
