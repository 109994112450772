import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GrupeService, Grupa } from '../grupe.service';
import { ClanoviService, Clan } from '../clanovi.service';
import { ApiRez, ModulService, PosJedinice } from '../modul.service';
import { ObavijestiService } from '../obavijesti.service';
import { ObavijestiSidebarComponent } from '../obavijesti-sidebar/obavijesti-sidebar.component';

@Component({
  selector: 'app-obavijest-send',
  templateUrl: './obavijest-send.component.html',
  styleUrls: ['./obavijest-send.component.scss']
})
export class ObavijestSendComponent implements OnInit {

  constructor(
    public srvObavijesti: ObavijestiService,
    public mod: ModulService
  ) { }

  bolSlanje: boolean = false;

  ngOnInit(): void {

  }

  //Sljedeći korak
  nastavi() {

    this.srvObavijesti.frmSendStep = 2;

  }

  //Pošalji poruku
  posalji() {

    this.srvObavijesti.frmSend.markAllAsTouched();

    if (this.srvObavijesti.frmSend.valid) {

      this.bolSlanje = true;

      this.srvObavijesti.novaObavijestSend(this.srvObavijesti.frmSend.value, (rez: ApiRez) => {
        if (rez && rez.rez == 'ok') {
          document.getElementById('btnMdlNovaObavijestClose').click();
          this.mod.msgBox(1, "Obavijest je uspješno poslana!", 3000);

          this.srvObavijesti.prikazi();
          
          /* //push - glavni objekt
          if (!this.srvObavijesti.objSent) {
            this.srvObavijesti.objSent = [];
          }
          this.srvObavijesti.objSent.unshift(rez.obj);

          //push - flt objekt
          if (!this.srvObavijesti.fltSent) {
            this.srvObavijesti.fltSent = [];
          }
          this.srvObavijesti.fltSent.unshift(rez.obj);
 */
        } else {
          this.mod.msgBox(2, "Greška prilikom slanja! Pokušajte ponovno!", 4000);
        }
        this.bolSlanje = false;
      });

    } else {
      this.mod.msgBox(2, "Popunite sva potrebna polja", 2000);
    }



  }

  grpChange() {

  }

}
