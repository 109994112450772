import { Component } from '@angular/core';
import { ModulService } from '../modul.service';
import { ClanoviService } from '../clanovi.service';
import * as moment from 'moment';

@Component({
  selector: 'app-prisutni-edit',
  templateUrl: './prisutni-edit.component.html',
  styleUrl: './prisutni-edit.component.scss'
})
export class PrisutniEditComponent {

  constructor(
    public mod: ModulService,
    public srvClanovi: ClanoviService
  ) { }

  //Trajanje posjeta
  trajanjePosjeta() {

    let dOd: string = this.srvClanovi.frmPosjetaEdit.get('dOd').value;
    let hOd: string = this.srvClanovi.frmPosjetaEdit.get('hOd').value;
    let mOd: string = this.srvClanovi.frmPosjetaEdit.get('mOd').value;

    let dDo: string = this.srvClanovi.frmPosjetaEdit.get('dDo').value;
    let hDo: string = this.srvClanovi.frmPosjetaEdit.get('hDo').value;
    let mDo: string = this.srvClanovi.frmPosjetaEdit.get('mDo').value;

    let d1 = moment(moment(dOd).format('YYYY-MM-DD') + ' ' + hOd + ':' + mOd + ':00');
    let d2 = moment(moment(dDo).format('YYYY-MM-DD') + ' ' + hDo + ':' + mDo + ':00');

    return this.srvClanovi.trajanjePosjetaHmm(d1, d2);

  }

  //Otkaži brisanje
  otkaziBrisanje() {
    this.srvClanovi.bolBrisanje = false;
  }

}
