<form *ngIf="srvNapredak.frmPnPrgStavka" [formGroup]="srvNapredak.frmPnPrgStavka">

    <div *ngIf="!srvNapredak.bolBrisanje">

        <!-- Brisanje -->
        <div *ngIf="srvNapredak.frmPnPrgStavka.get('ppsID').value" class="d-flex">
            <button type="button" class="btn mybtn-delete ml-auto"
                (click)="srvNapredak.bolBrisanje = true"> <i class="material-icons"
                    style="margin-right: 10px;">delete_sweep</i> Obriši stavku</button>
        </div>

        <!-- Naziv -->
        <div>
            <mat-form-field class="w-100">
                <input matInput autocomplete="off" type="text" id="ppsNaziv" formControlName="ppsNaziv" placeholder="naziv - max 100 znakova" required>
                <mat-error *ngIf="srvNapredak.frmPnPrgStavka.controls['ppsNaziv'].errors?.required">Polje je obavezno za
                    unos
                </mat-error>
                <mat-error *ngIf="srvNapredak.frmPnPrgStavka.controls['ppsNaziv'].errors?.maxlength">Duljina poruke je
                    maksimalno
                    100 znakova
                </mat-error>
            </mat-form-field>
        </div>

        <!-- jed. mj. -->
        <div>
            <mat-form-field class="w-100">
                <input matInput type="text" formControlName="ppsJedMj" placeholder="jed. mj" required>
                <mat-error *ngIf="srvNapredak.frmPnPrgStavka.controls['ppsJedMj'].errors?.required">Polje je obavezno za
                    unos
                </mat-error>
            </mat-form-field>
        </div>

        <!-- Aktivna chk -->
         <div class="d-flex align-items-center justify-content-center" style="cursor: pointer;" (click)="aktClick()">
            <div style="margin-right: 10px; font-size: 1.5rem;">
                <i *ngIf="srvNapredak.frmPnPrgStavka.get('ppsAkt').value == 1" class="fa fa-check-square-o" aria-hidden="true"></i>
                <i *ngIf="srvNapredak.frmPnPrgStavka.get('ppsAkt').value == 0" class="fa fa-square-o" aria-hidden="true"></i>
            </div>
            <div>
                Aktivan
            </div>
         </div>

    </div>

    <div *ngIf="srvNapredak.bolBrisanje">

        <div class="alert alert-warning" style="text-align: center;">
            <div>
                Potvrdite brisanje
            </div>
        </div>

        <div class="row">
            <div class="col col-6 mycol">
                <button type="button" class="btn mybtn-danger w-100" (click)="obrisiPnPrgStavku(srvNapredak.frmPnPrgStavka.get('ppsID').value)"><i class="material-icons"
                        style="margin-right: 10px;">delete_sweep</i> OBRIŠI</button>
            </div>
            <div class="col col-6 mycol">
                <button type="button" class="btn mybtn-cancel w-100" (click)="otkaziBrisanje()" data-dismiss="modal">X OTKAŽI</button>
            </div>
        </div>

    </div>


</form>