<div class="container" style="padding: 0; height: 100%;">

    <div class="card" style="height: 100%; max-height: 100%; overflow: hidden; opacity: 0.9;">
        <div class="row" style="margin: 0px; padding-top: 50px; height: 100%; max-height: 100%;">

            <!--Lijeva kolona-->
            <div class="d-none d-md-block col-4 card" style="padding: 16px 15px; height: 100%;">

                <app-obavijesti-sidebar></app-obavijesti-sidebar>

            </div>

            <!-- Desna kolona -->
            <div class="col" style="padding: 0; height: 100%; max-height: 100%;">

                <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden; padding-bottom: 30px;">

                    <div>

                        <div>
                            <app-pos-jed-hamburger></app-pos-jed-hamburger>
                        </div>

                        <div style="padding: 5px;">

                            <div style="padding: 2px;">

                                <div class="d-flex"
                                    style="border-radius: 5px; background-color: #d5f7d5; margin-bottom: 10px; padding: 5px; text-align: center;">

                                    <div *ngIf="srvObavijesti.fltVobavRez || srvObavijesti.fltSent"
                                        class="d-flex align-items-center" style="cursor: pointer;"
                                        (click)="chkOznacavanjeClick()">
                                        <div style="font-size: 1.3rem;">
                                            <i *ngIf="srvObavijesti.bolOznacavanje" class="fa fa-check-square-o"
                                                aria-hidden="true"></i>
                                            <i *ngIf="!srvObavijesti.bolOznacavanje" class="fa fa-square-o"
                                                aria-hidden="true"></i>
                                        </div>
                                        <span style="margin-left: 10px;">Označavanje</span>
                                        <span *ngIf="srvObavijesti.oznCount"
                                            style="margin-left: 10px;">({{srvObavijesti.oznCount}})</span>
                                    </div>

                                    <div *ngIf="srvObavijesti.bolOznacavanje && srvObavijesti.strPretragaVobavRez">
                                        <div class="alert alert-warning" style="margin: 0; margin-left: 10px;">
                                            <div>
                                                {{'filter: ' + srvObavijesti.strPretragaVobavRez}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center ml-auto" *ngIf="srvObavijesti.info">
                                        {{srvObavijesti.info.neprocitano + '/' + srvObavijesti.info.ukupno}}
                                    </div>
                                    <div class="d-flex align-items-center ml-auto" *ngIf="srvObavijesti.infoSent">
                                        {{srvObavijesti.infoSent}}
                                    </div>
                                    <div *ngIf="!srvObavijesti.fltVobavRez && !srvObavijesti.fltSent" class="w-100"
                                        style="text-align: center;">
                                        Nema obavijesti za prikaz
                                    </div>

                                </div>

                                <div *ngIf="!srvObavijesti.bolOznacavanje && (srvObavijesti.fltVobavRez || srvObavijesti.fltSent)"
                                    class="input-slim">
                                    <mat-form-field style="width: 100%;">
                                        <input autocomplete="off" type="search" type="text" name="pretraga"
                                            [(ngModel)]="srvObavijesti.strPretragaVobavRez"
                                            (ngModelChange)="srvObavijesti.intVrsta == 1 ? srvObavijesti.filtrirajVobavRez(srvObavijesti.strPretragaVobavRez) : srvObavijesti.filtrirajSent(srvObavijesti.strPretragaVobavRez)"
                                            matInput placeholder="pretraga..">
                                        <button mat-button *ngIf="srvObavijesti.strPretragaVobavRez" id="bc1" matSuffix
                                            aria-label="Clear" (click)="srvObavijesti.pretragaClear()">X</button>
                                        <button mat-button *ngIf="!srvObavijesti.strPretragaVobavRez" id="bc1" matSuffix
                                            aria-label="search"><i class="material-icons"
                                                style="font-size: 2rem;">search
                                            </i></button>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="srvObavijesti.bolOznacavanje" class="card">

                                    <div class="d-flex flex-wrap">

                                        <!-- Označi / Odznači sve -->
                                        <div class="btn-ozn" (click)="chkAllClick(1)">
                                            <div class="ico">
                                                <i *ngIf="intChkAll == 1" class="fa fa-check-square-o"
                                                    aria-hidden="true"></i>
                                                <i *ngIf="intChkAll != 1" class="fa fa-square-o" aria-hidden="true"></i>
                                            </div>
                                            <span>Odaberi sve</span>
                                        </div>

                                        <!-- Označi / Odznači pročitane -->
                                        <div *ngIf="srvObavijesti.intVrsta == 1" class="btn-ozn" (click)="chkAllClick(3)">
                                            <div class="ico">
                                                <i *ngIf="intChkAll == 3" class="fa fa-check-square-o"
                                                    aria-hidden="true"></i>
                                                <i *ngIf="intChkAll != 3" class="fa fa-square-o" aria-hidden="true"></i>
                                            </div>
                                            <span>Pročitane</span>
                                        </div>

                                        <!-- Označi / Odznači nepročitane -->
                                        <div *ngIf="srvObavijesti.intVrsta == 1" class="btn-ozn" (click)="chkAllClick(2)">
                                            <div class="ico">
                                                <i *ngIf="intChkAll == 2" class="fa fa-check-square-o"
                                                    aria-hidden="true"></i>
                                                <i *ngIf="intChkAll != 2" class="fa fa-square-o" aria-hidden="true"></i>
                                            </div>
                                            <span>Nepročitane</span>
                                        </div>

                                    </div>

                                    <div class="d-flex flex-wrap">

                                        <!-- Označi kao pročitano -->
                                        <div *ngIf="srvObavijesti.intVrsta == 1" class="btn-ozn" [ngClass]="{'disabled': (!srvObavijesti.oznCount ||intChkAll == 3)}"
                                            (click)="azurirajVocFake(3)">
                                            <div class="ico">
                                                <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                            </div>
                                            <div>
                                                Označi kao pročitano
                                            </div>
                                        </div>

                                        <!-- Označi kao nepročitano -->
                                        <div *ngIf="srvObavijesti.intVrsta == 1" class="btn-ozn" [ngClass]="{'disabled': (!srvObavijesti.oznCount || intChkAll == 2)}"
                                            (click)="azurirajVocFake(2)">
                                            <div class="ico">
                                                <i class="fa fa-envelope" aria-hidden="true"></i>
                                            </div>
                                            <div>
                                                Označi kao nepročitano
                                            </div>
                                        </div>

                                        <!-- Obriši označeno -->
                                        <div class="btn-ozn" [ngClass]="{'disabled': !srvObavijesti.oznCount}" (click)="obrisiOznaceno()">
                                            <div class="ico">
                                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                                            </div>
                                            <div>
                                                Obriši označeno
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="flex-grow-1" style="overflow-y: scroll; scrollbar-width: none; padding: 2px;">

                        <!-- Privatni korisnici -->
                        <div *ngIf="srvObavijesti.intVrsta == 1 && srvObavijesti.fltVobavRez" style="padding: 5px;">

                            <div class="row" *ngFor="let x of srvObavijesti.fltVobavRez; let i = index"
                                style="border-bottom: solid 0.5px #af9595; padding: 5px 0px; cursor: pointer;"
                                [ngStyle]="{'color': x.vocFake == 2 ? 'black' : '#94949f'}">

                                <div *ngIf="srvObavijesti.bolOznacavanje"
                                    class="col col-1 d-flex justify-content-center align-items-center"
                                    style="font-size: 1.2rem; padding: 0;" (click)="x.chk = !x.chk; countOznacenih(); intChkAll = 0;">
                                    <i *ngIf="x.chk" class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <i *ngIf="!x.chk" class="fa fa-square-o" aria-hidden="true"></i>
                                </div>

                                <div class="col col-7" style="padding: 0px 5px;" (click)="modalObavijest(x, i)">
                                    <div style="font-size: 1.1rem; font-weight: bold;">
                                        {{x.Naslov}}
                                    </div>
                                    <div>
                                        {{x.Ime == x.Naziv ? x.Naziv : x.Naziv + ' ' + x.Ime + ' ' + x.Prezime}}
                                    </div>
                                </div>

                                <div class="col d-flex align-items-center" style="padding: 0;"
                                    (click)="modalObavijest(x, i)">
                                    <div>
                                        {{x.VrijemeObv | amDateFormat:'DD.MM.YY. HH:mm'}}
                                    </div>
                                </div>
                                <div *ngIf="!srvObavijesti.bolOznacavanje" class="col col-1 d-flex justify-content-center align-items-center"
                                    [matMenuTriggerFor]="menu" style="padding: 0; font-size: 3rem;"
                                    (click)="obvMenuClick(x)">
                                    <!-- <i class="fa fa-pencil" aria-hidden="true"></i> -->
                                    <i class="material-icons" style="font-size: 2.2rem;">
                                        create
                                    </i>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item style="font-size: 1.2rem;" (click)="modalObavijest(x, i)">
                                            <i class="fa fa-external-link" aria-hidden="true"></i>
                                            <span style="margin-left: 10px;">Prikaži obavijest</span>
                                        </button>
                                        <button *ngIf="x.vocFake == 3" mat-menu-item style="font-size: 1.2rem;"
                                            (click)="oznNeprocitano(x,i)">
                                            <i class="fa fa-undo" aria-hidden="true"></i>
                                            <span style="margin-left: 10px;">Označi kao nepročitano</span>
                                        </button>
                                        <button *ngIf="x.vocFake == 2" mat-menu-item style="font-size: 1.2rem;"
                                            (click)="oznProcitano(x,i)">
                                            <i class="fa fa-undo" aria-hidden="true"></i>
                                            <span style="margin-left: 10px;">Označi kao pročitano</span>
                                        </button>
                                        <button mat-menu-item style="font-size: 1.2rem;" (click)="obrisiObavijest(x.vocID)">
                                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                                            <span style="margin-left: 10px;">Obriši</span>
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>

                        </div>

                        <!-- Poslovni korisnici -->
                        <div *ngIf="srvObavijesti.intVrsta == 2" style="padding: 5px;">

                            <div *ngFor="let x of srvObavijesti.fltSent" style="border-bottom: solid 0.5px #d7d7d7;">

                                <div class="row" style="padding: 5px; border-radius: 5px; cursor: pointer;">

                                    <!-- <div class="col col-2 col-md-1 d-flex justify-content-center align-items-center" style="padding: 0; font-size: 2rem; color: #384c5a;">
                                        <i *ngIf="ObavijestID != x.ObavijestID" class="fa fa-caret-right" aria-hidden="true"></i>
                                        <i *ngIf="ObavijestID == x.ObavijestID" class="fa fa-caret-down" aria-hidden="true"></i>
                                    </div> -->

                                    <div *ngIf="srvObavijesti.bolOznacavanje"
                                        class="col col-1 d-flex justify-content-center align-items-center"
                                        style="font-size: 1.2rem; padding: 0;"
                                        (click)="x.chk = !x.chk; countOznacenihSent();">
                                        <i *ngIf="x.chk" class="fa fa-check-square-o" aria-hidden="true"></i>
                                        <i *ngIf="!x.chk" class="fa fa-square-o" aria-hidden="true"></i>
                                    </div>

                                    <div class="col" style="padding: 0;">
                                        <div class="d-flex flex-column" style="color: #384c5a;">

                                            <div style="font-weight: bold;">
                                                {{x.Naslov}}
                                            </div>

                                            <div>
                                                {{(mod.objKor.vrstaID == 2 ? '' : x.Naziv) + (x.primatelji ? ' ' +
                                                x.primatelji : '')}}
                                            </div>

                                            <div style="font-size: 0.9rem;">
                                                {{x.VrijemeObv | amDateFormat:'DD.MM.YY HH:mm'}}
                                            </div>
                                            <!-- <div style="margin-left: 5px;">
                                                {{x.primatelji}}
                                            </div> -->

                                        </div>
                                    </div>

                                    <div *ngIf="!srvObavijesti.bolOznacavanje" class="col col-2 d-flex justify-content-center align-items-center"
                                        style="color: #92a2ad;" (click)="obvSelect(x)">
                                        <i class="fa fa-comments-o" aria-hidden="true" style="font-size: 2rem;"></i>
                                    </div>

                                    <div *ngIf="!srvObavijesti.bolOznacavanje" class="col col-2 d-flex justify-content-center align-items-center"
                                        style="color: #92a2ad;" (click)="mdlPrimatelji(x)">
                                        <i class="material-icons" style="font-size: 2rem;">
                                            people
                                        </i>
                                    </div>

                                    <!-- <div class="col col-3 d-flex justify-content-center align-items-center" style="padding: 0;">
                                        <div class="mybtn-danger w-100" style="font-size: 1rem;">
                                            <i class="fa fa-trash" aria-hidden="true"></i> Obriši
                                        </div>
                                    </div> -->

                                </div>

                                <!-- <div *ngIf="ObavijestID == x.ObavijestID" style="margin-bottom: 5px; padding: 5px; background-color: #f8feff;">
                                    <div style="white-space: pre-line; padding: 10px; min-height: 100px;">
                                        <div style="font-weight: bold;">
                                            {{x.Naslov}}
                                        </div>
                                        <div>
                                            {{x.TekstObv}}
                                        </div>
                                    </div>
                                    <div *ngIf="obvDelID != x.ObavijestID" class="row">
                                        <div class="col" style="padding: 0 2px 0 0;" (click)="ObavijestID = 0;">
                                            <div class="btn mybtn-cancel w-100">Zatvori</div>
                                        </div>
                                        <div class="col" style="padding: 0 0 0 2px;" (click)="obvDelID = x.ObavijestID">
                                            <div class="btn mybtn-danger w-100">Obriši</div>
                                        </div>
                                    </div>
        
                                    <div *ngIf="obvDelID == x.ObavijestID" class="row">
                                        <div class="col" style="padding: 0 2px 0 0;">
                                            <div class="btn mybtn-danger w-100">Potvrdi brisanje</div>
                                        </div>
                                        <div class="col" style="padding: 0 0 0 2px;" (click)="obvDelID = 0;">
                                            <div class="btn mybtn-cancel w-100">Otkaži</div>
                                        </div>
                                    </div>
                                </div> -->

                            </div>

                        </div>

                    </div>

                </div>



            </div>

        </div>
    </div>



</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header posjed-mod-header d-flex justify-content-center">
                Obavijesti
            </div>

            <div class="modal-body posjed-mod-body">

                <app-obavijesti-sidebar></app-obavijesti-sidebar>

            </div>

            <div class="modal-footer bgs-dark">
                <button type="button" id="btnSidebarClose" class="btn btn-outline-secondary" data-dismiss="modal"
                    style="width: 100%;">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal nova obavijest -->
<button hidden id="btnMdlNovaObavijest" type="button" data-toggle="modal" data-target="#mdlNovaObavijest">
    Launch demo modal
</button>

<div class="modal fade" id="mdlNovaObavijest" tabindex="-1" role="dialog" aria-labelledby="mdlNovaObavijestLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header" style="padding: 10px;">
                <h6 class="modal-title" id="mdlNovaObavijestLabel">Nova obavijest</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Zatvori">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="padding: 0px">
                <app-obavijest-send *ngIf="mod.objKor"></app-obavijest-send>
            </div>

            <div class="modal-footer">
                <button id="btnMdlNovaObavijestClose" type="button" class="btn mybtn-cancel w-100"
                    data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Obavijest selected - POSLOVNI KORISNIK -->
<button hidden id="btnMdlObvSelKl" type="button" data-toggle="modal" data-target="#mdlObvSelKl">
    Modal odabrana obavijest
</button>

<div class="modal fade" id="mdlObvSelKl" tabindex="-1" role="dialog" aria-labelledby="mdlObvSelKl" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div *ngIf="objVobavRezSel" class="modal-content" style="border-radius: 10px;">

            <div class="modal-header" style="padding: 10px;">
                <div>
                    <!-- {{objVobavRezSel.Ime + ' ' + objVobavRezSel.Prezime}} -->
                </div>

                <div class="ml-auto">
                    {{objVobavRezSel.VrijemeObv | amDateFormat:'DD.MM.YY. HH:mm'}}
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Zatvori">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="padding: 5px; white-space: pre-line;">

                <div style="margin-bottom: 5px; padding: 5px; background-color: #f8feff;">

                    <div style="white-space: pre-line; min-height: 100px;">
                        <div style="font-weight: bold;">
                            {{objVobavRezSel.Naslov}}
                        </div>
                        <div>
                            {{objVobavRezSel.TekstObv}}
                        </div>
                    </div>

                </div>

            </div>

            <div class="modal-footer d-block" style="padding: 5px;">
                <!-- <button id="btnObSelKlClose" type="button" class="btn mybtn-cancel w-100" data-dismiss="modal">Zatvori</button> -->
                <div *ngIf="obvDelID != objVobavRezSel.vocID" class="row">
                    <div class="col" style="padding: 0 2px 0 0;" data-dismiss="modal" (click)="ObavijestID = 0;">
                        <div class="btn mybtn-cancel w-100">Zatvori</div>
                    </div>
                    <div class="col" style="padding: 0 0 0 2px;" (click)="obvDelID = objVobavRezSel.vocID">
                        <div class="btn mybtn-danger w-100">Obriši</div>
                    </div>
                </div>

                <div *ngIf="obvDelID == objVobavRezSel.vocID" class="row">
                    <div class="col" style="padding: 0 2px 0 0;">
                        <div class="btn mybtn-danger w-100">Potvrdi brisanje</div>
                    </div>
                    <div class="col" style="padding: 0 0 0 2px;" data-dismiss="modal" (click)="obvDelID = 0;">
                        <div class="btn mybtn-cancel w-100">Otkaži</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Modal Obavijest selected - ČLAN -->
<button hidden id="btnMdlObvSel" type="button" data-toggle="modal" data-target="#mdlObvSel">
    Modal odabrana obavijest
</button>

<div class="modal fade" id="mdlObvSel" tabindex="-1" role="dialog" aria-labelledby="mdlObvSel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div *ngIf="objVobavRezSel" class="modal-content" style="border-radius: 10px;">

            <div class="modal-header" style="padding: 10px;">
                <div>
                    {{objVobavRezSel.Ime + ' ' + objVobavRezSel.Prezime}}
                </div>

                <div class="ml-auto">
                    {{objVobavRezSel.VrijemeObv | amDateFormat:'DD.MM.YY. HH:mm'}}
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Zatvori">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="padding: 12px; white-space: pre-line;">

                <div style="font-weight: bold;">
                    {{objVobavRezSel.Naslov}}
                </div>

                <div>
                    {{objVobavRezSel.TekstObv}}
                </div>

            </div>

            <div class="modal-footer">
                <button id="btnObSelClose" type="button" class="btn mybtn-cancel w-100"
                    data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal nova pozivnica -->
<button hidden id="btnMdlNovaPozivnica" type="button" data-toggle="modal" data-target="#mdlNovaPozivnica">
    Launch demo modal
</button>

<div class="modal fade" id="mdlNovaPozivnica" tabindex="-1" role="dialog" aria-labelledby="mdlNovaPozivnica"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header" style="padding: 10px;">
                <h6 class="modal-title" id="mdlNovaPozivnicaLabel">Nova pozivnica u grupu</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Zatvori">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="padding: 5px 10px">
                <!-- <app-pozivnica *ngIf="mod.objKor"></app-pozivnica> -->
            </div>

            <div class="modal-footer">
                <button id="btnMdlNovaPozivnicaClose" type="button" class="btn mybtn-cancel w-100"
                    data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal primatelji -->
<button hidden id="btnMdlPrimatelji" type="button" data-toggle="modal" data-target="#mdlPrimatelji">
    Launch demo modal
</button>

<div class="modal fade" id="mdlPrimatelji" tabindex="-1" role="dialog" aria-labelledby="mdlPrimateljiLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header" style="padding: 10px;">
                <h6 class="modal-title" id="mdlNovaPozivnicaLabel">Primatelji</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Zatvori">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="padding: 5px;">

                <div>
                    <div *ngFor="let x of srvObavijesti.objPrimatelji">

                        <div class="row"
                            [ngClass]="{'obrisana': x.vocDel == 1, 'neprocitana': x.vocStatus == 0, 'procitana': x.vocStatus == 1}"
                            style="font-size: 1.1rem;">
                            <div class="col" style="padding: 2px 10px;">
                                <div>
                                    {{x.Ime + ' ' + x.Prezime}}
                                </div>
                            </div>
                            <div class="col d-flex justify-content-center align-items-center" style="padding: 0;">
                                <div *ngIf="x.vocStatus == 1">
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="x.vocStatus == 0">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>

            <div class="modal-footer">
                <button id="btnMdlPrimateljiClose" type="button" class="btn mybtn-cancel w-100"
                    data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>