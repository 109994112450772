import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiRez, ModulService } from '../modul.service';
import { NapredakService, PnPrgStavka, PnProgram, PnStavkaSwRedBr } from '../napredak.service';

@Component({
  selector: 'app-pn-prg-template',
  templateUrl: './pn-prg-template.component.html',
  styleUrl: './pn-prg-template.component.scss'
})
export class PnPrgTemplateComponent implements OnInit, OnDestroy {

  constructor(
    public mod: ModulService,
    public srvNapredak: NapredakService
  ) { }

  bolSort: boolean = false;

  ngOnInit(): void {

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.mod.objKor.token;
    }

    this.mod.provjeraTokena(tok, () => {

      this.mod.provjeraPrijaveTrenOper(() => {

        this.mod.ucitajPosJed(() => {

          //Učitavanje predložaka programa
          this.srvNapredak.pnFillByDopustenje((rez: PnProgram[]) => {
            this.srvNapredak.objProgrami = rez;
          });


        });

      });

    });

  }

  //Spremanje programa
  spremiProgram() {

    this.srvNapredak.frmPnProgram.markAllAsTouched();

    if (this.srvNapredak.frmPnProgram.valid) {

      this.srvNapredak.spremiProgram(this.srvNapredak.frmPnProgram.value, (rez: ApiRez) => {

        if (rez && rez.rez == 'ok') {

          document.getElementById('btnMdlPnEditClose').click();

          if (!this.srvNapredak.objProgrami) {
            this.srvNapredak.objProgrami = [];
          }

          if (!this.srvNapredak.frmPnProgram.get('pnID').value) {
            this.srvNapredak.objProgrami.push(rez.obj);
          } else {
            this.srvNapredak.objProgrami.forEach((val, ind) => {
              if (val.pnID == rez.obj.pnID) {
                this.srvNapredak.objProgrami.splice(ind, 1, rez.obj);
              }
            });
          }

          this.mod.msgBox(1, "SPREMLJENO!", 700);

        } else if (rez && rez.rez == 'err') {
          this.mod.msgBox(2, rez.errMsg, 800);
        }

      });

    } else {
      this.mod.msgBox(2, "Popunite obavezna polja", 800)
    }

  }

  //Unos / uređivanje stavke - START
  unosUredPnStavke(x: PnPrgStavka) {

    this.srvNapredak.bolBrisanje = false;

    this.srvNapredak.napraviFrmPnPrgStavka(x, () => {

      this.srvNapredak.frmPnPrgStavka.get('ppsPnID').setValue(this.srvNapredak.objPrgSel.pnID);

      document.getElementById('btnMdlPnStavEdit').click();

      setTimeout(() => {
        document.getElementById('ppsNaziv').focus();
      }, 500);

    });

  }

  //Spremanje stavke / parametra
  spremiPnStavku() {

    this.srvNapredak.frmPnPrgStavka.markAllAsTouched();

    if (this.srvNapredak.frmPnPrgStavka.valid) {

      this.srvNapredak.spremiPnPrgStavku(this.srvNapredak.frmPnPrgStavka.value, (rez: ApiRez) => {

        if (rez && rez.rez == 'ok') {

          document.getElementById('btnMdlPnStavEditClose').click();

          let ppsTmpID: number = this.srvNapredak.frmPnPrgStavka.get('ppsID').value;

          if (!ppsTmpID) {

            if (!this.srvNapredak.objPnPrgStavke) {
              this.srvNapredak.objPnPrgStavke = [];
            }

            this.srvNapredak.objPnPrgStavke.push(rez.obj);

          } else {

            this.srvNapredak.objPnPrgStavke.forEach((val, ind) => {

              if (val.ppsID == ppsTmpID) {
                this.srvNapredak.objPnPrgStavke.splice(ind, 1, rez.obj);
              }

            });

          }

          this.mod.msgBox(1, "SPREMLJENO!", 800);

        } else {
          this.mod.msgBox(2, "Greška prilikom spremanja!", 800);
        }

      });

    } else {
      this.mod.msgBox(2, "Popunite ispravno polja!", 800);
    }

  }

  //Ažuriranje rednih brojeva - switch (smjer: + = gore, - = dolje)
  pnStavkaSwRedBr(x: PnPrgStavka, i: number, smjer: string) {

    let iTmp: number = i;

    if (smjer == '+') {
      iTmp += 1;
    } else if (smjer == '-') {
      iTmp -= 1;
    }

    let ppsIDnew: number = this.srvNapredak.objPnPrgStavke[iTmp].ppsID;
    let ppsRedBrNew: number = this.srvNapredak.objPnPrgStavke[iTmp].ppsRedBr;

    let obj0: PnStavkaSwRedBr = {
      ppsPnID: x.ppsPnID,
      ppsIDold: x.ppsID,
      ppsRedBrOld: x.ppsRedBr,
      ppsIDnew: ppsIDnew,
      ppsRedBrNew: ppsRedBrNew,
      fingerprint: this.mod.fingerprint()
    }

    this.srvNapredak.pnStavkaSwRedBr(obj0, (rez: ApiRez) => {

      if (rez && rez.rez == 'ok') {

        this.srvNapredak.objPnPrgStavke = rez.obj;

      } else {
        this.mod.msgBox(2, "Greška prilikom spremanja!", 800);
      }

    });

  }

  ngOnDestroy(): void {
    this.srvNapredak.objPnPrgStavke = null;
  }

}
