<div class="container" style="padding: 0; height: 100%;">

    <div class="row" style="height: 100%; padding-top: 50px; max-height: 100%;">

        <!-- Lijeva kolona -->
        <div class="d-none d-md-block col col-4 col-left">

            <div class="card" style="opacity: 0.9; padding-top: 10px;">
                <app-clanarine-clan-sidebar></app-clanarine-clan-sidebar>
            </div>

        </div>

        <!-- Desna kolona -->
        <div class="col" style="max-height: 100%; opacity: 0.9;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">

                <div class="bgs-light">
                    <div data-toggle="modal" data-target="#mdlSidebar">

                        <app-pos-jed-hamburger></app-pos-jed-hamburger>

                    </div>
                </div>

                <div class="flex-grow-1 bgs-light"
                    style="overflow-y: auto; scrollbar-width: none; padding-left: 2px; padding-bottom: 20px;">

                    <div>

                        <div *ngIf="!srvClanClan.objClanarine" class="alert alert-success" style="text-align: center; margin: 0; margin-top: 20px;">
                            nema podataka za prikaz
                        </div>

                        <div *ngFor="let x of srvClanClan.objClanarine" style="padding: 5px;">

                            <div style="padding: 5px; border-bottom: solid 0.5px lightgrey;">

                                <div class="row">

                                    <div class="col col-3 d-flex justify-content-center align-items-center">
                                        <img style="border-radius: 50%; max-height: 90px;"
                                            src="{{imgSrc(x.img, x.imgSize)}}"
                                            onerror="this.onerror=null; this.src='fitNet/assets/clanovi/0.jpg'"
                                            class="img-fluid p-slika" alt="{Slika nije dostupna}">
                                    </div>

                                    <div class="col mycol" style="padding: 5px;">

                                        <div class="row">

                                            <div class="col mycol d-flex flex-column justify-content-center">
                                                <div [ngStyle]="{'font-weight': !x.pJed ? 'bold' : 'normal'}">
                                                    {{x.kIme}}
                                                </div>
        
                                                <div>
                                                    <div *ngFor="let y of x.pJed">
        
                                                        <div *ngIf="y.intIsto != 1" style="font-weight: bold;">
                                                            {{y.NazivPos}}
                                                        </div>
        
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="mod.dateDiffNow(x.claVrijediDO) > 0" class="col col-4 d-flex align-items-center justify-content-center">

                                                <div style="font-size: 2rem; margin-right: 5px;" [ngStyle]="{'color': clanColor(mod.dateDiffNow(x.claVrijediDO))}">
                                                    {{mod.dateDiffNow(x.claVrijediDO)}}
                                                </div>
                                                <div>
                                                    {{mod.dateDiffNow(x.claVrijediDO) | sklanjanjeDaniClean}}
                                                </div>

                                            </div>

                                        </div>

                                        

                                        <div *ngIf="x.claVrstaID == 0">
                                            {{x.grpOpis}}
                                        </div>

                                        <div *ngIf="x.claVrstaID != 0">
                                            <div>
                                                Vrsta članarine / tjednih posjeta
                                            </div>
                                            <div>
                                                {{x.cvOpis + ' (' + (x.cvTjedno != 0 ? x.cvTjedno + 'X tj.' : 'neograničeno') + ')'}}
                                            </div>
                                        </div>

                                        <div [ngStyle]="{'color': clanColor(mod.dateDiffNow(x.claVrijediDO))}">
                                            {{'vrijedi od: ' + (x.claVrijediOD | amDateFormat:'DD.MM.YYYY.') + ' do ' +
                                            (x.claVrijediDO | amDateFormat:'DD.MM.YYYY.')}}
                                        </div>

                                        <div class="d-flex align-items-center">
                                            <div style="font-size: 1.2rem; font-weight: bold; margin-right: 10px;">
                                                {{x.claIznos}}€
                                            </div>
                                            <div *ngIf="x.intPlacena == 1">
                                                {{'plaćena ' + (x.claDatumUplate | amDateFormat:'DD.MM.YYYY.')}}
                                            </div>
                                            <div *ngIf="x.intPlacena == 0" style="color: red;">
                                                ! Nije plaćena
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header posjed-mod-header d-flex justify-content-center">
                Pregled članarina
            </div>

            <div class="modal-body" style="padding: 2px;">

                <app-clanarine-clan-sidebar></app-clanarine-clan-sidebar>

            </div>
            <div class="modal-footer bgs-dark" style="padding: 5px;">
                <button type="button" id="btnSidebarClose" class="btn mybtn-cancel" data-dismiss="modal"
                    style="width: 100%; font-size: 1.2rem;">Zatvori</button>
            </div>
        </div>
    </div>
</div>