import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';
import { ModulService } from '../modul.service';
import { Clan, ClanoviService, TrenutnoPrisutni, VClanstvo } from '../clanovi.service';
import * as moment from 'moment';
import { ClanarineService, Vclanarina } from '../clanarine.service';

export interface VTrenClan extends TrenutnoPrisutni, Clan {

}

@Component({
  selector: 'app-qr-scan-zxing',
  standalone: false,
  templateUrl: './qr-scan-zxing.component.html',
  styleUrl: './qr-scan-zxing.component.scss'
})
export class QrScanZxingComponent implements OnInit {

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices: boolean;
  hasPermission: boolean;

  qrResultString: string;

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;

  constructor(
    private readonly _dialog: MatDialog,
    public mod: ModulService,
    public srvClanovi: ClanoviService,
    public srvClanarine: ClanarineService
  ) { }

  ngOnInit(): void {
    this.srvClanovi.objVClanstva = null;
  }

  clearResult(): void {
    this.qrResultString = null;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {

    this.mod.beep(200, 1000, 50);

    this.qrResultString = resultString;

    try {

      let rez = JSON.parse(this.qrResultString);

      let fitId: number = Number(rez.fitnet);
      let klId: number = Number(rez.klijent);
      let clan: number = Number(rez.clan);

      this.mod.bolQrScaning = false;

      if (fitId != 0) {

        /* fitId = 71; //test */

        this.srvClanovi.clanstvaByFitnetId(fitId, this.mod.objPosJedSelected.KlijentIDpos, (rez) => {
          this.handleQrRez(rez);
        });

      } else if (klId != 0) {

        this.srvClanovi.clanstvaByKlijentId(klId, clan, (rez) => {
          this.handleQrRez(rez);
        });

      } else {
        this.mod.msgBox(2, "Nepoznat format", 5000);
        this.mod.bolQrScaning = false;
        setTimeout(() => {
          this.mod.bolQrScaning = true;
        }, 3000);
      }

    } catch (error) {
      this.mod.msgBox(2, "Nepoznat format 2", 5000);
      this.mod.bolQrScaning = false;
      setTimeout(() => {
        this.mod.bolQrScaning = true;
      }, 3000);
    }



  }

  //Obrada učitanog QR koda
  handleQrRez(rez) {

    if (rez?.prijavljen) {
      //već prijavljen
      /* this.mod.msgBox(2, "Član je već prijavljen!", 3000); */

      let objTren: VTrenClan = rez['data'];

      this.srvClanovi.napraviFrmOdjavaQr(objTren.trenID, objTren.Ime + ' ' + objTren.Prezime, moment(objTren.vrijemePrijava), (rez) => {
        document.getElementById('btnMdlQrScanClose').click();
        setTimeout(() => {
          document.getElementById('btnMdlOdjavaQr').click();  
        }, 300);
        
      });


    } else if (rez == null) {
      //nije pronađen
      this.mod.msgBox(2, "Član nije pronađen!", 3000);

      this.qrResultString = null;
      setTimeout(() => {
        this.mod.bolQrScaning = true;
      }, 3000);


    } else {

      if (rez.length == 1) {
        /* this.mod.msgBox(1, "Samo jedno članstvo!", 3000); */

        this.postaviFrmPrijava(this.mod.objPosJedSelected.PoslJedID, rez[0].clanID, rez[0].Ime + ' ' + rez[0].Prezime, rez[0].vrstaIDcl);

        document.getElementById('btnMdlQrPrijava').click();

      } else {
        this.srvClanovi.objVClanstva = rez;
      }
      
    }

  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    if (device != this.currentDevice) {
      this.currentDevice = device || null;
    }
  }

  openFormatsDialog() {
    const data = {
      formatsEnabled: this.formatsEnabled,
    };

    /* this._dialog
      .open(FormatsDialogComponent, { data })
      .afterClosed()
      .subscribe(x => { if (x) { this.formatsEnabled = x; } }); */
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  openInfoDialog() {
    const data = {
      hasDevices: this.hasDevices,
      hasPermission: this.hasPermission,
    };

    /* this._dialog.open(AppInfoDialogComponent, { data }); */
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }

  postaviFrmPrijava(PoslJedID, clanID, ImePrezime, vrstaIDcl) {

    this.srvClanovi.objVClanstva = null;
    document.getElementById('btnMdlQrScanClose').click();

    this.srvClanovi.postaviFrmPrijava(PoslJedID, clanID, ImePrezime, vrstaIDcl, (rez) => {

      this.srvClanovi.aktZaClana = null;
      this.srvClanovi.tjPosjeta = 0;
      this.srvClanovi.clanarineByClan = null;

      //Formatiranje vremena prijave
      let hTmp = this.srvClanovi.frmPrijava.get('priH').value;
      let minTmp = this.srvClanovi.frmPrijava.get('priMin').value;
      let datTmp: moment.Moment = moment(moment(this.srvClanovi.frmPrijava.get('priDat').value).format('YYYY-MM-DD') + ' ' + hTmp + ':' + minTmp);

      //Članarine
      this.srvClanarine.vazeceByClan(clanID, datTmp, (rez12: Vclanarina[]) => {
        this.srvClanovi.clanarineByClan = rez12;
      });

      //Dostupni grupni termini 
      this.srvClanovi.terminiZaClana(clanID, PoslJedID, datTmp, (rez0) => {
        this.srvClanovi.aktZaClana = rez0;
      });

      //broj tjednih posjeta
      this.srvClanovi.tjednihPosjeta(clanID, vrstaIDcl, PoslJedID, datTmp, (rez202) => {
        this.srvClanovi.tjPosjeta = rez202 + 1;
      });


    });

  }

}
