import { Injectable } from '@angular/core';
import { ApiRez, ModulService } from './modul.service';
import { HttpClient } from '@angular/common/http';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Moment } from 'moment';
import * as moment from 'moment';
import { isNum, isNumAllowNull } from './validatori';
import { Clan } from './clanovi.service';

export interface PnProgram {
  pnID: number,
  pnKorID: number,
  pnKlijentID: number,
  pnPublic: number,
  pnNaziv: string,
  pnNapomena: string
}

export interface PnPrgStavka {
  ppsID: number,
  ppsPnID: number,
  ppsRedBr: number,
  ppsNaziv: string,
  ppsJedMj: string,
  ppsAkt: number,
  ppsDel: number
}

//mjerenje - stavka
export interface VeMjerenjeStav extends PnMjerenje, PnPrgStavka {

}

//Switch Red Br PnPrg stavke
export interface PnStavkaSwRedBr {
  ppsPnID: number,
  ppsIDold: number,
  ppsIDnew: number,
  ppsRedBrOld: number,
  ppsRedBrNew: number,
  fingerprint: string
}

//Veza - korisnik / član - pn program
export interface PnVeza {
  pnvID: number
  pnvKorID: number
  pnvClanID: number
  pnvDatum: Moment
  pnvTemplID: number
  pnvAkt: number
}

//Veza pnVeza- pn program
export interface VePnVezaByClanID extends PnVeza, PnProgram {

}

//Pn Mjerenje
export interface PnMjerenje {
  pnmID: number,
  pnmPnvID: number,
  pnmDatum: Moment,
  pnmParametarID: number,
  pnmVrijednost: number
}

//Zadnje mjerenje
export interface ZadnjeMjerenje {
  datum: Moment,
  obj: PnMjerenje[]
}

//Zadani ciljevi
export interface PnCilj {
  pcID: number,
  pcPnvID: number,
  pcPpsID: number,
  pcVrijednost: number
}

// Veza program - cilj
export interface VePnCilj extends PnProgram, PnPrgStavka, PnCilj {
  prvoMj: number
}

//Mjerenje - statistika
export interface PnStat {
  naziv: string,
  jedMj: string,
  mjerenja: PnMjerenje[]
}

//Mjerenje statistika - pojedinačno (za uređivanje)
export interface PnStatPojedinacno {
  datum: Moment,
  stavke: VeMjerenjeStav[]
}

//Mjerenje - uređivanje datuma
export interface FrmPnMjDatEdit {
  datumOld: Moment,
  datum: Moment,
  datOd: Moment,
  datDo: Moment,
  pnmPnvID: number,
  fingerprint: string
}

//Mjerenje - uređivanje vrijednosti
export interface FrmPnMjStavEdit {
  pnmID: number,
  ppsJedMj: string,
  pnmVrijednost: number,
  fingerprint: string
}

@Injectable({
  providedIn: 'root'
})
export class NapredakService {

  constructor(
    private mod: ModulService,
    private http: HttpClient,
    public frmBuilder: FormBuilder
  ) { }

  objProgrami: PnProgram[]; //template
  objPrgSel: PnProgram; //odabrani program
  objVePnVezaByClanID: VePnVezaByClanID[]; //veza pn program by član
  objVePnVezaByClanIDsel: VePnVezaByClanID; //veza pn program by član

  objPnPrgStavke: PnPrgStavka[];

  objPnStat: PnStat[];
  objPnStatDetalji: PnStatPojedinacno[];

  objClanovi: Clan[];

  frmPnProgram: FormGroup;
  frmPnPrgStavka: FormGroup;
  frmPnVeza: FormGroup;
  frmPnMjerenje: FormGroup;
  frmVePnCilj: FormGroup;

  pnTempID: number; //Odabrani predložak tmp

  bolBrisanje: boolean = false;

  objClanSel: Clan;

  intAkt: number = 1;

  bolPnStatDetalji: boolean = false;
  bolDelPnMjStav: boolean = false;

  //napravi frmProgram
  napraviFrmProgram(val: PnProgram, callback) {

    this.frmPnProgram = this.frmBuilder.group({
      pnID: [0],
      pnKorID: [this.mod.objKor.korisnikID],
      pnKlijentID: [0],
      pnPublic: [0],
      pnNaziv: [, [Validators.required, Validators.maxLength(100)]],
      pnNapomena: [, [Validators.maxLength(750)]],
      fingerprint: [this.mod.fingerprint()]
    });

    if (val) {
      this.frmPnProgram.patchValue(val);
    }

    callback(this.frmPnProgram.value);

  }

  //napravi frmPnPrgStavka
  napraviFrmPnPrgStavka(val: PnPrgStavka, callback) {

    this.frmPnPrgStavka = this.frmBuilder.group({
      ppsID: [0],
      ppsPnID: [0],
      ppsNaziv: [, [Validators.required, Validators.maxLength(100)]],
      ppsJedMj: [, [Validators.required]],
      ppsAkt: [1],
      ppsDel: [0],
      fingerprint: [this.mod.fingerprint()]
    });

    if (val) {
      this.frmPnPrgStavka.patchValue(val);
    }

    callback(this.frmPnPrgStavka.value);

  }

  //napravi frmPnVeza
  napraviFrmPnVeza(val: PnVeza, callback) {

    this.frmPnVeza = this.frmBuilder.group({
      pnvID: [0],
      pnvKorID: [this.mod.objKor.korisnikID],
      pnvClanID: [0],
      pnvDatum: [moment(), [Validators.required]],
      pnvTemplID: [, [Validators.required]],
      pnvAkt: [1],
      fingerprint: [this.mod.fingerprint()]
    });

    if (val) {
      this.frmPnVeza.patchValue(val);
    }

    callback(this.frmPnVeza.value);

  }

  //napravi Frm Mjerenje

  //helper form
  napraviFrmMjStav(pnNaziv: string, ppsJedMj: string, pnmPnvID: number, pnmParametarID: number, vrijedost: number, ppsAkt: number) {
    return this.frmBuilder.group({
      pnmID: [0],
      pnNaziv: [pnNaziv],
      ppsJedMj: [ppsJedMj],
      pnmPnvID: [pnmPnvID],
      pnmDatum: [moment()],
      pnmParametarID: [pnmParametarID],
      pnmVrijednost: [vrijedost, [isNumAllowNull]],
      ppsAkt: [ppsAkt]
    });
  }

  //frm pn mjerenje
  stavke: FormArray;
  napraviFrmPnMjerenje(pnvID, obj0: PnPrgStavka[], callback) {

    this.frmPnMjerenje = this.frmBuilder.group({
      datum: [moment(), [Validators.required]],
      stavke: this.frmBuilder.array([])
    });


    this.stavke = <FormArray>this.frmPnMjerenje.controls['stavke'];
    obj0?.forEach(val => {
      if (val.ppsAkt) {
        this.stavke.push(this.napraviFrmMjStav(val.ppsNaziv, val.ppsJedMj, pnvID, val.ppsID, null, val.ppsAkt));
      }
    });

    callback(this.frmPnMjerenje.value);

  }

  //frm VePnCilj

  //Helper form - stavka
  stavkeVePnCilj: FormArray;
  napraviFrmVePnCiljStavka(val: VePnCilj) {

    return this.frmBuilder.group({
      pnID: [val.pnID],
      pnKorID: [val.pnKorID],
      pnKlijentID: [val.pnKlijentID],
      pnPublic: [val.pnPublic],
      pnNaziv: [val.pnNaziv],
      pnNapomena: [val.pnNapomena],
      ppsID: [val.ppsID],
      ppsPnID: [val.ppsPnID],
      ppsRedBr: [val.ppsRedBr],
      ppsNaziv: [val.ppsNaziv],
      ppsJedMj: [val.ppsJedMj],
      ppsAkt: [val.ppsAkt],
      ppsDel: [val.ppsDel],
      pcID: [val.pcID],
      pcPnvID: [val.pcPnvID],
      pcPpsID: [val.pcPpsID],
      pcVrijednost: [val.pcVrijednost, [isNumAllowNull]],
      prvoMj: [val.prvoMj],
      fingerprint: [this.mod.fingerprint()]
    });

  }

  napraviFrmVePnCilj(pnvID: number, obj0: VePnCilj[], callback) {

    this.frmVePnCilj = this.frmBuilder.group({
      pnvID: [pnvID],
      stavke: this.frmBuilder.array([])
    });


    this.stavkeVePnCilj = <FormArray>this.frmVePnCilj.controls['stavke'];
    obj0?.forEach(val => {
      this.stavkeVePnCilj.push(this.napraviFrmVePnCiljStavka(val));
    });

    callback(this.frmVePnCilj.value);

  }

  //Mjerenje - uređivanje datuma
  frmPnMjDatEdit: FormGroup;
  napraviFrmPnMjDatEdit(val: FrmPnMjDatEdit, callback) {

    this.frmPnMjDatEdit = this.frmBuilder.group({
      datumOld: [],
      datum: [moment(), [Validators.required]],
      datOd: [],
      datDo: [],
      pnmPnvID: [this.objVePnVezaByClanIDsel.pnvID],
      fingerprint: [this.mod.fingerprint()]
    });

    if (val) {
      this.frmPnMjDatEdit.patchValue(val);
    }

    callback(this.frmPnMjDatEdit.value);

  }

  //Mjerenje - uređivanje / spremi datum
  spremiPnMjDat(val: FrmPnMjDatEdit, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=spremiPnMjDat';
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez: PnStatPojedinacno[]) => {
      callback(rez);
    });

  }

  //Mjerenje - uređivanje stavke
  frmPnMjStavEdit: FormGroup;
  napraviFrmPnMjStavEdit(val: FrmPnMjStavEdit, callback) {

    this.frmPnMjStavEdit = this.frmBuilder.group({
      pnmID: [],
      ppsJedMj: [],
      pnmVrijednost: [, [Validators.required, isNum]],
      fingerprint: [this.mod.fingerprint()]
    });

    if (val) {
      this.frmPnMjStavEdit.patchValue(val);
    }

    callback(this.frmPnMjStavEdit.value);

  }

  //Mjerenje - uređivanje / spremi vrijednost
  spremiPnMjStav(val: FrmPnMjStavEdit, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=spremiPnMjStav';
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez: VeMjerenjeStav) => {
      callback(rez);
    });

  }

  //Brisanje mjerenja
  obrisiPnMjStav(val: FrmPnMjStavEdit, callback) {
    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=obrisiPnMjStav';
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez: VeMjerenjeStav) => {
      callback(rez);
    });
  }


  decFormatVr(ind, event) {
    let strNaziv: string = event.srcElement.ariaLabel;
    if (this.stavke.at(ind).get('pnmVrijednost').value) {
      this.stavke.at(ind).get('pnmVrijednost').setValue(this.stavke.at(ind).get('pnmVrijednost').value.replace(',', '.'));
    }
  }

  decFormatVrCilj(ind, event) {
    let strNaziv: string = event.srcElement.ariaLabel;
    if (this.stavkeVePnCilj.at(ind).get('pcVrijednost').value) {
      this.stavkeVePnCilj.at(ind).get('pcVrijednost').setValue(this.stavkeVePnCilj.at(ind).get('pcVrijednost').value.replace(',', '.'));
    }
  }

  decFormatStav() {
    this.frmPnMjStavEdit.get('pnmVrijednost').setValue(this.frmPnMjStavEdit.get('pnmVrijednost').value.replace(',', '.'));

  }

  //Popunjavanje objProgrami (template) po razini dopuštenja
  pnFillByDopustenje(callback) {

    let pnKlijentID: number;

    if (this.mod.objKor.vrstaID == 1) {

      if (this.mod.session) {
        pnKlijentID = this.mod.session.KlijentIDtren;
      } else {
        pnKlijentID = 0;
      }

    } else {
      pnKlijentID = this.mod.objKor.korisnikID;
    }

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=pnFillByDopustenje';
    let obj: string = JSON.stringify({ pnKlijentID: pnKlijentID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: PnProgram[]) => {
      callback(rez);
    });

  }

  //Spremanje programa
  spremiProgram(obj0: PnProgram, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=spremiProgram';
    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez: ApiRez) => {
      callback(rez);
    });

  }

  //Programi template stavke by pnID
  pnStavkeByPnID(pnID: number, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=pnStavkeByPnID';
    let obj: string = JSON.stringify({ pnID: pnID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: PnPrgStavka[]) => {
      callback(rez);
    });

  }

  //Spremanje stavke / parametra
  spremiPnPrgStavku(val: PnPrgStavka, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=spremiPnPrgStavku';
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez: ApiRez) => {
      callback(rez);
    });

  }

  //Brisanje stavke / parametra
  obrisiPnPrgStavku(ppsID: number, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=obrisiPnPrgStavku';
    let obj: string = JSON.stringify({ ppsID: ppsID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: ApiRez) => {
      callback(rez);
    });

  }

  //Ažuriranje rednih brojeva - switch
  pnStavkaSwRedBr(obj0: PnStavkaSwRedBr, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=pnStavkaSwRedBr';
    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez: PnPrgStavka[]) => {
      callback(rez);
    });

  }

  //Spremanje pn veze
  spremiPnVezu(obj0: PnVeza, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=spremiPnVezu';
    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez: ApiRez) => {
      callback(rez);
    });

  }

  //Praćenje napretka by član
  vePnVezaByClanID(pnvClanID: number, pnvAkt: number, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=vePnVezaByClanID';
    let obj: string = JSON.stringify({ pnvClanID: pnvClanID, pnvAkt: pnvAkt, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VePnVezaByClanID[]) => {
      callback(rez);
    });

  }

  //Praćenje napretka by korisnik ID - privatni korisnici (samo članovi)
  vePnVezaByKorisnikID(pnvAkt: number, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=vePnVezaByKorisnikID';
    let obj: string = JSON.stringify({ pnvAkt: pnvAkt, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VePnVezaByClanID[]) => {
      callback(rez);
    });

  }

  //zadnje mjerenje - data
  zadnjeMjerenje(pnmPnvID: number, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=zadnjeMjerenje';
    let obj: string = JSON.stringify({ pnmPnvID: pnmPnvID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: ZadnjeMjerenje) => {
      callback(rez);
    });

  }

  //Učitavanje zadnjeg mjerenja
  zadnjeMjerenjeHandle(pnvID: number) {

    this.zadnjeMjerenje(pnvID, (rez) => {

      if (rez && this.stavke?.length > 0) {

        this.stavke.controls.forEach((val, ind) => {

          let id1: number = val['controls'].pnmParametarID.value;

          //zadnje mjerenje
          rez.obj.forEach((val1, ind1) => {
            if (id1 == val1.pnmParametarID) {
              /* val['old'] = val1.pnmVrijednost; */
              /* val['controls']['old'] = val1.pnmVrijednost; */
              this.stavke.controls[ind]['controls'].old = val1.pnmVrijednost;
              this.stavke.controls[ind]['controls'].oldDat = val1.pnmDatum;
            }
          });

          //prvo mjerenje
          rez.obj0.forEach((val1, ind1) => {
            if (id1 == val1.pnmParametarID) {
              /* val['old'] = val1.pnmVrijednost; */
              /* val['controls']['old'] = val1.pnmVrijednost; */
              this.stavke.controls[ind]['controls'].first = val1.pnmVrijednost;
              this.stavke.controls[ind]['controls'].firstDat = val1.pnmDatum;
            }
          });

          //Zadani ciljevi
          rez.objPc?.forEach((val1, ind1) => {
            if (id1 == val1.pcPpsID) {
              /* val['old'] = val1.pnmVrijednost; */
              /* val['controls']['old'] = val1.pnmVrijednost; */
              this.stavke.controls[ind]['controls'].goal = val1.pcVrijednost;
            }
          });

        });

      }

    });

  }

  //Izračun napretka
  calcNapredak(val1: number, val2: number) {

    let razlika: number = val2 - val1;

    let postotak: number

    if (val1 != 0) {
      postotak = Math.round(razlika / val1 * 100);
    } else {
      postotak = 100;
    }

    let rez = {
      razlika: razlika,
      postotak: postotak
    }

    return rez;

  }

  //Izračun postotka
  calcNapredPosto(start: number, end: number, goal: number) {

    //Ukupno za pojačati
    let ukCilj: number = goal - start;

    //Ukupno pojačano
    let ukPoj: number = end - start;

    let rez = {
      ukPoj: ukPoj,
      ukCilj: ukCilj,
      postotak: Math.round(ukPoj / ukCilj * 100)
    }

    return rez;

  }

  //Spremi Mjerenje
  spremiMjerenje(obj0, callback) {

    interface Obj1 {
      datum: Moment,
      stavke: Stavka[],
      fingerprint: string
    }

    interface Stavka {
      pnmPnvID: number,
      pnmParametarID: number,
      pnmVrijednost: number
    }

    let objStav: Stavka[] = [];

    obj0.stavke?.forEach(val => {
      if (val.pnmVrijednost) {
        let objTmp: Stavka = {
          pnmPnvID: val.pnmPnvID,
          pnmParametarID: val.pnmParametarID,
          pnmVrijednost: val.pnmVrijednost
        }
        objStav.push(objTmp);
      }
    });

    if (objStav?.length > 0) {

      let obj1: Obj1 = {
        datum: obj0.datum,
        stavke: objStav,
        fingerprint: this.mod.fingerprint()
      }

      let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=spremiMjerenje';
      let obj: string = JSON.stringify(obj1);

      this.http.post(url, obj).subscribe((rez: ApiRez) => {
        callback(rez);
      });

    } else {

      let objRez: ApiRez = {
        rez: 'empty',
        errMsg: 'Nema podataka za spremanje',
        obj: null
      }

      callback(objRez);

    }

  }

  //Zadani ciljevi by pnvID
  ciljeviByPnvID(pnvID: number, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=ciljeviByPnvID';
    let obj: string = JSON.stringify({ pnvID: pnvID, fingerprint: this.mod.fingerprint() })

    this.http.post(url, obj).subscribe((rez: VePnCilj[]) => {
      callback(rez);
    });

  }

  //Spremanje ciljeva za odabrani probram (pnv)
  spremiCiljeve(obj0: VePnCilj[], callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=spremiCiljeve';
    let obj: string = JSON.stringify(obj0)

    this.http.post(url, obj).subscribe((rez: VePnCilj[]) => {
      callback(rez);
    });

  }

  //Mjerenja by program / član ID
  pnStatistikaByClan(pnvClanID: number, pnvID: number, datOd: Moment, datDo: Moment, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=pnStatistikaByClan';
    let obj: string = JSON.stringify({ pnvClanID: pnvClanID, pnvID: pnvID, datOd: datOd, datDo: datDo, fingerprint: this.mod.fingerprint() })

    this.http.post(url, obj).subscribe((rez: PnStat[]) => {
      callback(rez);
    });

  }

  //Mjerenja by program / član ID
  pnStatDetalji(pnmPnvID: number, datOd: Moment, datDo: Moment, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=pnStatDetalji';
    let obj: string = JSON.stringify({ pnmPnvID: pnmPnvID, datOd: datOd, datDo: datDo, fingerprint: this.mod.fingerprint() })

    this.http.post(url, obj).subscribe((rez: PnStatPojedinacno[]) => {
      callback(rez);
    });

  }

  //pn pregled- line chart data
  napuniChartDd() {

    if (this.objPnStat?.length > 0) {

      this.objPnStat.forEach((val, ind) => {

        let chartDdData;
        let cddWidth: number;

        let color: string = '#91d6ff';

        cddWidth = val.mjerenja?.length * 100;

        chartDdData = [];
        val.mjerenja?.forEach((val: PnMjerenje) => {

          /* val.pnmDatum = moment(val.pnmDatum).format('DD.MM.YYYY.'); */
          chartDdData.push([moment(val.pnmDatum).format('DD.MM.YYYY.'), Number(val.pnmVrijednost), color, Number(val.pnmVrijednost)]);

        });

        this.objPnStat[ind]['cddWidth'] = cddWidth;
        this.objPnStat[ind]['chartDdData'] = chartDdData;

        /* return { cddWidth: cddWidth, chartDdData: chartDdData }; */

      });

    }

  }

  //Razlika datuma u danima
  dateDiffDani(dat01, dat02) {

    let dat1: Moment = moment(dat01);
    let dat2: Moment = moment(dat02);
    return dat2.diff(dat1, 'days');
  }

  //Dodavnje programa za praćenje arr by grupa
  spremiPnvByGrupa(obj0, callback) {

    let url: string = this.mod.webUrl + '/php/napredak.php?token=' + this.mod.objKor.token + '&funk=spremiPnvByGrupa';
    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez: ApiRez) => {
      callback(rez);
    });

  }

}
