import { Component } from '@angular/core';
import { PosJediniceService } from '../pos-jedinice.service';
import { KorisniciService } from '../korisnici.service';

@Component({
  selector: 'app-pos-jed-edit',
  templateUrl: './pos-jed-edit.component.html',
  styleUrl: './pos-jed-edit.component.scss'
})
export class PosJedEditComponent {

  constructor(
    public srvPosJed: PosJediniceService,
    public srvKorisnici: KorisniciService
  ) {}

  //isto check click
  istoCheck() {
    if (this.srvPosJed.frmPosJed.controls['intIsto'].value == 0) {
      this.srvPosJed.frmPosJed.controls['intIsto'].setValue(1);
    } else {
      this.srvPosJed.frmPosJed.controls['intIsto'].setValue(0);
    }
  }

  //SET MjestoID (posl. jed.)
  postaviMjestoID2(mjestoID: number) {
    this.srvPosJed.frmPosJed.patchValue({ MjestoIDpos: mjestoID });
  }

  //aktivna check click
  aktivnaCheck() {
    if (this.srvPosJed.frmPosJed.controls['aktivna'].value == 0) {
      this.srvPosJed.frmPosJed.controls['aktivna'].setValue(1);
    } else {
      this.srvPosJed.frmPosJed.controls['aktivna'].setValue(0);
    }
  }

}
