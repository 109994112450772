<div class="container" style="padding:0; height: 100%;">


    <div class="row" style="padding-top: 50px; height: 100%; max-height: 100%;">

        <!-- Lijeva kolona -->
        <div class="d-none d-md-block card col col-4 col-left" style="padding: 5px;">

            <app-vjezbe-sidebar></app-vjezbe-sidebar>

        </div>

        <!-- Desna kolona -->
        <div class="col card col-right" style="padding: 5px; padding-bottom: 30px; height: 100%; max-height: 100%;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">


                <div class="d-block d-md-none" data-toggle="modal" data-target="#mdlSidebar">

                    <app-pos-jed-hamburger></app-pos-jed-hamburger>

                </div>

                <div class="flex-grow-1" style="overflow-y: scroll; scrollbar-width: none;">


                    <div *ngIf="srvVjezbe.objVjezbaSel">

                        <div>
                            <h5>{{srvVjezbe.objVjezbaSel?.vjNaziv}}</h5> 
                        </div>

                        <div style="white-space: pre-line; margin-bottom: 15px;">
                            {{srvVjezbe.objVjezbaSel?.vjOpis}}
                        </div>

                        <div>

                            <button type="button" class="btn mybtn-cancel w-100" (click)="prikazVjezbe()">Prikaz vježbe</button>

                        </div>

                    </div>



                </div>

            </div>

        </div>

    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#mdlSidebar"></button>

<div class="modal left fade" id="mdlSidebar" tabindex="-1" role="dialog" aria-labelledby="mdlSidebarLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header posjed-mod-header d-flex justify-content-center">
                Vježžbe
            </div>

            <div class="modal-body posjed-mod-body">

                <div>
                    <app-vjezbe-sidebar></app-vjezbe-sidebar>
                </div>
                
            </div>
            <div class="modal-footer bgs-dark" style="padding: 5px;">
                <button type="button" id="btnSidebarClose" class="btn mybtn-cancel" data-dismiss="modal"
                    style="width: 100%; font-size: 1.2rem;">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal - fb Video -->
<button hidden="true" id="btnMdlFbVideo" class="btn btn-info" data-toggle="modal" data-target="#mdlFbVideo"></button>

<div class="modal fade" id="mdlFbVideo" #mdlFbVideo tabindex="-1" role="dialog" aria-labelledby="Modal video prikaz vježbe"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header" style="padding: 10px;">
                <div class="modal-title" id="mdlFbVideoLabel">Video prikaz vježbe</div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center; padding: 0px 2px;">
                <div *ngIf="srvVjezbe.objVjezbaSel">
                    <app-fb-video></app-fb-video>
                </div>
            </div>
            <div class="modal-footer d-block" style="padding: 2px;">
                <div class="row">
                    <!-- <div class="col" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100"
                            (click)="spremiAktivnost()">SPREMI</button>
                    </div> -->
                    <div class="col" style="padding: 2px;">
                        <button id="btnMdlAktEditClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>