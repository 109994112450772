import { Component, OnInit, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { ApiRez, ModulService, PosJedinice } from '../modul.service';
import { HttpClient } from '@angular/common/http';
import { ClanoviService } from '../clanovi.service';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition, query, stagger } from '@angular/animations';
import { listFadeIn } from "../animacije";
import { NapredakService, PnPrgStavka, PnVeza, VePnCilj, VePnVezaByClanID } from '../napredak.service';

//Animacija - 
export const myInsertRemoveTrigger = trigger('myInsertRemoveTrigger', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('1000ms', style({ opacity: 1 })),
  ])/* ,
  transition(':leave', [
    animate('200ms', style({ opacity: 0 }))
  ]) */
]);

//Animacija - fly in/out
export const flyInOut = trigger('flyInOut', [
  state('in', style({ transform: 'translateY(0)' })),
  transition('void => *', [
    style({ transform: 'translateY(-100%)' }),
    animate(300)
  ])/* ,
  transition('* => void', [
    animate(300, style({ transform: 'translateX(100%)' }))
  ]) */
]);

//Animacija - fade
export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);



@Component({
  selector: 'app-clanovi',
  templateUrl: './clanovi.component.html',
  styleUrls: ['./clanovi.component.scss'],
  animations: [fadeAnimation, listFadeIn, flyInOut, myInsertRemoveTrigger]
})
export class ClanoviComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    public mod: ModulService,
    public srvClan: ClanoviService,
    private http: HttpClient,
    public srvNapredak: NapredakService
  ) { }

  @ViewChild('btnSidebarClose') btnSidebarClose: ElementRef;
  @ViewChild('btnSidebar') btnSidebar: ElementRef;

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    localStorage.setItem('clanID', this.srvClan.clanID.toString());
  }

  strPretraga: string = '';

  pjedTmp: number = 0;

  ngOnInit() {

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.mod.objKor.token;
    }

    //Provjera tokena
    this.mod.provjeraTokena(tok, () => {

      this.mod.provjeraPrijaveTrenOper(() => {

        this.mod.ucitajPosJed(() => {

          /* if (localStorage.getItem('objPosJedSelected')) {
            this.mod.objPosJedSelected = JSON.parse(localStorage.getItem('objPosJedSelected')); */
          this.ucitajClanove(this.mod.objPosJedSelected.PoslJedID, (rez) => {

            if (this.srvClan.clanID || localStorage.getItem('clanID')) {

              if (localStorage.getItem('clanID')) {
                this.srvClan.clanID = Number(localStorage.getItem('clanID'));
                localStorage.removeItem('clanID');
              }
              setTimeout(() => {
                if (document.getElementById('row' + this.srvClan.clanID)) {
                  document.getElementById('row' + this.srvClan.clanID).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                }
              }, 500);
            }

          });
          /* } */
        });

      });


    });

  }


  //Odabrana poslona jedinica - emitter
  odabranaPosJed(obj: PosJedinice) {

    this.pjedTmp = this.mod.objPosJedSelected.PoslJedID;
    this.ucitajClanove(this.pjedTmp, (rez) => {
      document.getElementById('btnSidebarClose').click();
    });

  }

  //Učitaj članove
  ucitajClanove(pJed: number, callback) {

    //let token: string = localStorage.getItem('token');

    let url: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&pjedID=' + pJed + '&funk=selectAll';
    let obj: string = JSON.stringify({ fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe(rez => {

      if (JSON.stringify(rez) != JSON.stringify(this.srvClan.objClanovi)) {
        this.srvClan.objClanovi = rez;
        this.srvClan.fltClanovi = rez;

        if (rez) {
          this.filtrirajClanove(this.strPretraga);
        }
      }

      callback(rez);

    });

  }

  //Očisti pretragu
  pretragaClear() {
    this.strPretraga = '';
    this.filtrirajClanove('');
  }

  //FILTRIRANJE ČLANOVA
  filtrirajClanove(strP: string) {
    //alert(strP);
    this.srvClan.fltClanovi = this.srvClan.objClanovi.filter(s => s.Ime.toLowerCase().includes(strP.toLowerCase()) || s.Prezime.toLowerCase().includes(strP.toLowerCase()));
  }

  //Odabir člana
  odabirClana(clID: number) {

    if (!this.srvClan.clanID || this.srvClan.clanID != clID) {
      /* localStorage.setItem('clanID', clID.toString()); */
      this.srvClan.clanID = clID;
    } else {
      /* localStorage.removeItem('clanID'); */
      this.srvClan.clanID = null;
    }

  }

  //Član Unos / Uređivanje
  otvoriClanComp(clanID: number) {

    if (clanID == 0) {
      localStorage.removeItem('clanID')
      this.srvClan.clanID = 0;
    }
    this.router.navigate(['clan']);
  }

  //Članarine
  otvoriClanarine() {
    this.router.navigate(['clanarine']);
  }

  openSidebar() {
    /* document.getElementById('btnSidebar').click(); */
    this.btnSidebar.nativeElement.click();
  }

  //Popunjavanje programa za praćenje napretka
  napredakFill(clanID: number) {
    this.srvNapredak.objVePnVezaByClanID = null;
    this.srvNapredak.vePnVezaByClanID(this.srvClan.clanID, 1, (rez: VePnVezaByClanID[]) => {
      this.srvNapredak.objVePnVezaByClanID = rez;
    });
  }

  //Novo mjerenje (praćenje napretka)
  novoMjerenjeStart(x: PnVeza) {

    //Učitavanje parametara za praćenje za odabrani program
    this.srvNapredak.pnStavkeByPnID(x.pnvTemplID, (rez: PnPrgStavka[]) => {

      document.getElementById('btnSidebarClose').click();

      this.srvNapredak.napraviFrmPnMjerenje(x.pnvID, rez, (val) => {

        this.srvNapredak.zadnjeMjerenjeHandle(x.pnvID);

        document.getElementById('btnMdlPnMjerEdit').click();
      });

    });

  }

  //Spremanje pn mjerenja
  spremiMjerenje() {

    this.srvNapredak.frmPnMjerenje.get('datum').markAsTouched();

    if (this.srvNapredak.frmPnMjerenje.valid) {

      this.srvNapredak.spremiMjerenje(this.srvNapredak.frmPnMjerenje.value, (rez: ApiRez) => {

        if (rez && rez.rez == 'ok') {

          document.getElementById('btnMdlPnMjerEditClose').click();

          this.mod.msgBox(1, "SPREMLJENO", 800);


        } else if (rez && rez.rez == 'empty') {
          this.mod.msgBox(1, "Nema podataka za spremanje!", 1000);
        } else {
          this.mod.msgBox(2, "Greška prilkom spremanja", 1000);
        }

      });


    } else {
      this.mod.msgBox(2, "Popunite ispravno sva polja", 800);
    }

  }

  //Učitavanje ciljeva po aktivnom programu
  ciljeviByPnvID(pnvID: number) {

    this.srvNapredak.ciljeviByPnvID(pnvID, (rez) => {
      this.srvNapredak.napraviFrmVePnCilj(pnvID, rez, () => {
        document.getElementById('btnMdlCiljeviEdit').click();
      });
    });

  }

  //Spremanje zadanih ciljeva
  spremiCiljeve() {
    let obj0 = this.srvNapredak.frmVePnCilj.value;
    this.srvNapredak.spremiCiljeve(obj0, (rez) => {

      if (rez && rez == 'ok') {
        document.getElementById('btnMdlCiljeviClose').click();
        this.mod.msgBox(1, "SPREMLJENO", 800);
      } else {
        this.mod.msgBox(2, "Greška!", 800);
      }

    });
  }

  //Pregled statistika
  pnPregled(x) {
    console.log(this.srvClan.clanID);
    console.log(x.pnvClanID)
    this.srvNapredak.objVePnVezaByClanIDsel = x;
    this.router.navigate(['/pn-pregled']);
  }

  ngOnDestroy(): void {
    document.getElementById('btnSidebarClose').click();

  }

}
