import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, fromEvent, map, merge, of, timer } from 'rxjs';
import { Moment } from 'moment';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { Aktivnost } from './kalendar.service';
import { Grupa } from './grupe.service';
import { Clanstvo } from './clanstva/clanstva.component';
import { FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { messaging } from '../configs/firebase.config';
import { environment } from '../environments/environment';
import * as moment from 'moment';
import { Dvorana } from './dvorane.service';

export interface Chk {
  chk: boolean
}

export interface DlgInfo {
  vrsta: number,
  text: string
}

export interface ApiRez {
  rez: string,
  obj: any,
  errMsg: string
}

export interface Kor {
  korisnikID: number,
  vrstaID: number,
  Ime: string,
  Prezime: string,
  Adresa: string,
  Kucni_br: string,
  MjestoID: number,
  Mjesto: string,
  korIme: string;
  OIB: string,
  token: string,
  int_kor_jed: number,
  img: number,
  imgSize: number,
  social: string
}

export interface SocialUser {
  email: string,
  firstName: string,
  lastName: string
}

export interface PosJedinice {
  PoslJedID: number,
  KlijentIDpos: number,
  NazivPos: string,
  AdresaPos: string,
  MjestoIDpos: number,
  Mjesto: string,
  kapacitet: number,
  aktivna: number,
  intIsto: number
}

export interface MjestaRH {
  Mjesto_ID: number,
  Mjesto: string,
  Broj_poste: number,
  Zupanija: string
}

export interface Login {
  kor: string,
  pass: string
}

export interface Session {
  vClansID: number,
  GrupaIDtren: number,
  KlijentIDtren: number,
  KoriniskIDtren: number,
  PosJedIDtren: number,
  clanVrstaIDtren: number,
  trenID: number,
  vrijemeOdjava: Moment,
  vrijemePrijava: Moment,
  pjedIDcl: number
}

export interface vAktivnost extends Aktivnost, Grupa {

}

@Injectable({
  providedIn: 'root'
})

export class ModulService {

  constructor(
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private authService: SocialAuthService
  ) { }

  //  webUrl: string = 'https://sharprobotics.synology.me/fitNet';
  //webUrl: string = 'https://sharprobotics.synology.me';
  //webUrl: string = './';

  webUrl: string = 'https://fitnet.com.hr';

  objKor: Kor;

  objDlgInfo: DlgInfo;
  s1: Subscription;

  //Poslovne jedinice
  objPosJed: PosJedinice[];
  fltPosJed: PosJedinice[];
  session: Session;
  objPosJedSelected: PosJedinice;
  dvoranePjed: Dvorana[];
  /* pjedID: number = 0;
  NazivPos: string = ''; */

  objLogin: Login = {
    'kor': 'ja@gmail.com',
    'pass': '123456'
  }

  btnLoginClass: string = 'Prijava';

  uvjetiKoristenja;

  poslanEmail: boolean = false;
  prviLogin: boolean = false;

  objAktSad

  objClanstvo: Clanstvo;

  firebasePushToken: string;

  bolQrScaning: boolean = false;

  //Provjera internet veze
  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        console.log('status', status);
        this.networkStatus = status;
        if (this.networkStatus) {
          //location.reload();
        }
        if (!this.networkStatus) {
          this.provjeriUredjaj();
        }
      });
  }

  //Provjera lokalnog uređaja
  bolUredjaj: boolean = false;
  provjeriUredjaj() {

    let url: string = "http://192.168.5.25/php/test2.php";
    this.http.get(url).subscribe((rez: string) => {
      if (rez) {
        this.bolUredjaj = true;
      } else {
        this.bolUredjaj = false;  
      }
    }, (error) => {
      this.bolUredjaj = false;
    });

  }

  //PROVJERA TOKENA I REDIRECT
  provjeraTokena(token0: string, callback) {

    if (!token0) {

      this.objKor = null;
      this.objPosJed = null;
      this.fltPosJed = null;
      this.objPosJedSelected = null;
      this.dvoranePjed = null;
      this.btnLoginClass = 'Prijava';
      this.router.navigate(['naslovnica']);
      return false;

    } else {

      //this.objKor = JSON.parse(localStorage.objKor);
      let token: string = token0; // this.objKor.token; // localStorage.getItem('token');
      let url: string = this.webUrl + '/php/token_chk.php?token=' + token;

      let korID: number = 0;
      if (this.objKor) {
        korID = this.objKor.korisnikID;
      }

      //fingerprint
      let fingerprint: string
      if (this.firebasePushToken == '') {
        fingerprint = getBrowserFingerprint();
      } else {
        fingerprint = this.firebasePushToken;
      }

      let obj: string = JSON.stringify({ korID: korID, fingerprint: fingerprint });

      this.http.post(url, obj).subscribe((rez: Kor) => {

        console.log(rez);

        if (!rez || (this.objKor && this.objKor.token != token)) {

          localStorage.removeItem('token');
          this.objKor = null;
          this.objPosJed = null;
          this.fltPosJed = null;
          this.objPosJedSelected = null;
          this.dvoranePjed = null;
          this.session = null;
          this.btnLoginClass = 'Prijava';
          this.router.navigate(['/naslovnica']);
          return false;
        } else {
          this.btnLoginClass = 'Odjava';
          this.objKor = rez;
          if (!this.objPosJed) {
            this.ucitajPosJed(() => { });
          }
          callback();
          return true;
        }

      }, (err) => {
        console.log('http error');
        localStorage.removeItem('token');
        this.objKor = null;
        this.objPosJed = null;
        this.fltPosJed = null;
        this.objPosJedSelected = null;
        this.dvoranePjed = null;
        this.session = null;
        this.btnLoginClass = 'Prijava';
        this.router.navigate(['naslovnica']);
        return false;
      });



    }

  }

  quickClean() {
    console.log('http error');
    localStorage.removeItem('token');
    this.objKor = null;
    this.objPosJed = null;
    this.fltPosJed = null;
    this.objPosJedSelected = null;
    this.dvoranePjed = null;
    this.session = null;
    this.btnLoginClass = 'Prijava';
    this.router.navigate(['/naslovnica']);
  }

  //echo fingerprint
  fingerprint() {
    let fingerprint: string
    if (this.firebasePushToken == '') {
      fingerprint = getBrowserFingerprint();
    } else {
      fingerprint = this.firebasePushToken;
    }
    return fingerprint;
  }

  //LOGIN
  loadLg: boolean = false;
  login(log: Login, callback) {

    this.requestPermission(() => {

      let fingerprint: string
      if (this.firebasePushToken != '') {
        fingerprint = this.firebasePushToken;
      } else {
        fingerprint = getBrowserFingerprint();
      }

      let url: string = this.webUrl + '/php/login.php';
      let obj: string = JSON.stringify({ kor: log.kor, pass: log.pass, fingerprint: fingerprint });

      /* let paramTmp = new HttpParams()
        .set('kor', log.kor)
        .set('pass', log.pass); */

      this.http.post(url, obj).subscribe((rez: Kor) => {

        console.log(rez);

        if (rez) {
          this.objKor = rez;
          localStorage.setItem('token', this.objKor.token);
          this.listen();
          //localStorage.objKor = JSON.stringify(this.objKor);
        } else {
          this.objKor = null;
          localStorage.removeItem('token');
        }

        callback(rez);

      }, (error) => {
        console.log(error);
        this.msgBox(2, "Greška prilikom prijave", 900);
      });

    });


  }

  //social media login
  socialLogin(obj0: SocialUser, callback) {

    this.requestPermission(() => {

      let fingerprint: string
      if (this.firebasePushToken != '') {
        fingerprint = this.firebasePushToken;
      } else {
        fingerprint = getBrowserFingerprint();
      }

      obj0['fingerprint'] = fingerprint;

      let url: string = this.webUrl + '/php/login-social.php?funk=socialLogin';
      let obj: string = JSON.stringify(obj0);

      this.http.post(url, obj).subscribe((rez: Kor) => {
        callback(rez);
      });

    });



  }

  requestPermission(callback) {
    messaging.getToken({ vapidKey: environment.firebaseConfig.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          /* console.log(currentToken); */
          this.firebasePushToken = currentToken;
        } else {
          console.log('No registration token available. Request permission to generate one.');
          this.firebasePushToken = '';
        }
        //alert(currentToken);
        callback();
      }).catch((err) => {
        /* console.log(err); */
        this.firebasePushToken = '';
        //alert(err);
        callback();
      });
  }

  listen() {
    messaging.onMessage((incomingMessage) => {
      /* console.log(incomingMessage); */
      this.router.navigate(['/obavijesti-panel']);
    });
  }

  //ODJAVA
  odjava() {

    let fingerprint: string;
    if (this.firebasePushToken != '') {
      fingerprint = this.firebasePushToken;
    } else {
      fingerprint = getBrowserFingerprint();
    }
    let url: string = this.webUrl + '/php/korisnici.php?token=' + this.objKor.token + '&funk=odjava';
    let obj: string = JSON.stringify({ fingerprint: fingerprint });

    this.firebasePushToken = '';
    localStorage.removeItem('firebasePushToken');

    this.http.post(url, obj).subscribe(rez => {

    });

    if (this.authService) {
      this.authService.signOut();
    }

    this.objKor = null;
    /* localStorage.removeItem('objKor'); */
    this.objPosJed = null;
    this.fltPosJed = null;
    /* localStorage.removeItem('objPosJed'); */
    this.objPosJedSelected = null;
    this.dvoranePjed = null;
    /* localStorage.removeItem('objPosJedSelected'); */
    this.session = null;
    localStorage.NazivPos = null;
    /* localStorage.removeItem('pjedID'); */
    this.btnLoginClass = 'Prijava';
    localStorage.clear();
    this.router.navigate(['naslovnica']);

    /* this.authService.authState.subscribe(rez => {
      if (rez) {
        this.authService.signOut();
      }
    }); */

  }

  //Poslovne jedinice
  ucitajPosJed(callback) {

    /* if (localStorage.getItem('session')) {
      this.session = JSON.parse(localStorage.getItem('session'));
    } */

    //let token: string = localStorage.getItem('token');

    if (localStorage.getItem('objClanstvo')) {
      this.objClanstvo = JSON.parse(localStorage.getItem('objClanstvo'));
      localStorage.removeItem('objClanstvo');
    }

    /* console.log(this.objKor); */

    let urlTmp: string = this.webUrl + '/php/posl_jed.php?token=' + this.objKor.token + '&funk=selectAll';
    let obj: string = JSON.stringify({ fingerprint: this.fingerprint() })

    this.http.post(urlTmp, obj).subscribe((rez: PosJedinice[]) => {

      if (rez != this.objPosJed || !this.objPosJed) { //samo poslovni

        //Objekt Poslovne jedinice
        if (rez) {

          //Lista poslovnih jedinica
          this.objPosJed = rez;
          this.fltPosJed = [];
          this.objPosJed.forEach(val => {
            if (val.aktivna == 1) {
              this.fltPosJed.push(val)
;            }
          });

          //odabir prve pos. jedinice s liste
          if (!this.objPosJedSelected) {

            if (localStorage.getItem('pjSel')) {

              let id: number = parseInt(localStorage.getItem('pjSel'));

              this.objPosJed.forEach(val => {
                if (val.PoslJedID == id) {
                  this.objPosJedSelected = val;
                  this.dvoraneByPosJedId(id, 1, (rezDvo: Dvorana[]) => {
                    this.dvoranePjed = rezDvo;
                  });

                }
              })

            } else {
              this.objPosJedSelected = rez[0];
              this.dvoraneByPosJedId(rez[0].PoslJedID, 1, (rezDvo: Dvorana[]) => {
                this.dvoranePjed = rezDvo;
              });
            }

          }

          //localStorage.objPosJedSelected = JSON.stringify(rez[0]);

          /* this.pjedID = this.objPosJed[0].PoslJedID;
          localStorage.pjedID = this.pjedID;
  
          this.NazivPos = this.objPosJed[0].NazivPos;
          localStorage.NazivPos = this.NazivPos;*/

        } else { //nema poslovnih jedinica

          if (this.objKor.vrstaID == 2) {
            this.objPosJedSelected = null;
            this.dvoranePjed = null;
            //localStorage.removeItem('objPosJedSelected');
          } else {

            /* this.provjeraPrijaveTrenOper(() => { 
              
            }); */

          }

          /* this.pjedID = 0;
          localStorage.pjedID = 0;
  
          this.NazivPos = '';
          localStorage.NazivPos = ''; */
        }

      } else { //samo privatni

        if (rez != this.objPosJed) {

          this.objPosJed = rez;

          if (this.objPosJedSelected) {

            //this.objPosJedSelected = JSON.parse(localStorage.objPosJedSelected);

            let tmp = this.objPosJed.filter(rez => {
              if (rez.PoslJedID == this.objPosJedSelected.PoslJedID) {
                'true';
              }
            });

            if (!tmp) {
              this.objPosJedSelected = this.objPosJed[0];
              this.dvoraneByPosJedId(this.objPosJed[0].PoslJedID, 1, (rezDvo: Dvorana[]) => {
                this.dvoranePjed = rezDvo;
              });
              //localStorage.objPosJedSelected = JSON.stringify(this.objPosJed[0]);
            }

          } else {

            if (!this.objPosJedSelected) {

              if (localStorage.getItem('pjSel')) {

                let id: number = parseInt(localStorage.getItem('pjSel'));

                this.objPosJed.forEach(val => {
                  if (val.PoslJedID == id) {
                    this.objPosJedSelected = val;
                    this.dvoraneByPosJedId(id, 1, (rezDvo: Dvorana[]) => {
                      this.dvoranePjed = rezDvo;
                    });
                  }
                })

              } else {
                this.objPosJedSelected = rez[0];
                this.dvoraneByPosJedId(rez[0].PoslJedID, 1, (rezDvo: Dvorana[]) => {
                  this.dvoranePjed = rezDvo;
                });
              }

            }

            //localStorage.objPosJedSelected = JSON.stringify(this.objPosJed[0]);

          }

        }
      }

      localStorage.removeItem('token');
      localStorage.removeItem('pjSel');
      localStorage.removeItem('session');
      localStorage.removeItem('objClanstvo');

      callback();

    });

  }

  //Dvorane by pos Jed ID
  dvoraneByPosJedId(posJedID: number, aktivna: number, callback: Function) {

    let urlTmp: string = this.webUrl + '/php/dvorane.php?token=' + this.objKor.token + '&funk=dvoraneByPosJedId';

    let obj0 = { posJedID: posJedID, aktivna: aktivna, fingerprint: this.fingerprint() };
    let obj: string = JSON.stringify(obj0);

    this.http.post(urlTmp, obj).subscribe((rez: Dvorana[]) => {
      callback(rez);
    });

  }

  //Prijava Trenera / Operatera u poslovnu jedinicu
  //frmPrijava Trener / Operater
  frmprijavaTrenOper: FormGroup;
  prijavaTrenOper(pjedTmp: PosJedinice) {

    if (this.frmprijavaTrenOper.valid) {

      //Formatiranje Datum/Vrijeme za slanje http
      let d1Tmp: Date = new Date(this.frmprijavaTrenOper.controls['priDat'].value);

      let godTmp: number = d1Tmp.getFullYear();
      let mjTmp: number = d1Tmp.getMonth();
      let danTmp: number = d1Tmp.getDate();
      let hTmp: number = this.frmprijavaTrenOper.controls['priH'].value;
      let minTmp: number = this.frmprijavaTrenOper.controls['priMin'].value;

      let dTmp: string = formatDate(new Date(godTmp, mjTmp, danTmp, hTmp, minTmp, 0), 'yyyy-MM-dd HH:mm:ss', 'en-US');

      //JSON body
      let phpBody = {

        vClansID: this.objClanstvo.vClansID,
        KlijentIDtren: this.frmprijavaTrenOper.controls['KlijentIDtren'].value,
        PosJedIDtren: pjedTmp.PoslJedID,  //this.frmprijavaTrenOper.controls['PosJedIDtren'].value,
        KoriniskIDtren: this.frmprijavaTrenOper.controls['KoriniskIDtren'].value, //this.frmprijavaTrenOper.controls['KoriniskIDtren'].value,
        vrijemePrijava: dTmp,
        vrstaIDcl: this.frmprijavaTrenOper.controls['vrstaIDcl'].value,
        fingerprint: this.fingerprint()

      }

      localStorage.setItem('korOdobrenje', this.frmprijavaTrenOper.controls['PosJedIDtren'].value);

      //www. url
      let urlTmp: string = this.webUrl + '/php/prisutni.php?token=' + this.objKor.token + '&funk=prijava';

      //http.get
      this.http.post(urlTmp, JSON.stringify(phpBody)).subscribe(rez => {

        if (rez && rez['rez'] == 'ok') {

          this.objPosJedSelected = {
            PoslJedID: pjedTmp.PoslJedID,
            KlijentIDpos: pjedTmp.KlijentIDpos,
            NazivPos: pjedTmp.NazivPos,
            AdresaPos: pjedTmp.AdresaPos,
            MjestoIDpos: pjedTmp.MjestoIDpos,
            Mjesto: pjedTmp.Mjesto,
            kapacitet: pjedTmp.kapacitet,
            aktivna: pjedTmp.aktivna,
            intIsto: pjedTmp.intIsto
          };

          //Dvorane by ps jed
          this.dvoraneByPosJedId(pjedTmp.PoslJedID, 1, (rezDvo: Dvorana[]) => {
            this.dvoranePjed = rezDvo;
          });

          if (rez['session']) {
            //localStorage.setItem('session', JSON.stringify(rez['session']));
            this.session = rez['session'];
            console.log(this.session);
          }

          //localStorage.setItem("objPosJedSelected", JSON.stringify(this.mod.objPosJedSelected));

          document.getElementById('btnModalPosJedTrenOper').click();
          document.getElementById('btnPrijavaClose').click();

          this.ucitajPosJed(() => {

          });


          this.msgBox(1, "Uspješno ste prijavljeni!", 2000);

        } else {

          this.msgBox(2, "Greška prilikom prijave!", 3000);

        }

      });

    }

  }

  //Provjera prijave trener / operater
  provjeraPrijaveTrenOper(callback) {

    if (this.objKor.vrstaID == 1) {
      let url: string = this.webUrl + '/php/prisutni.php?token=' + this.objKor.token + '&funk=provjeraTrenOperLogin';

      let vClansID: number;

      if (this.objClanstvo) {
        vClansID = this.objClanstvo.vClansID;
      } else {
        vClansID = 0;
      }

      let now: string = moment(moment().format('YYYY-MM-DD HH:mm')).format('YYYY-MM-DD HH:mm:ss');

      let obj: string = JSON.stringify({ vClansID: vClansID, now: now, fingerprint: this.fingerprint() });// => DEPRECATED

      this.http.post(url, obj).subscribe((rez) => {

        if (rez) {
          this.objPosJedSelected = rez['pjed'];
          this.session = rez['session'];
          this.objClanstvo = rez['objClanstvo'];
          this.dvoraneByPosJedId(this.objPosJedSelected.PoslJedID, 1, (rezDvo: Dvorana[]) => {
            this.dvoranePjed = rezDvo;
          });

          //Object.assign(this.session, rez['session']);


          /* localStorage.setItem('objPosJedSelected', JSON.stringify(this.objPosJedSelected));
          localStorage.setItem('session', JSON.stringify(rez['session'])); */
        } else {
          this.objPosJedSelected = null;
          this.dvoranePjed = null;
          this.session = null;
          /* this.objClanstvo = null; */

          /* localStorage.removeItem('objPosJedSelected');
          localStorage.removeItem('session'); */
        }

        callback(rez);
      });
    } else {
      callback(null);
    }

  }

  //korisnik by token
  korByToken(token: string, callback) {

    let url: string = this.webUrl + '/php/korisnici.php?token=' + token + '&funk=korByToken';
    let obj: string = JSON.stringify({ token: token, fingerprint: this.fingerprint() });

    this.http.post(url, obj).subscribe((rez: Kor) => {
      callback(rez);
    });

  }

  //Snackbar Alert
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  //msgBox
  msgBox(vrsta: number, tekst: string, trajanje: number) {

    this.objDlgInfo = {
      vrsta: vrsta,
      text: tekst
    };

    let tMsgBox = timer(trajanje);

    this.s1 = tMsgBox.subscribe(t => {
      this.objDlgInfo = null;
      this.s1.unsubscribe();
    })

  }

  myAudioContext = new AudioContext();

  beep(duration, frequency, volume) {
    return new Promise((resolve, reject) => {
      // Set default duration if not provided
      duration = duration || 200;
      frequency = frequency || 440;
      volume = volume || 100;

      try {
        let oscillatorNode = this.myAudioContext.createOscillator();
        let gainNode = this.myAudioContext.createGain();
        oscillatorNode.connect(gainNode);

        // Set the oscillator frequency in hertz
        oscillatorNode.frequency.value = frequency;

        // Set the type of oscillator
        oscillatorNode.type = "square";
        gainNode.connect(this.myAudioContext.destination);

        // Set the gain to the volume
        gainNode.gain.value = volume * 0.01;

        // Start audio with the desired duration
        oscillatorNode.start(this.myAudioContext.currentTime);
        oscillatorNode.stop(this.myAudioContext.currentTime + duration * 0.001);

        // Resolve the promise when the sound is finished
        oscillatorNode.onended = () => {
          /* resolve(); */
        };
      } catch (error) {
        reject(error);
      }
    });
  }

  //Info poruka
  infMsg: string
  msgInfo(msg: string) {

    this.infMsg = msg;

    document.getElementById('btnMdlInfo').click();

  }

  //razlika datuma u danima
  dateDiffDani(dat1: Moment, dat2: Moment) {

    return dat2.diff(dat1, 'days');

  }

  //razlika datuma u danima
  dateDiffNow(dat: Moment) {

    return moment(dat).diff(moment(), 'days') + 2;

  }

}
