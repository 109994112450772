import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ApiRez, ModulService } from '../modul.service';
import { ClanarineService, Vclanarina } from '../clanarine.service';
import { ClanoviService } from '../clanovi.service';
import * as moment from 'moment';
import { ClanEditService } from '../clan-edit.service';
import { ClanGrupa, Grupa, GrupeService } from '../grupe.service';
import { QRCodeComponent } from 'angularx-qrcode';
import { SafeUrl } from '@angular/platform-browser';
import { NapredakService, PnPrgStavka, PnProgram, PnVeza } from '../napredak.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clan-edit-sidebar',
  templateUrl: './clan-edit-sidebar.component.html',
  styleUrl: './clan-edit-sidebar.component.scss'
})
export class ClanEditSidebarComponent implements AfterViewInit {
  parent: any;

  constructor(
    public mod: ModulService,
    public srvClanovi: ClanoviService,
    public srvClanarine: ClanarineService,
    public srvClanEdit: ClanEditService,
    public srvGrupe: GrupeService,
    public srvNapredak: NapredakService,
    private router: Router
  ) { }

  /* link: string
  @ViewChild("qrcode", { static: false }) qrcode: QRCodeComponent */

  myAngularxQrCode: string = "";
  qrCodeDownloadLink: SafeUrl = "";

  upisGrpStart() {

    //Učitavanje grupa
    let bolTmp: boolean = this.mod.session && this.mod.session.clanVrstaIDtren == 2 ? true : false;
    this.srvGrupe.dostupneGrpByClan(bolTmp, this.srvClanovi.clanID, this.mod.objPosJedSelected.PoslJedID, (rez: Grupa[]) => {
      this.srvGrupe.objGrupe = rez;
      if (rez) {
        this.srvGrupe.frmClanGrupaUnos.controls['GrupaIDvcg'].setValue(this.srvGrupe.objGrupe[0].grpID);
      }
      this.srvGrupe.frmClanGrupaUnos.controls['ClanIDvcg'].setValue(this.srvClanovi.clanID);

    });
    document.getElementById('btnMdlClGrpUnos').click();
  }


  //Auto obnova članarina switch
  autoClanarine(x: ClanGrupa) {

    this.srvClanarine.vcgID = x.vcgID;

    this.srvClanarine.autoClanarine(x, (rez: string) => {

      if (rez) {
        if (rez == 'isključeno') {
          x.autoCl = 0;
        } else if (rez == 'uključeno') {
          x.autoCl = 1;
        } else if (rez == 'nema') {

          /* this.mod.msgBox(3, "Unesite prvu članarinu", 3000); */
          //unos prve članarine
          document.getElementById('btnMdlClanarina').click();

          this.srvGrupe.grupeByClan(1, x.ClanIDvcg, (rez: Grupa[]) => {

            this.srvGrupe.objGrupe = rez;

            this.srvClanarine.napraviFrmClanarina(null, (rez) => {
              this.srvClanarine.frmClanarina.get('claClanID').setValue(x.ClanIDvcg);
              this.srvClanarine.frmClanarina.get('claGrupaID').setValue(x.GrupaIDvcg);
              this.srvGrupe.objGrupe.forEach((val) => {
                if (val.grpID == Number(x.GrupaIDvcg)) {
                  this.srvClanarine.frmClanarina.get('claIznos').setValue(val.grpClanarina);
                }
              });
            });

          });
        }

      } else {
        console.log('greška');
      }


    });

  }

  //Dodavanje novog programa za praćenje napretka
  dodajProgramStart() {

    //Učitavanje predložaka programa
    this.srvNapredak.pnFillByDopustenje((rez: PnProgram[]) => {
      console.log(rez);
      this.srvNapredak.objProgrami = rez;
      this.srvNapredak.napraviFrmPnVeza(null, () => {
        this.srvNapredak.frmPnVeza.get('pnvClanID').setValue(this.srvClanovi.clanID);
        document.getElementById('btnMdlPnVezaEdit').click();
      });
    });

  }

  //Novo mjerenje (praćenje napretka)
  novoMjerenjeStart(x: PnVeza) {

    //Učitavanje parametara za praćenje za odabrani program
    this.srvNapredak.pnStavkeByPnID(x.pnvTemplID, (rez: PnPrgStavka[]) => {

      document.getElementById('btnSidebarClose').click();

      this.srvNapredak.napraviFrmPnMjerenje(x.pnvID, rez, (val) => {

        this.srvNapredak.zadnjeMjerenjeHandle(x.pnvID);
        
        document.getElementById('btnMdlPnMjerEdit').click();
      });

    });

  }

  //Spremanje pn mjerenja
  spremiMjerenje() {

    this.srvNapredak.frmPnMjerenje.get('datum').markAsTouched();

    if (this.srvNapredak.frmPnMjerenje.valid) {

      this.srvNapredak.spremiMjerenje(this.srvNapredak.frmPnMjerenje.value, (rez: ApiRez) => {

        if (rez && rez.rez == 'ok') {

          document.getElementById('btnMdlPnMjerEditClose').click();

          this.mod.msgBox(1, "SPREMLJENO", 800);


        } else if (rez && rez.rez == 'empty') {
          this.mod.msgBox(1, "Nema podataka za spremanje!", 1000);
        } else {
          this.mod.msgBox(2, "Greška prilkom spremanja", 1000);
        }

      });


    } else {
      this.mod.msgBox(2, "Popunite ispravno sva polja", 800);
    }

  }


  //Učitavanje ciljeva po aktivnom programu
  ciljeviByPnvID(pnvID: number) {

    this.srvNapredak.ciljeviByPnvID(pnvID, (rez) => {
      this.srvNapredak.napraviFrmVePnCilj(pnvID, rez, () => {
        document.getElementById('btnMdlCiljeviEdit').click();
      });
    });

  }

  

  //Pregled statistika
  pnPregled(x) {
    console.log(this.srvClanovi.clanID);
    console.log(x.pnvClanID)
    this.srvNapredak.objVePnVezaByClanIDsel = x;
    this.router.navigate(['/pn-pregled']);
  }

  //QR kod
  fitNetID() {
    let qrData: string = '{"fitnet":"' + this.srvClanovi.frmClan?.get('fitNetID').value + '", "klijent":"' + this.mod.objPosJedSelected?.KlijentIDpos + '", "clan":"' + this.srvClanovi.frmClan?.get('clanID').value + '"}';
    this.myAngularxQrCode = qrData;
    if (this.srvClanovi.frmClan) {
      return qrData;
    }
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  //Download img





  ngAfterViewInit(): void {


  }


}
