<form *ngIf="srvNapredak.frmPnVeza" [formGroup]="srvNapredak.frmPnVeza">

    <div *ngIf="srvNapredak.objProgrami">
        <!-- Datepicker Datum -->
        <mat-form-field class="w-100">
            <mat-label>Datum</mat-label>
            <input matInput name="dat" formControlName="pnvDatum" [matDatepicker]="picker" placeholder="Datum">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>

        <!-- Program template -->
        <mat-form-field *ngIf="srvNapredak.frmPnVeza.get('pnvID').value == 0" class="w-100" style="text-align: center;">
            <mat-label>Program</mat-label>
            <mat-select formControlName="pnvTemplID" #pnvTemplID>
                <mat-option *ngFor="let x of srvNapredak.objProgrami" [value]="x.pnID"> <span
                        style="margin-right: 10px; font-weight: bold;">{{x.pnNaziv}}</span> <span
                        style="color: grey;">{{' ' + x.Ime + ' ' + x.Prezime}}</span> </mat-option>
            </mat-select>
            <mat-error *ngIf="srvNapredak.frmPnVeza.get('pnvTemplID').errors?.required">Polje je obavezno za
                unos</mat-error>
        </mat-form-field>

        <div *ngIf="srvNapredak.frmPnVeza.get('pnvID').value != 0" class="d-flex align-items-center"
            (click)="aktSwitch()" style="padding: 5px; cursor: pointer;">
            <div style="margin-right: 10px; font-size: 1.5rem;">
                <i *ngIf="srvNapredak.frmPnVeza.get('pnvAkt').value == 1" class="fa fa-check-square-o"
                    aria-hidden="true"></i>
                <i *ngIf="srvNapredak.frmPnVeza.get('pnvAkt').value == 0" class="fa fa-square-o" aria-hidden="true"></i>
            </div>
            <div>
                Aktivno
            </div>
        </div>
    </div>

    <div *ngIf="!srvNapredak.objProgrami">

        <div class="alert alert-warning">
            Nema dostupnih programa
        </div>

        <div>
            <button type="button" class="btn mybtn-info w-100" routerLink="/pn-template" data-dismiss="modal">NAPRAVI
                PROGRAM</button>
        </div>

    </div>

</form>