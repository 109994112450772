import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({name: 'datumShort'})
export class DatumShort implements PipeTransform {
  transform(value: string): string {
    return formatDate(new Date(value), 'dd.MM.yyyy.', 'en-US'); //'2019-07-31';
  }
}

@Pipe({name: 'datum-mysql'})
export class DatumMySql implements PipeTransform {
  transform(value: string): string {
    return formatDate(new Date(value), 'yyyy-MM-dd', 'en-US'); //'2019-07-31';
  }
}

@Pipe({name: 'danNazivMysql'})
export class DanNazivMySql implements PipeTransform {
  transform(value: string): string {

    let strTmp: string = 'Test';

    switch(value) {

      case "1":
      strTmp = 'Nedjelja';
      break;

      case "2":
      strTmp = 'Ponedjeljak';
      break;

      case "3":
      strTmp = 'Utorak';
      break;

      case "4":
      strTmp = 'Srijeda';
      break;

      case "5":
      strTmp = 'Četvrtak';
      break;

      case "6":
      strTmp = 'Petak';
      break;

      case "7":
      strTmp = 'Subota';
      break;

    }

    return strTmp;

  }

}