<div>

    <div class="naslov-m justify-content-start">
        <span>{{mod.fltPosJed?.length > 1 ? 'Odabir poslovne jedinice' : 'Poslovna jedinica'}}</span>
    </div>

    <div class="card-posjed d-flex flex-column" *ngFor="let x of mod.fltPosJed" [ngClass]="{'selected-shadow' : x.PoslJedID == mod.objPosJedSelected.PoslJedID}" style="border-bottom: solid 0.5px #d1d1d1;" [ngStyle]="{'background-color' : x.PoslJedID == mod.objPosJedSelected.PoslJedID ? 'rgb(208, 245, 234)' : 'white', 'color': x.aktivna == 1 ? 'black' : 'lightgrey'}"
        (click)="btnPosJedClick(x)">
        <span style="font-weight: bold;">{{x.NazivPos}}</span>
        <span>{{x.AdresaPos}}</span>
        <span> {{x.Mjesto}}</span>
    </div>

    <div *ngIf="srvClanovi.objNepotZahtjeviKl" class="w-100">
        <div class="naslov-m">
            Zahtjevi za povezivanjem:
        </div>
        <div class="row card-posjed" *ngFor="let x of srvClanovi.objNepotZahtjeviKl">

            <div class="col" style="padding: 0px;">
                <div style="font-weight: bold;">
                    {{x.Ime + ' ' + x.Prezime}}
                </div>
                <div>
                    Datum slanja: {{x.DatumVfc | amDateFormat:'DD.MM.YYYY. HH:mm'}}
                </div>
                <div style="font-size: 0.8rem;">
                    prije {{srvClanovi.trajanjePosjetaParams(x.DatumVfc)}}
                </div>
                <div style="margin-top: 5px;">
                    <div class="row" style="margin: 0;">
                        <div class="col col-6" style="padding: 2px;">
                            <button class="btn mybtn-success" style="width: 100%; margin: 0;" data-dismiss="modal" (click)="poveziClana(x)">Potvrdi</button>
                        </div>
                        <div class="col col-6" style="padding: 2px;">
                            <button class="btn mybtn-danger" style="width: 100%; margin: 0;" data-dismiss="modal">Odbaci</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>