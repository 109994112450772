import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ModulService, PosJedinice } from './modul.service';
import { Vrsta } from './stat-clan.service';

export interface FrmFilter {
  klijentID: number,
  datOd: moment.Moment,
  datDo: moment.Moment,
  PoslJedID: number,
  dvoID: number,
  clanID: number
}

export interface FrmFilterRez {
  countID: number,
  datum: moment.Moment
}

//Statistika by FrmFilter
export interface StatByFrm {
  posjeta: number,
  posjetaMin: number,
  vrste: Vrsta[],
  pJed: Pjed[],
  grupe: Grp[],
  dani: Dani,
  dobaD: DobaDana[],
  topClanovi: TopClan[]
}

export interface Pjed {
  PoslJedID: number,
  podaci: PosJedinice,
  posjeta: number
}

export interface Grp {
  naziv: string,
  count: number,
  odaziv: number
}

export interface Dani {
  pon: number,
  uto: number,
  sri: number,
  cet: number,
  pet: number,
  sub: number,
  ned: number
}

export interface DobaDana {
  hh: number,
  count: number
}

export interface TopClan {
  Ime: string,
  Prezime: string
  Adresa: string
  Mjesto: string
  count: number
  min: number
}

export interface TcIndex {
  pInd: number,
  zInd: number
}

@Injectable({
  providedIn: 'root'
})
export class StatKlijentService {

  constructor(
    private mod: ModulService,
    private http: HttpClient,
    public frmBuilder: FormBuilder
  ) { }

  frmFilter: FormGroup
  lcPosjete;
  objStatByFrm: StatByFrm;


  //Prikaži
  prikazi() {

    this.statByFrm(this.frmFilter.value, (rez: StatByFrm) => {

      this.objStatByFrm = rez;

      //Pie chart Poslovne jedinice
      rez.pJed.forEach((val, ind) => {
        val['data'] = this.pcData(val.posjeta, rez.posjeta);
        val['options'] = this.options(val.posjeta, rez.posjeta, 80, 80);
      });

      if (rez.dani) {
        this.napuniChartDani(rez.posjeta);
      }

      if (rez.dobaD) {
        this.napuniChartDd(rez.dobaD);
      }

      this.tcIndex = { pInd: 0, zInd: this.tcCntPerPage - 1 };
      this.objTopClanoviPaginator = this.topClPaginator(0 , this.tcCntPerPage - 1);

    });

  }

  pcData(pJedCount: number, ukupnoCount: number) {

    let title = 'Trenutna popunjenost';
    let type = 'PieChart';
    let columnNames = ['Kapacitet', 'Popunjenost'];

    let popunjeno: number;
    let prazno: number;

    if (pJedCount == 0) {
      popunjeno = 0;
      prazno = 100;
    } else {
      popunjeno = Math.round(pJedCount / ukupnoCount * 100);
      if (popunjeno > 100) {
        popunjeno = 100;
      }
      prazno = 100 - popunjeno;
    }

    let color: string;

    if (popunjeno == 0) {
      color = 'grey'
    } else if (popunjeno < 70 && popunjeno > 0) {
      color = 'rgb(159, 224, 191)';
    } else if (popunjeno >= 70 && popunjeno < 90) {
      color = 'rgb(255, 166, 112)';
    } else if (popunjeno >= 90) {
      color = 'rgb(255, 91, 91)';
    }

    let options = {
      colors: [color, 'ghostwhite', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
      is3D: true,
      legend: 'none',
      chartArea: { width: '100%', height: '100%' },
      backgroundColor: 'transparent',
      width: 200,
      height: 200,
    };

    let data = [
      ['Popunjeno', popunjeno],
      ['Prazno', prazno]
    ];

    let pch = {
      title: title,
      type: type,
      columnNames: columnNames,
      data: data,
      options: options,
    };

    return data;

  }

  options(pJedCount: number, ukupnoCount: number, width: number, height: number) {

    let popunjeno: number;
    let prazno: number;

    if (pJedCount == 0) {
      popunjeno = 0;
      prazno = 100;
    } else {
      popunjeno = Math.round(pJedCount / ukupnoCount * 100);
      if (popunjeno > 100) {
        popunjeno = 100;
      }
      prazno = 100 - popunjeno;
    }

    let color: string;

    if (popunjeno == 0) {
      color = 'grey'
    } else if (popunjeno < 70 && popunjeno > 0) {
      color = 'rgb(159, 224, 191)';
    } else if (popunjeno >= 70 && popunjeno < 90) {
      color = 'rgb(255, 166, 112)';
    } else if (popunjeno >= 90) {
      color = 'rgb(255, 91, 91)';
    }

    let options2 = {
      colors: [color, 'ghostwhite', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
      is3D: true,
      legend: 'none',
      chartArea: { width: '100%', height: '100%' },
      backgroundColor: 'transparent',
      width: width,
      height: height,
    };

    return options2;

  }


  //PAGINATOR
  objTopClanoviPaginator: TopClan[];
  tcIndex: TcIndex;
  tcCntPerPage: number = 6;

  topClPaginator(pInd, zInd): TopClan[] {

    this.objTopClanoviPaginator = [];

    this.objStatByFrm.topClanovi?.forEach((val, ind) => {

      if (ind >= pInd && ind <= zInd) {
        this.objTopClanoviPaginator.push(val);
      }

    });

    if (this.objTopClanoviPaginator.length < this.tcCntPerPage) {

      let diff: number = this.tcCntPerPage - this.objTopClanoviPaginator.length;

      while (diff > 0) {

        this.objTopClanoviPaginator.push({ Ime: '', Prezime: '', Adresa: '', Mjesto: '', count: null, min: null});

        diff -= 1;
      }

    }

    return this.objTopClanoviPaginator
  }

  //Prva stranica
  pgFirst() {
    this.tcIndex.pInd = 0;
    this.tcIndex.zInd = this.tcCntPerPage - 1;
    this.topClPaginator(this.tcIndex.pInd, this.tcIndex.zInd);
  }

  //Prethodna
  pgPrev() {
    this.tcIndex.pInd -= this.tcCntPerPage;
    this.tcIndex.zInd -= this.tcCntPerPage;
    this.topClPaginator(this.tcIndex.pInd, this.tcIndex.zInd);
  }

  //Sljedeća stranica
  pgNext() {
    this.tcIndex.pInd += this.tcCntPerPage;
    this.tcIndex.zInd += this.tcCntPerPage;
    this.topClPaginator(this.tcIndex.pInd, this.tcIndex.zInd);
  }

  //Zadnja stranica
  pgLast() {

    let razlika: number = Math.floor(this.objStatByFrm.topClanovi.length / this.tcCntPerPage);
    let fali: number = ((razlika + 1) * this.tcCntPerPage) - this.objStatByFrm.topClanovi.length;

    this.tcIndex.pInd = this.objStatByFrm.topClanovi.length - this.tcCntPerPage + fali;
    this.tcIndex.zInd = this.objStatByFrm.topClanovi.length + fali - 1;
    this.topClPaginator(this.tcIndex.pInd, this.tcIndex.zInd);
  }


  //NAPRAVI FRM FILTER
  napraviFrmFilter(val: FrmFilter, callback) {

    this.frmFilter = this.frmBuilder.group({
      klijentID: [this.mod.objKor.korisnikID],
      datOd: [moment().utc(true)],
      datDo: [moment().utc(true)],
      PoslJedID: [0],
      dvoID: [0],
      clanID: [0],
      fingerprint: [this.mod.fingerprint()]
    });

    if (val) {
      this.frmFilter.patchValue(val);
    }

    callback(this.frmFilter.value);

  }

  //Statistika by frmFilter
  statByFrm(val: FrmFilter, callback) {

    let url: string = this.mod.webUrl + '/php/stat-klijent.php?token=' + this.mod.objKor.token + '&funk=statByFrm';

    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez: StatByFrm) => {
      callback(rez);
    });

  }

  chartDaniData;
  napuniChartDani(brPosjeta: number) {

    let color: string = '#d1f9ff';

    this.chartDaniData = [
      ['pon ' + Math.round(this.objStatByFrm.dani.pon / brPosjeta * 100) + '%', Number(this.objStatByFrm.dani.pon), color, Number(this.objStatByFrm.dani.pon)],
      ['uto ' + Math.round(this.objStatByFrm.dani.uto / brPosjeta * 100) + '%', Number(this.objStatByFrm.dani.uto), color, Number(this.objStatByFrm.dani.uto)],
      ['sri ' + Math.round(this.objStatByFrm.dani.sri / brPosjeta * 100) + '%', Number(this.objStatByFrm.dani.sri), color, Number(this.objStatByFrm.dani.sri)],
      ['čet ' + Math.round(this.objStatByFrm.dani.cet / brPosjeta * 100) + '%', Number(this.objStatByFrm.dani.cet), color, Number(this.objStatByFrm.dani.cet)],
      ['pet ' + Math.round(this.objStatByFrm.dani.pet / brPosjeta * 100) + '%', Number(this.objStatByFrm.dani.pet), color, Number(this.objStatByFrm.dani.pet)],
      ['sub ' + Math.round(this.objStatByFrm.dani.sub / brPosjeta * 100) + '%', Number(this.objStatByFrm.dani.sub), color, Number(this.objStatByFrm.dani.sub)],
      ['ned ' + Math.round(this.objStatByFrm.dani.ned / brPosjeta * 100) + '%', Number(this.objStatByFrm.dani.ned), color, Number(this.objStatByFrm.dani.ned)],
    ];

  }

  chartDdData;
  cddWidth: number;
  napuniChartDd(objDoba: DobaDana[]) {

    let color: string = '#91d6ff';

    this.cddWidth = objDoba?.length * 100;

    this.chartDdData = [];
    objDoba?.forEach((val: DobaDana) => {

      this.chartDdData.push([val.hh + 'h', val.count, color, val.count]);

    });

  }


  //Učitavanje podataka za line Chart - OLD

  ucitajLineChart(rez: FrmFilterRez[]) {

    this.lcPosjete.data = [];

    let rowTmp: number = 0;

    rez?.forEach(val => {
      let tmp: FrmFilterRez = val;
      let pTmp = [moment(val.datum).format('DD.MM.YY.'), Number(val.countID)];
      this.lcPosjete.data.push(pTmp);
      rowTmp += 1;
    });

    if (rowTmp <= 7) {
      rowTmp = 700;
    } else {
      rowTmp = rowTmp * 200;
    }

    this.lcPosjete.width = rowTmp;

  }

  //Line Chart - posjete - OLD
  napraviLcPosjete() {

    this.lcPosjete = {
      type: 'LineChart',
      data: [
        ["Jan", 500, 600],
        ["Feb", 800, 900],
        ["Mar", 400, 600],
        ["Apr", 600, 500],
        ["May", 400, 300],
        ["Jun", 750, 700],
        ["Jul", 800, 710],
        ["Aug", 810, 720],
        ["Sep", 820, 730],
        ["Oct", 900, 840],
        ["Nov", 910, 850],
        ["Dec", 920, 890]
      ],
      options: {
        legend: { position: 'none' },
        chartArea: {
          bottom: 120,
          top: 20,
          left: 50,
          right: 10
        }/* ,
        hAxis: {
          title: 'test'
        } */
      },
      width: 1000,
      height: 600,
      backgroundColor: 'transparent'
    };

  }

  //Broj posjeta by Klijent ID
  brojPosjeta(val: FrmFilter, callback) {

    let url: string = this.mod.webUrl + '/php/stat-klijent.php?token=' + this.mod.objKor.token + '&funk=brojPosjeta';
    val['fingerprint'] = this.mod.fingerprint();
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez: FrmFilterRez[]) => {
      callback(rez);
    });

  }

}
