<form *ngIf="srvKorisnici.frmKorEdit" [formGroup]="srvKorisnici.frmKorEdit">

    <!--IME-->
    <div class="col" style="padding: 0;">
        <mat-form-field class="w-100">
            <mat-label>{{mod.objKor.vrstaID == 1 ? 'Ime' : 'Naziv'}}</mat-label>
            <input autocomplete="off" matInput type="text" formControlName="Ime"
                placeholder="{{mod.objKor.vrstaID == 1 ? 'Ime' : 'npr. Test d.o.o.'}}" required>
            <mat-error *ngIf="srvKorisnici.frmKorEdit.controls['Ime'].errors?.required">Polje je obavezno za unos
            </mat-error>
        </mat-form-field>
    </div>

    <!--PREZIME-->
    <div class="col" style="padding: 0;" *ngIf="mod.objKor.vrstaID == 1">
        <mat-form-field class="w-100">
            <mat-label>Prezime</mat-label>
            <input autocomplete="off" matInput type="text" formControlName="Prezime" placeholder="Prezime">
        </mat-form-field>
    </div>

    <!--ADRESA-->
    <div class="col" style="padding: 0;">
        <mat-form-field class="w-100">
            <mat-label>Adresa</mat-label>
            <input autocomplete="off" matInput type="text" formControlName="Adresa" placeholder="Adresa">
        </mat-form-field>
    </div>

    <!--MJESTO-->
    <div class="col" style="padding: 0;">
        <mat-form-field class="w-100">
            <mat-label>Mjesto</mat-label>
            <input autocomplete="off" type="text" placeholder="Upišite 3 slova iz naziva" aria-label="Mjesto"
                matInput formControlName="Mjesto" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let x of srvKorisnici.fltMjesta2" [value]="x.Mjesto"
                    (onSelectionChange)="srvKorisnici.postaviMjestoID(x.Mjesto_ID)">
                    {{x.Mjesto}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <!--OIB-->
    <div class="col" style="padding: 0;" *ngIf="mod.objKor.vrstaID == 2">
        <mat-form-field class="w-100">
            <mat-label>OIB</mat-label>
            <input autocomplete="off" matInput type="text" formControlName="OIB" placeholder="OIB">
            <mat-error *ngIf="srvKorisnici.frmKorEdit.controls['OIB'].errors?.err">
                {{srvKorisnici.frmKorEdit.controls['OIB'].errors?.err}}</mat-error>
        </mat-form-field>
    </div>

</form>