<div>
    <button *ngIf="mod.objKor.vrstaID == 2 || mod.session" type="button" class="btn mybtn-cancel w-100" data-dismiss="modal" routerLink="/vjezbe">Vježbe</button>
</div>

<div>
    <div *ngIf="mod.objKor.vrstaID == 1 && !mod.session" class="card w-100" style="padding: 10px 4px 10px 4px;">
        <div style="font-weight: bold;">
            Poveži se s fitness centrom
        </div>
        <div style="margin-bottom: 10px;">
            Nađi svoj fitnes centar i pošalji im zahtjev za povezivanjem. Nakon što centar potvrdi zahtjev imat ćeš uvid u osobne statistike te aktivnosti centra, moći ćeš primati obavijesti i koristiti druge pogodnosti koje nudi aplikacija.
        </div>
        <button class="btn mybtn-info w-100" style="color: #004a8b; font-size: 1.1rem;" routerLink="/zahtjevi-zp" data-dismiss="modal"><i class="fa fa-user-plus" style="margin-right: 5px;" aria-hidden="true"></i> Prikaži fitness centre</button>
    </div>
</div>
