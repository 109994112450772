import { Injectable } from '@angular/core';
import { ModulService } from './modul.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PostavkeService {

  constructor(
    private mod: ModulService,
    private http: HttpClient
  ) { }


  //Brisanje profilne slike
  obrisiProfilnu(korID: number, callback) {

    let url: string = this.mod.webUrl + '/php/postavke.php?token=' + this.mod.objKor.token + '&funk=obrisiProfilnu';
    let obj: string = JSON.stringify({ korID: korID, fingerprint: this.mod.fingerprint()});

    this.http.post(url, obj).subscribe((rez: string) => {
      callback(rez);
    });

  }

}
