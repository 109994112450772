import { Component } from '@angular/core';
import { NapredakService } from '../napredak.service';
import { ModulService } from '../modul.service';

@Component({
  selector: 'app-pn-program-edit',
  templateUrl: './pn-program-edit.component.html',
  styleUrl: './pn-program-edit.component.scss'
})
export class PnProgramEditComponent {

  constructor(
    public mod: ModulService,
    public srvNapredak: NapredakService
  ) {}

  publicClick(){

    let pubTmp: number = this.srvNapredak.frmPnProgram.get('pnPublic').value;

    if (pubTmp == 1) {
      this.srvNapredak.frmPnProgram.get('pnPublic').setValue(0);
    } else {
      this.srvNapredak.frmPnProgram.get('pnPublic').setValue(1);
    }

  }

}
