import { Injectable } from '@angular/core';
import { Vclanarina } from './clanarine.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Grupa } from './grupe.service';

@Injectable({
  providedIn: 'root'
})
export class ClanEditService {

  constructor() { }

  objVazeceClanarine: Vclanarina[];
  objGrupe: Grupa[];

  //vazeća članarina - boje
  clrVazeca(claVrijediDO: Moment) {

    claVrijediDO = moment(claVrijediDO);

    let duration = moment.duration(claVrijediDO.diff(moment()));

    let mTmp: number;
    mTmp = duration.asDays();

    if (mTmp <= 3) {
      return 'red'; //crvena
    } else if (mTmp > 3 && mTmp < 7) {
      return '#ff995a'; //narančasta
    } else {
      return '#9fe0bf'; //zelena
    }

  }

}
