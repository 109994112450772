<div class="container pozadina mat-reg input-slim" style="padding-top: 50px;">

    <div class="alert alert-info alert-dismissible fade show" role="alert">
        <span><strong>Upišite valjanu e-mail adresu</strong> jer ćemo preko nje izvršiti potvrdu registracije! Ona će
            Vam ujedno služiti kao korisničko ime prilikom prijave u aplikaciju!</span>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="row" style="margin: 0;">

        <form [formGroup]="frmRegistracija" class="form-inline" style="text-align: center;">

            <!--Dropdown Vrsta Korisnika-->
            <div class="col">
                <mat-form-field>
                    <mat-label>Vrsta korisnika</mat-label>
                    <mat-select formControlName="vrstaID" placeholder="Vrsta korisnika" required>
                        <mat-option value="1" (click)="odabirVrsteKor(1)">Privatni</mat-option>
                        <mat-option value="2" (click)="odabirVrsteKor(2)">Poslovni</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!--IME-->
            <div class="col">
                <mat-form-field>
                    <mat-label>{{vrstaKorID == 1 ? 'Ime' : 'Naziv'}}</mat-label>
                    <input autocomplete="off" matInput type="text" formControlName="Ime"
                        placeholder="{{vrstaKorID == 1 ? 'Ime' : 'npr. Test d.o.o.'}}" required>
                    <mat-error *ngIf="frmRegistracija.controls['Ime'].errors?.required">Polje je obavezno za unos
                    </mat-error>
                </mat-form-field>
            </div>

            <!--PREZIME-->
            <div class="col" *ngIf="vrstaKorID == 1">
                <mat-form-field>
                    <mat-label>Prezime</mat-label>
                    <input autocomplete="off" matInput type="text" formControlName="Prezime" placeholder="Prezime">
                </mat-form-field>
            </div>

            <!--ADRESA-->
            <div class="col">
                <mat-form-field>
                    <mat-label>Adresa</mat-label>
                    <input autocomplete="off" matInput type="text" formControlName="Adresa" placeholder="Adresa">
                </mat-form-field>
            </div>

            <!--KUĆNI BROJ-->
            <!-- <mat-form-field>
                <mat-label>Kućni broj</mat-label>
                <input matInput type="text" formControlName="Kucni_br" placeholder="Kucni_br">
            </mat-form-field> -->

            <!--MJESTO-->
            <div class="col">
                <mat-form-field>
                    <mat-label>Mjesto</mat-label>
                    <input autocomplete="off" type="text" placeholder="Upišite 3 slova iz naziva" aria-label="Mjesto"
                        matInput formControlName="Mjesto" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let x of fltMjesta" [value]="x.Mjesto"
                            (onSelectionChange)="postaviMjestoID(x.Mjesto_ID)">
                            {{x.Mjesto}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <!--OIB-->
            <div class="col" *ngIf="vrstaKorID == 2">
                <mat-form-field>
                    <mat-label>OIB</mat-label>
                    <input autocomplete="off" matInput type="text" formControlName="OIB" placeholder="OIB">
                    <mat-error *ngIf="frmRegistracija.controls['OIB'].errors?.err">
                        {{frmRegistracija.controls['OIB'].errors?.err}}</mat-error>
                </mat-form-field>
            </div>

            <!--Korisničko ime-->
            <div class="col">
                <mat-form-field>
                    <mat-label>e-mail (korisničko ime)</mat-label>
                    <input autocomplete="off" matInput type="email" formControlName="korIme"
                        placeholder="Korisničko ime" (focusout)="dupliEmail()" required>

                    <mat-error *ngIf="frmRegistracija.controls['korIme'].errors?.email">Neispravna e-mail adresa
                    </mat-error>
                    <mat-error *ngIf="frmRegistracija.controls['korIme'].errors?.required">Polje je obavezno za unos
                    </mat-error>
                    <mat-error *ngIf="frmRegistracija.controls['korIme'].errors?.dupla">e-mail adresa je zauzeta
                    </mat-error>


                </mat-form-field>
            </div>

            <!--Lozinka-->
            <div class="col">
                <mat-form-field>
                    <mat-label>Lozinka</mat-label>
                    <input autocomplete="off" matInput type="password" formControlName="lozinka" required>
                    <mat-error *ngIf="frmRegistracija.controls['lozinka'].errors?.required">Polje je obavezno za unos
                    </mat-error>
                    <mat-error *ngIf="frmRegistracija.controls['lozinka'].errors?.minlength">Minimalno 6 znakova
                    </mat-error>
                </mat-form-field>
            </div>

            <!--Lozinka2-->
            <div class="col">

                <mat-form-field>
                    <mat-label>Potvrdite lozinku</mat-label>
                    <input autocomplete="off" matInput type="password" formControlName="lozinka2" required>
                    <mat-error *ngIf="frmRegistracija.controls['lozinka2'].errors?.required">Polje je obavezno za unos
                    </mat-error>
                    <mat-error *ngIf="frmRegistracija.controls['lozinka2'].errors?.minlength">Minimalno 6 znakova
                    </mat-error>
                    <mat-error *ngIf="frmRegistracija.controls['lozinka2'].errors?.mustMatch">Morate upisati identičnu
                        vrijednost kao u polju 'Lozinka'</mat-error>
                </mat-form-field>

            </div>

            <div class="col col-12" style="padding: 0px;">
                <div *ngIf="vrstaKorID == 2">
                    <form *ngIf="srvPosJed.frmPosJed" [formGroup]="srvPosJed.frmPosJed">

                        <div class="row">

                            <div class="col-12" style="text-align: left; margin-top: 10px;">
                                Podaci o poslovnoj jedinici
                            </div>

                            <div class="col col-12 d-flex align-items-center"
                                style="padding: 0px 10px; cursor: pointer;" (click)="istoCheck()">
                                <div style="font-size: 1.5rem;">
                                    <div *ngIf="srvPosJed.frmPosJed.controls['intIsto'].value == '0'"
                                        class="fa fa-square-o" aria-hidden="true"></div>
                                    <div *ngIf="srvPosJed.frmPosJed.controls['intIsto'].value == '1'"
                                        class="fa fa-check-square-o" aria-hidden="true"></div>
                                </div>
                                <div style="margin-left: 10px;">
                                    isti kao podaci tvrtke
                                </div>

                            </div>

                            <div class="col" *ngIf="srvPosJed.frmPosJed.controls['intIsto'].value == '0'">
                                <mat-form-field>
                                    <mat-label>Naziv poslovne jedinice</mat-label>
                                    <input autocomplete="off" autocomplete="off" matInput type="text"
                                        formControlName="NazivPos" placeholder="npr. Fitness centar Test" required>
                                    <mat-error *ngIf="srvPosJed.frmPosJed.controls['NazivPos'].errors?.required">Polje
                                        je
                                        obavezno za unos
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col" *ngIf="srvPosJed.frmPosJed.controls['intIsto'].value == '0'">
                                <mat-form-field>
                                    <mat-label>Adresa</mat-label>
                                    <input autocomplete="off" matInput type="text" formControlName="AdresaPos"
                                        placeholder="Adresa">
                                </mat-form-field>
                            </div>

                            <div class="col" *ngIf="srvPosJed.frmPosJed.controls['intIsto'].value == '0'">
                                <mat-form-field>
                                    <mat-label>Mjesto</mat-label>
                                    <input autocomplete="off" type="text" placeholder="Upišite 3 slova iz naziva"
                                        aria-label="Mjesto" matInput formControlName="Mjesto" [matAutocomplete]="auto1">
                                    <mat-autocomplete #auto1="matAutocomplete">
                                        <mat-option *ngFor="let x of fltMjesta2" [value]="x.Mjesto"
                                            (onSelectionChange)="postaviMjestoID2(x.Mjesto_ID)">
                                            {{x.Mjesto}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>

                            <div class="col">
                                <mat-form-field>
                                    <mat-label>Kapacitet (broj osoba)</mat-label>
                                    <input autocomplete="off" matInput type="number" formControlName="kapacitet"
                                        placeholder="broj osoba" required>
                                    <mat-error *ngIf="srvPosJed.frmPosJed.controls['kapacitet'].errors?.required">Polje
                                        je obavezno za unos</mat-error>
                                    <mat-error *ngIf="srvPosJed.frmPosJed.controls['kapacitet'].errors?.min">Broj mora
                                        biti veći od 0</mat-error>
                                    <mat-error *ngIf="srvPosJed.frmPosJed.controls['kapacitet'].errors?.max">Broj mora
                                        biti veći od 1000</mat-error>
                                </mat-form-field>
                            </div>

                        </div>

                    </form>
                </div>
            </div>

            <!--btn UVJETI KORIŠTENJA-->
            <div class="col">

                <div class="row">
                    <div class="col">
                        <div class="w-100">
                            <button type="button" class="btn {{btnUvjetiClass}} btn-spremi w-100" data-toggle="modal"
                                data-target="#modalUvjetiKoristenja">
                                <input formControlName="uvjKoristenja" class="form-check-input" type="checkbox"
                                    id="inlineCheckbox1" value="option1"> Uvjeti korištenja
                            </button>
                        </div>

                        <div>
                            <mat-hint
                                *ngIf="frmRegistracija.controls['uvjKoristenja'].pristine && frmRegistracija.controls['uvjKoristenja'].errors?.required"
                                style="font-size: 0.7rem;"> Morate prihvatiti uvjete korištenja kako biste mogli
                                koristiti
                                aplikaciju
                            </mat-hint>
                            <mat-error
                                *ngIf="!frmRegistracija.controls['uvjKoristenja'].pristine && frmRegistracija.controls['uvjKoristenja'].errors?.required"
                                style="font-size: 0.7rem;"> Morate prihvatiti uvjete korištenja kako biste mogli
                                koristiti
                                aplikaciju
                            </mat-error>
                        </div>
                    </div>
                    <!--btn SPREMI-->
                    <div class="col">
                        <button type="submit"
                            class="btn {{frmRegistracija.valid ? 'btn-outline-success' : 'btn-outline-danger'}} btn-spremi w-100"
                            (click)="registracija()" style="padding: 2px; vertical-align: middle;">
                            <div class="row" style="margin: 0px;">
                                <div class="col-4 align-self-center" style="text-align: right;">
                                    <i class="material-icons" style="padding-top: 3px;">save</i>
                                </div>
                                <div class="col-8 align-self-center" style="text-align: left;">
                                    <span>REGISTRIRAJ SE</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>

            </div>

            <div class="col-12" *ngIf="spinner">
                <div class="d-flex justify-content-center" style="margin: 20px;">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p style="margin-left:15px;">obrada u tijeku...</p>
                </div>
            </div>

        </form>

    </div>

</div>

<button hidden="true" class="btn btn-primary" (click)="test()">test</button>

<!--MODAL UVJETI KORIŠTENJA-->
<div class="modal fade" id="modalUvjetiKoristenja" tabindex="-1" role="dialog" aria-labelledby="Modal Uvjeti koristenja"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="modalKorisnikLabel">Uvjeti korištenja</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p><strong>e-mail adresa</strong> <br />
                    <span>Jamčimo Vam da vašu e-mail adresu nećemo koristiti u nikakve druge svrhe, osim za potrebe
                        identifikacije i za međusobnu komunikaciju.
                        Nećemo ju prosljeđivati trećim stranama pod nikakvim uvjetima.
                    </span>
                </p>
                <p><strong>Privola za obradu osobnih podataka</strong> <br />
                    <span>Prihvaćanjem ovih uvjeta korištenja dajete nam privolu za obradu osobnih podataka. Mi
                        Vam
                        jamčimo da ćemo vaše podake
                        koristiti isključivo za potrebe fitNet mreže i centara čiji ste članovi.
                    </span>
                </p>
                <p><strong>Korištenje kolačića</strong> <br />
                    <span>Prihvaćanjem ovih uvjeta korištenja dozvoljavate našoj aplikaciji korištenje kolačića.
                        Aplikacija
                        koristi samo kolačiće
                        nužne za rad aplikacije koji ne sadrže nikakve osobne informacije i ne bilježe nikakve podatke o
                        vašim aktivnostima.
                    </span>
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn mybtn-success w-100" (click)="prihvacamUvjete()"
                    data-dismiss="modal">Prihvaćam</button>
                <button id="btnUvjetClose" type="button" class="btn mybt-cancel w-100" (click)="prihvacamUvjeteNot() "
                    data-dismiss="modal">Ne prihvaćam</button>
            </div>
        </div>
    </div>
</div>