<div class="container" style="padding: 0px; height: 100%;">

    <div class="row" style="margin: 0px !important; height: 100%; padding-top: 50px; max-height: 100%;">

        <!--Lijeva kolona-->
        <div class="d-none d-md-block col-md-4">

            <!-- <div class="lco" style="position: -webkit-sticky; position: sticky; top: 50px;"> -->

            <!--Kontrole-->
            <div class="row cardio" style="margin: 0px;">

                <!--Novi član-->
                <div class="col d-flex justify-content-center col-btn" (click)="otvoriClanComp(0)">
                    <span class="material-icons cp-icon" style="color: rgb(156, 240, 198);">
                        person_add
                    </span>
                    <span style="font-size: 1.3rem;">Novi član</span>
                </div>
            </div>

            <!--Poslovne jedinice-->
            <div class="card" *ngIf="mod.objPosJed" style="padding: 0px;">
                <app-pos-jedinice (odabranaPjed)="odabranaPosJed($event)"></app-pos-jedinice>
            </div>

            <!-- </div> -->

        </div>

        <!-- Desna kolona -->
        <div class="col" style="padding: 0px; max-height: 100%; overflow-x: hidden;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">

                <div>
                    <!-- <div style="position: -webkit-sticky; position: sticky; top: 50px; z-index: 800;"> -->

                    <div class="bgs-dark">
                        <app-pos-jed-hamburger (hamburgerClickEmitt)="openSidebar()"></app-pos-jed-hamburger>
                    </div>

                    <div class="row-pretraga">

                        <!--PRETRAGA-->
                        <div class="row pretraga-slim" style="margin: 0px; border-bottom: solid 0.5px grey;">

                            <div class="col" style="text-align: center; padding: 5px">
                                <mat-form-field style="width: 100%;">
                                    <input autocomplete="off" type="search" type="text" name="pretraga"
                                        [(ngModel)]="strPretraga" (ngModelChange)="filtrirajClanove(strPretraga)"
                                        matInput placeholder="pretraga..">
                                    <button mat-button *ngIf="strPretraga" id="bc1" matSuffix aria-label="Clear"
                                        (click)="pretragaClear()">X</button>
                                    <button mat-button *ngIf="!strPretraga" id="bc1" matSuffix aria-label="search"><i
                                            class="material-icons" style="font-size: 2rem;">search </i></button>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>

                    <!-- </div> -->
                </div>

                <div class="flex-grow-1 card"
                    style="overflow-y: scroll; scrollbar-width: none; padding-top: 0; padding-bottom: 50px;" #colChOkvir>

                    <!--Lista članova-->
                    <div *ngFor="let x of srvClan.fltClanovi" class="mymat-menu"
                        style="padding: 0px 5px 5px 5px; border-bottom: solid 0.5px #c7c5c5;"
                        [@listFadeIn]="srvClan.fltClanovi?.length">

                        <div class="row card-clanovi" [attr.id]="'row' + x.clanID" style="cursor: pointer;"
                            (click)="odabirClana(x.clanID)">

                            <div class="col col-2 d-flex justify-content-center align-items-center"
                                style="padding: 5px; font-size: 2rem; cursor: pointer;">
                                <i class="fa fa-pencil"
                                    [ngStyle]="{'color': srvClan.clanID == x.clanID ? '#16b75b' : '#8d9f9b'}"
                                    aria-hidden="true"></i>
                            </div>

                            <div class="col d-flex flex-column"
                                style="text-align: left; font-size: 1.2rem; padding: 2px;">

                                <div class="d-flex">
                                    <strong>{{x.Ime}} {{x.Prezime}} </strong>
                                    <span *ngIf="x.fitNetID > 0" class="ml-auto"
                                        style="color: rgb(61, 175, 122); font-size: 1rem;">fitNET</span>
                                </div>

                                <span style="font-size: 1rem; margin-bottom: 8px">{{x.Adresa}} {{x.Mjesto &&
                                    x.Adresa ?
                                    ', ' +
                                    x.Mjesto : x.Mjesto}}</span>

                            </div>

                        </div>

                        <div *ngIf="this.srvClan.clanID == x.clanID" class="row" style="font-size: 1.2rem;">
                            <div *ngIf="x.pn" class="col" style="padding: 5px; text-align: center;"
                                (click)="napredakFill(x.clanID)">
                                <div class="dropdown h-100">

                                    <div class="btn mybtn-blank dropdown-toggle h-100" data-toggle="dropdown"><i
                                            class="fa fa-line-chart" style="margin-right: 5px; font-size: 1.2rem;"
                                            aria-hidden="true"></i>Napredak</div>

                                    <!-- <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown"
                                        aria-expanded="false">
                                        Dropdown button
                                    </button> -->
                                    <div class="dropdown-menu" style="background-color: #6ba8d9;" [ngStyle]="{'width': colChOkvir.offsetWidth*1 - 20 + 'px'}">
                                        <div *ngFor="let x of srvNapredak.objVePnVezaByClanID">
                                            <div style="padding: 2px;">
            
                                                <div style="padding: 5px; font-weight: bold;">
                                                    {{x.pnNaziv}}
                                                </div>
            
                                                <div class="row">
                                                    <div class="col" style="padding: 5px;" (click)="novoMjerenjeStart(x)">
                                                        <button type="button" class="btn mybtn-blank w-100 h-100"><i
                                                                class="fa fa-clock-o" style="margin-right: 5px; font-size: 1.5rem;"
                                                                aria-hidden="true"></i>Novo mjerenje</button>
                                                    </div>
                                                    <div class="col" style="padding: 5px;" (click)="ciljeviByPnvID(x.pnvID)">
                                                        <button type="button" class="btn mybtn-blank w-100 h-100"><i
                                                                class="fa fa-flag-checkered"
                                                                style="margin-right: 5px; font-size: 1.5rem;"
                                                                aria-hidden="true"></i>Zadani ciljevi</button>
                                                    </div>
                                                    <div class="col" style="padding: 5px;" (click)="pnPregled(x)">
                                                        <button type="button" class="btn mybtn-blank w-100 h-100"><i
                                                                class="fa fa-line-chart"
                                                                style="margin-right: 5px; font-size: 1.5rem;"
                                                                aria-hidden="true"></i>Statistika</button>
                                                    </div>
                                                </div>
            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col" style="padding: 5px; text-align: center;" (click)="otvoriClanarine()">
                                <div class="mybtn-blank h-100"><i class="fa fa-list-alt"
                                        style="margin-right: 5px; font-size: 1.2rem;" aria-hidden="true"></i>Članarine
                                </div>
                            </div>
                            <div class="col" style="padding: 5px; text-align: center;"
                                (click)="otvoriClanComp(x.clanID)">
                                <div class="mybtn-blank h-100"><i class="fa fa-pencil"
                                        style="margin-right: 5px; font-size: 1.2rem;" aria-hidden="true"></i>Uredi
                                    podatke</div>
                            </div>
                        </div>



                        <mat-menu #menu="matMenu" class="mymat-menu">

                            

                        </mat-menu>

                    </div>


                    <div *ngIf="!srvClan.objClanovi" class="alert alert-success" style="text-align: center;">
                        <span>Nema upisanih članova</span>
                    </div>

                    <div *ngIf="srvClan.objClanovi && srvClan.fltClanovi?.length == 0" class="alert alert-success"
                        style="text-align: center;">
                        <span>Nema stavki za prikaz</span>
                    </div>



                </div>

            </div>

        </div>

    </div>

</div>

<!--SIDEBAR - poslovne jedinice-->
<button hidden="true" #btnSidebar data-toggle="modal" data-target="#modalClanovi"></button>
<div class="modal left fade" id="modalClanovi" tabindex="-1" role="dialog" aria-labelledby="modalClanoviLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header bgs-dark" style="border: none; padding: 0px;">

                <div class="w-100 naslov-m">
                    <span>Članovi</span>
                </div>
            </div>

            <div class="modal-body bgs-dark" style="padding: 0px;">
                <!--Kontrole-->
                <div class="row cardio bgs-dark" style="margin: 0px;">

                    <!--Novi član-->
                    <div class="col d-flex col-btn justify-content-center" data-dismiss="modal"
                        (click)="otvoriClanComp(0)">
                        <i class="material-icons cp-icon" style="color: rgb(156, 240, 198);">
                            person_add
                        </i>
                        <span style="font-size: 1.2rem; margin-left: 10px">Novi član</span>
                    </div>

                </div>

                <app-pos-jedinice (odabranaPjed)="odabranaPosJed($event)"></app-pos-jedinice>

            </div>
            <div class="modal-footer bgs-dark" style="padding: 5px;">
                <button type="button" id="btnSidebarClose" class="btn mybtn-cancel"
                    style="width: 100%; font-size: 1.2rem;" data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!--MODAL novo mjerenje-->
<button hidden="true" id="btnMdlPnMjerEdit" class="btn btn-info" data-toggle="modal" data-target="#mdlPnMjerEdit">Modal
    poslan e-mail</button>

<div class="modal fade" id="mdlPnMjerEdit" tabindex="-1" role="dialog" aria-labelledby="mdlMjerEditLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-block">

                <!-- <div class="d-flex" style="margin-bottom: 10px;">
                    <h6 class="modal-title" id="mdlPnStavEditLabel">Mjerenje</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div> -->
                
                <div class="input-slim">
                    <form *ngIf="srvNapredak.frmPnMjerenje" [formGroup]="srvNapredak.frmPnMjerenje">
                        
                        <mat-form-field style="width: 100%; text-align: center;">
                            <mat-label>Datum</mat-label>
                            <input matInput name="datum" formControlName="datum" style="text-align: center;"
                                [matDatepicker]="picker1" placeholder="Datum">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #picker1></mat-datepicker>
                        </mat-form-field>
                        
                    </form>
                </div>

            </div>

            <div class="modal-body" style="padding: 5px;">

                <app-pn-mjerenje-edit></app-pn-mjerenje-edit>

            </div>
            <div class="modal-footer" style="display: block; padding: 5px;">
                <div class="row">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100" (click)="spremiMjerenje()">Spremi</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button id="btnMdlPnMjerEditClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--MODAL zadani ciljevi-->
<button hidden="true" id="btnMdlCiljeviEdit" class="btn btn-info" data-toggle="modal" data-target="#mdlCiljevi">Modal
    poslan e-mail</button>

<div class="modal fade" id="mdlCiljevi" tabindex="-1" role="dialog" aria-labelledby="mdlCiljeviLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-block">

                <div class="d-flex" style="margin-bottom: 10px;">
                    <h6 class="modal-title" id="mdlPnStavEditLabel">Zadani ciljevi</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="d-flex" style="color: blue;">
                    <div style="margin-right: 10px;">
                        <i class="fa fa-square" aria-hidden="true" style="color: blue;"></i>
                    </div>
                    <div>
                        Prvo mjerenje
                    </div>
                </div>

            </div>

            <div class="modal-body" style="padding: 5px;">

                <app-pn-cilj-edit></app-pn-cilj-edit>

            </div>
            <div class="modal-footer" style="display: block; padding: 5px;">
                <div class="row">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100" (click)="spremiCiljeve()">Spremi</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button id="btnMdlCiljeviClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>