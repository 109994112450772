import { Component, ElementRef, OnInit, ViewChild, viewChild } from '@angular/core';
import { ModulService } from '../modul.service';
import { Vjezba, VjezbeService } from '../vjezbe.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-vjezbe',
  templateUrl: './vjezbe.component.html',
  styleUrl: './vjezbe.component.scss'
})
export class VjezbeComponent implements OnInit {

  constructor(
    public mod: ModulService,
    public srvVjezbe: VjezbeService,
    public sanitizer: DomSanitizer
  ) { }

  /* @ViewChild('fbVideo') fbVideo: ElementRef; */
  @ViewChild('mdlFbVideo') mdlFbVideo: ElementRef



  ngOnInit(): void {
    this.srvVjezbe.vjezbeByKlijenId(this.mod.objKor.korisnikID, (rez: Vjezba[]) => {
      this.srvVjezbe.objVjezbe = rez;

      this.mdlFbVideo.nativeElement.addEventListener('shown.bs.modal', () => {
      });



    });
  }

  prikazVjezbe() {
    document.getElementById('btnMdlFbVideo').click();
    this.srvVjezbe.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.facebook.com/plugins/video.php?href=' + this.srvVjezbe.objVjezbaSel.vjLink + '&t=0');
    /* this.srvVjezbe.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl('http://www.facebook.com/video/embed?video_id=281108758426286'); */
  }

}
