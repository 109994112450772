<div class="d-flex align-items-center justify-content-center" style="height: 300px; max-height: 300px; overflow: hidden;">

  <div class="scanner-shell" [hidden]="!hasDevices" *ngIf="mod.bolQrScaning && !srvClanovi.objVClanstva">
    <!-- <mat-menu #appMenu="matMenu">
      <mat-selection-list>
        <mat-list-option checkboxPosition="start" (click)="toggleTryHarder()" [selected]="tryHarder">
          Enable Try-harder
        </mat-list-option>
        <mat-list-option checkboxPosition="start" (click)="toggleTorch()" [selected]="torchEnabled"
          *ngIf="torchAvailable$ | async">
          Enable Torch
        </mat-list-option>
      </mat-selection-list>
      <button mat-menu-item (click)="openFormatsDialog()">Barcode formats</button>
      <button mat-menu-item (click)="openInfoDialog()">App info</button>
    </mat-menu>
  
    <header>
      <mat-form-field appearance="fill">
        <select matNativeControl matInput (change)="onDeviceSelectChange($event.target.value)">
          <option value="" [selected]="!currentDevice">No Device Selected</option>
          <option *ngFor="let device of availableDevices" [value]="device.deviceId"
            [selected]="currentDevice && device.deviceId === currentDevice.deviceId">{{device.label}}</option>
        </select>
      </mat-form-field>
      <button mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </header> -->

    <zxing-scanner [torch]="torchEnabled" [(device)]="currentDevice" (scanSuccess)="onCodeResult($event)"
      [formats]="['QR_CODE', 'EAN_13', 'CODE_128', 'DATA_MATRIX']" [tryHarder]="tryHarder"
      (permissionResponse)="onHasPermission($event)" (camerasFound)="onCamerasFound($event)"
      (torchCompatible)="onTorchCompatible($event)"></zxing-scanner>

    <!-- <section class="results" *ngIf="qrResultString">
      <div>
        <small>Result</small>
        <strong>{{ qrResultString }}</strong>
      </div>
      <button mat-icon-button (click)="clearResult()">&times;</button>
    </section> -->

    

  </div>

  <div *ngIf="mod.bolQrScaning && !srvClanovi.objVClanstva && !hasDevices">
    <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
      <span class="visually-hidden"></span>
    </div>
    <div>
      Učitavam...
    </div>
  </div>

  <div *ngIf="srvClanovi.objVClanstva" class="w-100">

    <div *ngFor="let x of srvClanovi.objVClanstva"
      (click)="postaviFrmPrijava(mod.objPosJedSelected.PoslJedID, x.clanID, x.Ime + ' ' + x.Prezime, x.vrstaIDcl)" data-toggle="modal" data-target="#mdlQrPrijava">

      <div *ngIf="mod.objKor.vrstaID == 2 || (mod.objKor.vrstaID == 1 && mod.session?.clanVrstaIDtren == 2 && x.vrstaIDcl == 1) || mod.session?.clanVrstaIDtren == 3" class="btn-lst-clanovi">

        <div class="row">
          <div class="col d-flex" style="align-items: center;">
            <span style="color: rgb(85, 5, 5);">{{x.Ime}} {{x.Prezime}}</span>
          </div>
        </div>
        <div style="padding: 0 15px; font-size: 1.2rem;">
          {{x.Adresa + ' ' + (x.Mjesto ? ', ' + x.Mjesto : '')}}
        </div>

        <div *ngIf="x.vrstaIDcl == '2'" style="font-size: 1.1rem; color: blue; padding: 0px 15px;">
          Trener
        </div>

        <div *ngIf="x.vrstaIDcl == '3'" style="font-size: 1.1rem; color: green; padding: 0px 15px;">
          Operater
        </div>

      </div>

    </div>

  </div>

</div>



<!-- <ng-container *ngIf="hasPermission === false">
  
    <h2>You denied the camera permission, we can't scan anything without it. 😪</h2>
  
  </ng-container>
  
  <ng-container *ngIf="hasDevices === undefined">
  
    <h2>Couldn't check for devices.</h2>
  
    <blockquote>
      This may be caused by some security error.
    </blockquote>
  
  </ng-container>
  
  <ng-container *ngIf="hasDevices === false">
  
    <h2>No devices were found.</h2>
  
    <blockquote>
      I believe your device has no media devices attached to.
    </blockquote>
  
  </ng-container> -->