import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ModulService, vAktivnost } from './modul.service';
import { Clan } from './clanovi.service';

export interface AktivnostiGrp {
  aktGrpID: number,
  GrupaIDakt: number,
  PocetakAkt: moment.Moment,
  KrajAkt: moment.Moment,
  statusAkt: number,
  dvoranaIdAkt: number
}

export interface VezTrenAktivnost {
  vtaID: number,
  aktIDvta: number,
  trenIDvta: number
}

export interface TreneriByAktID extends AktivnostiGrp, Clan, VezTrenAktivnost {

}

@Injectable({
  providedIn: 'root'
})
export class AktivnostiService {

  constructor(
    private mod: ModulService,
    private http: HttpClient
  ) { }

  objVaktivnost: vAktivnost[];
  objTreneriByAktID: TreneriByAktID[];


  //Dnevne aktivnosti by Kijent ID (poslovni)
  dnevneByKliID(klID: number, callback) {

    let datum: string = moment().format("YYYY-MM-DD");

    let url: string = this.mod.webUrl + '/php/aktivnosti.php?token=' + this.mod.objKor.token + '&funk=dnevneByKliID';
    let obj: string = JSON.stringify({ klID: klID, datum: datum, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: vAktivnost[]) => {
      callback(rez);
    });

  }

  //Treneri by aktivnost ID
  treneriByAktID(aktGrpID: number, callback) {

    let url: string = this.mod.webUrl + '/php/aktivnosti.php?token=' + this.mod.objKor.token + '&funk=treneriByAktID';
    let obj: string = JSON.stringify({ aktGrpID: aktGrpID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: TreneriByAktID[]) => {
      callback(rez);
    });

  }

  //Treneri by aktivnost ID edit
  treneriByAktIdEdit(aktGrpID: number, callback) {

    let url: string = this.mod.webUrl + '/php/aktivnosti.php?token=' + this.mod.objKor.token + '&funk=treneriByAktIdEdit';
    let obj: string = JSON.stringify({ aktGrpID: aktGrpID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: TreneriByAktID[]) => {
      callback(rez);
    });

  }

  //Dodavanje trenera u Aktivnost
  dodajTreneraUakt(aktIDvta: number, trenIDvta: number, callback) {

    let url: string = this.mod.webUrl + '/php/aktivnosti.php?token=' + this.mod.objKor.token + '&funk=dodajTreneraUakt';
    let obj: string = JSON.stringify({ aktIDvta: aktIDvta, trenIDvta: trenIDvta, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: TreneriByAktID) => {
      callback(rez);
    });

  }

  //Brisanje trenera iz aktivnosti
  obrisiTreneraAkt(vtaID: number, callback) {

    let url: string = this.mod.webUrl + '/php/aktivnosti.php?token=' + this.mod.objKor.token + '&funk=obrisiTreneraAkt';
    let obj: string = JSON.stringify({ vtaID: vtaID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: string) => {
      callback(rez);
    });

  }

}
