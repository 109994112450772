import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Kor, ModulService } from '../modul.service';
import { Subscription } from 'rxjs';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { AngularDeviceInformationService } from 'angular-device-information';

@Component({
  selector: 'app-naslovnica',
  templateUrl: './naslovnica.component.html',
  styleUrls: ['./naslovnica.component.scss']
})
export class NaslovnicaComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    public mod: ModulService,
    private authService: SocialAuthService,
    private deviceInformationService: AngularDeviceInformationService
  ) { }

  authSubscription!: Subscription;

  standAlone: boolean;
  os: string;
  
  ngOnInit() {

    console.log('naslovnica init');

    this.os = this.deviceInformationService.getDeviceInfo().os;
    console.log(this.os);

    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.standAlone = true;
    } else {
      this.standAlone = false;
    }

    /* if (this.authService) {
      this.authService.signOut();
    } */

    if (localStorage.getItem('token') || this.mod.objKor) {
      this.router.navigate(['cpanel1']);
    } else {
      this.mod.btnLoginClass = "Prijava";

      this.authSubscription = this.authService.authState.subscribe((user) => {
        if (user) {
          this.socialLogin(user);
        }
      });

      //registrtacija
      if (this.mod.poslanEmail) {
        document.getElementById('btnPoslano').click();
        this.mod.poslanEmail = false;
      }

      //aktivacija
      if (this.mod.prviLogin) {
        document.getElementById('btnMdlPrviLogin').click();
        this.mod.prviLogin = false;
      }

    }

  }

  socialLogin(user: SocialUser) {
    if (user && user.email) {
      this.mod.socialLogin(user, (rez0: Kor) => {
        if (rez0) {
          this.mod.objKor = rez0;
          document.getElementById('btnLoginModalClose').click();
          this.router.navigate(['cpanel1']);
          /* this.authSubscription.unsubscribe(); */
          this.mod.msgBox(1, "Uspješno ste prijavljeni", 2000);
        } else {
          this.mod.msgBox(2, "Greška prilikom prijave", 2000);
        }
      });
    } else {
      if (this.mod.btnLoginClass == 'Odjava' && !this.mod.objKor) {
        this.mod.msgBox(2, "Greška prilikom prijave 12", 2000);
      }
    }
  }

  ngOnDestroy(): void {
    document.getElementById('btnPolsanMailClose').click();
  }

}
