import { Injectable } from '@angular/core';
import { ModulService } from './modul.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeResourceUrl } from '@angular/platform-browser';

export interface Vjezba {
  vjID: number,
  vjKlijentID: number,
  vjNaziv: string,
  vjOpis: string,
  vjLink: string
}

@Injectable({
  providedIn: 'root'
})
export class VjezbeService {

  constructor(
    private mod: ModulService,
    private http: HttpClient,
    private frmBuilder: FormBuilder
  ) { }


  objVjezbe: Vjezba[];
  objVjezbaSel: Vjezba;

  frmVjezba: FormGroup;

  /* urlSafe: SafeResourceUrl; */
  srcUrl: SafeResourceUrl;

  //Iniciranje forme frmVjezba
  napraviFrmVjezba(val: Vjezba, callback) {

    this.frmVjezba = this.frmBuilder.group({
      vjID: [0],
      vjKlijentID: [this.mod.objKor.korisnikID],
      vjNaziv: [, Validators.required],
      vjOpis: [],
      vjLink: []
    });

    if (val) {
      this.frmVjezba.patchValue(val);
    }

    callback(this.frmVjezba.value);

  }

  //Vježbe by vjKlijentID
  vjezbeByKlijenId(vjKlijentID: number, callback) {

    let url: string = this.mod.webUrl + '/php/vjezbe.php?token=' + this.mod.objKor.token + '&funk=vjezbeByKlijenId';
    let obj: string = JSON.stringify({ vjKlijentID: vjKlijentID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: Vjezba[]) => {
      callback(rez);
    });

  }

  //Postavljanje objVjezbaSel
  odabirVjezbe(x) {
    this.objVjezbaSel = x;
  }

}
