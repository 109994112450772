import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClanoviService, PoslovniKorisnik, ZahtjevClanFitnet } from '../clanovi.service';
import { ModulService } from '../modul.service';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-zahtjevi-zp',
  templateUrl: './zahtjevi-zp.component.html',
  styleUrl: './zahtjevi-zp.component.scss'
})
export class ZahtjeviZpComponent implements OnInit, OnDestroy {

  constructor(
    public mod: ModulService,
    public srvClanovi: ClanoviService
  ) { }

  s: Subscription;
  t = timer(2000, 2000);

  //Subscribe timer
  subscribeTimer() {
    this.s = this.t.subscribe(t => {
      this.srvClanovi.lstPoslovniKorisnici((rez: PoslovniKorisnik[]) => {
        if (JSON.stringify(this.srvClanovi.objPoslovniKor) != JSON.stringify(rez)) {
          this.srvClanovi.objPoslovniKor = rez;
          this.srvClanovi.filtrirajPosKor(this.srvClanovi.strPretragaPosl);
        }
      });
    });
  }

  //Image source
  imgSrc(img, imgSize) {
    return 'fitNet/assets/clanovi/' + img + '.jpg?timestamp=' + imgSize;
  }


  ngOnInit(): void {

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.mod.objKor.token;
    }

    this.mod.provjeraTokena(tok, () => {

      this.mod.provjeraPrijaveTrenOper(rez9 => {

        this.ucitajPoslovnekorisnike(() => {
          this.subscribeTimer();
        });

      });

    });

  }

  ucitajPoslovnekorisnike(callback) {

    this.srvClanovi.lstPoslovniKorisnici((rez: PoslovniKorisnik[]) => {

      if (rez) {

        this.srvClanovi.objPoslovniKor = rez;
        this.srvClanovi.fltPoslovniKor = rez;

      } else {
        this.srvClanovi.objPoslovniKor = null;
        this.srvClanovi.fltPoslovniKor = null;
        this.mod.msgBox(2, "Greška prilikom učitavanja fitness centara!", 3000);
      }

      callback();

    });

  }

  //frm zahtjev
  napraviFrmZahtjev(x: PoslovniKorisnik) {

    let obj0: ZahtjevClanFitnet = {
      vfcID: 0,
      klijentIDvfc: x.korisnikID,
      naziv: x.Ime,
      korIDvfc: this.mod.objKor.korisnikID,
      clanIDvfc: 0,
      statusVfc: 0,
      DatumVfc: null
    }

    this.srvClanovi.napraviFrmZahtjevClFit(obj0, () => {
      document.getElementById('btnMdlZahtjevClFit').click();
    });

  }

  //Očisti pretragu
  pretragaPoslClear() {
    this.srvClanovi.strPretragaPosl = '';
    this.srvClanovi.fltPoslovniKor = this.srvClanovi.objPoslovniKor;
  }


  //Destroy
  ngOnDestroy(): void {
    this.s.unsubscribe();
    document.getElementById('btnSidebarClose').click();
    document.getElementById('btnZahtjevClose').click();
  }


}
