import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Dani, FrmFilter, StatKlijentService } from '../stat-klijent.service';
import { NavMsg } from '../animacije';
import { ModulService } from '../modul.service';
import { ClanoviService } from '../clanovi.service';
import { DanUtjednu } from '../kalendar.service';

@Component({
  selector: 'app-stat-klijent',
  templateUrl: './stat-klijent.component.html',
  styleUrls: ['./stat-klijent.component.scss'],
  animations: [NavMsg]
})
export class StatKlijentComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public mod: ModulService,
    public srvStatKlijent: StatKlijentService,
    public srvClanovi: ClanoviService
  ) { }

  ngOnInit(): void {

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.mod.objKor.token;
    }

    this.mod.provjeraTokena(tok, () => {
      this.mod.provjeraPrijaveTrenOper((rez) => {
        this.mod.ucitajPosJed(() => {

          this.srvStatKlijent.napraviFrmFilter(null, (rez: FrmFilter) => {

            this.srvStatKlijent.prikazi();

          });

        });
      });
    });



  }

  ngAfterViewInit(): void {
    /* this.cWidth = document.getElementById('colChOkvir').offsetWidth; */
  }

  //postotak
  postotak(pjPosjeta: number, uPosjeta: number) {

    return Math.round(pjPosjeta / uPosjeta * 100);

  }

  //Chart - posjete po danima - 

  //chartData u servisu

  columnsChartdatacolumn = [
    'Year',
    'broj posjeta ',
    { role: 'style' },
    { role: 'annotation' },
  ];

  columnsChartoptions = {

    chartArea: { top: 10, width: '100%', height: '80%' },
    fontSize: 12,
    annotations: {
      textStyle: {
        fontSize: 18,
        bold: true,
      }
    }

  };

  lineChartoptions = {

    chartArea: { top: 10, left: 10, width: '100%', height: '80%' },
    fontSize: 12,
    annotations: {
      textStyle: {
        fontSize: 18,
        bold: true,
      }
    }
  };

  onSelect(event) {

  }

  //Provjera paginator: Prva stranica
  ifFirst() {
    if (this.srvStatKlijent.tcIndex.pInd == 0) {
      return true;
    } else {
      return false
    }
  }

  //Provjera paginator: Zadnja stranica
  ifLast() {
    if (this.srvStatKlijent.tcIndex.pInd > this.srvStatKlijent.objStatByFrm.topClanovi.length - 1 - this.srvStatKlijent.tcCntPerPage) {
      return true;
    } else {
      return false
    }
  }

  //Provjera jel' ima posjeta po danima
  daniCountAsBool() {
    let ob: Dani = this.srvStatKlijent.objStatByFrm.dani;

    let c: number = 0;

    c += ob.pon;
    c += ob.uto;
    c += ob.sri;
    c += ob.cet;
    c += ob.pet;
    c += ob.sub;
    c += ob.ned;

    if (c > 0) {
      return true;
    } else {
      return false;
    }
  }

  //Provjera posjeta doba dana - član
  dobaDanaAsBool() {
    let c: number = 0;
    this.srvStatKlijent.objStatByFrm.dobaD?.forEach(val => {
      c += val.count;
    });
    if (c > 0) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.srvStatKlijent.objStatByFrm = null;
    this.srvClanovi.bolPojedinacno = false;
    this.srvClanovi.objPregldPosjetaClan = null;
    document.getElementById('btnSidebarClose').click();
  }

}
