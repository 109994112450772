import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Moment } from 'moment';
import { ModulService } from '../modul.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ClanoviService, PoslovniKorisnik, TrenutnoPrisutni, ZahtjevClanFitnet } from '../clanovi.service';
import { FormBuilder, FormGroup, MinValidator, Validators } from '@angular/forms';
import * as moment from 'moment';
import * as validatori from '../validatori';
import { MatDatepickerInput } from '@angular/material/datepicker';

export interface Clanstvo {
  vClansID: number,
  vrstaIDcl: number,
  ClanIDcl: number,
  KlijentIDcl: number,
  pjedIDcl: number,
  PocetakDatum: Moment,
  KrajDatum: Moment,
  intAkt: number,
  korisnikID: number,
  Ime: string,
  Prezime: string,
  Adresa: string,
  Mjesto: string,
  NazivPos: string,
  AdresaPos: string,
  MjestoPos: string,
  opis_vrsta_cl: string
}

@Component({
  selector: 'app-clanstva',
  templateUrl: './clanstva.component.html',
  styleUrls: ['./clanstva.component.scss']
})
export class ClanstvaComponent implements OnInit {

  constructor(
    public mod: ModulService,
    private http: HttpClient,
    private router: Router,
    public srvClanovi: ClanoviService
  ) {


  }

  @Output() dialgTrenOper: EventEmitter<any> = new EventEmitter();
  /* @ViewChild(MatDatepickerInput) dat123: MatDatepickerInput<Date>; */

  objClanstva: Clanstvo[];

  minDate: Moment;

  //session;

  ngOnInit() {
    setTimeout(() => {
      this.ucitajClanstva(() => { });
    }, 500);

  }

  //Učitaj članstva
  ucitajClanstva(callback) {

    if (localStorage.getItem('session')) {
      this.mod.session = JSON.parse(localStorage.getItem('session'));
    }

    let urlTmp: string = this.mod.webUrl + '/php/clanovi.php?token=' + this.mod.objKor.token + '&funk=korisnikClanstva';

    let obj = {
      korisnikID: this.mod.objKor.korisnikID
    }

    let obj2: string = JSON.stringify(obj);

    this.http.post(urlTmp, obj2).subscribe((rez: Clanstvo[]) => {
      console.log(rez);
      this.objClanstva = rez;
      callback(rez);
    });

  }

  //Emitter Modal poslovne jedinice za prijavu trenera / operatera by KlijentID
  posJedTrenOper(val: Clanstvo) {
    this.mod.objClanstvo = val;
    this.dialgTrenOper.emit(val);
  }

  //Odjava Trener / Operater
  odjavaTrenOperStart() {

    this.srvClanovi.napraviFrmOdjava((rez) => {

      this.srvClanovi.minDate = moment(this.srvClanovi.frmOdjava.controls['vrijemePrijava'].value).format('YYYY-MM-DD');

      this.srvClanovi.frmOdjava.controls['vrijemePrijava'].setValue(moment(this.mod.session.vrijemePrijava));
      this.srvClanovi.frmOdjava.controls['odjDat'].setValue(moment(moment().format('YYYY-MM-DD')));
      this.srvClanovi.frmOdjava.controls['odjH'].setValue(moment().format('HH'));
      this.srvClanovi.frmOdjava.controls['odjMin'].setValue(moment().format('mm'));

      document.getElementById('btnOdjavaTrenOper').click();

    });

    /* this.mod.objPosJedSelected = null;
    localStorage.removeItem('objPosJedSelected');
    this.router.navigate(['cpanel1']); */
  }

  //Modal Novi zahtjev za povezivanje
  noviZahtjevStart() {

    /* this.srvClanovi.lstPoslovniKorisnici((rez: PoslovniKorisnik[]) => {

      if (rez) {

        this.srvClanovi.objPoslovniKor = rez;
        this.srvClanovi.fltPoslovniKor = null;

        let obj0: ZahtjevClanFitnet = {
          vfcID: 0,
          klijentIDvfc: 0,
          naziv: null,
          korIDvfc: this.mod.objKor.korisnikID, 
          clanIDvfc: 0, 
          statusVfc: 0, 
          DatumVfc: null
        }

        this.srvClanovi.napraviFrmZahtjevClFit(obj0, () => {
          document.getElementById('btnMdlZahtjevClFit').click();
        });

      } else {
        this.srvClanovi.objPoslovniKor = null;
        this.srvClanovi.fltPoslovniKor = null;
        this.mod.msgBox(2, "Greška prilikom učitavanja fitness centara!", 3000);
      }

    }); */

    this.router.navigate(['/zahtjevi-zp']);

  }

  //Boja članarine
  clanColor(x: number) {

    x = Number(x);

    if (x > 5) {
      return '#27ad77'; //zelena
    } else if (x <= 5 && x > 3) {
      return '#ff6000'; //narančasta
    } else if (x <= 3 && x > 0) {
      return 'red'; //crvena
    } else if (x <= 0) {
      return '#b1aeaa'; //siva
    }

  }

}
