import { Component, OnDestroy, OnInit, ViewChild, input } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { ClanoviService } from '../clanovi.service';
import { Grupa, GrupeService } from '../grupe.service';
import { ApiRez, ModulService, PosJedinice } from '../modul.service';
import { ObavijestiSidebarComponent } from '../obavijesti-sidebar/obavijesti-sidebar.component';
import { FrmFilter, Obavijest, ObavijestiService, Primatelj, VobavRez } from '../obavijesti.service';

@Component({
  selector: 'app-obavijesti-panel',
  templateUrl: './obavijesti-panel.component.html',
  styleUrls: ['./obavijesti-panel.component.scss']
})
export class ObavijestiPanelComponent implements OnInit, OnDestroy {

  constructor(
    public mod: ModulService,
    public srvObavijesti: ObavijestiService,
    public srvClanovi: ClanoviService,
    public srvGrupe: GrupeService
  ) { }

  //Timer 2 sec
  s: Subscription;
  t = timer(0, 1000);

  ObavijestID: number; //Odabrana obavijest
  obvDelID: number; //Odabrana obavijest za brisanje

  objVobavRezSel: VobavRez;

  intChkAll: number = 0;

  ngOnInit() {

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      if (this.mod.objKor) {
        tok = this.mod.objKor.token;
      } else {
        this.mod.quickClean();
      }
    }

    this.srvObavijesti.objVobavRez = null;
    this.srvObavijesti.objSent = null;

    this.mod.provjeraTokena(tok, () => {

      //Provjera prijave trener / Operater
      this.mod.provjeraPrijaveTrenOper((rez) => {

        //Učitavanje poslovnih jedinica
        this.mod.ucitajPosJed(() => {

          if (this.mod.objKor.vrstaID == 1) {
            this.srvObavijesti.intVrsta = 1;
          } else {
            this.srvObavijesti.intVrsta = 2;
          }

          this.srvObavijesti.napraviFrmFilter(null, (rez: FrmFilter) => {

            if (this.srvObavijesti.intVrsta == 1) {

              if (this.mod.session) {
                this.srvObavijesti.neprocitaneByClanID(this.mod.session.KoriniskIDtren, (rez) => {
                  this.srvObavijesti.objVobavRez = rez;
                  this.srvObavijesti.fltVobavRez = rez;
                  this.srvObavijesti.fnInfo();
                });
              } else {
                this.srvObavijesti.neprocitaneByKorIdPrivatni(this.mod.objKor.korisnikID, (rez) => {
                  this.srvObavijesti.objVobavRez = rez;
                  this.srvObavijesti.fltVobavRez = rez;
                  this.srvObavijesti.fnInfo();
                });
              }

            } else {
              this.srvObavijesti.ucitajPodatke(this.srvObavijesti.frmFilter.value, () => { });
            }
            /* this.subscribeTimer(); */
          });

        });
      });

    });

  }


  //Subscribe timer
  subscribeTimer() {
    /* this.s = this.t.subscribe(t => {
      this.srvObavijesti.ucitajPodatke(this.srvObavijesti.frmFilter.value, () => { });
    }); */
  }

  //Odabir obavijesti
  obvSelect(x) {

    console.log(x);

    this.obvDelID = 0; this.ObavijestID && this.ObavijestID == x.ObavijestID ? this.ObavijestID = 0 : this.ObavijestID = x.ObavijestID

    this.objVobavRezSel = x;

    document.getElementById('btnMdlObvSelKl').click();

  }

  //Označavanje uključi / isključi
  chkOznacavanjeClick() {
    this.srvObavijesti.bolOznacavanje = !this.srvObavijesti.bolOznacavanje;
    if (!this.srvObavijesti.bolOznacavanje) {
      this.intChkAll = 0;
      this.chkAllClick(0);
    }
    this.countOznacenih();
  }

  //Označi / Odznači sve
  chkAllClick(intChkAll: number) {

    if (this.intChkAll == intChkAll) {
      this.intChkAll = 0;
    } else {
      this.intChkAll = intChkAll;
    }

    //ponišravanje prijašnjeg označavanja
    this.srvObavijesti.fltVobavRez?.forEach(val => {
      val.chk = false;
    });

    if (this.srvObavijesti.intVrsta == 1) {

      if (this.intChkAll == 0) {

        //Odznači sve
        this.srvObavijesti.fltVobavRez?.forEach(val => {
          val.chk = false;
        });
        this.countOznacenih();

      } else {

        //Označi sve
        if (this.intChkAll == 1) {

          this.srvObavijesti.fltVobavRez?.forEach(val => {
            val.chk = true;
          });
          this.countOznacenih();

          //Označi pročitane
        } else if (this.intChkAll == 3) {

          this.srvObavijesti.fltVobavRez?.forEach(val => {
            if (val.vocFake == 3) {
              val.chk = true;
            }
          });
          this.countOznacenih();

          //Označi nepročitane
        } else if (this.intChkAll == 2) {

          this.srvObavijesti.fltVobavRez?.forEach(val => {
            if (val.vocFake == 2) {
              val.chk = true;
            }
          });
          this.countOznacenih();

        }

      }

    } else if (this.srvObavijesti.intVrsta == 2) {

      this.srvObavijesti.fltSent?.forEach(val => {
        if (this.intChkAll == 0) {
          val.chk = false;
        } else {
          val.chk = true;
        }
      });

      this.countOznacenihSent();

    }

  }


  countOznacenih() {
    let oznTmp: number = 0;
    this.srvObavijesti.fltVobavRez?.forEach(val => {
      if (val.chk) {
        oznTmp += 1;
      }
    });
    this.srvObavijesti.oznCount = oznTmp;
  }

  countOznacenihSent() {
    let oznTmp: number = 0;
    this.srvObavijesti.fltSent?.forEach(val => {
      if (val.chk) {
        oznTmp += 1;
      }
    });
    this.srvObavijesti.oznCount = oznTmp;
  }

  //Modal primatelji
  mdlPrimatelji(x) {

    this.srvObavijesti.primateljiByObId(x.ObavijestID, (rez: Primatelj[]) => {
      this.srvObavijesti.objPrimatelji = rez;
    });

    document.getElementById('btnMdlPrimatelji').click();
  }


  //Click - obavijest menu
  obvMenuClick(x: VobavRez) {
    this.objVobavRezSel = x;
  }

  //Modal obavijest
  modalObavijest(x: VobavRez, i: number) {
    this.objVobavRezSel = x;

    console.log(x);

    this.srvObavijesti.oznProcitano(x.vocID, false, (rez: string) => {

      /* this.s.unsubscribe(); */

      if (rez == 'ok') {

        //Update glavnog objekta
        this.srvObavijesti.objVobavRez?.forEach((val, ind) => {
          if (val.vocID == x.vocID) {
            this.srvObavijesti.objVobavRez[ind].vocStatus = 1;
            this.srvObavijesti.objVobavRez[ind].vocFake = 3;
          }
        });

        //Update flt objekta
        this.srvObavijesti.fltVobavRez?.forEach((val, ind) => {
          if (val.vocID == x.vocID) {
            this.srvObavijesti.fltVobavRez[ind].vocStatus = 1;
            this.srvObavijesti.fltVobavRez[ind].vocFake = 3;
          }
        });

        this.srvObavijesti.fnInfo();

        document.getElementById('btnMdlObvSel').click();
      }

      /* this.subscribeTimer(); */

    })

  }

  //Označi kao nepročitano
  oznNeprocitano(x: VobavRez, i: number) {
    /* this.s.unsubscribe(); */
    this.srvObavijesti.oznNeprocitano(x.vocID, true, (rez: string) => {
      if (rez == 'ok') {
        this.srvObavijesti.objVobavRez[i].vocFake = 2;
        this.srvObavijesti.fltVobavRez.forEach((v1, i1) => {
          if (v1.vocID == x.vocID) {
            this.srvObavijesti.fltVobavRez[i1].vocFake = 2;
          }
        });
        this.srvObavijesti.fnInfo();
        /* this.subscribeTimer(); */
      }
    });
  }

  //Označi kao pročitano
  oznProcitano(x: VobavRez, i: number) {
    /* this.s.unsubscribe(); */
    this.srvObavijesti.oznProcitano(x.vocID, true, (rez: string) => {
      if (rez == 'ok') {
        this.srvObavijesti.objVobavRez[i].vocFake = 3;
        this.srvObavijesti.fltVobavRez.forEach((v1, i1) => {
          if (v1.vocID == x.vocID) {
            this.srvObavijesti.fltVobavRez[i1].vocFake = 3;
          }
        });
        this.srvObavijesti.fnInfo();
        /* this.subscribeTimer(); */
      }
    });
  }

  //Ažuriranje voc Fake statusa (2 = fake nepročitano, 3 = fake pročitano)
  azurirajVocFake(vocFake: number) {

    if (this.srvObavijesti.oznCount) {

      this.napraviArrVocFake((objArr: number[]) => {

        this.srvObavijesti.azurirajVocFake(objArr, vocFake, (rez: string) => {

          console.log(rez);

          if (rez == 'ok') {

            this.srvObavijesti.bolManual = true;

            this.srvObavijesti.ucitajPodatke(this.srvObavijesti.frmFilter.value, () => {

              this.srvObavijesti.bolManual = false;

              this.srvObavijesti.fltVobavRez?.forEach(val => {
                objArr?.forEach(val2 => {
                  if (val2 == val.vocID) {
                    val.chk = true;
                  }
                });
              });

            });

            this.mod.msgBox(1, "Ažurirano!", 500);

          } else {
            this.mod.msgBox(2, "Greška!", 700);
          }

        });

      });

    }

  }

  napraviArrVocFake(callback) {
    let objArr: number[] = [];
    this.srvObavijesti.fltVobavRez?.forEach(val => {
      if (val.chk) {
        objArr.push(val.vocID);
      }
    });
    callback(objArr);
  }

  //Brisanje obavijesti (primatelj)
  obrisiObavijest(vocID: number) {

    this.srvObavijesti.obrisiObavijest(vocID, (rez: ApiRez) => {

      console.log(rez);

      if (rez && rez.rez == 'ok') {

        //objekt - obavijesti
        this.srvObavijesti.objVobavRez.forEach((v, i) => {
          if (v.vocID == vocID) {
            this.srvObavijesti.objVobavRez.splice(i, 1);
          }
        });

        //objekt - flt obavijesti
        this.srvObavijesti.fltVobavRez.forEach((v1, i1) => {
          if (v1.vocID == vocID) {
            this.srvObavijesti.fltVobavRez.splice(i1, 1);
          }
        });


        this.mod.msgBox(1, "OBRISANO!", 800);

      } else {
        this.mod.msgBox(2, "Greška!", 800);
      }

    });

  }

  //Brisanje označenih obavijesti
  obrisiOznaceno() {

    if (this.srvObavijesti.intVrsta == 1) {

      let obj0: number[] = [];

      this.srvObavijesti.fltVobavRez.forEach((v, i) => {
        if (v.chk) {
          obj0.push(v.vocID);
        }
      });

      console.log(obj0);

      if (obj0.length > 0) {

        this.srvObavijesti.obrisiOznaceno(obj0, (rez: ApiRez) => {

          console.log(rez);

          if (rez && rez.rez == 'ok') {

            this.mod.msgBox(1, "OBRISANO!", 800);

            /* obj0.forEach((v1) => {
              //Glavni objekt
              this.srvObavijesti.objVobavRez.forEach((v2, i2) => {
                if (v2.vocID = v1) {
                  this.srvObavijesti.objVobavRez.splice(i2, 1);
                }
              });
              //flt objekt
              this.srvObavijesti.fltVobavRez.forEach((v3, i3) => {
                if (v3.vocID = v1) {
                  this.srvObavijesti.fltVobavRez.splice(i3, 1);
                }
              });
            }); */

            this.srvObavijesti.prikazi();


          } else {
            this.mod.msgBox(2, "Greška!", 900);
          }

        });

      } else {
        this.mod.msgBox(2, "Nema odabranih stavki!", 1000);
      }



    }

  }

  ngOnDestroy() {
    if (this.s) {
      this.s.unsubscribe();
    }
    this.srvObavijesti.objVobavRez = null;
    this.srvObavijesti.fltVobavRez = null;
    this.srvObavijesti.strPretragaVobavRez = '';
    this.srvObavijesti.bolOznacavanje = false;
    this.srvObavijesti.oznCount = 0;
    document.getElementById('btnSidebarClose').click();
    document.getElementById('btnMdlNovaObavijestClose').click();
    if (this.objVobavRezSel) {
      document.getElementById('btnObSelClose').click();
    }
    document.getElementById('btnMdlNovaPozivnicaClose').click();
  }


}
