import { Component, OnInit } from '@angular/core';
import { ClGrpUnos, Grupa, GrupeService } from '../grupe.service';
import { ModulService } from '../modul.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { ClanoviService } from '../clanovi.service';


@Component({
  selector: 'app-clan-grupa-unos',
  templateUrl: './clan-grupa-unos.component.html',
  styleUrl: './clan-grupa-unos.component.scss'
})
export class ClanGrupaUnosComponent implements OnInit {

  constructor (
    public mod: ModulService,
    public srvGrupe: GrupeService,
    public srvClanovi: ClanoviService
  ) {}


  ngOnInit(): void {
    
    this.srvGrupe.napraviFrmClGrpUnos(null, (rez: ClGrpUnos) => {

      this.srvGrupe.frmClanGrupaUnos.controls['ClanIDvcg'].setValue(this.srvClanovi.clanID);


    });

  }

}
