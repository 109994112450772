import { Component, OnInit } from '@angular/core';
import { ModulService } from '../modul.service';
import { ClanoviService, FrmPreglPosjetaClan, PregledPosjetaClan } from '../clanovi.service';
import * as moment from 'moment';

@Component({
  selector: 'app-pregled-posjeta-sidebar',
  templateUrl: './pregled-posjeta-sidebar.component.html',
  styleUrl: './pregled-posjeta-sidebar.component.scss'
})
export class PregledPosjetaSidebarComponent implements OnInit {

  constructor(
    public mod: ModulService,
    public srvClanovi: ClanoviService
  ){}

  objFlt: FrmPreglPosjetaClan;

  ngOnInit(): void {
    
    this.objFlt = {
      clanID: this.srvClanovi.clanID,
      datOd: moment(),
      datDo: moment(),
      fingerprint: this.mod.fingerprint()
    }

    this.srvClanovi.napraviFrmPreglPosjetaClan(this.objFlt, (rez) => {

    });

  }

  //Pregled posjeta by Član
  pregledPosjetaByClan() {

    this.srvClanovi.pregledPosjetaByClan(this.srvClanovi.frmPreglPosjetaClan.value, (rez) => {

      document.getElementById('btnSidebarClose').click();

      if (rez && rez.rez == 'ok') {
        this.srvClanovi.objPregldPosjetaClan = rez.objPregldPosjetaClan;
        this.srvClanovi.rekapitulacija = rez.rekapitulacija;

        this.srvClanovi.rekapSum = {count: 0, vrijeme: 0};
        if (this.srvClanovi.rekapitulacija?.length > 1) {
          this.srvClanovi.rekapitulacija.forEach(val => {
            this.srvClanovi.rekapSum.count += Number(val.count);
            this.srvClanovi.rekapSum.vrijeme += Number(val.vrijeme);
          });
        }

      } else {
        this.srvClanovi.objPregldPosjetaClan = null;
        this.srvClanovi.rekapitulacija = null;
      }
      
    });

  }

  //Pregled posjeta by fitNet ID
  pregledPosjetaByFitNEtID() {
    
  }

}
