<div class="container" style="padding: 0; padding-top: 50px;">

    <div class="row">

        <!--Lijeva kolona-->
        <div class="d-none d-md-block col col-4" style="padding: 0;">
            <div class="card" style="height: calc(100vh - 52px); opacity: 0.9;">
                <div style="padding: 15px 5px;">
                    <app-stat-klijent-sidebar></app-stat-klijent-sidebar>
                </div>

            </div>
        </div>

        <!--Desna kolona-->
        <div class="col col-md-8" style="padding: 0;">
            <button type="button" class="d-block d-md-none btn" style="position: absolute; z-index: 200; background-color: transparent; padding: 2px; margin-left: 10px; margin-top: 5px;" data-toggle="modal" data-target="#mdlSidebar"><i class="material-icons" style="font-size: 3rem;">menu</i></button>
            <div class="card" style="height: calc(100vh - 52px); opacity: 0.9;">
                <div *ngIf="srvStatKlijent.lcPosjete" style="overflow-x: auto; overflow-y: hidden;">
                    <google-chart (select)="datumSelect($event)" [type]="srvStatKlijent.lcPosjete.type" [data]="srvStatKlijent.lcPosjete.data" [options]="srvStatKlijent.lcPosjete.options" [width]="srvStatKlijent.lcPosjete.width" [height]="srvStatKlijent.lcPosjete.height">

                    </google-chart>

                </div>
                <div style="height: 20px; margin-top: -10px; background-color: white;"></div>
            </div>
        </div>

    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="mdlSidebar" tabindex="-1" role="dialog" aria-labelledby="mdlSidebar" aria-hidden="true">
    <div class="modal-dialog" role="document" style="font-size: 1.2rem;">
        <div class="modal-content">

            <div class="modal-heade d-flex justify-content-center">
                Pregled posjeta
            </div>

            <div class="modal-body posjed-mod-body">

                <app-stat-klijent-sidebar></app-stat-klijent-sidebar>

            </div>
            <div class="modal-footer bgs-dark" style="padding: 5px;">
                <button type="button" id="btnSidebarClose" class="btn mybtn-cancel" data-dismiss="modal" style="width: 100%;">Zatvori</button>
            </div>
        </div>
    </div>
</div>