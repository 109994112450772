import { Injectable } from '@angular/core';
import { Kor, MjestaRH, ModulService } from './modul.service';
import { HttpClient } from '@angular/common/http';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MjestaService } from './mjesta.service';
import { MustMatch } from './validatori';

export interface KorEdit {
  korisnikID: number,
  Ime: string,
  Prezime: string,
  Adresa: string,
  Kucni_br: string,
  MjestoID: number,
  Mjesto: string,
  OIB: string
}

export interface FrmPromjenaLozinke {
  korisnikID: number,
  stara: string,
  nova: string,
  nova2: string,
  fingerprint: string
}

@Injectable({
  providedIn: 'root'
})
export class KorisniciService {

  constructor(
    private mod: ModulService,
    private http: HttpClient,
    public frmBuilder: FormBuilder,
    private srvMjesta: MjestaService
  ) { }


  frmKorEdit: FormGroup
  fltMjesta2: MjestaRH[];

  //napravi frm Kor Edit
  napraviFrmKorEdit(val: Kor, callback) {

    this.frmKorEdit = this.frmBuilder.group({
      korisnikID: [0],
      Ime: [, Validators.required],
      Prezime: [],
      Adresa: [],
      Kucni_br: [],
      MjestoID: [0],
      Mjesto: [],
      OIB: []
    });

    if (val) {
      this.frmKorEdit.patchValue(val);
    }

    callback(this.frmKorEdit.value);

  }

  //FILTRIRANJE MJESTA  (posl.jed)
  filtrirajMjestaRH2(strP: string) {
    this.fltMjesta2 = this.srvMjesta.objMjestaRH.filter(s => s.Mjesto.toLowerCase().includes(strP.toLowerCase()));
  }

  //SET MjestoID (mod.objKor.MjestoID)
  postaviMjestoID(mjestoID: number) {
    this.frmKorEdit.patchValue({ MjestoID: mjestoID });
  }

  //Update - opći podaci
  spremiOpce(val: KorEdit, callback) {

    let url: string = this.mod.webUrl + '/php/korisnici.php?token=' + this.mod.objKor.token + '&funk=spremiOpce';
    val['fingerprint'] = this.mod.fingerprint();
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez: string) => {
      callback(rez);
    });

  }

  //Frm Promejna lozinke
  frmPromjenaLozinke: FormGroup;
  //napravi frmPromjenaLozinke
  napraviFrmPromjenaLozinke() {

    this.frmPromjenaLozinke = this.frmBuilder.group({
      korisnikID: [this.mod.objKor.korisnikID],
      stara: [, [Validators.required]],
      nova: [, [Validators.required, Validators.minLength(6)]],
      nova2: [, [Validators.required, Validators.minLength(6)]],
      fingerprint: [this.mod.fingerprint()]
    }, {
      validator: MustMatch('nova', 'nova2')
    } as AbstractControlOptions);

  }

  //promjena lozinke
  promijeniLozinku(val: FrmPromjenaLozinke, callback) {
    
    let url: string = this.mod.webUrl + '/php/korisnici.php?token=' + this.mod.objKor.token + '&funk=promijeniLozinku';
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez: string) => {
      callback(rez);
    });

  }

}
