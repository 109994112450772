import { Component } from '@angular/core';
import { ModulService } from '../modul.service';

@Component({
  selector: 'app-postavke-sidebar',
  templateUrl: './postavke-sidebar.component.html',
  styleUrl: './postavke-sidebar.component.scss'
})
export class PostavkeSidebarComponent {

  constructor(
    public mod: ModulService
  ){}

}
