<div *ngIf="!srvClanovi.bolBrisanje" class="d-flex">
    <button type="button" class="btn mybtn-delete ml-auto" (click)="srvClanovi.bolBrisanje = !srvClanovi.bolBrisanje"><i
            class="fa fa-trash-o" aria-hidden="true" style="margin-right: 10px;"></i>Obriši posjetu</button>
</div>

<form *ngIf="srvClanovi.frmPosjetaEdit && !srvClanovi.bolBrisanje" [formGroup]="srvClanovi.frmPosjetaEdit">

    <div style="padding: 5px; font-size: 1rem;">
        Prijava
    </div>

    <div class="row">

        <div class="col col-6" style="padding: 2px;">
            <mat-form-field style="text-align: center; width: 100%;">
                <mat-label>Datum</mat-label>
                <input matInput name="dat1" formControlName="dOd" style="text-align: center;" [matDatepicker]="picker1"
                    placeholder="Datum">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker1></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col col-3" style="padding: 2px;">
            <mat-form-field style="text-align: center; width: 100%;">
                <mat-label>Sati</mat-label>
                <input matInput type="tel" formControlName="hOd" placeholder="Sati" style="text-align: center;"
                    required>
            </mat-form-field>
        </div>

        <div class="col col-3" style="padding: 2px;">
            <mat-form-field style="text-align: center; width: 100%;">
                <mat-label>Min.</mat-label>
                <input matInput type="tel" formControlName="mOd" placeholder="Min." style="text-align: center;"
                    required>
            </mat-form-field>
        </div>

    </div>

    <div *ngIf="srvClanovi.frmPosjetaEdit.get('dDo').value._d != 'Invalid Date'" style="padding: 5px; font-size: 1rem;">
        Odjava
    </div>

    <div *ngIf="srvClanovi.frmPosjetaEdit.get('dDo').value._d != 'Invalid Date'" class="row">

        <div class="col col-6" style="padding: 2px;">
            <mat-form-field style="text-align: center; width: 100%;">
                <mat-label>Datum</mat-label>
                <input matInput name="dat2" formControlName="dDo" style="text-align: center;" [matDatepicker]="picker2"
                    placeholder="Datum">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker2></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col col-3" style="padding: 2px;">
            <mat-form-field style="text-align: center; width: 100%;">
                <mat-label>Sati</mat-label>
                <input matInput type="tel" formControlName="hDo" placeholder="Sati" style="text-align: center;"
                    required>
            </mat-form-field>
        </div>

        <div class="col col-3" style="padding: 2px;">
            <mat-form-field style="text-align: center; width: 100%;">
                <mat-label>Min.</mat-label>
                <input matInput type="tel" formControlName="mDo" placeholder="Min." style="text-align: center;"
                    required>
            </mat-form-field>
        </div>

    </div>

    <div *ngIf="this.srvClanovi.objDvorane && srvClanovi.frmPosjetaEdit.get('GrupaIDtren').value == 0"
        style="margin: 10px 0;">
        <mat-form-field style="width: 100%;">
            <mat-label>Dvorana</mat-label>
            <mat-select formControlName="dvoID">
                <mat-option [value]="'0'">-</mat-option>
                <mat-option *ngFor="let x of this.srvClanovi.objDvorane" [value]="x.dvoID">
                    {{x.opisDvo}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="srvClanovi.frmPosjetaEdit.get('dDo').value._d != 'Invalid Date'"
        style="padding: 5px; font-size: 1rem; text-align: center;">
        trajanje: {{trajanjePosjeta()}}
    </div>


</form>

<div *ngIf="srvClanovi.bolBrisanje">

    <div class="alert-alert-warning" style="text-align: center;">
        Želite li trajno obrisati unos iz evidencije?
    </div>

    <div class="row">
        <div class="col" style="padding: 5px;">
            <button type="button" class="btn mybtn-danger w-100">POTVRDI BRISANJE</button>
        </div>
        <div class="col" style="padding: 5px;">
            <button type="button" class="btn mybtn-cancel w-100" (click)="otkaziBrisanje()">OTKAŽI</button>
        </div>
    </div>

</div>