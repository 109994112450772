import { Component, OnInit } from '@angular/core';
import { Kor, ModulService } from '../modul.service';
import { ClanarinaClan, ClanarineClanService, FrmFilter } from '../clanarine-clan.service';

@Component({
  selector: 'app-clanarine-clan-sidebar',
  templateUrl: './clanarine-clan-sidebar.component.html',
  styleUrl: './clanarine-clan-sidebar.component.scss'
})
export class ClanarineClanSidebarComponent implements OnInit {

  constructor(
    public mod: ModulService,
    public srvClanClan: ClanarineClanService
  ) { }

  ngOnInit(): void {

    

  }

  //Prikaži
  prikazi() {

    document.getElementById('btnSidebarClose').click();

    let rez: FrmFilter = this.srvClanClan.frmFilter.value;
    this.srvClanClan.clanarineByFilter(rez, (rez1: ClanarinaClan[]) => {
      console.log(rez1);
      this.srvClanClan.objClanarine = rez1;
    });
  }

}
