import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModulService } from './modul.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class UploadService {

  constructor(
    public http: HttpClient,
    public mod: ModulService
  ) { }

  //SERVER_URL: string = "http://sharprobotics.synology.me/fitNet/php";
  SERVER_URL: string = this.mod.webUrl + '/php';

  public uploadFile(data) {
    let uploadURL = `${this.SERVER_URL}/upload.php?korID=` + this.mod.objKor.korisnikID;
    return this.http.post<any>(uploadURL, data);
  }

}
