import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Moment } from 'moment';
import { Clan, ClanoviService } from './clanovi.service';
import { Grupa, GrupeService } from './grupe.service';
import { ApiRez, ModulService, PosJedinice } from './modul.service';
import { FrmSend } from './validatori';
import * as moment from 'moment';

export interface Obavijest {
  ObavijestID: number,
  KorClanID: number,
  korVrstaID: number,
  Naslov: string,
  TekstObv: string,
  VrijemeObv: Moment,
  del: boolean,
  chk: boolean
}

export interface VezObavClan {
  vocID: number,
  vocObavID: number,
  vocClanID: number,
  vocDel: number,
  vocStatus: number,
  vocFake: number
}

export interface Vobavijest extends Obavijest, VezObavClan {

}

export interface VobavRez {
  vocID: number,
  vocStatus: number,
  vocFake: number,
  VrijemeObv: Moment,
  Naslov: string,
  TekstObv: string,
  Ime: string,
  Prezime: string,
  Naziv: string,
  chk: boolean
}

//Primatelji interface
export interface Primatelj extends Clan, VezObavClan {
}

//Info helper
interface Info {
  neprocitano: number,
  ukupno: number
}

//frm Filter
export interface FrmFilter {
  datOd: Moment,
  datDo: Moment
}

@Injectable({
  providedIn: 'root'
})
export class ObavijestiService {

  constructor(
    private frmBuilder: FormBuilder,
    public mod: ModulService,
    private srvClanovi: ClanoviService,
    private srvGrupe: GrupeService,
    private http: HttpClient
  ) { }

  objPosJed: PosJedinice[];
  objGrupe: Grupa[];
  objClanovi: Clan[];

  intVrsta: number; // 1 = primljene obavijesti, 2 = poslane obavijesti, 3 = primljene pozivnice, 4 = poslane pozivnice
  objVobavRez: VobavRez[];
  fltVobavRez: VobavRez[];
  strPretragaVobavRez: string = '';
  objSent: Obavijest[];
  fltSent: Obavijest[];

  frmSend: FormGroup;

  bolOznacavanje: boolean = false;
  info: Info;
  infoSent: number;
  frmFilter: FormGroup;
  oznCount: number = 0;

  //Učitavanje
  bolManual: boolean = true;
  ucitajPodatke(objFilter: FrmFilter, callback) {

    if (this.mod.objKor) { //&& (!this.bolOznacavanje || this.bolManual) - DEPRECATED

      if (this.mod.objKor.vrstaID == 1) {

        if (this.mod.session) {

          //Primljene by Član ID
          if (this.intVrsta == 1) {
            this.fillByClanID(objFilter, this.mod.session.KoriniskIDtren, (rez: VobavRez[]) => {
              if (JSON.stringify(rez) != JSON.stringify(this.objVobavRez)) {
                this.objVobavRez = rez;
                console.log(rez);
                this.filtrirajVobavRez(this.strPretragaVobavRez);
                this.fnInfo();
                callback(rez);
              }
            });
          } else {
            this.objVobavRez = null;
            this.fltVobavRez = null;
          }


          //Poslane by Član ID (treneri i operateri)
          if (this.intVrsta == 2) {
            this.fillByClanIDsent(objFilter, this.mod.session.KoriniskIDtren, (rez: Obavijest[]) => {
              if (JSON.stringify(rez) != JSON.stringify(this.objSent)) {
                this.objSent = rez;
                this.filtrirajSent(this.strPretragaVobavRez);
                this.fnInfoSent();
                callback(rez);
              }
            });
          } else {
            this.objSent = null;
          }



        } else {

          //Primljene by fitNet ID
          if (this.intVrsta == 1) {
            this.fillByKorIdPrivatni(objFilter, this.mod.objKor.korisnikID, (rez: VobavRez[]) => {
              if (JSON.stringify(rez) != JSON.stringify(this.objVobavRez)) {
                this.objVobavRez = rez;
                this.filtrirajVobavRez(this.strPretragaVobavRez);
                this.fnInfo();
                callback(rez);
                console.log(this.objVobavRez);
              }
            });
          } else {
            this.objVobavRez = null;
            this.fltVobavRez = null;
          }


          //Poslane by korisnik ID - privatni i poslovni
          if (this.intVrsta == 2) {
            this.fillByKorIdPrivSent(objFilter, this.mod.objKor.korisnikID, (rez: Obavijest[]) => {
              if (JSON.stringify(rez) != JSON.stringify(this.objSent)) {
                this.objSent = rez;
                this.filtrirajSent(this.strPretragaVobavRez);
                this.fnInfoSent();
                callback(rez);
              }
            });
          } else {
            this.objSent = null;
          }

        }

      } else {

        //Poslane by korisnik ID - privatni i poslovni
        this.fillByKorIdPoslSent(objFilter, this.mod.objKor.korisnikID, (rez: Obavijest[]) => {
          this.objVobavRez = null;
          this.fltVobavRez = null;
          if (JSON.stringify(rez) != JSON.stringify(this.objSent)) {
            this.objSent = rez;
            this.filtrirajSent(this.strPretragaVobavRez);
            this.fnInfoSent();
            callback(rez);
          }
        });

      }

      this.fnInfo();
      this.fnInfoSent();

    }

  }

  //Info
  fnInfo() {

    if (this.fltVobavRez) {

      let neprocitano: number = 0;
      let ukupno: number = 0;

      this.fltVobavRez?.forEach(val => {
        ukupno += 1;
        if (val.vocFake == 2) {
          neprocitano += 1;
        }
      })

      this.info = { ukupno: ukupno, neprocitano: neprocitano }

    } else {
      this.info = null; // { ukupno: 0, neprocitano: 0 }
    }

  }

  fnInfoSent() {

    if (this.fltSent) {
      this.infoSent = 0;
      this.fltSent.forEach(val => {
        this.infoSent += 1;
      })
    } else {
      this.infoSent = 0;
    }

  }

  //Nova obavijest
  novaObavijest() {

    if (this.mod.session) {

      let klijentID: number = this.mod.session.KlijentIDtren;
      let ClanIDcl: number = this.mod.session.KoriniskIDtren;
      let vrstaID: number = this.mod.session.clanVrstaIDtren;

      this.srvClanovi.posjedByTrenOper(klijentID, ClanIDcl, vrstaID, (rez2: PosJedinice[]) => {
        this.objPosJed = rez2;
      });

    } else {
      this.objPosJed = this.mod.objPosJed;
    }

    //Učitavanje grupa
    this.srvGrupe.fillByKorisnikID((rez: Grupa[]) => {
      this.objGrupe = rez;
    });

    //Učitavanje članova - DEPRECATED
    /* this.srvClanovi.clanoviByKorisnikID((rez: Clan[]) => {
      this.objClanovi = rez;
    }); */

    this.napraviFrmSend();

  }

  //frmSend
  frmSendStep: number;
  napraviFrmSend() {

    this.frmSendStep = 1;
    let vClansID: number = 0;
    let clanID: number = 0;

    if (this.mod.session) {
      vClansID = this.mod.session.vClansID;
      clanID = this.mod.session.KoriniskIDtren;
    } else {
      vClansID = 0;
      clanID = 0;
    }

    this.frmSend = this.frmBuilder.group({
      chkID: [1],
      posJedID: [0],
      grpID: [0],
      klijentID: [this.mod.objPosJedSelected.KlijentIDpos],
      clanID: [clanID],
      vClansID: [vClansID],
      naslov: [, [Validators.required, Validators.maxLength(200)]],
      tekst: [, [Validators.required, Validators.maxLength(750)]]
    }, { validators: FrmSend });

  }

  prikazi() {

    this.oznCount = 0;
    document.getElementById('btnSidebarClose').click();

    if (this.intVrsta == 1) {

      this.napraviFakeChkArray((objArr) => {
        /* this.bolManual = true; */
        this.ucitajPodatke(this.frmFilter.value, () => {
          this.oznCount = 0;
          /* this.bolManual = false; */
          this.fltVobavRez?.forEach(val => {
            objArr?.forEach(val2 => {
              if (val2 == val.vocID) {
                val.chk = true;
                this.oznCount += 1;
              }
            });
          });
        });
      });

    } else if (this.intVrsta == 2) {

      this.napraviFakeChkArraySent((objArr) => {
        /* this.bolManual = true; */
        this.ucitajPodatke(this.frmFilter.value, () => {
          this.oznCount = 0;
          /* this.bolManual = false; */
          this.fltSent?.forEach(val => {
            objArr?.forEach(val2 => {
              if (val2 == val.ObavijestID) {
                val.chk = true;
                this.oznCount += 1;
              }
            });
          });
        });
      });

    }

  }

  //Fake chk array Primljene poruke
  napraviFakeChkArray(callback) {
    //array za chk update
    let objArr: number[] = [];
    this.fltVobavRez?.forEach(val => {
      if (val.chk) {
        objArr.push(val.vocID);
      }
    });
    callback(objArr);
  }

  //Fake chk array Poslane poruke
  napraviFakeChkArraySent(callback) {
    //array za chk update
    let objArr: number[] = [];
    this.fltSent?.forEach(val => {
      if (val.chk) {
        objArr.push(val.ObavijestID);
      }
    });
    callback(objArr);
  }

  //Nova obavijest - send
  novaObavijestSend(frmSend, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti-send.php?token=' + this.mod.objKor.token + '&funk=novaObavijestSend';
    frmSend['fingerprint'] = this.mod.fingerprint();
    let obj: string = JSON.stringify(frmSend);

    this.http.post(url, obj).subscribe((rez) => {
      callback(rez);
    });

  }

  //frm Filter - Učitavanje datum od - do
  minDat: Moment = moment().add(-2, 'months');
  maxDat: Moment = moment();
  napraviFrmFilter(val: FrmFilter, callback) {

    this.frmFilter = this.frmBuilder.group({
      datOd: [moment()],
      datDo: [moment()]
    });

    if (val) {
      this.frmFilter.patchValue(val);
    }

    callback(this.frmFilter.value);

  }

  //fill by Član ID
  fillByClanID(objFilter: FrmFilter, clID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=fillByClanID';
    let obj: string = JSON.stringify({ objFilter: objFilter, vocClanID: clID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VobavRez[]) => {
      callback(rez);
    });

  }

  //fill by Član ID - sve nepročitane
  neprocitaneByClanID(clID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=neprocitaneByClanID';
    let obj: string = JSON.stringify({ vocClanID: clID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VobavRez[]) => {
      callback(rez);
    });

  }

  //fill by fitNet ID
  fillByKorIdPrivatni(objFilter: FrmFilter, korID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=fillByKorIdPrivatni';
    let obj: string = JSON.stringify({ objFilter: objFilter, korID: korID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VobavRez[]) => {
      callback(rez);
    });

  }

  //fill by fitNet ID - sve nepročitane
  neprocitaneByKorIdPrivatni(korID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=neprocitaneByKorIdPrivatni';
    let obj: string = JSON.stringify({ korID: korID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VobavRez[]) => {
      callback(rez);
    });

  }

  //fill by član ID - poslane (treneri i operateri)
  fillByClanIDsent(objFilter: FrmFilter, clID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=fillByClanIDsent';
    let obj: string = JSON.stringify({ objFilter: objFilter, vocClanID: clID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VobavRez[]) => {
      callback(rez);
    });

  }

  //fill by korisnik ID privatni - sent
  fillByKorIdPrivSent(objFilter: FrmFilter, korID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=fillByKorIdPrivSent';
    let obj: string = JSON.stringify({ objFilter: objFilter, korID: korID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VobavRez[]) => {
      callback(rez);
    });

  }

  //fill by korisnik ID poslovni - sent
  fillByKorIdPoslSent(objFilter: FrmFilter, korID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=fillByKorIdPoslSent';
    let obj: string = JSON.stringify({ objFilter: objFilter, korID: korID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VobavRez[]) => {
      callback(rez);
    });

  }

  //Filtriranje primljenih obavijesti
  filtrirajVobavRez(strP: string) {
    if (this.objVobavRez) {
      this.fltVobavRez = this.objVobavRez.filter(s =>
        (s.Ime?.toLowerCase().includes(strP.toLowerCase()) || s.Prezime?.toLowerCase().includes(strP.toLowerCase()) || s.Naslov?.toLowerCase().includes(strP.toLowerCase()) || s.TekstObv?.toLowerCase().includes(strP.toLowerCase()))
      );
    } else {
      this.fltVobavRez = null;
    }
    this.fnInfo();
  }

  //Filtriranje poslanih obavijesti
  filtrirajSent(strP: string) {
    if (this.objSent) {
      this.fltSent = this.objSent.filter(s =>
        (s.Naslov?.toLowerCase().includes(strP.toLowerCase()) || s.TekstObv?.toLowerCase().includes(strP.toLowerCase()))
      );
    } else {
      this.fltSent = null;
    }
    this.fnInfoSent();
  }

  //Clear pretraga primljenih obavijesti
  pretragaClear() {
    this.strPretragaVobavRez = '';
    if (this.intVrsta == 1) {
      this.fltVobavRez = this.objVobavRez;
    } else if (this.intVrsta == 2) {
      this.fltSent = this.objSent;
    }
  }

  //Primatelji by obavijest ID
  objPrimatelji: Primatelj[];
  primateljiByObId(vocObavID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=primateljiByObId';
    let obj: string = JSON.stringify({ vocObavID: vocObavID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: Primatelj[]) => {
      callback(rez);
    });

  }

  //Označi obavijest kao pročitanu
  oznProcitano(vocID: number, fake: boolean, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=oznProcitano';
    let obj: string = JSON.stringify({ vocID: vocID, fake: fake, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: string) => {
      callback(rez);
    });

  }

  //Označi obavijest kao Npročitanu
  oznNeprocitano(vocID: number, fake: boolean, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=oznNeprocitano';
    let obj: string = JSON.stringify({ vocID: vocID, fake: fake, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: string) => {
      callback(rez);
    });

  }

  //Ažuriraj statuse array
  azurirajVocFake(objArr: number[], vocFake: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=azurirajVocFake';
    let obj: string = JSON.stringify({ objArr: objArr, vocFake: vocFake, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: string) => {
      callback(rez);
    });

  }

  //Obriši obavijest (primatelj)
  obrisiObavijest(vocID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=obrisiObavijest';
    let obj: string = JSON.stringify({ vocID: vocID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: ApiRez) => {
      callback(rez);
    });

  }

  //Obriši označene obavijesti
  obrisiOznaceno(arr0: number[], callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=obrisiOznaceno';
    let obj: string = JSON.stringify({ arr0: arr0, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: ApiRez) => {
      callback(rez);
    });

  }

}
