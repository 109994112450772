<div class="container" style="padding: 0; height: 100%; opacity: 0.9;">

    <div class="row" style="height: 100%; padding-top: 50px; max-height: 100%;">

        <!-- Lijeva kolona -->
        <div class="d-none d-md-block col-md-4" style="padding: 0;">
            <div class="card" style="width: 100%; padding: 15px 2px;">
                <app-pregled-posjeta-sidebar></app-pregled-posjeta-sidebar>
            </div>
        </div>

        <!-- Desna kolona -->
        <div class="col" style="padding: 0; max-height: 100%;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">

                <div class="bgs-light">
                    <app-pos-jed-hamburger></app-pos-jed-hamburger>
                </div>

                <div class="flex-grow-1 card" style="overflow-y: scroll; scrollbar-width: none; padding-bottom: 20px;">

                    <div>

                        <div *ngFor="let x of this.srvClanovi.objPregldPosjetaClan" style="border-bottom: dashed 0.5px grey;">

                            <div style="padding: 5px;">

                                <div class="row">

                                    <div class="col" style="padding: 0;">

                                        <div class="d-flex">
                                            <div>
                                                {{x.vrijemePrijava | amDateFormat:'DD.MM.YYYY.'}}
                                            </div>
                                            <div style="margin-left: 10px; font-weight: bold;">
                                                {{x.grpOpis}}
                                            </div>
                                        </div>
            
                                        <div class="d-flex">
                                            <div style="margin-right: 5px;">
                                                {{x.vrijemePrijava | amDateFormat:'HH:mm'}}h 
                                            </div>
                                            <div>
                                                - {{x.vrijemeOdjava | amDateFormat:'HH:mm'}}h
                                            </div>
                                            <div style="margin-left: 15px;">
                                                ({{srvClanovi.trajanjePosjetaHmm(x.vrijemePrijava, x.vrijemeOdjava)}})
                                            </div>
                                        </div>

                                        <div class="d-flex" style="color: grey;">
                                            <div *ngIf="x.opisDvo" style="margin-right: 10px;">
                                                {{x.opisDvo}}
                                            </div>
                                            <div *ngFor="let y of x.treneri" style="margin-right: 10px;">
                                            {{y.Ime + ' ' + y.Prezime}}
                                            </div>
                                        </div>

                                    </div>

                                    <div *ngIf="srvClanovi.bolUredPosjeta" (click)="posjetaEditStart(x)" class="col col-2 d-flex justify-content-center align-items-center" style="padding: 0; cursor: pointer;">
                                        <i class="material-icons" style="font-size: 2.5rem;">
                                            create
                                        </i>
                                    </div>

                                </div>
    
                            </div>

                        </div>

                        <div *ngIf="!this.srvClanovi.objPregldPosjetaClan" style="padding: 2px;">
                            <div class="alert alert-success" style="text-align: center;">
                                Nema podataka za odabrano razdoblje
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header posjed-mod-header d-flex justify-content-center">
                Statistike
            </div>

            <div class="modal-body posjed-mod-body" style="padding: 5px;">

                <app-pregled-posjeta-sidebar></app-pregled-posjeta-sidebar>

            </div>
            <div class="modal-footer bgs-dark" style="padding: 5px;">
                <button type="button" id="btnSidebarClose" class="btn mybtn-cancel" data-dismiss="modal"
                    style="width: 100%; font-size: 1.2rem;">Zatvori</button>
            </div>
        </div>
    </div>
</div>


<!--Modal Posjeta Edit-->
<button hidden="true" id="btnMdlPosjetaEdit" data-toggle="modal" data-target="#mdlPosjetaEdit"></button>

<div class="modal fade" id="mdlPosjetaEdit" tabindex="-1" role="dialog" aria-labelledby="mdlPosjetaEditlabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header justify-content-center" style="font-size: 1.2rem; color: #6c1d1d;">
                <div>
                    {{!srvClanovi.bolBrisanje ? 'Uređivanje posjete' : 'Brisanje posjete'}}
                </div>
            </div>

            <div class="modal-body" style="padding: 10px 7px; font-size: 1.5rem;">

                <app-prisutni-edit></app-prisutni-edit>

            </div>

            <div class="modal-footer d-block" style="padding: 0;">
                <div *ngIf="!srvClanovi.bolBrisanje" class="row">
                    <div class="col" style="padding: 5px;">
                        <button type="button" class="btn mybtn-success w-100"
                            (click)="spremiPosjetu()">Spremi</button>
                    </div>
                    <div class="col" style="padding: 5px;">
                        <button type="button" id="btnMdlPosjetaEditClose" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Otkaži</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>