<form *ngIf="srvNapredak.frmPnMjerenje" [formGroup]="srvNapredak.frmPnMjerenje">

    <div formArrayName="stavke" *ngFor="let x of srvNapredak.stavke.controls; let i=index" style="border-bottom: solid 0.5px lightgrey;">

        <div *ngIf="x.controls.ppsAkt.value == 1" class="stavka" [ngClass]="{'stavka-sel': intFocus == i}">

            <div [formGroupName]="i" class="row input-slim">

                <div class="col col-8 mycol align-self-center">
                    <div style="font-size: 1.2rem;">
                        <div style="margin-right: 10px;" [ngClass]="{'stavka-neakt': !x.controls.pnmVrijednost.value}">
                            <div class="naslov">
                                {{x.controls.pnNaziv.value}}
                            </div>
                            
                        </div>
                        <div *ngIf="x.controls.goal" class="d-flex align-items-center" style="margin-right: 10px; color: #024a7f">
                            <i class="fa fa-flag-checkered" style="font-size: 1.2rem; margin-right: 5px;" aria-hidden="true"></i> 
                            <div style="margin-right: 10px;" class="d-flex align-items-center">
                                {{(x.controls.goal | number)}}
                            </div>
                            <div *ngIf="!x.controls.pnmVrijednost.value" class="d-flex" style="font-size: 1.2rem; color: #0b90f1;">
                                <div style="margin-right: 10px;">
                                    {{srvNapredak.calcNapredPosto(x.controls.first, x.controls.old , x.controls.goal).ukPoj + '/' + srvNapredak.calcNapredPosto(x.controls.first, x.controls.old , x.controls.goal).ukCilj}}
                                </div>
                                <div>
                                    ({{srvNapredak.calcNapredPosto(x.controls.first, x.controls.old , x.controls.goal).postotak + '%'}})
                                </div>
                            </div>
                            <div *ngIf="x.controls.pnmVrijednost.value" class="d-flex" style="font-size: 1.2rem; color: #0b90f1;">
                                <div style="margin-right: 10px;">
                                    {{srvNapredak.calcNapredPosto(x.controls.first, x.controls.pnmVrijednost.value , x.controls.goal).ukPoj + '/' + srvNapredak.calcNapredPosto(x.controls.first, x.controls.pnmVrijednost.value , x.controls.goal).ukCilj}}
                                </div>
                                <div>
                                    ({{srvNapredak.calcNapredPosto(x.controls.first, x.controls.pnmVrijednost.value , x.controls.goal).postotak + '%'}})
                                </div>
                            </div>
                        </div>
                         
                    </div>
                    <div>
                        <div *ngIf="x.controls.firstDat" class="row">
                            <div class="col col-3 mycol">
                                <div>
                                    {{x.controls.firstDat |amDateFormat: "DD.MM.'YY."}}
                                </div>
                            </div>
                            <div class="col col-4 mycol" style="text-align: center;">
                                <div style="font-weight: bold;">
                                    {{(x.controls.first | number) + ' ' + x.controls.ppsJedMj.value}}
                                </div>
                            </div>

                            <div class="col col-5 mycol" style="text-align: center;">
                                <div *ngIf="x.controls.pnmVrijednost.value && x.controls.first" class="d-flex">
                                    <div style="margin-right: 10px; color: green;">
                                        {{(srvNapredak.calcNapredak(x.controls.first, x.controls.pnmVrijednost.value).razlika > 0 ? '+' : '') + (srvNapredak.calcNapredak(x.controls.first, x.controls.pnmVrijednost.value).razlika | number)}}
                                    </div>
                                    <div>
                                        ({{(srvNapredak.calcNapredak(x.controls.first, x.controls.pnmVrijednost.value).postotak| number) + '%'}})
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="x.controls.firstDat != x.controls.oldDat">
                        <div class="row">
                            <div class="col col-3 mycol">
                                <div>
                                    {{x.controls.oldDat |amDateFormat: "DD.MM.'YY."}}
                                </div>
                            </div>
                            <div class="col col-4 mycol" style="text-align: center;">
                                <div *ngIf="x.controls.old" style="font-weight: bold;">
                                    {{(x.controls.old | number) + ' ' + x.controls.ppsJedMj.value}}
                                </div>
                            </div>

                            <div class="col col-5 mycol" style="text-align: center;">
                                <div *ngIf="x.controls.pnmVrijednost.value" class="d-flex">
                                    <div style="margin-right: 10px; color: green;">
                                        {{(srvNapredak.calcNapredak(x.controls.old, x.controls.pnmVrijednost.value).razlika > 0 ? '+' : '') + (srvNapredak.calcNapredak(x.controls.old, x.controls.pnmVrijednost.value).razlika | number)}}
                                    </div>
                                    <div>
                                        ({{(srvNapredak.calcNapredak(x.controls.old, x.controls.pnmVrijednost.value).postotak | number) + '%'}})
                                    </div>
                                    
                                </div>
                                <div *ngIf="!x.controls.pnmVrijednost.value && x.controls.first && x.controls.old" class="d-flex">
                                    <div style="margin-right: 10px; color: green;">
                                        {{(srvNapredak.calcNapredak(x.controls.first, x.controls.old).razlika > 0 ? '+' : '') + (srvNapredak.calcNapredak(x.controls.first, x.controls.old).razlika | number)}}
                                    </div>
                                    <div>
                                        ({{(srvNapredak.calcNapredak(x.controls.first, x.controls.old).postotak | number) + '%'}})
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="col col-4 d-flex align-items-center mycol">
    
                    <mat-form-field class="w-100" style="text-align: center;">
                        <mat-label>{{x.controls.ppsJedMj.value}}</mat-label>
                        <input matInput type="tel" formControlName="pnmVrijednost" ariaLabel="pnmVrijednost" (focus)="intFocus = i"
                            (blur)="srvNapredak.decFormatVr(i, $event); intFocus = null" placeholder="{{x.controls.ppsJedMj.value}}"
                            style="text-align: center;">
                    </mat-form-field>
    
                </div>
    
            </div>

        </div>

    </div>

</form>