import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ModulService, PosJedinice } from '../modul.service';
import { Grupa, GrupeService } from '../grupe.service';
import { Router } from '@angular/router';
import { KalendarService } from '../kalendar.service';
import { FormGroup } from '@angular/forms';
import { listFadeIn } from '../animacije';
import { ClanoviService } from '../clanovi.service';
import * as moment from 'moment';


@Component({
  selector: 'app-grupe',
  templateUrl: './grupe.component.html',
  styleUrls: ['./grupe.component.scss'],
  animations: [listFadeIn]
})
export class GrupeComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public mod: ModulService,
    private router: Router,
    /* public mod: ModulService, */
    public srvGrupe: GrupeService,
    public srvKalendar: KalendarService,
    public srvClanovi: ClanoviService
  ) { }

  @ViewChild('btnModalNoviCl') btnModalNoviCl: ElementRef;
  @ViewChild('btnMdlNovaGrupaClose') btnMdlNovaGrupaClose: ElementRef;
  /* @ViewChild('btnModalNovaGrupa', {static: false}) btnModalNovaGrupa: ElementRef; */

  intTab: number = 1;

  frmNovaGrupa: FormGroup;

  pjedTmp: number;
  objPosJedinice: PosJedinice[];

  objAktivne: Grupa[];
  objNeaktivne: Grupa[];
  objArhiva: Grupa[];

  objGrupaShow: Grupa[];

  ngOnInit() {

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.srvGrupe.mod.objKor.token;
    }

    this.srvGrupe.mod.provjeraTokena(tok, () => {

      /* this.srvGrupe.mod.session = null; */
      this.srvGrupe.mod.provjeraPrijaveTrenOper(() => {

        this.srvGrupe.mod.ucitajPosJed(() => {
          this.pocetnePostavke();
        });

      });

    });

  }

  //Početne postavke
  pocetnePostavke() {

    if (this.srvGrupe.mod.objPosJedSelected) {

      this.pjedTmp = this.srvGrupe.mod.objPosJedSelected.PoslJedID;

      this.srvGrupe.objGrupe = null;
  
      this.frmNovaGrupa = this.srvGrupe.fnNovaGrupa();
  
      if (this.mod.objKor.vrstaID == 2) {
        this.srvGrupe.ucitajGrupe((rez) => {
          this.srvGrupe.objGrupe = rez;
          this.razvrstajGrupe();
        });
      } else {
        this.srvGrupe.fillByKorisnikID((rez) => {
          this.srvGrupe.objGrupe = rez;
          this.razvrstajGrupe();
        })
      }
  
      this.postaviPosJedinice();

    } else if (this.mod.objKor.vrstaID == 1 && !this.mod.session) {

      //fill by terner - offline
      this.srvGrupe.grupeByTrenerOffline((rez: Grupa[]) => {
        console.log(rez);
        this.srvGrupe.objGrupe = rez;
        this.razvrstajGrupe();
      });


    }

    

  }

  //Razvrstavanje grupa po objektima (aktivne, neaktivne, arhiva)
  razvrstajGrupe() {

    this.objAktivne = null;
    this.objNeaktivne = null;
    this.objArhiva = null;
    this.objGrupaShow = null;

    if (this.srvGrupe.objGrupe) {

      this.objAktivne = [];
      this.objNeaktivne = [];
      this.objArhiva = [];

      this.srvGrupe.objGrupe.forEach(val => {

        if (val.grpAkt == 1 && (!val.grpDatumDO || moment(val.grpDatumDO) >= moment())) {
          this.objAktivne.push(val);
        }

        if (val.grpAkt == 0 && (!val.grpDatumDO || moment(val.grpDatumDO) >= moment())) {
          this.objNeaktivne.push(val);
        }

        if (moment(val.grpDatumDO) < moment()) {
          this.objArhiva.push(val);
        }

      });

      if (this.intTab == 1) {
        this.objGrupaShow = this.objAktivne;
      } else if (this.intTab == 2) {
        this.objGrupaShow = this.objNeaktivne;
      } else if (this.intTab == 3) {
        this.objGrupaShow = this.objArhiva;
      } else {
        this.objGrupaShow = null;
      }

      if (this.objGrupaShow) {
        this.objGrupaShow?.forEach((val0) => {
          val0['data'] = this.pcPopunjenost(val0);
          val0['options'] = this.pcOptions(val0, 80, 80);
        });
      }

    }

  }

  pcPopunjenost(x) {

    let title = 'Trenutna popunjenost';
    let type = 'PieChart';
    let columnNames = ['Kapacitet', 'Popunjenost'];

    let popunjeno: number;
    let prazno: number;

    if (x.clanova == 0) {
      popunjeno = 0;
      prazno = 100;
    } else {
      popunjeno = Math.round(x.clanova / x.maxCl * 100);
      if (popunjeno > 100) {
        popunjeno = 100;
      }
      prazno = 100 - popunjeno;
    }

    let color: string;

    if (popunjeno == 0) {
      color = 'grey'
    } else if (popunjeno < 70 && popunjeno > 0) {
      color = 'rgb(159, 224, 191)';
    } else if (popunjeno >= 70 && popunjeno < 90) {
      color = 'rgb(255, 166, 112)';
    } else if (popunjeno >= 90) {
      color = 'rgb(255, 91, 91)';
    }

    let options = {
      colors: [color, 'ghostwhite', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
      is3D: true,
      legend: 'none',
      chartArea: { width: '100%', height: '100%' },
      backgroundColor: 'transparent',
      width: 200,
      height: 200
    };

    let data = [
      ['Popunjeno', popunjeno],
      ['Prazno', prazno]
    ];

    let pch = {
      title: title,
      type: type,
      columnNames: columnNames,
      data: data,
      options: options
    };

    return data;

  }

  pcOptions(x, width: number, height: number) {

    let popunjeno: number;
    let prazno: number;

    if (x.clanova == 0) {
      popunjeno = 0;
      prazno = 100;
    } else {
      popunjeno = Math.round(x.clanova / x.maxCl * 100);
      if (popunjeno > 100) {
        popunjeno = 100;
      }
      prazno = 100 - popunjeno;
    }

    let color: string;

    if (popunjeno == 0) {
      color = 'grey'
    } else if (popunjeno < 70 && popunjeno > 0) {
      color = 'rgb(159, 224, 191)';
    } else if (popunjeno >= 70 && popunjeno < 90) {
      color = 'rgb(255, 166, 112)';
    } else if (popunjeno >= 90) {
      color = 'rgb(255, 91, 91)';
    }

    let options2 = {
      colors: [color, 'ghostwhite', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
      is3D: true,
      legend: 'none',
      chartArea: { width: '100%', height: '100%' },
      backgroundColor: 'transparent',
      width: width,
      height: height,
    };

    return options2;

  }

  //Postavljanje poslovnih jedinica
  postaviPosJedinice() {

    if (this.mod.objKor.vrstaID == 1) {
      this.srvClanovi.posjedByTrenOper(this.mod.session.KlijentIDtren, this.mod.session.KoriniskIDtren, this.mod.session.clanVrstaIDtren, (rez: PosJedinice[]) => {
        this.objPosJedinice = rez;
      })
    } else {
      this.objPosJedinice = this.mod.objPosJed;
    }



  }

  ngAfterViewInit() {

  }

  //Odabrana poslona jedinica - emitter
  odabranaPosJed(obj: PosJedinice) {

    this.srvGrupe.mod.objPosJedSelected = obj;

    this.pjedTmp = this.srvGrupe.mod.objPosJedSelected.PoslJedID;
    this.srvGrupe.ucitajGrupe((rez) => {
      this.srvGrupe.objGrupe = rez;
      this.razvrstajGrupe();
    });
    document.getElementById('btnSidebarClose').click();

  }

  //Članovi - link
  linkGrupaClanovi(x: Grupa) {

    console.log(x['pJed']);

    if (this.mod.objKor.vrstaID ==1 && !this.mod.session && x['pJed']) {
      this.srvGrupe.objPosJedTmp = x['pJed'];
    }
    this.srvGrupe.objGrupa = x;
    this.srvKalendar.fnKalendarUnos(x.grpID, () => { });
    this.srvKalendar.frmKalendarUnos.controls['grpID'].setValue(this.srvGrupe.objGrupa.grpID);

    localStorage.objGrupa = JSON.stringify(this.srvGrupe.objGrupa);

    this.router.navigate(['grupa-clanovi']);

  }

  //Nova grupa
  novaGrupaStart() {

    this.frmNovaGrupa = this.srvGrupe.fnNovaGrupa();

    document.getElementById('btnModalNovaGrupa').click();

  }

  novaGrupaCommit() {

    this.frmNovaGrupa.markAllAsTouched();

    if (this.frmNovaGrupa.valid) {

      this.srvGrupe.novaGrupa(this.frmNovaGrupa.value, (rez) => {

        if (rez) {
          this.pocetnePostavke();
          this.btnMdlNovaGrupaClose.nativeElement.click();
        } else {
          this.frmNovaGrupa.setErrors({ err: 'Greška prilikom spremanja!' })
        }

      });
    }

  }

  //Formatiranje decimalnog broja
  decFormat(event) {
    let strNaziv: string = event.srcElement.ariaLabel;
    if (this.frmNovaGrupa.get(strNaziv).value) {
      this.frmNovaGrupa.get(strNaziv).setValue(this.frmNovaGrupa.get(strNaziv).value.replace(',', '.'));
    }
  }

  ngOnDestroy() {
    this.srvGrupe.objGrupe = null;
    document.getElementById('btnSidebarClose').click();
    document.getElementById('btnNovaGrpClose').click();
    /* this.btnMdlNovaGrupaClose.nativeElement.click(); */
  }

}
