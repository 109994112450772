<div>

    <div class="card">

        <div style="padding: 5px; background-color: #ebebeb;">
            Programi
        </div>

        <!-- Lista - programi predlošci -->
        <div *ngFor="let x of srvNapredak.objProgrami" class="btn-prg">

            <div class="row">

                <div class="col">

                    <div (click)="programSelect(x)">
                        <div>
                            <i *ngIf="x.pnID == srvNapredak.objPrgSel?.pnID" class="fa fa-check" aria-hidden="true"
                                style="margin-right: 10px; color: green;"></i>{{x.pnNaziv}}
                        </div>
                        <div style="color: grey;">
                            {{'by ' + x.Ime + ' ' + x.Prezime}}
                        </div>
                    </div>

                </div>

                <div *ngIf="mod.objKor.korisnikID == x.pnKorID && x.pnID == srvNapredak.objPrgSel?.pnID"
                    class="col col-2 d-flex justify-content-center align-items-center" (click)="editPnStart(x)">
                    <i class="material-icons" style="font-size: 1.5rem;">
                        create
                    </i>
                </div>

            </div>


        </div>

        <!-- Novi predložak -->
        <div style="padding: 5px 0;">
            <button type="button" class="btn mybtn-cancel w-100" (click)="noviPnStart()">+ novi program</button>
        </div>

    </div>

</div>