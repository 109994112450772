import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ApiRez, ModulService } from '../modul.service';
import { FrmPnMjDatEdit, FrmPnMjStavEdit, NapredakService, PnMjerenje, PnStatPojedinacno, VeMjerenjeStav, VePnVezaByClanID } from '../napredak.service';
import { Clan, ClanoviService } from '../clanovi.service';
import * as moment from 'moment';

@Component({
  selector: 'app-pn-pregled',
  templateUrl: './pn-pregled.component.html',
  styleUrl: './pn-pregled.component.scss'
})
export class PnPregledComponent implements OnInit, OnDestroy {

  constructor(
    public mod: ModulService,
    public srvNapredak: NapredakService,
    public srvClanovi: ClanoviService
  ) { }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (document.hidden) {
      if (this.srvNapredak.objVePnVezaByClanIDsel) {
        localStorage.setItem('objVePnVezaByClanIDsel', JSON.stringify(this.srvNapredak.objVePnVezaByClanIDsel));
      }
    }
  }

  localStorageCallBack(callback) {
    if (localStorage.getItem("objVePnVezaByClanIDsel") && !this.srvNapredak.objVePnVezaByClanIDsel) {
      this.srvNapredak.objVePnVezaByClanIDsel = JSON.parse(localStorage.getItem("objVePnVezaByClanIDsel"));
    }
    callback();
  }

  ngOnInit(): void {

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      if (this.mod.objKor) {
        tok = this.mod.objKor.token;
      } else {
        this.mod.quickClean();
      }
    }

    this.mod.provjeraTokena(tok, () => {

      this.mod.provjeraPrijaveTrenOper((rez) => {

        this.mod.ucitajPosJed(() => {

          this.localStorageCallBack(() => {
            this.pocetnePostavke();
          });

        });

      });

    });

  }

  //početne postavke
  pocetnePostavke() {

    //Unaprijed odabran program / član
    if ((this.mod.objKor.vrstaID == 2 || this.mod.session) && this.srvNapredak.objVePnVezaByClanIDsel) {

      this.postavkeByClan();

    }

    if (this.mod.objKor.vrstaID == 1 && !this.mod.session) {

      if (this.srvNapredak.objVePnVezaByClanIDsel) {
        this.postavkeByClan();
      } else {

        this.srvNapredak.vePnVezaByKorisnikID(this.srvNapredak.intAkt, (rez: VePnVezaByClanID[]) => {
          this.srvNapredak.objVePnVezaByClanID = rez;
          console.log(this.srvNapredak.objVePnVezaByClanID);

          if (rez) {

            this.srvNapredak.objVePnVezaByClanIDsel = rez[0];

            //Učitavanje mjerenja
            let pnvID: number = this.srvNapredak.objVePnVezaByClanIDsel.pnvID;
            let clanID: number = 0
            this.srvNapredak.pnStatistikaByClan(clanID, pnvID, null, null, (rez) => {
              this.srvNapredak.objPnStat = rez;
              console.log(this.srvNapredak.objPnStat);
              this.srvNapredak.napuniChartDd();
            });

            //Učitavanje mjerenja - pojedinačno (za uređivanje)
            if (this.srvNapredak.bolPnStatDetalji) {
              this.srvNapredak.pnStatDetalji(pnvID, null, null, (rez: PnStatPojedinacno[]) => {
                this.srvNapredak.objPnStatDetalji = rez;
                console.log(rez);
              });
            }

          }

        });

      }

    }

  }

  //postavke by član
  postavkeByClan() {

    //Član by član ID
    let clanID: number = this.srvNapredak.objVePnVezaByClanIDsel.pnvClanID;
    this.srvClanovi.clanByClanId(clanID, (rez: Clan[]) => {
      this.srvNapredak.objClanSel = rez[0];
    });

    //Programi by član ID
    this.srvNapredak.vePnVezaByClanID(clanID, this.srvNapredak.intAkt, (rez: VePnVezaByClanID[]) => {
      this.srvNapredak.objVePnVezaByClanID = rez;
      console.log(this.srvNapredak.objVePnVezaByClanID);
    });

    //Učitavanje mjerenja
    let pnvID: number = this.srvNapredak.objVePnVezaByClanIDsel.pnvID;
    this.srvNapredak.pnStatistikaByClan(clanID, pnvID, null, null, (rez) => {
      this.srvNapredak.objPnStat = rez;
      console.log(this.srvNapredak.objPnStat);
      this.srvNapredak.napuniChartDd();
    });

    //Učitavanje mjerenja - pojedinačno (za uređivanje)
    if (this.srvNapredak.bolPnStatDetalji) {
      this.srvNapredak.pnStatDetalji(pnvID, null, null, (rez: PnStatPojedinacno[]) => {
        this.srvNapredak.objPnStatDetalji = rez;
        console.log(rez);
      });
    }

  }

  //Odabir člana - end
  odabirClana(x: Clan) {

    document.getElementById('btnMdlOdabirClanaClose').click();
    document.getElementById('btnSidebarClose').click();

    this.srvNapredak.objClanSel = x;

    //Programi by član ID
    this.srvNapredak.vePnVezaByClanID(x.clanID, this.srvNapredak.intAkt, (rez: VePnVezaByClanID[]) => {
      this.srvNapredak.objVePnVezaByClanID = rez;
      this.srvNapredak.objVePnVezaByClanIDsel = rez[0];

      //Učitavanje mjerenja
      let pnvID: number = this.srvNapredak.objVePnVezaByClanIDsel.pnvID;
      this.srvNapredak.pnStatistikaByClan(x.clanID, pnvID, null, null, (rez) => {
        this.srvNapredak.objPnStat = rez;
        this.srvNapredak.napuniChartDd();
      });

      //Učitavanje mjerenja - pojedinačno (za uređivanje)
      if (this.srvNapredak.bolPnStatDetalji) {
        this.srvNapredak.pnStatDetalji(pnvID, null, null, (rez: PnStatPojedinacno[]) => {
          this.srvNapredak.objPnStatDetalji = rez;
          console.log(rez);
        });
      }

    });

  }

  //Line chart data
  lineChartdatacolumn = [
    'Datum',
    'vrijednost',
    { role: 'style' },
    { role: 'annotation' },
  ];

  lineChartoptions = {

    chartArea: { top: 10, left: 10, width: '100%', height: '80%' },
    fontSize: 12,
    annotations: {
      textStyle: {
        fontSize: 18,
        bold: true,
      }
    }
  };

  onSelect(event) {
    console.log(event);
  }

  napredak(start: number, end: number) {

    let np: number = end - start;

    let pred: string = end - start > 0 ? '+' : '';

    let post: number = Math.round(np / start * 100);

    return pred + np + ' (' + post + '%)';

  }

  //Privatni korisnik - samo članovi
  spremiNovoPracenje() {

    this.srvNapredak.frmPnVeza.markAllAsTouched();

    if (this.srvNapredak.frmPnVeza.valid) {

      this.srvNapredak.spremiPnVezu(this.srvNapredak.frmPnVeza.value, (rez: ApiRez) => {

        if (rez && rez.rez == 'ok') {

          document.getElementById('btnMdlNovoPracenjeClose').click();

          let pnvID: number = this.srvNapredak.frmPnVeza.get('pnvID').value;

          if (!pnvID) {

            if (!this.srvNapredak.objVePnVezaByClanID) {
              this.srvNapredak.objVePnVezaByClanID = [];
            }

            this.srvNapredak.objVePnVezaByClanID.push(rez.obj);
            this.srvNapredak.objVePnVezaByClanIDsel = rez.obj;

            let clanID: number = this.srvNapredak.objVePnVezaByClanIDsel.pnvClanID;

            this.srvNapredak.pnStatistikaByClan(clanID, pnvID, null, null, (rez) => {
              this.srvNapredak.objPnStat = rez;
              this.srvNapredak.napuniChartDd();
            });


          } else {

            this.srvNapredak.objVePnVezaByClanID.forEach((val, ind) => {

              if (val.pnvID == rez.obj.pnvID) {

                let aktTmp: number = val.pnvAkt;

                this.srvNapredak.objVePnVezaByClanID.splice(ind, 1, rez.obj);

                if (aktTmp != rez.obj.pnvAkt) {

                  //Programi by član ID
                  this.srvNapredak.intAkt = Number(rez.obj.pnvAkt);
                  this.srvNapredak.vePnVezaByClanID(this.srvNapredak.objVePnVezaByClanIDsel.pnvClanID, this.srvNapredak.intAkt, (rez: VePnVezaByClanID[]) => {
                    this.srvNapredak.objVePnVezaByClanID = rez;
                  });

                  //Učitavanje mjerenja - pojedinačno (za uređivanje)
                  if (this.srvNapredak.bolPnStatDetalji) {
                    this.srvNapredak.pnStatDetalji(pnvID, null, null, (rez: PnStatPojedinacno[]) => {
                      this.srvNapredak.objPnStatDetalji = rez;
                      console.log(rez);
                    });
                  }

                }

              }

            });

          }

          this.mod.msgBox(1, "SPREMLJNO!", 800);

        } else {
          this.mod.msgBox(2, "Greška priliom spremanja!", 900);
        }

      });



    } else {
      this.mod.msgBox(2, "popunite obavezna polja!", 900);
    }

  }

  //Spremanje pn mjerenja
  spremiMjerenje() {

    this.srvNapredak.frmPnMjerenje.get('datum').markAsTouched();

    if (this.srvNapredak.frmPnMjerenje.valid) {

      this.srvNapredak.spremiMjerenje(this.srvNapredak.frmPnMjerenje.value, (rez: ApiRez) => {

        if (rez && rez.rez == 'ok') {

          document.getElementById('btnMdlPnMjerEditClose').click();

          this.mod.msgBox(1, "SPREMLJENO", 800);

          //Učitavanje mjerenja
          let pnvID: number = this.srvNapredak.objVePnVezaByClanIDsel.pnvID;

          let clanID: number;
          if (this.srvNapredak.objClanSel) {
            clanID = this.srvNapredak.objClanSel.clanID;
          } else {
            clanID = 0;
          }

          //Učitavanje mjerenja
          this.srvNapredak.pnStatistikaByClan(clanID, pnvID, null, null, (rez) => {
            this.srvNapredak.objPnStat = rez;
            this.srvNapredak.napuniChartDd();
          });

          //Učitavanje mjerenja - pojedinačno (za uređivanje)
          if (this.srvNapredak.bolPnStatDetalji) {
            this.srvNapredak.pnStatDetalji(pnvID, null, null, (rez: PnStatPojedinacno[]) => {
              this.srvNapredak.objPnStatDetalji = rez;
              console.log(rez);
            });
          }


        } else if (rez && rez.rez == 'empty') {
          this.mod.msgBox(1, "Nema podataka za spremanje!", 1000);
        } else {
          this.mod.msgBox(2, "Greška prilkom spremanja", 1000);
        }

      });


    } else {
      this.mod.msgBox(2, "Popunite ispravno sva polja", 800);
    }

  }

  //Spremanje zadanih ciljeva
  spremiCiljeve() {
    let obj0 = this.srvNapredak.frmVePnCilj.value;
    this.srvNapredak.spremiCiljeve(obj0, (rez) => {

      if (rez && rez == 'ok') {
        document.getElementById('btnMdlCiljeviClose').click();
        this.mod.msgBox(1, "SPREMLJENO", 800);
        let pnvID: number = this.srvNapredak.objVePnVezaByClanIDsel.pnvID;
        let clanID: number = 0
        this.srvNapredak.pnStatistikaByClan(clanID, pnvID, null, null, (rez) => {
          this.srvNapredak.objPnStat = rez;
          this.srvNapredak.napuniChartDd();
        });
      } else {
        this.mod.msgBox(2, "Greška!", 800);
      }

    });
  }

  //Datum na mjerenju edit - start
  spremiPnMjDatStart(x: PnStatPojedinacno) {

    console.log(x);

    let obj: FrmPnMjDatEdit = {
      datumOld: x.datum,
      datum: x.datum,
      datOd: null,
      datDo: null,
      pnmPnvID: this.srvNapredak.objVePnVezaByClanIDsel.pnvID,
      fingerprint: this.mod.fingerprint()
    }

    this.srvNapredak.napraviFrmPnMjDatEdit(obj, (rez: FrmPnMjDatEdit) => {
      console.log(rez);
      document.getElementById('btnMdlPnMjDatEdit').click();
    });

  }

  //Spremi izmjenu datum an amjerenju
  spremiPnMjDat() {

    this.srvNapredak.frmPnMjDatEdit.markAllAsTouched();

    if (this.srvNapredak.frmPnMjDatEdit.valid) {

      document.getElementById('btnMdlPnMjDatEditClose').click();

      this.srvNapredak.spremiPnMjDat(this.srvNapredak.frmPnMjDatEdit.value, (rez: PnStatPojedinacno[]) => {
        this.srvNapredak.objPnStatDetalji = rez;
        console.log(rez);
      });

      this.mod.msgBox(1, "SPREMLJENO", 800);

    } else {
      this.mod.msgBox(2, "Morate upisati datum", 1000);
    }

  }

  //Spremi izmjenu mjerenja start
  spremiPnMjStavStart(x: VeMjerenjeStav) {

    let obj: FrmPnMjStavEdit = {
      pnmID: x.pnmID,
      ppsJedMj: x.ppsJedMj,
      pnmVrijednost: x.pnmVrijednost,
      fingerprint: this.mod.fingerprint()
    }

    this.srvNapredak.napraviFrmPnMjStavEdit(obj, (rez) => {
      console.log(rez);
      document.getElementById('btnMdlPnMjStavEdit').click();
    });

  }

  //Spremi izmjenu mjerenja
  spremiPnMjStav() {

    this.srvNapredak.frmPnMjStavEdit.markAllAsTouched();

    if (this.srvNapredak.frmPnMjStavEdit.valid) {

      /* let obj: FrmPnMjStavEdit = {
        pnmID: this.srvNapredak.frmPnMjStavEdit.get('pnmID').value,
        ppsJedMj: this.srvNapredak.frmPnMjStavEdit.get('ppsJedMj').value,
        pnmVrijednost: this.srvNapredak.frmPnMjStavEdit.get('pnmVrijednost').value,
        fingerprint: this.mod.fingerprint()
      } */

      document.getElementById('btnMdlPnMjStavEdit').click();

      this.mod.msgBox(1, "SPREMLJENO", 800);

      let obj = this.srvNapredak.frmPnMjStavEdit.value;

      this.srvNapredak.spremiPnMjStav(obj, (rez: VeMjerenjeStav) => {

        console.log(rez);

        if (rez) {

          this.srvNapredak.objPnStatDetalji?.forEach((val, ind) => {
            val.stavke?.forEach((val2, ind2) => {

              if (val2.pnmID == rez.pnmID) {
                this.srvNapredak.objPnStatDetalji[ind].stavke.splice(ind2, 1, rez);
              }

            });
          });

        }

      });


    } else {
      this.mod.msgBox(2, "Upišite ispravnu vrijednost", 1000);
    }

  }

  //Brisanje mjerenja
  obrisiPnMjStav() {

    let obj = this.srvNapredak.frmPnMjStavEdit.value;

    this.srvNapredak.obrisiPnMjStav(obj, (rez: ApiRez) => {

      if (rez && rez.rez == 'ok') {

        this.srvNapredak.bolDelPnMjStav = false;

        document.getElementById('btnMdlPnMjStavEditClose2').click();

        this.srvNapredak.objPnStatDetalji?.forEach((val, ind) => {
          val.stavke?.forEach((val2, ind2) => {

            if (val2.pnmID == obj.pnmID) {
              this.srvNapredak.objPnStatDetalji[ind].stavke.splice(ind2, 1);
            }

          });
        });

        this.mod.msgBox(1, "SPREMLJENO", 800);


      } else {
        this.mod.msgBox(2, "Greška!", 800);
      }

    });

  }

  ngOnDestroy(): void {
    console.log('pn-pregled destroy');
    document.getElementById('btnSidebarClose').click();
    this.srvNapredak.objVePnVezaByClanID = null;
    this.srvNapredak.bolPnStatDetalji = false;
    this.srvNapredak.objPnStatDetalji = null;
    this.srvNapredak.objVePnVezaByClanIDsel = null;
    this.srvNapredak.objPnStat = null;
    this.srvNapredak.objClanSel = null;
  }

}
