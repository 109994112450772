import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Moment } from 'moment';
import * as moment from 'moment';
import { GrupeService, ClanGrupa, VclanZaPrijavu, Grupa } from '../grupe.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KalendarService, Aktivnost, Vaktivnost, DaniUtjednuChk, DanUtjednu } from '../kalendar.service';
import { DatTmp } from '../kalendar/kalendar.component';
import { listFadeIn } from '../animacije';
import { ApiRez, Chk, ModulService, PosJedinice, vAktivnost } from '../modul.service';
import { Clan, ClanoviService } from '../clanovi.service';
import { Clanarina, ClanarineService } from '../clanarine.service';
import { Dvorana, DvoraneService } from '../dvorane.service';
import { AktivnostiService, TreneriByAktID } from '../aktivnosti.service';
import { NapredakService, PnPrgStavka, PnProgram, PnVeza } from '../napredak.service';
import { Router } from '@angular/router';

export interface TerminTmp {
  aktGrpID: number,
  grpID: number,
  datumOD: string,
  hOD: string,
  mOD: string,
  hDO: string,
  mDO: string
}

export interface PonavljanjaDel {
  danID: number,
  selected: boolean
}

export interface ClanUnosTmp {
  clanID: number,
  ime: string,
  prezime: string,
  grpID: number,
  datumOD: string
}

export interface VezGrupaClan {
  vcgID: number,
  GrupaIDvcg: number,
  ClanIDvcg: number,
  DatumODvcg: Moment,
  DatumDOvcg: Moment,
  AktivanVcg: number,
  autoCl: number
}

export function datValidatorManjiVeci(frm: FormGroup) {

  let dOD: Moment = moment(frm.get('DatumODvcg').value);
  let dDO: Moment = moment(frm.get('DatumDOvcg').value);

  if (dOD > dDO) {
    return { err: "Datum iščlanjenja ne može biti manji od datuma upisa!" };
  }

}

@Component({
  selector: 'app-grupa-clanovi',
  templateUrl: './grupa-clanovi.component.html',
  styleUrls: ['./grupa-clanovi.component.scss'],
  animations: [listFadeIn]
})

export class GrupaClanoviComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('btnModalNovaAktivnost') btnModalNovaAktivnostL: ElementRef;
  @ViewChild('btnNovaAktivnostClose') btnNovaAktivnostClose: ElementRef;
  @ViewChild('btnModalTermin') btnModalTermin: ElementRef;
  @ViewChild('btnModalTerminClose') btnModalTerminClose: ElementRef;
  @ViewChild('btnSidebar') btnSidebar: ElementRef;
  @ViewChild('btnSidebarClose') btnSidebarClose: ElementRef;

  constructor(
    private http: HttpClient,
    public srvGrupe: GrupeService,
    public srvKalendar: KalendarService,
    private formBuilder: FormBuilder,
    public mod: ModulService,
    public srvClanovi: ClanoviService,
    private srvClanarine: ClanarineService,
    public srvDvorane: DvoraneService,
    public srvAktivnosti: AktivnostiService,
    public srvNapredak: NapredakService,
    public router: Router
  ) { }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {

    localStorage.setItem('objGrupa', JSON.stringify(this.srvGrupe.objGrupa));

  }

  intTab: number = 1;
  strPretraga: string = null;

  datTermin: Moment = moment();
  danIdMySql: number;
  objDnevniTermini: Vaktivnost[];
  objDanasnjiTermini: Vaktivnost[];
  aktivnostTmp: Aktivnost;

  blEdit: boolean = false;
  bolTrenEdit: boolean = false;
  blDelete: boolean = false;
  blPonavljanje: boolean = false;
  intPonavljanje: number = 3;
  datDoPonavljanje: Moment = moment();
  objTerminTmp: TerminTmp = { aktGrpID: 0, grpID: 0, datumOD: '', hOD: '', mOD: '', hDO: '', mDO: '' };
  objPonDelTmp: PonavljanjaDel[];
  clanUnosTmp: ClanUnosTmp;
  vcgID: number;

  booClBrisanje: boolean;

  frmNovaGrupa: FormGroup;
  objPosJedinice: PosJedinice[];

  frmVezGrupaClan: FormGroup;

  objTreneri: Clan[];
  objSlobodniTreneri: Clan[];

  objAktivni: ClanGrupa[];
  objNeaktivni: ClanGrupa[];
  objIspisani: ClanGrupa[];

  bolGrpGost: boolean;
  bolGostSearch: boolean;

  objDvorane: Dvorana[];

  clanEditTmp = {
    ime: '',
    prezime: ''
  }

  intTrenDelete: number;


  ngOnInit() {

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.srvGrupe.mod.objKor.token;
    }

    this.mod.provjeraTokena(tok, () => {

      if (localStorage.getItem('objGrupa')) {
        this.srvGrupe.objGrupa = JSON.parse(localStorage.objGrupa);
        localStorage.removeItem('objGrupa');
      }

      this.clanUnosTmp = { clanID: 0, grpID: this.srvGrupe.objGrupa.grpID, ime: '', prezime: '', datumOD: moment().format('YYYY-MM-DD') };

      this.mod.provjeraPrijaveTrenOper((rez9 => {

        this.mod.ucitajPosJed(() => {

          if (this.srvGrupe.objPosJedTmp) {
            this.mod.objPosJedSelected = this.srvGrupe.objPosJedTmp;
          }

          this.srvGrupe.ucitajUclanjene(this.srvGrupe.objGrupa.grpID, () => {
            this.razvrstajClanove();
          });
          this.treneriByGrupaID(this.srvGrupe.objGrupa.grpID);

          this.srvKalendar.fnKalendarUnos(this.srvGrupe.objGrupa.grpID, () => { });

          this.srvKalendar.ucitajAktivnosti(this.srvGrupe.objGrupa.grpID);

          this.danasnjiTermini();

          this.frmVezGrupaClan = this.formBuilder.group({

            vcgID: [0],
            DatumODvcg: [''],
            DatumDOvcg: [''],
            AktivanVcg: [0],

          });

          //Provjera unosa novog termina
          if (this.srvGrupe.intUnosTermina == 1) {
            document.getElementById('btnModalNovaAktivnost').click();
            this.srvGrupe.intUnosTermina = 0;
          }

        });

      }));

    });

  }

  //Razvrstavanje članova po objektima (aktivni, neaktivni, ispisani)
  razvrstajClanove() {

    this.objAktivni = [];
    this.objNeaktivni = [];
    this.objIspisani = [];

    if (this.srvGrupe.objClanoviGrupa) {

      this.srvGrupe.objClanoviGrupa.forEach(val => {

        if (val.AktivanVcg == 1 && (!val.DatumDOvcg || moment(val.DatumDOvcg) >= moment())) {
          this.objAktivni.push(val);
        }

        if (val.AktivanVcg == 0 && (!val.DatumDOvcg || moment(val.DatumDOvcg) >= moment())) {
          this.objNeaktivni.push(val);
        }

        if (moment(val.DatumDOvcg) < moment()) {
          this.objIspisani.push(val);
        }

      });
    }

  }

  //Uklanjanje obrisanog člana iz pripadajućeg objekta
  ukloniIzObjekta(vcgID: number) {

    //Aktivni članovi
    this.objAktivni.forEach((val, ind) => {
      if (val.vcgID == vcgID) {
        this.objAktivni.splice(ind, 1);
      }
    });

    //Neaktivni članovi
    this.objNeaktivni.forEach((val, ind) => {
      if (val.vcgID == vcgID) {
        this.objAktivni.splice(ind, 1);
      }
    });

    //Ispisani članovi
    this.objIspisani.forEach((val, ind) => {
      if (val.vcgID == vcgID) {
        this.objAktivni.splice(ind, 1);
      }
    });

  }

  //Pretraga start
  pretragaStart() {
    this.strPretraga = '';
    setTimeout(() => {
      document.getElementById('txtPretraga').focus();
    }, 500);
  }

  //Treneri by Grupa ID
  treneriByGrupaID(tgGrupaID: number) {
    this.srvGrupe.treneriByGrupaID(tgGrupaID, (rez: Clan[]) => {
      this.objTreneri = rez;
    });
  }


  //Današnji termini
  danasnjiTermini() {
    this.srvGrupe.ucitajDnevneAktivnosti(moment(), (obj: Vaktivnost[]) => {
      if (obj) {
        this.objDanasnjiTermini = obj;
      } else {
        this.objDanasnjiTermini = null;
      }
    });
  }

  //Grupa Edit - frm
  grupaEdit() {

    this.postaviPosJedinice();

    this.frmNovaGrupa = this.srvGrupe.fnNovaGrupa();

    this.frmNovaGrupa.patchValue(this.srvGrupe.objGrupa);

  }

  //Checkbox grupa akt
  grupaAktChk() {

    let intChk: number = this.frmNovaGrupa.get('grpAkt').value;

    if (intChk == 0) {
      this.frmNovaGrupa.get('grpAkt').setValue(1);
    } else {
      this.frmNovaGrupa.get('grpAkt').setValue(0);
    }

  }

  //Formatiranje decimalnog broja
  decFormat(event) {
    let strNaziv: string = event.srcElement.ariaLabel;
    if (this.frmNovaGrupa.get(strNaziv).value) {
      this.frmNovaGrupa.get(strNaziv).setValue(this.frmNovaGrupa.get(strNaziv).value.replace(',', '.'));
    }
  }

  //Spremi grupu
  spremiGrupaEdit() {

    this.frmNovaGrupa.markAllAsTouched();

    if (this.frmNovaGrupa.valid) {

      this.srvGrupe.spremiGrupaEdit(this.frmNovaGrupa.value, (rez: Grupa) => {
        if (rez) {

          this.srvGrupe.objGrupa = rez;
          this.frmNovaGrupa = null;
          this.mod.msgBox(1, "Spremljeno!", 1000);

        } else {
          this.mod.msgBox(2, "Greška prilikom spremanja!", 3000);
        }

      });

    } else {
      this.mod.msgBox(2, "Provjerite ispravnost upisanih polja!", 3000);
    }

  }

  //Postavljanje poslovnih jedinica
  postaviPosJedinice() {

    if (this.mod.objKor.vrstaID == 1) {
      this.srvClanovi.posjedByTrenOper(this.mod.session.KlijentIDtren, this.mod.session.KoriniskIDtren, this.mod.session.clanVrstaIDtren, (rez: PosJedinice[]) => {
        this.objPosJedinice = rez;
      })
    } else {
      this.objPosJedinice = this.mod.objPosJed;
    }



  }

  //Bojanje termina u tijeku
  aktivnosColor(PocetakAkt: Moment, KrajAkt: Moment, statusAkt: number): string {

    statusAkt = Number(statusAkt);

    if (statusAkt == 0) {
      if (moment(PocetakAkt) <= moment() && moment(KrajAkt) >= moment()) {
        return '#7fd3a9';
      } else {
        return 'grey';
      }
    } else {
      return '#feb2b2';
    }

  }

  //CheckBox Aktivan član
  chkClAkt() {

    this.frmVezGrupaClan.controls['AktivanVcg'].setValue(!this.frmVezGrupaClan.controls['AktivanVcg'].value);

  }

  //Obriši člana iz grupe
  obrisiClana(vcgID: number) {

    this.srvGrupe.obrisiClana(vcgID, (rez) => {
      if (rez == 'ok') {

        this.srvGrupe.objClanoviGrupa.forEach((val, ind) => {
          if (val.vcgID == vcgID) {
            this.srvGrupe.objClanoviGrupa.splice(ind, 1);
            this.ukloniIzObjekta(vcgID);
          }
        });

        document.getElementById('btnModalClUredjivanje').click();
        this.mod.msgBox(1, "Uspješno obrisano!", 2000);

        this.booClBrisanje = false;

      } else {
        this.mod.msgBox(2, "Greška prilikom brisanja!", 3000);
      }
    });

  }

  ngAfterViewInit() {
    /* console.log('ready');
    let myModalEl = document.getElementById('modalTermin')
    document.getElementById('modalTermin').addEventListener('hidden', function (event) {
      console.log('zatvoren modal Termini');
    }); */
  }

  //Modal Slobodni članovi
  slobodniClanovi() {

    let grpID: number = this.srvGrupe.objGrupa.grpID;

    this.srvGrupe.ucitajSlobodneClanove(grpID, 1, this.mod.objPosJedSelected.PoslJedID);

    document.getElementById('btnSidebarClose').click()

    document.getElementById('btnModalNoviCl').click();

  }

  //Unos novih članova - start
  odabirClanaZaUnos() {

    /* this.clanUnosTmp.clanID = x.clanID;
    this.clanUnosTmp.grpID = this.srvGrupe.objGrupa.grpID;
    this.clanUnosTmp.ime = x.Ime;
    this.clanUnosTmp.prezime = x.Prezime; => DEPRECATED (objSlobodniCl) */

    document.getElementById('btnModalNoviCl').click();
    document.getElementById('btnModalPotvrdiCl').click();


  }

  //Potvrdi unos člana u grupu
  potvrdiUnosClana(objRez: DatTmp) {

    let datumOD: string = objRez.datum.format('YYYY-MM-DD');
    let grpID: number = this.srvGrupe.objGrupa.grpID;

    let clTmp: number[] = [];
    this.srvGrupe.objSlobodniCl.forEach((val) => {
      if (val['sel']) {
        clTmp.push(Number(val.clanID));
      }
    });

    interface NoviClanovi {
      datumOD: string,
      grpID: number,
      clTmp: number[]
    }

    let obj0: NoviClanovi = { datumOD: datumOD, grpID: grpID, clTmp: clTmp };
    let obj: string = JSON.stringify(obj0);

    let urlTmp: string = this.srvGrupe.mod.webUrl + '/php/grupe.php?token=' + this.srvGrupe.mod.objKor.token + '&funk=noviClan';

    this.http.post(urlTmp, obj).subscribe((rez: ClanGrupa[]) => {

      if (rez) {

        document.getElementById('btnNoviClClose2').click();
        this.srvGrupe.objClanoviGrupa = rez;

        this.razvrstajClanove();

        this.mod.msgBox(1, "Spremljeno!", 2000);

      } else {
        this.mod.msgBox(2, "Greška prilikom unosa!", 3000);
      }

    });

  }

  //Frm Uređivanje podataka članstva
  clanUredjivanje(x: any) {

    this.booClBrisanje = false;

    this.clanEditTmp.ime = x.Ime;
    this.clanEditTmp.prezime = x.Prezime;

    this.frmVezGrupaClan = this.formBuilder.group({

      vcgID: [x.vcgID],
      DatumODvcg: [x.DatumODvcg, [Validators.required]],
      DatumDOvcg: [x.DatumDOvcg],
      AktivanVcg: [x.AktivanVcg]

    }, {
      validator: datValidatorManjiVeci
    })

    if (this.frmVezGrupaClan.controls['AktivanVcg'].value == 1) {
      this.frmVezGrupaClan.controls['AktivanVcg'].setValue(true);
    } else {
      this.frmVezGrupaClan.controls['AktivanVcg'].setValue(false);
    }

    document.getElementById('btnModalClUredjivanje').click();

  }

  //Spremi promjene - Član Edit
  spremiClEdit() {

    let urlTmp: string = this.srvGrupe.mod.webUrl + '/php/grupe.php?token=' + this.srvGrupe.mod.objKor.token + '&funk=vezGrupaClUpdate';
    let objTmp: string = JSON.stringify(this.frmVezGrupaClan.value);

    this.http.post(urlTmp, objTmp).subscribe((rez: ClanGrupa) => {

      if (rez) {

        document.getElementById('btnNoviClClose3').click();

        this.frmVezGrupaClan.patchValue(rez);

        if (rez.AktivanVcg == 1) {
          this.frmVezGrupaClan.controls['AktivanVcg'].setValue(true);
        } else {
          this.frmVezGrupaClan.controls['AktivanVcg'].setValue(false);
        }

        /* this.srvGrupe.objClanoviGrupa.find(x => x.vcgID == rez.vcgID).DatumODvcg = moment(rez.DatumODvcg).format('YYYY-MM-DD');
        this.srvGrupe.objClanoviGrupa.find(x => x.vcgID == rez.vcgID).DatumDOvcg = moment(rez.DatumDOvcg).format('YYYY-MM-DD');
        this.srvGrupe.objClanoviGrupa.find(x => x.vcgID == rez.vcgID).AktivanVcg = rez.AktivanVcg; */

        this.srvGrupe.objClanoviGrupa.forEach((val, ind) => {

          if (val.vcgID == rez.vcgID) {
            this.srvGrupe.objClanoviGrupa.splice(ind, 1, rez);
            this.razvrstajClanove();
          }

        });

        this.mod.msgBox(1, "Spremljeno!", 2000);

      } else {
        this.mod.msgBox(2, "Greška prilikom spremanja!", 3000);
      }


    });

  }

  //Zboj odabranih članova
  zbrojClSel(): number {
    let i: number = 0;
    if (this.srvGrupe.objSlobodniCl) {
      this.srvGrupe.objSlobodniCl.forEach((val => {
        if (val['sel']) {
          i += 1;
        }
      }));
    }
    return i;
  }

  //Zboj odabranih članova
  zbrojTrenSel(): number {
    let i: number = 0;
    if (this.objSlobodniTreneri) {
      this.objSlobodniTreneri.forEach((val => {
        if (val['sel']) {
          i += 1;
        }
      }));
    }
    return i;
  }


  //Otvori Sidebar
  otvoriSidebar() {
    if (window.innerWidth < 768) {
      this.btnSidebar.nativeElement.click();
    }
  }

  //Odabran datum na kalendaru (emitter)
  odabranDatum(objRez: DatTmp) {

    this.blEdit = false;
    this.obrisiTerminCancel();

    this.datTermin = objRez.datum;

    if (objRez.akt) {

      this.srvGrupe.ucitajDnevneAktivnosti(this.datTermin, (obj: Vaktivnost[]) => {
        this.objDnevniTermini = obj;

        this.srvDvorane.dvoraneByPosJedId(this.mod.objPosJedSelected.PoslJedID, 1, (rez: Dvorana[]) => {

          this.objDvorane = rez;

          //ima aktivnost
          document.getElementById('btnModalTermin').click();
        });

      });

    } else {

      this.noviTermin();

      /* this.srvKalendar.fnKalendarUnos(this.srvGrupe.objGrupa.grpID);
      // nema aktivnost
      this.srvKalendar.frmKalendarUnos.controls['datumOD'].setValue(objRez.datum);
      this.srvKalendar.frmKalendarUnos.controls['hOD'].setValue(moment().format('HH'));
      this.srvKalendar.frmKalendarUnos.controls['mOD'].setValue(moment().format('mm'));
      document.getElementById('btnModalNovaAktivnost').click(); */
    }

    document.getElementById('btnSidebarClose').click();

  }

  //Nova aktivnost
  objTreneriTmp: Clan[];
  objDani //: DaniUtjednuChk[];
  noviTermin() {

    this.objDani = [];
    this.srvKalendar.daniUtjednu.forEach((dan) => {
      dan['chk'] = false;
      this.objDani.push(dan);
    });

    this.srvDvorane.dvoraneByPosJedId(this.mod.objPosJedSelected.PoslJedID, 1, (rez: Dvorana[]) => {

      this.objDvorane = rez;

      this.srvKalendar.fnKalendarUnos(this.srvGrupe.objGrupa.grpID, () => {

        this.srvKalendar.frmKalendarUnos.get('datumOD').valueChanges.subscribe(change => {
          this.dateChanged(change);
          this.danIdMySql = this.srvKalendar.danUtjMomentToMySql(Number(moment(change).format('E')));
        });

        //Treneri by grupa ID
        this.srvGrupe.treneriByGrupaID(this.srvGrupe.objGrupa.grpID, (rez: Clan[]) => {
          this.objTreneriTmp = rez;

          this.objTreneriTmp?.forEach((val) => {
            val['sel'] = false;
          });
        });


        this.srvKalendar.frmKalendarUnos.controls['datumOD'].setValue(this.datTermin.locale('hr').utc(true));
        this.srvKalendar.frmKalendarUnos.controls['hOD'].setValue(moment().format('HH'));
        this.srvKalendar.frmKalendarUnos.controls['mOD'].setValue(moment().format('mm'));

        document.getElementById('btnModalNovaAktivnost').click();
      });

    });

  }

  dateChanged(ev) {
    /* console.log(moment(ev.value._i.year + '-' + ev.value._i.month + '-' + ev.value._i.day).utc(true)); */
    /* console.log(moment(ev.value._i).utc(true).format('YYYY-MM-DD')); */

    let dut: number = this.srvKalendar.danUtjMomentToMySql(Number(moment(ev).utc(true).format('E')));

    this.objDani.forEach((val) => {
      if (val.id == dut) {
        val.chk = true;
      } else {
        val.chk = false;
      }
    });


  }

  //Odabrani termin
  odabraniTermin(objAkt: Aktivnost) {

    this.danIdMySql = this.srvKalendar.danUtjMomentToMySql(Number(moment(objAkt.PocetakAkt).format('E')));

    //Dani ponavljanja
    this.datDoPonavljanje = moment(objAkt.PocetakAkt);

    this.objTerminTmp.aktGrpID = objAkt.aktGrpID;
    this.objTerminTmp.grpID = objAkt.GrupaIDakt;
    this.objTerminTmp.datumOD = moment(objAkt.PocetakAkt).format('YYYY-MM-DD');
    this.objTerminTmp.hOD = moment(objAkt.PocetakAkt).format('HH');
    this.objTerminTmp.mOD = moment(objAkt.PocetakAkt).format('mm');
    this.objTerminTmp.hDO = moment(objAkt.KrajAkt).format('HH');
    this.objTerminTmp.mDO = moment(objAkt.KrajAkt).format('mm');

    this.terminiPoDanima(() => {

      this.objPonDelTmp?.forEach((val) => {
        if (val.danID == this.danIdMySql) {
          val.selected = true;
        }
      });

    });

    //Treneri by aktivnost ID
    this.srvAktivnosti.treneriByAktIdEdit(objAkt.aktGrpID, (rez: TreneriByAktID[]) => {
      this.objTreneriTmp = rez;

      this.objTreneriTmp?.forEach((val) => {
        if (val['vtaID']) {
          val['sel'] = true;
        } else {
          val['sel'] = false;
        }
      });

    });


    let minTotal: number = moment(objAkt.KrajAkt).diff(moment(objAkt.PocetakAkt), 'minutes');

    let hours: number = 0; //Math.floor(minTotal / 60); - depreced
    let minutes: number = minTotal - (hours * 60);

    this.datDoPonavljanje = moment(objAkt.PocetakAkt);

    this.srvKalendar.frmKalendarUnos.controls['aktGrpID'].setValue(objAkt.aktGrpID);

    this.srvKalendar.frmKalendarUnos.controls['grpID'].setValue(objAkt.GrupaIDakt);
    this.srvKalendar.frmKalendarUnos.controls['datumOD'].setValue(moment(objAkt.PocetakAkt).utc(true));
    this.srvKalendar.frmKalendarUnos.controls['hOD'].setValue(moment(objAkt.PocetakAkt).format('HH'));
    this.srvKalendar.frmKalendarUnos.controls['mOD'].setValue(moment(objAkt.PocetakAkt).format('mm'));
    this.srvKalendar.frmKalendarUnos.controls['hDO'].setValue(hours);
    this.srvKalendar.frmKalendarUnos.controls['mDO'].setValue(minTotal); //minutes - depreced
    this.srvKalendar.frmKalendarUnos.controls['datumDO'].setValue(moment(objAkt.KrajAkt));
    this.srvKalendar.frmKalendarUnos.controls['statusAkt'].setValue(Number(objAkt.statusAkt));
    this.srvKalendar.frmKalendarUnos.controls['dvoranaIdAkt'].setValue(objAkt.dvoranaIdAkt);

    this.blEdit = true;

  }

  //Checkbox ponavljanje klik
  ponavljanjeClick() {
    this.srvKalendar.frmKalendarUnos.controls['ponavljanje'].setValue(!this.srvKalendar.frmKalendarUnos.controls['ponavljanje'].value);

    if (this.srvKalendar.frmKalendarUnos.controls['ponavljanje'].value) {
      this.srvKalendar.frmKalendarUnos.controls['datumDO'].addValidators(Validators.required);
    } else {
      this.srvKalendar.frmKalendarUnos.controls['datumDO'].removeValidators(Validators.required);
    }

  }

  //Nova Aktivnost / Termin
  unesiAktivnost() {

    this.srvKalendar.frmKalendarUnos.markAllAsTouched();

    if (this.srvKalendar.frmKalendarUnos.valid) {

      //Treneri
      let arr: number[] = [];
      this.objTreneriTmp?.forEach(val => {
        if (val['sel']) {
          arr.push(val.clanID);
        }
      });

      //Ponavljanje - Dani u tjednu
      let arrDut: number[] = [];
      this.objDani.forEach((val) => {
        if (val.chk) {
          arrDut.push(val.id);
        }
      });

      this.srvKalendar.unesiAktivnost(arr, arrDut, () => {

        this.srvGrupe.ucitajDnevneAktivnosti(this.datTermin, (obj: Vaktivnost[]) => {
          this.objDnevniTermini = obj;
          if (window.innerWidth < 768) {
            this.btnSidebar.nativeElement.click();
          }
        });

        this.danasnjiTermini();

      });


    } else {
      this.mod.msgBox(2, "Popunite ispravno polja!", 3000);
    }

  }

  //Uređivanje termina
  urediAktivnost(x: Aktivnost) {

    this.srvKalendar.fnKalendarUnos(0, () => {

      //Treneri by aktivnost ID
      this.srvAktivnosti.treneriByAktIdEdit(x.aktGrpID, (rez: TreneriByAktID[]) => {
        this.srvAktivnosti.objTreneriByAktID = rez;
      });

      //Dvorane
      this.srvDvorane.dvoraneByPosJedId(this.mod.objPosJedSelected.PoslJedID, 1, (rez: Dvorana[]) => {

        this.srvKalendar.objDvorane = rez;

        x.statusAkt = Number(x.statusAkt);
        x['datumOD'] = moment(x.PocetakAkt).format('YYYY-MM-DD');
        x['hOD'] = moment(x.PocetakAkt).format('HH');
        x['mOD'] = moment(x.PocetakAkt).format('mm');

        let duration = moment.duration(moment(x.KrajAkt).diff(moment(x.PocetakAkt)));

        let hTmp, mTmp: number;
        mTmp = duration.asMinutes();
        //hTmp = Math.trunc(mTmp / 60); - depreced
        //mTmp = mTmp - (hTmp * 60); - depreced

        x['hDO'] = 0; //hTmp; - depreced
        x['mDO'] = mTmp;

        this.srvKalendar.frmKalendarUnos.patchValue(x);

        document.getElementById('btnAktEdit').click();

      });

    });

  }

  //Spremi Aktivnost
  spremiAktivnost(dat: Moment) {

    //Treneri
    let arrTr: number[] = [];
    this.objTreneriTmp?.forEach(val => {
      if (val['sel']) {
        arrTr.push(val.clanID);
      }
    });


    //Filtriranje i pakiranje Ponavljanja - dani u tjednu
    let fltPonDel: number[];
    if (this.blPonavljanje) {
      fltPonDel = [];
      this.objPonDelTmp?.forEach((val) => {
        if (val.selected) {
          fltPonDel.push(val.danID);
        }
      });
    }

    this.srvKalendar.spremiAktivnost(this.bolTrenEdit, arrTr, fltPonDel, this.intPonavljanje, this.datDoPonavljanje, (rez) => {

      this.srvGrupe.ucitajDnevneAktivnosti(dat, (obj: Vaktivnost[]) => {
        this.objDnevniTermini = obj;
        this.danasnjiTermini();
        this.blEdit = false;
      });

    });
  }

  //Spremamnje promjena u terminu
  spremiAktivnost2() {

    this.srvKalendar.aktivnostQuickUpdate((rez) => {
      document.getElementById('btnMdlAktEditClose').click();

      this.objDanasnjiTermini?.forEach((val, ind) => {

        if (val.aktGrpID == rez['aktGrpID']) {
          this.objDanasnjiTermini[ind] = rez;
        }

      });

    });

  }

  //Brisanje Termina - start
  obrisiTermin(obj) {

    /* alert(JSON.stringify(obj)); */
    this.datDoPonavljanje = moment(obj.PocetakAkt);

    this.objTerminTmp.aktGrpID = obj.aktGrpID;
    this.objTerminTmp.grpID = obj.GrupaIDakt;
    this.objTerminTmp.datumOD = moment(obj.PocetakAkt).format('YYYY-MM-DD');
    this.objTerminTmp.hOD = moment(obj.PocetakAkt).format('HH');
    this.objTerminTmp.mOD = moment(obj.PocetakAkt).format('mm');
    this.objTerminTmp.hDO = moment(obj.KrajAkt).format('HH');
    this.objTerminTmp.mDO = moment(obj.KrajAkt).format('mm');

    this.blDelete = true;

    this.terminiPoDanima(() => { });

  }

  //Ponavljanja Termina
  terminiPoDanima(callbback) {

    let urlTmp: string = this.srvGrupe.mod.webUrl + '/php/aktivnosti.php?token=' + this.srvGrupe.mod.objKor.token + '&funk=grpDani'

    this.http.post<PonavljanjaDel[]>(urlTmp, this.objTerminTmp).subscribe(rez => {

      rez?.forEach(rez => {
        rez.selected = false;
      });

      this.objPonDelTmp = rez;

      callbback(this.objPonDelTmp);

    });

  }

  //Potvrdi brisanje Termina
  obrisiTerminKraj() {

    let objTmp = { objTerminTmp: {}, objPonDelTmp: [] };

    //Pakiranje Termina
    objTmp.objTerminTmp = this.objTerminTmp;

    //Filtriranje i pakiranje Ponavljanja
    let fltPonDel: PonavljanjaDel[] = this.objPonDelTmp?.filter(s => s.selected == true);

    objTmp.objPonDelTmp = fltPonDel;

    objTmp['interval'] = this.intPonavljanje;
    objTmp['datDoPonavljanje'] = this.datDoPonavljanje.format('YYYY-MM-DD');
    objTmp['fingerprint'] = this.mod.fingerprint();

    let urlTmp: string = this.srvGrupe.mod.webUrl + '/php/aktivnosti.php?token=' + this.srvGrupe.mod.objKor.token + '&funk=obrisiTermine';

    /* alert(JSON.stringify(objTmp)); */

    this.http.post(urlTmp, objTmp).subscribe(rez => {

      if (rez) {
        this.objDnevniTermini = rez['dnevniTermini'];
        this.srvKalendar.objAktivnosti = rez['sveAktivnosti'];

        this.obrisiTerminCancel();

        if (this.objDnevniTermini?.length == 0) {
          /* this.btnModalTerminClose.nativeElement.click(); */
          document.getElementById('btnModalTerminClose').click();
          if (window.innerWidth < 768) {
            this.btnSidebar.nativeElement.click();
          }

        }

        this.danasnjiTermini();

        this.mod.msgBox(1, "Podaci su obrisani!", 2000);
      } else {
        this.mod.msgBox(2, "Greška prilikom brisanja!", 3000);
      }



    });

  }

  obrisiTerminCancel() {
    this.blDelete = false
    this.blPonavljanje = false;
    this.intPonavljanje = 3;
    this.objPonDelTmp = [];
  }

  resetDialogEdit() {
    document.getElementById('btnModalTerminClose').click();
    /* this.btnModalTerminClose.nativeElement.click(); */

    this.objDnevniTermini = null;
    this.blEdit = false;
    this.blDelete = false;

    if (window.innerWidth < 768) {
      this.btnSidebar.nativeElement.click();
    }

  }

  //Login članova Start
  clanoviLoginStart(x: Aktivnost) {
    this.bolGrpGost = false;
    this.bolGostSearch = false;
    this.srvClanovi.strPretraga2 = "";
    this.aktivnostTmp = x;
    this.srvGrupe.ucitajClanoveZaPrijavu(x.aktGrpID, (rez) => {
      if (rez) {
        this.srvGrupe.objClanoviZaPrijavu = rez;
        document.getElementById('btnMdlListaClPrijava').click();
      } else {
        this.mod.msgBox(2, "Greška prilikom učitavanja članova!", 3000);
      }

    });
  }

  //FILTRIRANJE ČLANOVA
  filtrirajClanove(strP: string) {
    //alert(strP);
    this.srvGrupe.fltSlobodniCl = this.srvGrupe.objSlobodniCl.filter(s => s.Ime.toLowerCase().includes(strP.toLowerCase()) || s.Prezime.toLowerCase().includes(strP.toLowerCase()));
  }

  //Očisti pretragu
  pretragaClear() {
    this.strPretraga = null;
    this.filtrirajClanove('');
    /*this.srvGrupe.fltSlobodniCl = this.srvGrupe.objSlobodniCl; */
  }

  //Označavanje prisutnih članova u grupi
  clanChk(x: VclanZaPrijavu, i: number) {

    this.srvGrupe.clanChk(x, this.aktivnostTmp, (rez) => {
      if (rez == 'gost') {
        this.srvGrupe.objClanoviZaPrijavu.splice(i, 1);
        this.mod.msgBox(1, "Član je uspješno odjavljen", 2000);
      } else {
        this.srvGrupe.objClanoviZaPrijavu.splice(i, 1, rez); //: VclanZaPrijavu
      }
    });

  }

  //Dodaj trenera u grupu
  dodajTreneraStart() {

    this.objSlobodniTreneri = [];

    document.getElementById('btnMdlListaSlobTren').click();

    let grpID: number = this.srvGrupe.objGrupa.grpID;
    let klID: number = this.mod.objPosJedSelected.KlijentIDpos;
    let pjID: number = this.mod.objPosJedSelected.PoslJedID;

    this.srvGrupe.treneriZaGrupu(grpID, klID, pjID, (rez: Clan[]) => {
      this.objSlobodniTreneri = rez;
    });

  }

  //Dodavanje trenera u grupu - send
  dodajTreneraEnd() {

    let slTren: number[] = [];
    this.objSlobodniTreneri?.forEach(val => {
      if (val['sel']) {
        slTren.push(Number(val.clanID));
      }
    });

    let grpID: number = this.srvGrupe.objGrupa.grpID;
    let klID: number = this.mod.objPosJedSelected.KlijentIDpos;
    let pjID: number = this.mod.objPosJedSelected.PoslJedID;

    this.srvGrupe.dodajTreneraEnd(slTren, grpID, klID, pjID, (rez: Clan[]) => {

      if (rez) {
        document.getElementById('btnMdlListaSlobTrenClose').click();
        this.objTreneri = rez;
        /* this.objSlobodniTreneri = rez['objSlobodniTreneri']; */
        this.mod.msgBox(1, "Uspješno dodano", 2000);
      } else {
        this.mod.msgBox(2, "Greška prilikom unosa", 3000);
      }

    })
  }

  //Brisanje trenera iz grupe
  obrisiTrenera(vtgID) {

    this.srvGrupe.obrisiTrenera(vtgID, (rez: string) => {

      if (rez == 'ok') {
        this.mod.msgBox(1, 'Uspješno obrisano!', 2000);
        this.objTreneri?.forEach((val, ind) => {
          if (val['vtgID'] == vtgID) {
            this.objTreneri.splice(ind, 1);
          }
        });
        if (!this.objTreneri.length) {
          this.objTreneri = null;
        }
      } else {
        this.mod.msgBox(2, 'Greška! Trener nije obrisan', 3000);
      }

    })

  }

  //Auto obnova članarina
  autoClanarine(x: ClanGrupa) {

    this.vcgID = x.vcgID;

    this.srvClanarine.autoClanarine(x, (rez: string) => {

      if (rez) {
        if (rez == 'isključeno') {
          x.autoCl = 0;
        } else if (rez == 'uključeno') {
          x.autoCl = 1;
        } else if (rez == 'nema') {

          /* this.mod.msgBox(3, "Unesite prvu članarinu", 3000); */
          //unos prve članarine
          document.getElementById('btnMdlClanarina').click();

          this.srvGrupe.grupeByClan(1, x.ClanIDvcg, (rez: Grupa[]) => {
            this.srvGrupe.objGrupe = rez;
          });

          this.srvClanarine.napraviFrmClanarina(null, (rez) => {
            this.srvClanarine.frmClanarina.get('claClanID').setValue(x.ClanIDvcg);
            this.srvClanarine.frmClanarina.get('claGrupaID').setValue(this.srvGrupe.objGrupa.grpID);
            this.srvClanarine.frmClanarina.get('claIznos').setValue(this.srvGrupe.objGrupa.grpClanarina);
          });

        }
      } else {
        console.log('greška');
      }


    });

  }

  //Spremanje prve članarine
  spremiClanarinu() {

    this.srvClanarine.frmClanarina.get('claGrupaID').enable();

    let rTmp = this.srvClanarine.frmClanarina.value;
    rTmp.vcgID = this.vcgID;

    this.srvClanarine.spremiClanarinu(rTmp, (rez: ClanGrupa) => {

      if (rez) {

        this.srvGrupe.objClanoviGrupa?.forEach((val, ind) => {
          if (val.vcgID == this.vcgID) {
            val.autoCl = 1;
          }
        });

        this.vcgID = 0;
        document.getElementById('btnMdlClanarinaClose').click();
        this.mod.msgBox(1, "Spremljeno!", 2000);
      } else {
        this.mod.msgBox(2, "Greška! promjene nisu prihvaćene.", 3000);
        this.srvClanarine.frmClanarina.get('claGrupaID').disable();
      }

    });

  }


  ngOnDestroy() {

    if (this.mod.objKor.vrstaID == 1 && !this.mod.session) {
      this.mod.objPosJedSelected = null;
    }
    this.srvGrupe.objPosJedTmp = null;

    this.srvGrupe.objClanoviGrupa = null;
    document.getElementById('btnNovaAktivnostClose').click();
    document.getElementById('btnSidebarClose').click();
    document.getElementById('btnModalTerminClose').click();

    document.getElementById('btnNoviClClose').click();
    document.getElementById('btnNoviClClose2').click();
    document.getElementById('btnNoviClClose3').click();
    document.getElementById('btnMdlListaClPrijavaClose').click();
    document.getElementById('btnMdlListaSlobTrenClose').click();

  }

  //Učitavanje neprijavljenih članova
  ucitajNeprijavljene() {
    this.srvClanovi.ucitajNeprijavljene(true, this.mod.objPosJedSelected.PoslJedID, () => {

    });
  }

  //Prijava gosta u grupu
  prijaviGosta(aktTmp) {
    this.bolGrpGost = true;
    this.ucitajNeprijavljene();
  }

  //Potvrdi prijavu gosta
  potvrdiGosta(x: Clan) {

    this.srvGrupe.prijavaGosta(x.clanID, this.aktivnostTmp, (rez) => {

      if (rez) {
        //provjera jel gost član grupe
        let bolIma: boolean;
        this.srvGrupe.objClanoviZaPrijavu?.forEach(val => {
          if (val.clanID == rez.clanID) {
            val.trenID = rez.trenID;
            bolIma = true;
          }
        });

        if (!bolIma) {
          this.srvGrupe.objClanoviZaPrijavu.push(rez);
        }

        this.bolGostSearch = false;
        this.bolGrpGost = false;
        /* document.getElementById('btnMdlListaClPrijavaClose').click(); */
        this.mod.msgBox(1, "Član je uspješno prijavljen!", 2000);
      } else {
        this.mod.msgBox(2, "Greška! Član nije prijavljen. :(", 2000);
      }

    });

  }

  //Novi program za praćenje napretka
  pnDat: Moment;
  pnID: number;
  chkAll: boolean = false;

  noviPnvStart() {
    this.pnID = null;
    this.pnDat = moment();

    this.srvNapredak.pnFillByDopustenje((rez: PnProgram[]) => {
      this.srvNapredak.objProgrami = rez;
      console.log(rez);
    });

    this.srvGrupe.clanoviByGrupaID(this.srvGrupe.objGrupa.grpID, 1, (rez: Clan[]) => {
      console.log(rez);
      this.srvNapredak.objClanovi = rez;
      this.srvNapredak.objClanovi.forEach(val => {
        val['chk'] = false;
      })
      document.getElementById('btnMdlPn').click();
    })

  }

  //Spremi program
  spremiProgram() {

    let arrClan: number[] = [];

    //Provjera jel' označen ijedan član
    let bolCl: boolean = false;
    this.srvNapredak.objClanovi.forEach(val => {
      if (val['chk']) {
        arrClan.push(Number(val['ClanIDvcg']));
        bolCl = true;
      }
    });

    if (this.pnDat && this.pnID && bolCl) {

      let obj0 = {
        pnvKorID: this.mod.objKor.korisnikID,
        pnvDatum: this.pnDat,
        pnvTemplID: this.pnID,
        clanovi: arrClan,
        fingerprint: this.mod.fingerprint()
      }

      console.log(obj0);

      this.srvNapredak.spremiPnvByGrupa(obj0, (rez: ApiRez) => {

        console.log(rez);

        if (rez && rez.rez == 'ok') {

          document.getElementById('btnMdlPnClose').click();

          this.srvGrupe.ucitajUclanjene(this.srvGrupe.objGrupa.grpID, () => {
            this.razvrstajClanove();
          });

          this.mod.msgBox(1, "SPREMLJENO", 900);

        } else {
          this.mod.msgBox(2, "Greška prilikom spremanja!", 900);
        }

      });

    } else {
      this.mod.msgBox(2, "Popunite sva obavezna polja!", 900);
    }

  }

  chkAllSwitch() {

    this.chkAll = !this.chkAll

    this.srvNapredak.objClanovi.forEach( val => {
      val['chk'] = this.chkAll;
    });

  }

  //pn Switch
  pnSwitch(ClanIDvcg: number) {

    if (this.srvGrupe.intClanSelID == ClanIDvcg) {
      this.srvGrupe.intClanSelID = 0;
    } else {
      this.srvGrupe.intClanSelID = ClanIDvcg;
    }

  }

  //Novo mjerenje (praćenje napretka)
  novoMjerenjeStart(x: PnVeza) {

    console.log(x);

    //Učitavanje parametara za praćenje za odabrani program
    this.srvNapredak.pnStavkeByPnID(x.pnvTemplID, (rez: PnPrgStavka[]) => {

      document.getElementById('btnSidebarClose').click();

      this.srvNapredak.napraviFrmPnMjerenje(x.pnvID, rez, (val) => {

        this.srvNapredak.zadnjeMjerenjeHandle(x.pnvID);

        document.getElementById('btnMdlPnMjerEdit').click();
      });

    });

  }

  //Spremanje pn mjerenja
  spremiMjerenje() {

    this.srvNapredak.frmPnMjerenje.get('datum').markAsTouched();

    if (this.srvNapredak.frmPnMjerenje.valid) {

      this.srvNapredak.spremiMjerenje(this.srvNapredak.frmPnMjerenje.value, (rez: ApiRez) => {

        if (rez && rez.rez == 'ok') {

          document.getElementById('btnMdlPnMjerEditClose').click();

          this.mod.msgBox(1, "SPREMLJENO", 800);


        } else if (rez && rez.rez == 'empty') {
          this.mod.msgBox(1, "Nema podataka za spremanje!", 1000);
        } else {
          this.mod.msgBox(2, "Greška prilkom spremanja", 1000);
        }

      });


    } else {
      this.mod.msgBox(2, "Popunite ispravno sva polja", 800);
    }

  }

  //Učitavanje ciljeva po aktivnom programu
  ciljeviByPnvID(pnvID: number) {

    this.srvNapredak.ciljeviByPnvID(pnvID, (rez) => {
      this.srvNapredak.napraviFrmVePnCilj(pnvID, rez, () => {
        document.getElementById('btnMdlCiljeviEdit').click();
      });
    });

  }

  //Spremanje zadanih ciljeva
  spremiCiljeve() {
    let obj0 = this.srvNapredak.frmVePnCilj.value;
    this.srvNapredak.spremiCiljeve(obj0, (rez) => {

      if (rez && rez == 'ok') {
        document.getElementById('btnMdlCiljeviClose').click();
        this.mod.msgBox(1, "SPREMLJENO", 800);
      } else {
        this.mod.msgBox(2, "Greška!", 800);
      }

    });
  }

  //Pregled statistika
  pnPregled(x) {
    console.log(this.srvClanovi.clanID);
    if (!this.srvClanovi.clanID) {
      this.srvClanovi.clanID = x.pnvClanID;
    }
    console.log(x.pnvClanID)
    this.srvNapredak.objVePnVezaByClanIDsel = x;
    this.router.navigate(['/pn-pregled']);
  }

}
