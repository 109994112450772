import { Component } from '@angular/core';
import { ModulService } from '../modul.service';
import { NapredakService } from '../napredak.service';

@Component({
  selector: 'app-pn-cilj-edit',
  templateUrl: './pn-cilj-edit.component.html',
  styleUrl: './pn-cilj-edit.component.scss'
})
export class PnCiljEditComponent {

  constructor(
    public mod:ModulService,
    public srvNapredak: NapredakService
  ){}

  intFocus: boolean = false;

}
