import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Vjezba, VjezbeService } from '../vjezbe.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FbVideoComponent } from '../fb-video/fb-video.component';

@Component({
  selector: 'app-vjezbe-sidebar',
  templateUrl: './vjezbe-sidebar.component.html',
  styleUrl: './vjezbe-sidebar.component.scss'
})
export class VjezbeSidebarComponent {

  constructor(
    public srvVjezbe: VjezbeService,
    public sanitizer: DomSanitizer
  ) {}

  /* @ViewChild('fbVideo') fbVideo: HTMLElement; */
  @ViewChild('fbVideo') fbVideo: ElementRef;
  /* @Input() fbVid: FbVideoComponent; */

  FB: any;

  odabirVjezbe(x: Vjezba) {
    this.srvVjezbe.objVjezbaSel = null;
    this.srvVjezbe.odabirVjezbe(x);
    document.getElementById('btnSidebarClose').click();
    /* this.srvVjezbe.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.facebook.com/plugins/video.php?height=314&href=' + this.srvVjezbe.objVjezbaSel.vjLink + '&show_text=false&width=560&t=0'); */
    
    
    /* this.fbVid.refresh(); */
    /* this.fbVideo.nativeElement.setAttribute('data-href',x.vjLink) */
    /* this.srvVjezbe.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(x.vjLink); */
    /* document.getElementById('fbVideo').innerHTML=this.srvVjezbe.objVjezbaSel.vjLink; */
    /* this.fbVideo.innerHTML = this.srvVjezbe.objVjezbaSel.vjLink; */
  }

}
