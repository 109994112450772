import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModulService } from './modul.service';

export interface Pozivnica {
  pozID: number,
  KorClanID: number,
  korVrstaID: number,
  TekstPoz: string,
  VrijemePoz: Moment,
  VrijediDo: Moment,
  del: number,
  limit: number,
  chkID: number,
  posJedID: number,
  grpID: number
}

@Injectable({
  providedIn: 'root'
})
export class PozivniceService {

  constructor(
    private mod: ModulService,
    private frmBuilder: FormBuilder
  ) { }

  frmPozivnica: FormGroup;

  //Napravi frm pozivnica
  napraviFrmPozivnica(val: Pozivnica, callback) {

    let korClanID: number;

    if (this.mod.objKor.vrstaID == 1) {
      korClanID = this.mod.session.KoriniskIDtren;
    } else {
      korClanID = this.mod.objKor.korisnikID;
    }

    this.frmPozivnica = this.frmBuilder.group({

      pozID: [0],
      KorClanID: [korClanID],
      korVrstaID: [this.mod.objKor.vrstaID],
      TekstPoz: [],
      VrijemePoz: [moment().toDate()],
      VrijediDo: [],
      del: [0],
      limit: [0],
      chkID: [1],
      posJedID: [0],
      grpID: [0]

    });

    if (val) {
      this.frmPozivnica.patchValue(val);
    }

    callback(this.frmPozivnica.value)

  }


}
