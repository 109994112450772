<div class="container" style="padding: 0px; height: 100%; padding-bottom: 5px; opacity: 0.9;">

    <div class="row" style="padding-top: 50px; height: 100%; max-height: 100%;">

        <!-- Lijeva kolona -->
        <div class="d-none d-md-block col col-4" style="padding: 0;">

            <app-pn-prg-template-sidebar></app-pn-prg-template-sidebar>

        </div>

        <!-- Desna kolona -->
        <div class="col col-right" style="max-height: 100%; padding: 0;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">

                <div class="bgs-dark" data-toggle="modal" data-target="#mdlSidebar">

                    <app-pos-jed-hamburger></app-pos-jed-hamburger>

                </div>

                <div *ngIf="mod.objKor.korisnikID == srvNapredak.objPrgSel?.pnKorID && srvNapredak.objPrgSel" class="bgs-light" style="padding: 10px 2px;">

                    <div class="row">
                        <div class="col col-8" style="padding: 2px;">
                            <button type="button" class="btn mybtn-cancel w-100" (click)="unosUredPnStavke(null)">+ novi
                                parametar</button>
                        </div>
                        <div class="col col-4" style="padding: 2px;">
                            <button type="button" class="btn w-100" [ngClass]="{'mybtn-success': bolSort, 'mybtn-cancel': !bolSort}" (click)="bolSort = !bolSort">Sortiranje</button>
                        </div>
                    </div>

                </div>

                <div class="card flex-grow-1" style="overflow-y: scroll; scrollbar-width: none; padding-left: 2px;">

                    <div *ngIf="!srvNapredak.objPnPrgStavke && srvNapredak.objPrgSel" class="alert alert-success"
                        style="margin-top: 15px; text-align: center;">
                        nema parametara za odabrani program
                    </div>

                    <div *ngIf="!srvNapredak.objPrgSel" class="alert alert-success"
                        style="margin-top: 15px; text-align: center;">
                        nije odabran niti jedan program
                    </div>

                    <div *ngIf="srvNapredak.objPnPrgStavke" class="row btn-stavka" style="font-size: 1rem;">

                        <div class="col">
                            <div>
                                naziv
                            </div>
                        </div>

                        <div *ngIf="!bolSort" class="col col-3 d-flex justify-content-center align-items-center">
                            <div>
                                jed. mj.
                            </div>
                        </div>

                        <div *ngIf="mod.objKor.korisnikID == srvNapredak.objPrgSel?.pnKorID && !bolSort" class="col col-2">

                        </div>

                    </div>

                    <div *ngFor="let x of srvNapredak.objPnPrgStavke; let i = index" class="btn-stavka">

                        <div class="row" [ngClass]="{'neaktivna': x.ppsAkt == 0}">

                            <div class="col">
                                <div>
                                    {{x.ppsNaziv}}
                                </div>
                            </div>

                            <div *ngIf="!bolSort" class="col col-3 d-flex justify-content-center align-items-center">
                                <div>
                                    {{x.ppsJedMj}}
                                </div>
                            </div>

                            <div *ngIf="mod.objKor.korisnikID == srvNapredak.objPrgSel?.pnKorID && !bolSort" class="col col-2 d-flex justify-content-center align-items-center"
                                style="cursor: pointer;" (click)="unosUredPnStavke(x)">
                                <i class="material-icons" style="font-size: 1.5rem;">
                                    create
                                </i>
                            </div>

                            <div *ngIf="bolSort" class="col col-2" style="padding: 2px;">
                                <div *ngIf="i < srvNapredak.objPnPrgStavke?.length - 1" class="d-flex justify-content-center align-items-center h-100" style="cursor: pointer;" (click)="pnStavkaSwRedBr(x, i, '+')">
                                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                </div>
                            </div>

                            <div *ngIf="bolSort" class="col col-2" style="padding: 2px;">
                                <div *ngIf="i > 0" class="d-flex justify-content-center align-items-center h-100" style="cursor: pointer;" (click)="pnStavkaSwRedBr(x, i, '-')">
                                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>

            </div>

        </div>

    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header posjed-mod-header d-flex justify-content-center">
                Praćenje napretka
            </div>

            <div class="modal-body posjed-mod-body" style="padding: 0;">

                <app-pn-prg-template-sidebar></app-pn-prg-template-sidebar>

            </div>

            <div class="modal-footer bgs-dark">
                <button type="button" id="btnSidebarClose" class="btn btn-outline-secondary" data-dismiss="modal"
                    style="width: 100%;">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!--MODAL program edit-->
<button hidden="true" id="btnMdlPnEdit" class="btn btn-info" data-toggle="modal" data-target="#mdlPnEdit">Modal
    poslan e-mail</button>

<div class="modal fade" id="mdlPnEdit" tabindex="-1" role="dialog" aria-labelledby="Modal Poslan email"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centred" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlPnEditLabel">{{srvNapredak.frmPnProgram?.get('pnID').value == 0 ? 'Unos novog programa' : 'Uređivanje programa'}}</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="padding: 5px;">

                <app-pn-program-edit></app-pn-program-edit>

            </div>
            <div class="modal-footer" style="display: block; padding: 5px;">
                <div class="row">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100" (click)="spremiProgram()">Spremi</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button id="btnMdlPnEditClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--MODAL prg stavka edit-->
<button hidden="true" id="btnMdlPnStavEdit" class="btn btn-info" data-toggle="modal" data-target="#mdlPnStavEdit">Modal
    poslan e-mail</button>

<div class="modal fade" id="mdlPnStavEdit" tabindex="-1" role="dialog" aria-labelledby="Modal Poslan email"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centred" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlPnStavEditLabel">{{srvNapredak.frmPnPrgStavka?.get('ppsID').value == 0 ?
                    'Novi parametar' : 'Uređivanje parametra'}}</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="padding: 5px;">

                <app-pn-stavka-edit></app-pn-stavka-edit>

            </div>
            <div *ngIf="!srvNapredak.bolBrisanje" class="modal-footer" style="display: block; padding: 5px;">
                <div class="row">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100" (click)="spremiPnStavku()">Spremi</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button id="btnMdlPnStavEditClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>