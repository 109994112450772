<div class="row">
    <div class="col d-flex justify-content-center align-items-center" style="padding: 6px;">
        <i class="material-icons">people</i>
        <span style="height: 20px; margin-left: 5px;"> Članstva</span>
    </div>
</div>

<div *ngIf="!objClanstva">
    <div style="white-space: pre-line;">
        <p>Još niste povezani s ni jednim fitness centrom.</p>
        <p>
            Pošaljite zahtjev za povezivanjem kako biste mogli pratiti događanja, statistike i primati obavijesti.
        </p>
        <p>
            Svi podaci bit će Vam dostupni nakon što fitness centar potvrdi zahtjev.
        </p>
        <p>
            Objašnjenja i upute vezane uz povezivanje možete naći na linku: <span
                style="color:cornflowerblue;">povezivanje korisnika</span>
        </p>
    </div>
</div>

<div class="row" style="padding: 0px 0px 8px 0px;">

    <div class="col col-12" *ngFor="let x of objClanstva" style="padding: 0px;">

        <div *ngIf="!mod.session || x.vClansID == mod.session.vClansID">

            <div class="col-card">

                <!-- <div class="card-header c-header">
                    {{x.Ime}}
                </div> -->

                <div class="d-flex flex-column c-body">
                    <span class="d-flex"><span style="font-weight: bold;">{{x.Ime}}</span><span class="ml-auto"
                            style="color: rgb(128, 151, 165); cursor: pointer;">{{x.opis_vrsta_cl}}</span></span>
                    <span *ngIf="x.NazivPos != x.Ime">{{x.NazivPos}} </span>
                    <span style="font-size: 0.9rem;">Od: {{x.PocetakDatum | amDateFormat:'DD.MM.YYYY.'}} {{x.KrajDatum ?
                        ' do ' + (x.KrajDatum | amDateFormat:'DD.MM.YYYY.') : ''}}</span>

                    <div *ngIf="x.vrstaIDcl != 1 && !mod.objPosJedSelected && !x.KrajDatum && x.intAkt == 1"
                        (click)="posJedTrenOper(x)" data-dismiss="modal"
                        class="mybtn-success d-flex align-items-center justify-content-center ml-auto"
                        style="width: 50%; color: white; background-color: rgb(136, 199, 136);">
                        <i class="material-icons" style="color: white;">input</i>
                        <span style="margin-left: 5px; font-size: 1.1rem;">Prijava</span>
                    </div>

                    <div *ngIf="x.vrstaIDcl != 1 && mod.objPosJedSelected && !x.KrajDatum && mod.session && mod.session.vClansID == x.vClansID"
                        (click)="odjavaTrenOperStart()" data-dismiss="modal"
                        class="mybtn-danger d-flex align-items-center justify-content-center ml-auto"
                        style="width: 50%; color: white;">
                        <i class="material-icons" style="color: white;">logout</i>
                        <span style="margin-left: 5px; font-size: 1.1rem;">Odjava</span>
                    </div>

                </div>

                <div *ngIf="x.vrstaIDcl == 1">

                    <div *ngIf="x.clanarine" style="padding: 0 5px;">
                        Članarina:
                    </div>


                    <div *ngFor="let y of x.clanarine" style="padding: 0 5px;">

                        <div class="row">

                            <div class="col mycol">
                                <div *ngIf="y.claGrupaID != 0" style="font-weight: bold;">
                                    {{y.grpOpis}}
                                </div>
                                <div *ngIf="y.claGrupaID == 0 && y.claVrstaID != 0" style="font-weight: bold;">
                                    {{y.cvOpis + ' (' + y.cvTjedno + 'x tj.)'}}
                                </div>
                                <div [ngStyle]="{'color': clanColor(mod.dateDiffNow(y.claVrijediDO))}">
                                    {{'vrijedi do: ' + (y.claVrijediDO | amDateFormat:'DD.MM.YYYY.')}}
                                </div>
                                <div *ngIf="y.intPlacena == 0" style="color: red; width: 100%;">
                                    ! NIJE PLAĆENA
                                </div>
                                <div *ngIf="y.intPlacena != 0">
                                    {{'Plaćena ' + (y.claDatumUplate | amDateFormat:'DD.MM.YYYY.')}}
                                </div>
                            </div>

                            <div class="col col-3 mycol d-flex align-items-center justfy-content-center">
                                <div style="font-size: 2rem; margin-right: 5px;"
                                    [ngStyle]="{'color': clanColor(mod.dateDiffNow(y.claVrijediDO))}">
                                    {{mod.dateDiffNow(y.claVrijediDO)}}
                                </div>
                                <div>
                                    {{mod.dateDiffNow(y.claVrijediDO) | sklanjanjeDaniClean}}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div *ngIf="!x.clanarine" style="color: darkred; padding: 0 5px; font-size: 0.9rem;">
                        NEMATE VAŽEĆU ČLANARINU
                    </div>

                </div>

            </div>

        </div>

    </div>

    <div *ngIf="!objClanstva && !mod.session" class="card w-100" style="padding: 10px 4px 10px 4px;">
        <div style="font-weight: bold;">
            Poveži se s fitness centrom
        </div>
        <div style="margin-bottom: 10px;">
            Nađi svoj fitnes centar i pošalji im zahtjev za povezivanjem. Nakon što centar potvrdi zahtjev imat ćeš uvid
            u osobne statistike te aktivnosti centra, moći ćeš primati obavijesti i koristiti druge pogodnosti koje nudi
            aplikacija.
        </div>
        <button class="btn mybtn-info w-100" style="color: #004a8b; font-size: 1.1rem;" (click)="noviZahtjevStart()"
            data-dismiss="modal"><i class="fa fa-user-plus" style="margin-right: 5px;" aria-hidden="true"></i> Prikaži
            fitness centre</button>
    </div>

    <div *ngIf="srvClanovi.objNepotZahtjevi && !mod.session" class="w-100">
        <div style="padding: 5px 5px 2px 10px;">
            Poslani zahtjevi:
        </div>
        <div class="row col-card" *ngFor="let x of srvClanovi.objNepotZahtjevi">

            <div class="col" style="padding: 5px 15px;">
                <div style="font-weight: bold;">
                    {{x.Ime}}
                </div>
                <div>
                    Datum slanja: {{x.DatumVfc | amDateFormat:'DD.MM.YYYY. HH:mm'}}
                </div>
                <div style="font-size: 0.8rem;">
                    prije {{srvClanovi.trajanjePosjetaParams(x.DatumVfc)}}
                </div>
            </div>

        </div>
    </div>

</div>



<!--MODAL ODJAVA trener / operater => app.component.html-->
<button id="btnOdjavaTrenOper" hidden data-toggle="modal" data-target="#mdlOdjavaTrenOper">MODAL ODJAVA TRENER /
    OPERATER</button>