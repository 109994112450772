import { Component, OnInit } from '@angular/core';
import { ClanoviService } from '../clanovi.service';
import { ModulService } from '../modul.service';
import { Dvorana, DvoraneService } from '../dvorane.service';

@Component({
  selector: 'app-clan-prijava',
  templateUrl: './clan-prijava.component.html',
  styleUrl: './clan-prijava.component.scss'
})
export class ClanPrijavaComponent implements OnInit {

  constructor(
    public mod: ModulService,
    public srvClanovi: ClanoviService,
    public srvDvorane: DvoraneService
  ) { }

  ngOnInit(): void {

    this.srvClanovi.napraviFrmPrijava(() => {});

  }

  claColor(x) {
    return '#348b5f';
  }

  //Prijava člana u grupu (glavni modal za prijavu)
  prijavaGrupa(x) {

    let obj;
    let vrstaTmp: number = this.srvClanovi.frmPrijava.controls['vrstaIDcl'].value; // this.srvClanovi.frmPrijava.get('vrstaIDc').value;

    let dvoranaIDtren: number = this.srvClanovi.frmPrijava.get('dvoranaIDtren').value;

    obj = {
      KlijentIDtren: this.mod.objPosJedSelected.KlijentIDpos,
      PosJedIDtren: x.grpPosJedID,
      KoriniskIDtren: x.ClanIDvcg,
      clanVrstaIDtren: vrstaTmp,
      GrupaIDtren: x.aktGrpID,
      vrijemePrijava: x.PocetakAkt,
      vrijemeOdjava: x.KrajAkt,
      dvoranaIDtren: dvoranaIDtren
    }

    this.srvClanovi.prijavaGrupa(obj, (rez) => {

      if (rez == 'ok') {
        document.getElementById('btnMdlQrPrijavaClose').click();
        this.mod.msgBox(1, "Član je uspješno prijavljen", 1000);
      } else {
        this.mod.msgBox(2, "Greška! pokušajte ponovno!", 1000);
      }
    });

  }

  //Prijava člana
  prijaviClana() {
    this.srvClanovi.prijaviClana();
    document.getElementById('btnMdlQrPrijavaClose').click();
  }

  objDvorane: Dvorana[];

}
