<div class="container" style="padding: 0px; height: 100%;">

    <div class="row" style="margin: 0px !important; padding-top: 50px; height: 100%; max-height: 100%;">

        <!--Lijeva kolona-->
        <div class="col-md-4 d-none d-md-block" style="margin: 0px !important;">

            <!--Kontrole-->
            <div class="row cardio" style="margin: 0px;">

                <!--btn Nova Grupa-->
                <div class="col mybtn-success d-flex align-items-center justify-content-center"
                    (click)="novaGrupaStart()">
                    <i class="material-icons" style="color: white; font-size: 1.5rem;">
                        exit_to_app
                    </i>
                    <span style="font-size: 1.3rem;">Nova grupa</span>
                </div>

            </div>

            <!--Poslovne jedinice-->
            <div class="card" style="padding: 0px;">
                <app-pos-jedinice (odabranaPjed)="odabranaPosJed($event)"></app-pos-jedinice>
            </div>
            <!--Korisnik component-->
            <!-- <div class="card d-none d-md-block">
                <app-korisnik></app-korisnik>
            </div> -->

        </div>

        <!-- Desna kolona -->
        <div class="col" style="padding: 0px; height: 100%; max-height: 100%;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">

                <div>

                    <div class="d-block d-md-none bgs-dark" *ngIf="srvGrupe.mod.objPosJedSelected" data-toggle="modal"
                        data-target="#modalPrisutni">

                        <app-pos-jed-hamburger></app-pos-jed-hamburger>

                    </div>

                    <div class="cardio">
                        <div class="test">
                            <mat-form-field appearance="fill" style="text-align: center; width: 100%;">
                                <!-- <mat-label>Izbornik:</mat-label> -->
                                <mat-select [(value)]="intTab" (selectionChange)="razvrstajGrupe()">
                                    <mat-option [value]="1">Aktivne grupe</mat-option>
                                    <mat-option [value]="2">Neaktivne grupe</mat-option>
                                    <mat-option [value]="3">Arhiva</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                </div>

                <div class="flex-grow-1" style="overflow-y: scroll; scrollbar-width: none;">

                    <div class="cardio" style="background-color: white;">

                        <div *ngFor="let x of objGrupaShow" (click)="linkGrupaClanovi(x)">

                            <div class="row grp-row">

                                <!-- <div class="col col-2 d-flex justify-content-center align-items-center" style="font-size: 2.2rem; padding: 0;">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                </div> -->

                                <div class="col" style="padding: 0;">
                                    <div style="font-weight: bold;">
                                        {{x.grpOpis}}
                                    </div>
                                    <div style="font-size: 1rem; font-weight: normal;">
                                        Od: {{x.grpDatumOD | amDateFormat:'DD.MM.YYYY.'}}
                                    </div>
                                    <div>
                                        {{'članova: ' + x.clanova + (x.maxCl == 0 ? '' : '/' + x.maxCl)}}
                                    </div>
                                    <div *ngIf="x.pJed">
                                        <div>
                                            {{x.pJed.NazivPos}}
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="x.maxCl != 0"
                                    class="col col-1 d-flex justify-content-center align-items-center">
                                    <div *ngIf="x.maxCl - x.clanova > 0" style="font-size: 2rem; color: #43cf9d;">
                                        {{x.maxCl - x.clanova}}
                                    </div>
                                </div>

                                <div *ngIf="x.maxCl != 0 && (x.maxCl > x.clanova)" class="col col-4 d-flex justify-content-center" style="padding: 2px;">

                                    <google-chart style="max-width: 80px; max-height: 80px;" #chart [type]="'PieChart'"
                                        [data]="x.data" [options]="x.options">
                                    </google-chart>

                                </div>

                                <div *ngIf="x.maxCl - x.clanova <= 0 && x.maxCl != 0"
                                    class="col col-4 d-flex align-items-center justify-content-center"
                                    style="font-size: 1rem; color: #aba6a2; padding: 2px; text-align: center;">
                                    <div>
                                        nema slobodnih mjesta
                                    </div>
                                </div>

                                <div *ngIf="x.maxCl == 0"
                                    class="col col-4 d-flex align-items-center justify-content-center"
                                    style="font-size: 1rem; color: #adacac; padding: 2px;">
                                    <div>
                                        <!-- nije određen max. broj polaznika -->
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div *ngIf="!objGrupaShow" class="row alert alert-success d-flex justify-content-center">
                            Nema grupa za prikaz
                        </div>

                    </div>

                </div>


            </div>

        </div>

    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <!-- <div class="modal-header posjed-mod-header d-flex justify-content-center">
                Odabir poslovne jedinice
            </div> -->

            <div class="modal-body posjed-mod-body">
                <!--btn Nova Grupa-->
                <div style="padding: 5px; margin: 10px 0;">
                    <div class="col mybtn-success" (click)="novaGrupaStart()">
                        <i class="material-icons cp-icon">
                            exit_to_app
                        </i>
                        <span style="font-size: 1.5rem;">Nova grupa</span>
                    </div>
                </div>

                <!--Poslovne jedinice-->
                <app-pos-jedinice (odabranaPjed)="odabranaPosJed($event)"></app-pos-jedinice>

            </div>
            <div class="modal-footer bgs-dark" style="padding: 5px; font-size: 1.3rem;">
                <button type="button" id="btnSidebarClose" class="btn mybtn-cancel" data-dismiss="modal"
                    style="width: 100%;">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Nova Grupa-->
<button [hidden]="true" id="btnModalNovaGrupa" data-toggle="modal" data-target="#modalNovaGrupa"></button>

<div class="modal fade" id="modalNovaGrupa" tabindex="-1" role="dialog" aria-labelledby="modalNovaGrupaLabel"
    aria-hidden="true">

    <div class="modal-dialog" role="document">

        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header bgs-dark d-flex justify-content-center" style="padding: 5px;">
                Nova grupa
            </div>

            <div class="modal-body bgs-dark" style="border-top: solid 0.5px #d1d1d1; font-size: 1.2rem;">

                <form *ngIf="frmNovaGrupa" [formGroup]="frmNovaGrupa">

                    <div class="row">

                        <!--Naziv-->
                        <div class="col-12">
                            <mat-form-field style="width: 100%;">
                                <mat-label>Naziv</mat-label>
                                <input autocomplete="off" matInput type="text" formControlName="grpOpis"
                                    placeholder="Naziv" required>
                                <mat-error
                                    *ngIf="frmNovaGrupa.controls['grpOpis'].touched && frmNovaGrupa.controls['grpOpis'].errors">
                                    Neispravan unos</mat-error>
                            </mat-form-field>
                        </div>


                        <!--Poslovna jednica-->
                        <!-- <div class="col-12">
                            <mat-form-field style="width: 100%;">
                                <mat-label>Odaberite poslovnu jedinicu</mat-label>
                                <mat-select formControlName="grpPosJedID">
                                    <mat-option [value]="'0'">Sve poslovne jedinice</mat-option>
                                    <mat-option *ngFor="let x of objPosJedinice" [value]="x.PoslJedID">{{x.NazivPos}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->

                        <!--Datum osnivanja-->
                        <div class="col-12 d-felx justify-content-start align-items-center">
                            <span style="margin-right: 10px;">Datum osnivanja:</span>
                            <mat-form-field style="width: 50%;">
                                <input matInput name="grpDatumOD" formControlName="grpDatumOD" [matDatepicker]="picker1"
                                    style="text-align: center;">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker touchUi #picker1 disabled="false"></mat-datepicker>

                            </mat-form-field>
                            <mat-error
                                *ngIf="frmNovaGrupa.controls['grpDatumOD'].touched && frmNovaGrupa.controls['grpDatumOD'].errors">
                                Neispravan unos</mat-error>
                        </div>

                        <!--Iznos članarine-->
                        <div class="col-6">
                            <mat-form-field style="width: 100%; text-align: center;">
                                <mat-label>Cijena članarine</mat-label>
                                <input autocomplete="off" matInput type="text" formControlName="grpClanarina"
                                    (blur)="decFormat($event)" placeholder="Cijena članarine" aria-label="grpClanarina"
                                    required>
                                <mat-error
                                    *ngIf="frmNovaGrupa.controls['grpClanarina'].touched && frmNovaGrupa.controls['grpClanarina'].errors">
                                    Neispravan unos</mat-error>
                            </mat-form-field>
                        </div>

                        <!--Maksimalni broj članova-->
                        <div class="col-6">
                            <mat-form-field style="width: 100%; text-align: center;">
                                <mat-label>Maksimalno polaznika (0 = nije određeno)</mat-label>
                                <input autocomplete="off" matInput type="text" formControlName="maxCl"
                                    (blur)="decFormat($event)" placeholder="Cijena članarine" aria-label="maxCl"
                                    required>
                                <mat-error
                                    *ngIf="frmNovaGrupa.controls['maxCl'].touched && frmNovaGrupa.controls['maxCl'].errors">
                                    Neispravan unos</mat-error>
                            </mat-form-field>
                        </div>

                        <div *ngIf="frmNovaGrupa.errors?.err" class="col-12">
                            <mat-error>{{frmNovaGrupa.errors.err}}</mat-error>
                        </div>

                    </div>

                </form>

            </div>

            <div class="modal-footer bgs-dark">

                <div class="row" style="width: 100%;">

                    <div class="col-6">
                        <div class="mybtn-success" (click)="novaGrupaCommit()">
                            <i class="material-icons" style="color: white;">save</i>
                            <span style="margin-left: 5px;">Spremi</span>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="mybtn-cancel" #btnMdlNovaGrupaClose data-toggle="modal"
                            data-target="#modalNovaGrupa">
                            <i class="material-icons">cancel</i>
                            <span style="margin-left: 5px;">Zatvori</span>
                        </div>
                    </div>

                </div>

                <button hidden id="btnNovaGrpClose" data-dismiss="modal"></button>

            </div>

        </div>

    </div>

</div>