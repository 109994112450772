import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModulService, PosJedinice } from './modul.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PosJediniceService {

  constructor(
    private mod: ModulService,
    private router: Router,
    public frmBuilder: FormBuilder,
    private http: HttpClient
  ) { }

  frmPosJed: FormGroup

  //napravi frmPosJed
  napraviFrmPosJed(val: PosJedinice, callback) {

    this.frmPosJed = this.frmBuilder.group({
      PoslJedID: [0],
      KlijentIDpos: [0],
      NazivPos: [],
      AdresaPos: [],
      MjestoIDpos: [0],
      Mjesto: [],
      kapacitet: [5, [Validators.min(1), Validators.max(1000)]],
      aktivna: [0],
      intIsto: [1]
    });

    if (val) {
      this.frmPosJed.patchValue(val);
    }

    callback(this.frmPosJed.value);

  }

  //UPDATE - Start
  unosUpdatePosJed(val: PosJedinice, callback) {

    let url: string = this.mod.webUrl + '/php/posl_jed.php?token=' + this.mod.objKor.token + '&funk=unosUpdatePosJed';
    val['fingerprint'] = this.mod.fingerprint();
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez: string) => {
      callback(rez);
    });

  }

}
