<div class="container" style="padding: 0px; height: 100%; padding-bottom: 5px;">

    <div class="row" style="padding-top: 50px; height: 100%; max-height: 100%;">

        <!--Lijeva kolona-->
        <div class="col-lg-4 col-md-4 d-none d-md-block"
            style="margin: 0px !important; padding-bottom: 30px; overflow: hidden; max-height: 100%; min-width: 300px;">

            <div class="card" style="max-height: 100%; overflow-y: scroll; scrollbar-width: none;">

                <!--Poslovne jedinice-->
                <div *ngIf="mod.objKor && mod.objKor.vrstaID == 2" style="padding: 5px 0px;">
                    <app-pos-jedinice (odabranaPjed)="odabranaPosJed($event)"></app-pos-jedinice>
                </div>

                <!--Članstva component - privatni korisnik-->
                <div *ngIf="mod.objKor && mod.objKor.vrstaID == 1" class="card" style="padding: 0px 0px 0px 0px;">
                    <app-clanstva (dialgTrenOper)="modalTrenOperPosJed($event)" #clanstva></app-clanstva>
                </div>


                <!--Dnevne aktivnosti-->
                <div *ngIf="srvAktivnosti.objVaktivnost" style="padding: 0 10px; color: rgb(134, 66, 66);">
                    {{(danas | amDateFormat:'dddd') + ' ' + (danas | amDateFormat:'DD.MM.YYYY.')}}
                </div>

                <div *ngIf="mod.objKor && (mod.objKor.vrstaID == 2 || mod.session)">

                    <div style="padding: 5px; margin-bottom: 10px;">
                        <div *ngFor="let x of srvAktivnosti.objVaktivnost"
                            [ngClass]="{'u-tijeku': clsUtijeku(x.PocetakAkt, x.KrajAkt), 'zavrsena': clsZavrsena(x.PocetakAkt, x.KrajAkt), 'otkazana': x.statusAkt != 0}"
                            style="border-bottom: dashed 0.5px grey;">

                            <div class="naslov" style="padding: 2px;">
                                {{x.grpOpis}}
                            </div>

                            <div class="row" style="margin: 0;">
                                <div class="col col-6" style="padding: 2px;">
                                    <!-- <div>
                                        {{x.grpOpis}}
                                    </div> -->
                                    <div>
                                        {{x.PocetakAkt | amDateFormat:'HH:mm'}} - {{x.KrajAkt | amDateFormat:'HH:mm'}}
                                    </div>
                                    <div style="font-weight: bold;">
                                        {{x.opisDvo}}
                                    </div>
                                </div>
                                <div *ngIf="x.edit"
                                    class="col d-flex align-items-center justify-content-center align-items-center"
                                    style="padding: 0 5px; cursor: pointer;" (click)="urediAktivnost(x)">
                                    <span class="material-icons" style="font-size: 2.5rem;">
                                        create
                                    </span>
                                </div>
                                <div *ngIf="x.edit" class="col-3 d-flex align-items-center justify-content-center"
                                    style="padding: 0 5px; cursor: pointer;" (click)="clanoviLoginStart(x)">
                                    <i class="material-icons" style="font-size: 3rem;">
                                        people
                                    </i>
                                </div>
                            </div>
                            <div *ngIf="x.statusAkt != 0" class="col d-flex justify-content-center align-items-center"
                                style="padding: 2px; color: red;">
                                OTKAZANO
                            </div>

                            <div class="d-flex">
                                <div *ngFor="let y of x.treneri"
                                    style="padding: 2px; padding-right: 15px; color: #00309f;">
                                    {{y.Ime + ' ' + y.Prezime + ' '}}
                                </div>
                            </div>

                        </div>
                    </div>

                    <!--btn Prikaz aktivnosti-->
                    <div style="padding: 2px;">
                        <button type="button" class="btn mybtn-cancel w-100" style="background-color: #f7f7f7;"
                            routerLink="/termini-pregled"><i class="material-icons"
                                style="font-size: 2rem; margin: 0 5px;">access_time</i> Termini</button>
                    </div>

                    <!--btn Pregled članarina-->
                    <div style="padding: 10px 2px 2px;">
                        <button type="button" class="btn mybtn-cancel w-100" style="background-color: #f7f7f7;"
                            routerLink="/clanarine-pregled">
                            <i class="material-icons" style="font-size: 2rem; margin: 0 5px;">payment</i>
                            Članarine</button>
                    </div>

                    <!-- Dozvola za primanje obavijesti -->
                    <!-- <div style="padding: 2px;">
                    <button type="button" class="btn btn-primary w-100" (click)="mod.requestPermission(test())">obavijesti</button>
                </div> -->

                </div>

            </div>


        </div>

        <!-- Desna kolona-->
        <div class="col" style="padding: 0px 0px 0px 0px; overflow: hidden; max-height: 100%;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">

                <div>

                    <div class="bgs-dark">
                        <app-pos-jed-hamburger></app-pos-jed-hamburger>
                    </div>

                    <div *ngIf="mod.bolUredjaj">
                        <div class="alert alert-info" style="text-align: center; margin: 0;">
                            Dostupan uređaj
                        </div>
                    </div>

                </div>

                <div *ngIf="this.objTrenutnaPrijava && !mod.session" class="alert alert-info"
                    style="text-align: center; margin: 0; opacity: 0.9; background-color: #e4fbff; padding: 5px;">
                    <div>
                        Trenutno ste prijavljeni u
                    </div>

                    <div *ngFor="let x of this.objTrenutnaPrijava">
                        <div style="font-weight: bold;">
                            {{x.NazivPos}}
                        </div>
                        <div>
                            {{x.grpOpis + '  od ' + (x.vrijemePrijava | amDateFormat:'HH:mm') + ' (' + srvClanovi.trajanjePosjetaParams(x.vrijemePrijava) + ')'}}
                        </div>
                        <!-- <div>
                            {{' vrijeme prijave: ' + (x.vrijemePrijava | amDateFormat:'HH:mm') + ' (' + srvClanovi.trajanjePosjetaParams(x.vrijemePrijava) + ')'}}
                        </div> -->
                    </div>



                </div>

                <div class="flex-grow-1 card" style="overflow-y: scroll; scrollbar-width: none;">

                    <div class="rco">


                        <div class="cardio" style="padding-bottom: 30px;">

                            <div class="row" style="text-align: center; margin: 0px;">

                                <!--QR code-->
                                <div *ngIf="mod.objKor && mod.objKor.vrstaID == 1 && !mod.objPosJedSelected"
                                    class="col col-btn" data-toggle="modal" data-target="#modalQR">
                                    <i class="material-icons cp-icon">
                                        filter_center_focus
                                    </i>
                                    <!-- <i class="fa fa-qrcode" style="font-size: 4rem; color: #d4c9c9;" aria-hidden="true"></i> -->
                                    <span>fitNet ID</span>
                                </div>

                                <!--Prijava člana-->
                                <div *ngIf="mod.objPosJedSelected && mod.objPosJedSelected?.PoslJedID"
                                    class="col col-btn" (click)="ucitajNeprijavljene();" data-toggle="modal"
                                    data-target="#modalClanLogin">
                                    <i class="material-icons cp-icon">input</i>
                                    <span>Prijava člana</span>
                                </div>

                                <!--Trenutno prisutni-->
                                <div *ngIf="mod.objPosJedSelected && mod.objPosJedSelected?.PoslJedID"
                                    class="col col-btn" routerLink="/prisutni">
                                    <i class="material-icons cp-icon"
                                        [ngClass]="{'br-prisutnih': prisutnih > 0}">how_to_reg</i>

                                    <span>Prisutni</span>
                                </div>

                                <!--Članovi-->
                                <div *ngIf="mod.objPosJedSelected && mod.objPosJedSelected?.PoslJedID"
                                    class="col col-btn" routerLink="/clanovi">
                                    <i class="material-icons cp-icon">people</i>
                                    <span>Članovi</span>
                                </div>

                                <!--Grupe-->
                                <div *ngIf="bolGrupaShowHide"
                                    class="col col-btn" routerLink="/grupe">
                                    <i class="material-icons cp-icon">people_alt</i>
                                    <span>Grupe</span>
                                </div>

                                <!--Operateri-->
                                <!-- <div *ngIf="mod.objPosJedSelected?.PoslJedID" class="col col-btn">
                                    <i class="material-icons cp-icon">transfer_within_a_station</i> <br />
                                    <span>Operateri</span>
                                </div> -->

                                <!--Obavijesti-->
                                <div *ngIf="true" class="col col-btn d-flex flex-column" style="position: relative;"
                                    (click)="obavijesti()">
                                    <i class="material-icons cp-icon">contact_mail</i>
                                    <div *ngIf="noveObav" class="msg-badge" [ngClass]="{'new-msgs': noveObav}"
                                        style="font-weight: bold;">{{noveObav}}</div>
                                    <span>Obavijesti</span>
                                </div>

                                <!--Termini-->
                                <div class="col col-btn d-flex flex-column" style="position: relative;"
                                    routerLink="/termini-pregled">
                                    <i class="material-icons cp-icon"
                                        [ngClass]="{'new-msgs': mod.objAktSad}">access_time</i>
                                    <span>Termini</span>
                                </div>

                                <!--Članarine - poslovni korisnici / treneri / operateri-->
                                <div *ngIf="mod.objKor && (mod.objKor.vrstaID == 2 || mod.session)"
                                    class="col col-btn d-flex flex-column" style="position: relative;"
                                    routerLink="/clanarine-pregled">
                                    <i class="material-icons cp-icon">payment</i>
                                    <span>Članarine</span>
                                </div>

                                <!--Članarine - članovi-->
                                <div *ngIf="mod.objKor && (mod.objKor.vrstaID == 1 && !mod.session)"
                                    class="col col-btn d-flex flex-column" style="position: relative;"
                                    routerLink="/clanarine-clan">
                                    <i class="material-icons cp-icon">payment</i>
                                    <span>Članarine</span>
                                </div>

                                <!--Dvorane-->
                                <!-- <div *ngIf="mod.objPosJedSelected?.PoslJedID" class="col col-btn">
                                    <i class="material-icons cp-icon">meeting_room</i> <br />
                                    <span>Dvorane</span>
                                </div> -->

                                <!--Oprema-->
                                <!-- <div *ngIf="mod.objPosJedSelected?.PoslJedID" class="col col-btn">
                                    <i class="material-icons cp-icon">sports_cricket</i> <br />
                                    <span>Oprema</span>
                                </div> -->

                                <!--Statistike-->
                                <div *ngIf="true" class="col col-btn" routerLink="/stat-klijent">
                                    <i class="material-icons cp-icon">bar_chart</i>
                                    <span>Statistike</span>
                                </div>

                                <!--Praćenje napretka-->
                                <div class="col col-btn" routerLink="/pn-pregled">
                                    <i class="material-icons cp-icon" aria-hidden="true">show_chart</i>
                                    <span>Napredak</span>
                                </div>

                                <!--Postavke-->
                                <div *ngIf="true" class="col col-btn" routerLink="/postavke">
                                    <i class="material-icons cp-icon">perm_data_setting</i>
                                    <span>Postavke</span>
                                </div>


                                <div *ngIf="mod.objPosJedSelected" class="col-12" style="padding: 0px;">

                                    <div #chartPopunjenost class="card" style="padding: 5px;">

                                        <!-- Poslovna jedinica -->
                                        <div class="row">
                                            <div class="col-md-5 d-flex flex-column" style="min-width: 200px;">
                                                <span>Kapacitet: <span style="font-weight: bold;">{{kapacitet |
                                                        sklanjanjeOsoba}}</span></span>
                                                <span>Prisutno: <span style="font-weight: bold;">{{prisutnih |
                                                        sklanjanjeClan}}</span></span>
                                                <span
                                                    [ngStyle]="{'color': brPrisutnih, 'background-color': 'white'}">Popunjenost:</span>
                                                <div class="d-flex justify-content-center align-items-center"
                                                    [ngStyle]="{color: brPrisutnih}"
                                                    style="height: 100%; font-size: 4rem;">
                                                    <span>{{prisutnih / kapacitet * 100 | number:'1.0-0'}} %</span>
                                                </div>
                                            </div>
                                            <div class="col d-flex justify-content-center align-items-center">
                                                <google-chart #chart [type]="pchPrisutni.type" [data]="pchPrisutni.data"
                                                    [options]="pchPrisutni.options"></google-chart>
                                            </div>
                                            <div *ngIf="objTreneri" class="col"
                                                style="text-align: left; padding: 0 5px;">
                                                <div style="color: grey;">
                                                    Treneri:
                                                </div>
                                                <div *ngFor="let x of objTreneri">
                                                    <div
                                                        [ngStyle]="{'color': x.prisutan == '1' ? '#058544' : 'black' }">
                                                        {{x.Ime + ' ' + x.Prezime}}
                                                    </div>
                                                </div>
                                                <div *ngIf="mod.dvoranePjed" style="margin-top: 15px;">
                                                    <button class="btn mybtn-cancel w-100" data-toggle="modal"
                                                        [ngStyle]="{'color': objDvoraneChart ? '#058544' : 'black' }"
                                                        data-target="#mdlPopDvor"><i class="material-icons cp-icon"
                                                            [ngStyle]="{'color': objDvoraneChart ? '#058544' : 'black' }"
                                                            style="font-size: 1.3rem; margin-right: 10px;">meeting_room</i>
                                                        Dvorane</button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div style="text-align: left; padding: 0; margin: 2px 0;">
                                        <!-- Aktivnosti u tijeku -->
                                        <div>
                                            <div style="padding: 0px 15px; color: #81af8f;">
                                                {{mod.objAktSad ? 'Aktivnosti u tijeku:' : 'Nema aktivnosti u tijeku'}}
                                            </div>

                                            <div class="row card flex-row" *ngFor="let x of mod.objAktSad"
                                                style="padding: 0px; margin: 2px 0;">

                                                <div class="col-9" style="padding: 5px 10px;">
                                                    <div>
                                                        <span
                                                            style="font-size: 1.2rem; color: #47a865;">{{x.grpOpis}}</span>
                                                    </div>
                                                    <div>
                                                        {{x.PocetakAkt | amDateFormat:'HH:mm'}}h - {{x.KrajAkt |
                                                        amDateFormat:'HH:mm'}}h <span style="color: grey;">{{' još ' +
                                                            srvClanovi.trajanjePosjetaParamsDo(x.KrajAkt)}}</span>
                                                    </div>
                                                    <div class="d-flex">
                                                        <div>
                                                            <span style="font-weight: bold; margin-right: 5px;">
                                                                {{x.opisDvo}}
                                                            </span>
                                                        </div>
                                                        <div *ngFor="let z of x.treneri"
                                                            style="margin-right: 5px; color: #00309f;">
                                                            {{z.Ime + ' ' + z.Prezime}}
                                                        </div>

                                                    </div>

                                                    <div class="row" *ngFor="let y of x.posJed" style="margin: 0;">
                                                        <div class="col" style="padding: 0;">
                                                            {{y.NazivPos}} <span style="color: grey;">({{y.count |
                                                                sklanjanjeClan}})</span>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div *ngIf="x.edit"
                                                    class="col d-flex justify-content-center align-items-center"
                                                    style="padding: 0 5px; cursor: pointer;"
                                                    (click)="clanoviLoginStart(x)">
                                                    <i class="material-icons" style="font-size: 4rem; color: #7fd3a9;">
                                                        people
                                                    </i>
                                                </div>


                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <!-- Popunjenost - privatni korisnik (član)-->
                        <div *ngIf="!mod.objPosJedSelected" style="margin-top: 1px;">

                            <div class="card" *ngFor="let x of objClanCentri" style="margin: 2px;">

                                <div class="row" style="margin: 0;">
                                    <div class="col" style="padding: 5px;">
                                        <div style="font-size: 1.1rem; font-weight: bold;">
                                            {{x.NazivPos}}
                                        </div>
                                        <div style="font-size: 0.9rem;">
                                            {{x.naziv}}
                                        </div>
                                        <div>
                                            <span>Kapacitet: <span style="font-weight: bold;">{{x.kapacitet |
                                                    sklanjanjeOsoba}}</span></span>
                                        </div>
                                        <div>
                                            <span>Prisutno: <span style="font-weight: bold;">{{x.prisutnih |
                                                    sklanjanjeClan}}</span></span>
                                        </div>

                                        <div class="d-flex align-items-center">

                                            <div style="margin-right: 20px;">
                                                <span
                                                    [ngStyle]="{'color': x.options.colors[0], 'background-color': 'white'}">Popunjenost:</span>
                                            </div>

                                            <div class="d-flex justify-content-center align-items-center"
                                                [ngStyle]="{color: x.options.colors[0]}" style="font-size: 2rem;">

                                                <span>{{x.prisutnih / x.kapacitet * 100 | number:'1.0-0'}} %</span>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="col d-flex justify-content-center" style="padding: 10px;">
                                        <google-chart #chart [type]="'PieChart'" [data]="x.data" [options]="x.options">
                                        </google-chart>
                                    </div>

                                    <div *ngIf="x.objTreneri" class="col col-12 col-lg-3" style="text-align: left; padding: 0 5px;">
                                        <div style="color: grey;">
                                            Treneri:
                                        </div>
                                        <div *ngFor="let y of x.objTreneri">
                                            <div [ngStyle]="{'color': y.prisutan == '1' ? '#058544' : 'black' }">
                                                {{y.Ime + ' ' + y.Prezime}}
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div *ngFor="let y of x.aktivByPjedID" class="row">
                                    <div class="col" style="padding: 5px;">
                                        <span
                                            style="font-size: 1.1rem; color: #47a865; font-weight: bold;">{{y.grpOpis}}</span>
                                        {{y.PocetakAkt | amDateFormat:'HH:mm'}}h - {{y.KrajAkt | amDateFormat:'HH:mm'}}h
                                        <span style="color: grey;">{{' još ' +
                                            srvClanovi.trajanjePosjetaParamsDo(y.KrajAkt)}}</span>
                                        <span style="margin-left: 5px; margin-right: 10px;">({{y.posJed[0]?.count |
                                            sklanjanjeClan}})</span> <span
                                            style="font-weight: bold; margin-right: 10px;">{{y.opisDvo}}</span>
                                        <span *ngFor="let z of y.treneri"
                                            style="margin-right: 10px; color: blue;">{{z.Ime + ' ' + z.Prezime}}</span>
                                        <!-- <div class="row" *ngFor="let z of y.posJed" style="margin: 0;">
                                            <div class="col" style="padding: 0;">
                                                {{z.NazivPos}} <span style="color: grey;">({{z.count |
                                                    sklanjanjeClan}})</span>
                                            </div>
                                        </div> -->
                                    </div>
                                    <!-- <div class="col col-12" style="padding: 0px 5px; color: #00309f;">
                                        <div *ngFor="let z of y.treneri">
                                            <div>
                                                {{z.Ime}}
                                            </div>
                                        </div>
                                    </div> -->
                                </div>

                            </div>



                        </div>

                        <!-- <div class="row">
                            <div class="col" style="padding: 0px;">
                                <div #chartPopunjenost class="card" style="padding: 5px;">
                                    <div class="row">
                                        <div class="col-md-6 d-flex flex-column">
                                            <span>Kapacitet: <span style="font-weight: bold;">{{kapacitet}}</span> članova</span>
                                            <span>Prisutno: <span style="font-weight: bold;">{{prisutnih}}</span></span>
                                            <span [ngStyle]="{'color': brPrisutnih, 'background-color': 'white'}">Popunjenost:</span>
                                            <div class="d-flex justify-content-center align-items-center" [ngStyle]="{color: brPrisutnih}" style="height: 100%; font-size: 4rem;">
                                                <span>{{prisutnih / kapacitet * 100 | number:'1.0-0'}} %</span>
                                            </div>
                                        </div>
                                        <div class="col d-flex justify-content-center align-items-center">
                                            <google-chart #chart [type]="pchPrisutni.type" [data]="pchPrisutni.data" [options]="pchPrisutni.options"></google-chart>
                                        </div>
                                    </div>
        
                                </div>
                            </div>
                        </div> -->



                    </div>

                </div>

            </div>

            <!-- <button type="button" class="btn mybtn-cancel" (click)=" test()">test session</button> -->

        </div>

    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" style="height: 100vh;" id="modalPrisutni" tabindex="-1" role="dialog"
    aria-labelledby="modalPrisutniLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="background-color: #c4cbca;">
            <div class="modal-body"
                style="padding: 5px; height: calc(100vh - 50px); overflow: auto; scrollbar-width: none;">

                <!--Poslovne jedinice-->
                <app-pos-jedinice (odabranaPjed)="odabranaPosJed($event)" *ngIf="mod.objKor && mod.objKor.vrstaID == 2">
                </app-pos-jedinice>

                <!--Članstva-->
                <div *ngIf="mod.objKor && mod.objKor.vrstaID == 1">
                    <app-clanstva (dialgTrenOper)="modalTrenOperPosJed($event)" #clanstva></app-clanstva>
                </div>

                <div class="card">

                    <!--Dnevne aktivnosti-->
                    <div *ngIf="srvAktivnosti.objVaktivnost" style="padding: 0 10px; color: rgb(134, 66, 66);">
                        Današnje aktivnosti
                    </div>

                    <div *ngIf="mod.objKor && (mod.objKor.vrstaID == 2 || mod.session)">
                        <div style="padding: 10px; margin-bottom: 10px;">
                            <div *ngFor="let x of srvAktivnosti.objVaktivnost"
                                [ngClass]="{'u-tijeku': clsUtijeku(x.PocetakAkt, x.KrajAkt), 'zavrsena': clsZavrsena(x.PocetakAkt, x.KrajAkt), 'otkazana': x.statusAkt != 0}"
                                style="border-bottom: dashed 0.5px grey;">

                                <div style="padding: 2px;">
                                    {{x.grpOpis}}
                                </div>

                                <div class="row" style="margin: 0;">
                                    <div class="col col-6" style="padding: 2px;">
                                        <!-- <div>
                                            {{x.grpOpis}}
                                        </div> -->
                                        <div>
                                            {{x.PocetakAkt | amDateFormat:'HH:mm'}} - {{x.KrajAkt |
                                            amDateFormat:'HH:mm'}}
                                        </div>
                                        <div style="font-weight: bold;">
                                            {{x.opisDvo}}
                                        </div>
                                    </div>
                                    <div class="col d-flex align-items-center justify-content-center align-items-center"
                                        style="padding: 0 5px; cursor: pointer;" (click)="urediAktivnost(x)">
                                        <i class="material-icons" style="font-size: 2.5rem;">
                                            create
                                        </i>
                                    </div>
                                    <div class="col-3 d-flex align-items-center justify-content-center"
                                        style="padding: 0 5px; cursor: pointer;" (click)="clanoviLoginStart(x)">
                                        <i class="material-icons" style="font-size: 3rem;">
                                            people
                                        </i>
                                    </div>
                                </div>
                                <div *ngIf="x.statusAkt != 0"
                                    class="col d-flex justify-content-center align-items-center"
                                    style="padding: 2px; color: red;">
                                    OTKAZANO
                                </div>

                            </div>
                        </div>

                        <!--btn Prikaz aktivnosti-->
                        <div style="padding: 2px;">
                            <button type="button" class="btn mybtn-cancel w-100" style="background-color: #f7f7f7;"
                                routerLink="/termini-pregled" data-dismiss="modal">
                                <i class="material-icons">access_time</i>
                                Termini</button>
                        </div>

                        <!--btn Pregled članarina-->
                        <div style="padding: 10px 2px 2px;">
                            <button type="button" class="btn mybtn-info w-100" style="background-color: #f7f7f7;"
                                routerLink="/clanarine-pregled" data-dismiss="modal"><i
                                    class="material-icons">payment</i>
                                Članarine</button>
                        </div>

                        <!-- Dozvola za primanje obavijesti -->
                        <!-- <div>
                        <button type="button" class="btn btn-primary" (click)="mod.requestPermission(test())">obavijesti</button>
                    </div> -->

                        <!-- <div>
                    <div>
                        Aktivnosti u tijeku
                    </div>

                    <div class="row" *ngFor="let x of mod.objAktSad" style="margin: 0;">
                        <div class="col">
                            {{x.grpOpis}}
                        </div>
                    </div>
                </div> -->
                    </div>

                </div>

            </div>

            <div class="modal-footer" style="padding: 5px 5px 15px 5px; font-size: 1.2rem; margin: 5px 0px;">
                <div id="btnSidebarClose" style="width: 100%;" class="mybtn-cancel" data-dismiss="modal">Zatvori</div>
            </div>
        </div>
    </div>
</div>



<!--MODAL Aktiviran račun-->
<button hidden="true" id="btnPoslano" class="btn btn-info" data-toggle="modal" data-target="#modalPoslanEmail">Modal
    poslan e-mail</button>

<div class="modal fade" id="modalPoslanEmail" tabindex="-1" role="dialog" aria-labelledby="Modal Poslan email"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="modalPoslanEmailLabel">Dobro došli u fitNet</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center;">
                <div style="font-size: 1.2rem;">
                    Želimo Vam ugodno druženje uz rekreaciju!
                </div>
            </div>
            <div class="modal-footer">
                <button id="btnPoslanoClose" type="button" class="btn btn-outline-success btn-sm btn-block"
                    data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!--MODAL QR Code-->
<div class="modal fade" id="modalQR" tabindex="-1" role="dialog" aria-labelledby="Modal QR" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="modaQRLabel">fitNet ID</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="align-self: center;">
                <qrcode *ngIf="mod.objKor" [width]="400" [qrdata]="fitNetID()"></qrcode>
            </div>
            <div class="modal-footer d-block">
                <button id="mdlQrClose" type="button" class="btn mybtn-cancel w-100"
                    data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Lista neprijavljenih-->
<button hidden id="btnModalClanLogin" type="button" class="btn btn-primary" data-toggle="modal"
    data-target="#modalClanLogin"></button>
<div class="modal fade" id="modalClanLogin" tabindex="-1" role="dialog" aria-labelledby="modalClanLogin"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header d-block" style="padding: 2px 5px;">
                <div class="d-flex" style="padding: 5px;">
                    <h6 class="modal-title" id="modalClanLoginLabel">Prijava člana</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="row-pretraga">

                    <!--PRETRAGA-->
                    <div class="row" style="margin: 0px;">
                        <!--Input-->
                        <div class="col input-slim" style="text-align: center; padding: 0;">
                            <!-- <i class="material-icons"
                                style="text-align:center; vertical-align: middle; font-size: 2rem;">search </i> -->
                            <mat-form-field style="width: 100%;">
                                <input autocomplete="off" name="pretraga" [(ngModel)]="srvClanovi.strPretraga2"
                                    (ngModelChange)="srvClanovi.filtrirajNeprijavljene(true, srvClanovi.strPretraga2)"
                                    matInput placeholder="pretraga..">
                                <button mat-button *ngIf="srvClanovi.strPretraga2" matSuffix aria-label="Clear"
                                    (click)="pretragaClear(true)">X</button>
                                <button mat-button *ngIf="!srvClanovi.strPretraga2" matSuffix aria-label="search"
                                    (click)="pretragaClear(true)"><i class="material-icons"
                                        style="text-align:center; vertical-align: middle; font-size: 1.5rem;">search
                                    </i></button>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-body" style="padding: 2px; scrollbar-width: none;">

                <form>

                    <div *ngFor="let x of srvClanovi.fltNeprijavljeni"
                        (click)="postaviFrmPrijava(mod.objPosJedSelected.PoslJedID, x.clanID, x.Ime + ' ' + x.Prezime, x.vrstaIDcl)"
                        data-dismiss="modal" data-toggle="modal" data-target="#modalPrijava">

                        <div *ngIf="mod.objKor.vrstaID == 2 || (mod.objKor.vrstaID == 1 && mod.session?.clanVrstaIDtren == 2 && x.vrstaIDcl == 1) || mod.session?.clanVrstaIDtren == 3"
                            class="btn-lst-clanovi">

                            <div class="row">
                                <div class="col d-flex" style="align-items: center;">
                                    <!-- <i class="material-icons"
                                        style="color: rgb(233, 233, 233); font-size: 1.5rem;">exit_to_app</i> -->
                                    <span style="color: rgb(85, 5, 5);">{{x.Ime}} {{x.Prezime}}</span>
                                </div>
                            </div>
                            <div style="padding: 0 15px; font-size: 1.2rem;">
                                {{x.Adresa && x.Mjesto ? x.Adresa + ', ' + x.Mjesto : x.Adresa ? x.Adresa : x.Mjesto}}
                            </div>

                            <div *ngIf="x.vrstaIDcl == '2'" style="font-size: 1.1rem; color: blue; padding: 0px 15px;">
                                Trener
                            </div>

                            <div *ngIf="x.vrstaIDcl == '3'" style="font-size: 1.1rem; color: green; padding: 0px 15px;">
                                Operater
                            </div>

                        </div>

                    </div>

                    <div class="alert alert-success" *ngIf="!srvClanovi.fltNeprijavljeni?.length">
                        Nema rezultata za prikaz
                    </div>

                </form>


            </div>
            <div class="modal-footer" style="padding: 15px 2px;">
                <button id="btnClLogin" type="button" class="btn mybtn-cancel w-100"
                    data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!--MODAL PRIJAVA ČLANA-->
<button id="btnModalPrijava" hidden="true" data-toggle="modal" data-target="#btnModalPrijava"></button>

<div class="modal fade" id="modalPrijava" tabindex="-1" role="dialog" aria-labelledby="modalPrijavaLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-flex justify-content-between align-items-center" style="padding: 5px;">
                <div>
                    Prijava člana
                </div>
                <div style="margin-right: 15px; font-size: 2rem;">
                    <span class="badge rounded-pill text-dark" style="background-color: #e1e1e1;">{{tjPosjeta}}</span>
                </div>

            </div>
            <div class="modal-body" style="padding: 5px;">

                <div style="text-align: center;">

                    <p style="font-size: 1.3rem; margin: 0; padding-left: 5px;">
                        {{srvClanovi.frmPrijava.controls['ime'].value}}</p>

                    <div style="padding-left: 5px;">

                        <div *ngIf="clanarineByClan && srvClanovi.frmPrijava.get('vrstaIDcl').value == 1">
                            <div *ngFor="let x of clanarineByClan" class="d-flex justify-content-center"
                                [ngStyle]="{'color': claColor(x)}">

                                <div>
                                    {{x.cvOpis2}}
                                </div>
                                <!-- <div *ngIf="x.intPlacena == 1" style="margin-left: 10px;">
                                    [{{x.cvTjedno ? x.cvTjedno + 'x tj.' : 'neograničeno'}}]
                                </div> -->
                                <div style="margin-left: 10px;">
                                    <!-- {{'[ ' + (x.cvTjedno ? x.cvTjedno + 'x tj.' : 'neograničeno') + ' ]'}}  --><span
                                        *ngIf="x.intPlacena == 0" style="color: red; font-weight: bold;">!</span>
                                </div>
                                <div style="margin-left: 20px;">
                                    {{' do ' + (x.claVrijediDO | amDateFormat: 'DD.MM.YYYY.')}}
                                </div>

                            </div>
                        </div>

                        <div *ngIf="!clanarineByClan && srvClanovi.frmPrijava.get('vrstaIDcl').value == 1"
                            style="color: #971111" Style="padding: 0 10px">
                            nema važeću članarinu
                        </div>

                        <div *ngIf="srvClanovi.frmPrijava.get('vrstaIDcl').value == 2" style="color: blue;">
                            Trener
                        </div>

                        <div *ngIf="srvClanovi.frmPrijava.get('vrstaIDcl').value == 3" style="color: green;">
                            Operater
                        </div>

                    </div>

                </div>


                <form [formGroup]="srvClanovi.frmPrijava" class="input-slim" style="text-align: center; padding: 5px;">

                    <div class="row">

                        <div class="col col-6" style="padding: 2px;">
                            <mat-form-field style="text-align: center; width: 100%;">
                                <mat-label>Datum</mat-label>
                                <input matInput name="dat2" formControlName="priDat" style="text-align: center;"
                                    [matDatepicker]="picker1" placeholder="Datum">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker touchUi #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col col-3" style="padding: 2px;">
                            <mat-form-field style="text-align: center; width: 100%;">
                                <mat-label>Sati</mat-label>
                                <input matInput type="tel" formControlName="priH" placeholder="Sati"
                                    style="text-align: center;" required>
                            </mat-form-field>
                        </div>

                        <div class="col col-3" style="padding: 2px;">
                            <mat-form-field style="text-align: center; width: 100%;">
                                <mat-label>Min.</mat-label>
                                <input matInput type="tel" formControlName="priMin" placeholder="Min."
                                    style="text-align: center;" required>
                            </mat-form-field>
                        </div>

                    </div>

                    <div *ngIf="objDvorane" style="margin: 10px 0;">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Dvorana</mat-label>
                            <mat-select formControlName="dvoranaIDtren">
                                <mat-option [value]="'0'">-</mat-option>
                                <mat-option *ngFor="let x of objDvorane" [value]="x.dvoID">
                                    {{x.opisDvo}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-error
                            *ngIf="srvClanovi.frmPrijava.controls['priDat'].errors || srvClanovi.frmPrijava.controls['priH'].errors || srvClanovi.frmPrijava.controls['priMin'].errors">
                            Sva polja moraju biti ispravno popunjena</mat-error>
                    </div>

                </form>

                <div class="row" style="width: 100%; margin-top: 2px;">
                    <div class="col" style="padding: 5px;">
                        <button type="submit"
                            class="btn {{srvClanovi.frmPrijava.valid ? 'mybtn-success' : 'mybtn-danger'}}"
                            (click)="prijaviClana()" style="width: 100%;">PRIJAVI</button>
                    </div>

                </div>


                <div *ngIf="aktZaClana">

                    <div style="margin: 15px 0; text-align: center; background-color: #dfdfdf; padding: 5px;">
                        Prijava u grupu:
                    </div>

                    <div *ngFor="let x of aktZaClana" style="padding: 3px 0;">

                        <div class="card" style="padding: 8px;">

                            <div style="font-weight: bold;">
                                {{x.grpOpis + ' ' + (x.PocetakAkt | amDateFormat: 'HH:mm') + ' - '
                                + (x.KrajAkt | amDateFormat: 'HH:mm') + 'h'}}
                            </div>

                            <div *ngFor="let y of x.clanarine" class="d-flex">
                                <div [ngStyle]="{'color': claColor(x)}" style="margin-right: 5px;">
                                    {{'Članarina do ' + (y.claVrijediDO | amDateFormat: 'DD.MM.YYYY.')}}
                                </div>
                                <div *ngIf="y.intPlacena == 0" style="color: red; font-weight: bold;">
                                    !
                                </div>
                            </div>

                            <div *ngIf="!x.clanarine" style="color: #971111">
                                nema važeću članarinu
                            </div>

                            <div>
                                <button type="button" class="btn mybtn-success" (click)="prijavaGrupa(x)"
                                    style="width: 100%;">PRIJAVI</button>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
            <div class="modal-footer d-block" style="padding: 0;">

                <div style="padding: 5px; margin-top: 15px;">
                    <button id="btnPrijavaClose" type="button" class="btn mybtn-cancel" style="width: 100%;"
                        data-dismiss="modal">ODUSTANI</button>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal Prijava trenera / operatera (posjed by KlijentID) -->
<button id="btnModalPosJedTrenOper" hidden="true" data-toggle="modal" data-target="#modalPosJedTrenOper"></button>

<div class="modal fade" id="modalPosJedTrenOper" tabindex="-1" role="dialog" aria-labelledby="modalPosJedTrenOper"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header">
                <div class="w-100 d-flex align-items-center">
                    <span *ngIf="pjedTmp && mod.frmprijavaTrenOper.get('PosJedIDtren').value == 0;"
                        class="btn btn-success" style="background-color: #b4ffe3; color: black;"
                        (click)="pjedTmp = null"><i class="fa fa-arrow-circle-left" aria-hidden="true"></i> Odabir
                        poslovne
                        jedinice</span>
                    <span class="ml-auto">{{pjedTmp ? pjedTmp.NazivPos : 'Prijava'}}</span>
                </div>
            </div>
            <div class="modal-body" style="overflow: hidden;">

                <div *ngIf="!pjedTmp" [@listFadeIn]="objPosJedTrenOper?.length">
                    <div *ngFor="let x of objPosJedTrenOper">
                        <div class="btns-o-blue" style="font-size: 1.5rem;" (click)="postaviPosJedTrenOper(x)">
                            {{x.NazivPos}}
                        </div>
                    </div>
                </div>


                <div>
                    <div class="card d-flex justify-content-center align-items-center" *ngIf="pjedTmp" @slideIn>

                        <form [formGroup]="mod.frmprijavaTrenOper">

                            <!--Datepicker Datum OD-->
                            <mat-form-field style="width: 170px; margin-right: 15px;">
                                <mat-label>Datum</mat-label>
                                <input matInput name="dat3" formControlName="priDat" style="text-align: center;"
                                    [matDatepicker]="picker3" placeholder="Datum">
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker touchUi #picker3></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field style="width: 60px;">
                                <mat-label>Sati</mat-label>
                                <input matInput type="tel" style="text-align: center;" formControlName="priH"
                                    placeholder="Sati" required>
                            </mat-form-field> :

                            <mat-form-field style="width: 60px;">
                                <mat-label>Min.</mat-label>
                                <input matInput type="tel" style="text-align: center;" formControlName="priMin"
                                    placeholder="Min." required>
                            </mat-form-field>

                            <mat-error
                                *ngIf="srvClanovi.frmPrijava.controls['priDat'].errors || srvClanovi.frmPrijava.controls['priH'].errors || srvClanovi.frmPrijava.controls['priMin'].errors">
                                Sva polja moraju biti ispravno popunjena
                            </mat-error>

                        </form>

                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <div class="row" style="width: 100%;">
                    <div *ngIf="pjedTmp" class="col col-6" style="padding: 2px;">
                        <button *ngIf="pjedTmp" type="submit" class="btn mybtn-success" style="width: 100%;"
                            (click)="prijavaTrenOper()">PRIJAVA</button>
                    </div>
                    <div class="col" style="padding: 2px;">
                        <button id="btnModalPosJedTrenOperClose" type="button" class="btn mybtn-cancel"
                            style="width: 100%;" data-dismiss="modal" (click)="pjedTmp = null;">ODUSTANI</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal zahtjev za povezivanje -->
<button type="button" hidden id="btnMdlZahtjevClFit" class="btn btn-primary" data-toggle="modal"
    data-target="#mdlZahtjevClFit">
    Modal zahtjev za povezivanje / član - fitNet
</button>

<div class="modal fade" id="mdlZahtjevClFit" tabindex="-1" role="dialog" aria-labelledby="mdlZahtjevClFitLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlZahtjevClFitLabel">Zahtjev za povezivanjem s fitness centrom</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div>
                    <form *ngIf="srvClanovi.frmZahtjevClFit" [formGroup]="srvClanovi.frmZahtjevClFit">

                        <div class="d-flex">
                            <div class="d-flex justify-content-center align-items-center"
                                style="padding: 10px; font-size: 1.3rem;">
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </div>

                            <div style="width: calc(100% - 40px);">
                                <mat-form-field style="width: 100%; font-size: 1.2rem;">
                                    <input autocomplete="off" type="text"
                                        placeholder="upišite dio iz naziva fitness centra" aria-label="naziv" matInput
                                        formControlName="naziv" [matAutocomplete]="auto">
                                    <button mat-button *ngIf="srvClanovi.frmZahtjevClFit.controls['naziv'].value"
                                        matSuffix aria-label="Clear"
                                        (click)="srvClanovi.frmZahtjevClFit.controls['naziv'].setValue('')">X</button>
                                    <mat-error *ngIf="srvClanovi.frmZahtjevClFit.controls['naziv'].errors?.required">
                                        Polje je obavezno za unos</mat-error>
                                    <mat-error *ngIf="srvClanovi.frmZahtjevClFit.controls['naziv'].errors?.mustMatch">
                                        Upišite dio naziva te odaberite s liste</mat-error>
                                    <mat-error *ngIf="srvClanovi.frmZahtjevClFit.controls['nazivChk'].errors?.required">
                                        Upišite dio naziva te odaberite s liste</mat-error>
                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                        <mat-option *ngFor="let x of srvClanovi.fltPoslovniKor" [value]="x.Ime"
                                            (onSelectionChange)="srvClanovi.postaviZahtjevCentar(x)" multiLineOption>
                                            {{x.Ime + ', ' + x.Adresa + ' ' + x.Mjesto}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>

                        </div>
                    </form>
                </div>

            </div>
            <div class="modal-footer d-block" style="padding: 2px;">
                <div class="row">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success" style="width: 100%;"
                            (click)="srvClanovi.posaljiZahtjev()"><i class="fa fa-paper-plane-o" aria-hidden="true"
                                style="color: white;"></i> Pošalji zahtjev</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button id="btnZahtjevClose" type="button" class="btn mybtn-cancel" style="width: 100%;"
                            data-dismiss="modal"><i class="fa fa-times" aria-hidden="true"></i> Otkaži</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Lista članova za prijavu-->
<button hidden="true" id="btnMdlListaClPrijava" data-toggle="modal" data-target="#mdlListaClPrijava"></button>

<div class="modal fade" id="mdlListaClPrijava" tabindex="-1" role="dialog" aria-labelledby="mdlListaClPrijava"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-block" style="font-size: 1.3rem; color: #1d4c6c; padding: 15px 15px 5px;">
                <div *ngIf="aktivnostTmp">
                    <i *ngIf="bolGrpGost" class="material-icons" style="cursor: pointer;"
                        (click)="bolGostSearch = !bolGostSearch">search</i> {{srvGrupe.objGrupa.grpOpis + ' ' +
                    (aktivnostTmp.PocetakAkt | amDateFormat:'HH:mm') + ' - ' + (aktivnostTmp.KrajAkt
                    | amDateFormat:'HH:mm')}}
                </div>

                <!--PRETRAGA-->
                <div *ngIf="bolGostSearch" class="row" style="margin: 0px;">
                    <!--Input-->
                    <div class="col w-100" style="text-align: center; padding: 0;">
                        <mat-form-field style="width: 100%;">
                            <input autocomplete="off" type="search" name="pretraga"
                                [(ngModel)]="srvClanovi.strPretraga2"
                                (ngModelChange)="srvClanovi.filtrirajNeprijavljene(false, srvClanovi.strPretraga2)"
                                matInput placeholder="pretraga..">
                            <!-- <button mat-button *ngIf="srvClanovi.strPretraga2" matSuffix mat-icon-button aria-label="Clear" (click)="pretragaClear()">X</button> -->
                        </mat-form-field>
                    </div>
                </div>

            </div>

            <div class="modal-body" style="padding: 10px 7px; font-size: 1.5rem;">

                <!--članovi grupe-->
                <div *ngIf="!bolGrpGost">
                    <div class="row" *ngFor="let x of srvGrupe.objClanoviZaPrijavu; let i = index"
                        [ngClass]="{'cl-nepr': !x.trenID}" style="cursor: pointer;" (click)="clanChk(x, i)">
                        <div class="col col-2 d-flex justify-content-center align-items-center"
                            style="font-size: 2.5rem;">
                            <div *ngIf="!x.trenID" class="fa fa-square-o" style="align-items: center;"
                                aria-hidden="true">
                            </div>
                            <div *ngIf="x.trenID" class="fa fa-check-square-o" aria-hidden="true"></div>
                        </div>
                        <div class="col d-flex align-items-center">
                            <div>
                                {{x.Ime + ' ' + x.Prezime}}
                            </div>
                            <div class="d-flex align-items-center"
                                style="margin-left: 10px; color:#6ea1ff; font-size: 1.3rem;">
                                {{x.vcgID ? '' : ' - Gost'}}
                            </div>

                        </div>
                    </div>
                    <div *ngIf="!this.srvGrupe.objClanoviZaPrijavu" class="alert alert-info"
                        style="text-align: center;">
                        Nema dostupnih članova grupe
                    </div>
                </div>

                <!--Gosti-->
                <div *ngIf="bolGrpGost">
                    <div *ngFor="let x of srvClanovi.fltNeprijavljeni" (click)="potvrdiGosta(x)"
                        style="cursor: pointer;">
                        <div *ngIf="x.vrstaIDcl == '1'" class="row"
                            style="margin: 0; padding: 8px; border-bottom: solid 0.5px #dbdbdb;">
                            <div class="col" style="padding: 2px;">

                                <div>
                                    {{x.Ime}} {{x.Prezime}}
                                </div>

                                <div style="font-size: 1.1rem;">
                                    {{x.Adresa && x.Mjesto ? x.Adresa + ', ' + x.Mjesto : x.Adresa ? x.Adresa :
                                    x.Mjesto}}
                                </div>

                                <div *ngIf="x.vrstaIDcl == '2'" style="font-size: 1.1rem; color: blue;">
                                    Trener
                                </div>

                                <div *ngIf="x.vrstaIDcl == '3'" style="font-size: 1.1rem; color: green;">
                                    Operater
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="modal-footer d-block" style="padding: 2px;">

                <button hidden id="btnMdlListaClPrijavaClose" data-dismiss="modal"></button>
                <div *ngIf="!bolGrpGost" class="row" style="margin: 0; padding: 0;">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn btns-lo-grey w-100" style="font-size: 1.3rem;"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn btns-info w-100" style="font-size: 1.3rem;"
                            (click)="prijaviGosta(aktivnostTmp)">Svi članovi</button>
                    </div>
                </div>

                <div *ngIf="bolGrpGost" class="row" style="margin: 0; padding: 0;">
                    <!-- <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn btns-info w-100" style="font-size: 1.3rem;"
                            (click)="prijaviGosta(aktivnostTmp)">Prijavi</button>
                    </div> -->
                    <div class="col" style="padding: 2px;">
                        <button type="button" class="btn btns-lo-grey w-100" style="font-size: 1.3rem;"
                            (click)="bolGrpGost = false; bolGostSearch = false; srvClanovi.strPretraga2 = ''">Otkaži</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal - uređivanje termina -->
<button hidden="true" id="btnAktEdit" class="btn btn-info" data-toggle="modal" data-target="#mdlAktEdit">Uređivanje
    termina aktivnosti</button>

<div class="modal fade" id="mdlAktEdit" tabindex="-1" role="dialog" aria-labelledby="Modal uređivanje aktivnosti"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlAktEditLabel">Uređivanje termina</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center; padding: 5px;">
                <app-aktivnost-edit></app-aktivnost-edit>
            </div>
            <div class="modal-footer d-block" style="padding: 5px;">
                <div class="row">
                    <div class="col" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100"
                            (click)="spremiAktivnost()">SPREMI</button>
                    </div>
                    <div class="col" style="padding: 2px;">
                        <button id="btnMdlAktEditClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal - popunjenost dvorana -->
<button hidden="true" id="btnPopDvor" class="btn btn-info" data-toggle="modal" data-target="#mdlPopDvor">Popunjenost
    dvorana</button>

<div class="modal fade" id="mdlPopDvor" tabindex="-1" role="dialog" aria-labelledby="Modal opounjenost dvorana"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlPopDvorLabel">Popunjenost dvorana</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center; padding: 5px;">

                <!-- Dvorane -->
                <div>
                    <div *ngFor="let zy of objDvoraneChart" class="d-flex justify-content-between"
                        style="padding: 5px;">
                        <div style="margin-right: 20px; text-align: left;">
                            <div style="font-weight: bold;">
                                {{zy.opisDvo}}
                            </div>
                            <div>
                                {{'kapacitet: ' + zy.kapacitet}}
                            </div>
                            <div>
                                {{'prisutno: ' + zy.prisutnih}}
                            </div>
                        </div>
                        <div style="margin-right: 10px;">
                            <div [ngStyle]="{color: zy.options.colors[0]}">
                                Popunjenost:
                            </div>
                            <div>
                                <div class="d-flex justify-content-center align-items-center"
                                    [ngStyle]="{color: zy.options.colors[0]}" style="font-size: 2rem;">

                                    <span>{{zy.prisutnih / zy.kapacitet * 100 | number:'1.0-0'}}
                                        %</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <google-chart style="max-width: 80px; max-height: 80px;" #chart [type]="'PieChart'"
                                [data]="zy.data" [options]="zy.options">
                            </google-chart>
                        </div>
                    </div>

                    <div *ngIf="!objDvoraneChart" class="alert-success" style="padding: 10px 0;">
                        Nema prisutnih u dvoranama
                    </div>

                </div>

            </div>

            <div class="modal-footer d-block" style="padding: 12px 5px;">
                <div>
                    <button id="btnMdlPopDvorClose" type="button" class="btn mybtn-cancel w-100"
                        data-dismiss="modal">Zatvori</button>
                </div>
            </div>

        </div>
    </div>
</div>