<!-- <div class="row" style="margin: 0 0 10px 0;">
    <div class="col int-tab" (click)="srvObavijesti.frmSendStep = 1"
        [ngStyle]="{'background-color': srvObavijesti.frmSend && srvObavijesti.frmSend.errors?.err ? '#ffe8cb' : '#d5ffef'}">
        <i *ngIf="srvObavijesti.frmSend && srvObavijesti.frmSend.errors?.err " class="fa fa-exclamation-triangle"
            style="color: #fb7575;" aria-hidden="true"></i> Primatelji
    </div>
    <div class="col int-tab" (click)="srvObavijesti.frmSendStep = 2"
        [ngStyle]="{'background-color': srvObavijesti.frmSend && srvObavijesti.frmSend.get('tekst').errors ? '#ffe8cb' : '#d5ffef'}">
        <i *ngIf="srvObavijesti.frmSend && srvObavijesti.frmSend.get('tekst').errors" class="fa fa-exclamation-triangle"
            style="color: #fb7575;" aria-hidden="true"></i> Tekst poruke
    </div>
</div> -->

<form *ngIf="srvObavijesti.frmSend" [formGroup]="srvObavijesti.frmSend" style="padding: 5px 12px;">

    <div *ngIf="srvObavijesti.frmSendStep == 1">

        <!-- <div>
            <mat-radio-group formControlName="chkID" (change)="grpChange()" aria-label="Select an option"
                class="d-flex flex-column">
                <mat-radio-button [value]="1">Svi članovi</mat-radio-button>
                <mat-radio-button [value]="2">Određena poslovna jedinica</mat-radio-button>
                <mat-radio-button *ngIf="srvObavijesti.objGrupe" [value]="3">Određena grupa</mat-radio-button>
            </mat-radio-group>
        </div> -->

        <mat-form-field class="w-100" style="text-align: center;">
            <mat-label>Dan u tjednu</mat-label>
            <mat-select formControlName="chkID" (selectionChange)="grpChange()">
                <mat-option [value]="1">Svi članovi</mat-option>
                <mat-option *ngIf="mod.objPosJed?.length > 1" [value]="2">Određena poslovna jedinica</mat-option>
                <mat-option *ngIf="srvObavijesti.objGrupe" [value]="3">Određena grupa</mat-option>
            </mat-select>
        </mat-form-field>


        <!--Poslovne jedinice-->

        <!-- <div *ngIf="srvObavijesti.frmSend.controls['chkID'].value == '2'" class="collapse d-flex flex-column" id="clpsPosJed">
            <mat-radio-group formControlName="posJedID" aria-label="Select an option" class="d-flex flex-column">
                <mat-radio-button style="margin-left: 20px;" *ngFor="let x of srvObavijesti.objPosJed"
                    [value]="x.PoslJedID">
                    {{x.NazivPos}}</mat-radio-button>
            </mat-radio-group>
        </div> -->

        <div *ngIf="srvObavijesti.frmSend.controls['chkID'].value == '2'" class="collapse d-flex flex-column"
            id="clpsPosJed">
            <mat-form-field class="w-100" style="text-align: center;">
                <mat-label>Poslovna jedinica</mat-label>
                <mat-select formControlName="posJedID">
                    <mat-option *ngFor="let x of srvObavijesti.objPosJed"
                        [value]="x.PoslJedID">{{x.NazivPos}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <!--Grupe-->

        <!-- <div *ngIf="srvObavijesti.frmSend.controls['chkID'].value == '3'" class="collapse d-flex flex-column"
            id="clpsGrupe">
            <mat-radio-group formControlName="grpID" aria-label="Select an option" class="d-flex flex-column">
                <mat-radio-button style="margin-left: 20px;" *ngFor="let x of srvObavijesti.objGrupe" [value]="x.grpID">
                    {{x.grpOpis}}</mat-radio-button>
            </mat-radio-group>
        </div> -->

        <div *ngIf="srvObavijesti.frmSend.controls['chkID'].value == '3'" class="collapse d-flex flex-column"
            id="clpsPosJed">
            <mat-form-field class="w-100" style="text-align: center;">
                <mat-label>Grupa</mat-label>
                <mat-select formControlName="grpID">
                    <mat-option *ngFor="let x of srvObavijesti.objGrupe" [value]="x.grpID">{{x.grpOpis}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!--Članovi-->
        <div *ngIf="srvObavijesti.frmSend.controls['chkID'].value == '4'" class="collapse d-flex flex-column"
            id="clpsGrupe">
            <mat-radio-group formControlName="clanID" aria-label="Select an option" class="d-flex flex-column">
                <mat-radio-button style="margin-left: 20px;" *ngFor="let x of srvObavijesti.objClanovi"
                    [value]="x.clanID">
                    {{x.Ime + ' ' + x.Prezime}}</mat-radio-button>
            </mat-radio-group>
        </div>
        <!-- <mat-error *ngIf="srvObavijesti.frmSend.errors?.err" style="text-align: center;">{{srvObavijesti.frmSend.errors.err}}</mat-error> -->
    </div>

    <!-- Naslov -->
    <div>
        <mat-form-field class="w-100">
            <input matInput type="text" formControlName="naslov" placeholder="naslov - max 200 znakova" required>
            <mat-error *ngIf="srvObavijesti.frmSend.controls['naslov'].errors?.required">Niste upisali naslov
            </mat-error>
            <mat-error *ngIf="srvObavijesti.frmSend.controls['naslov'].errors?.maxlength">Duljina poruke je maksimalno
                200 znakova
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Tekst -->
    <div>

        <mat-form-field class="w-100">
            <textarea matInput type="text" formControlName="tekst" placeholder="tekst poruke - max 750 znakova"
                style="height: 200px;" required> </textarea>
            <mat-error *ngIf="srvObavijesti.frmSend.controls['tekst'].errors?.required">Niste upisali tekst poruke
            </mat-error>
            <mat-error *ngIf="srvObavijesti.frmSend.controls['tekst'].errors?.maxlength">Duljina poruke je maksimalno
                750 znakova
            </mat-error>
        </mat-form-field>

    </div>

    <div *ngIf="srvObavijesti.frmSend.errors?.err" class="alert alert-warning"
        style="padding: 5px; margin: 0; text-align: center; background-color: #ffe8cb;">
        {{srvObavijesti.frmSend.errors.err}}
    </div>

    <!-- <button *ngIf="srvObavijesti.frmSendStep == 1 && !srvObavijesti.frmSend.errors?.err" type="button"
        class="btn mybtn-info w-100" style="margin-top: 15px;" (click)="nastavi()">Nastavi</button> -->
        <div style="text-align: center;">
            <div *ngIf="bolSlanje" class="spinner-border text-primary" role="status"></div>
            <div *ngIf="bolSlanje">
                slanje u tijeku...
            </div>
        </div>
    
    <button *ngIf="!bolSlanje" type="button" class="btn mybtn-info w-100"
        [ngClass]="{'mybt-warning': !srvObavijesti.frmSend.valid}" style="margin-top: 15px;"
        (click)="posalji()">Pošalji</button>

</form>