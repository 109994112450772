import { Component, OnInit } from '@angular/core';
import { Grupa, GrupeService } from '../grupe.service';
import { ModulService } from '../modul.service';
import { TerminiFilter, TerminiRez, TerminiRezGrp, TerminiService } from '../termini.service';
import { KalendarService } from '../kalendar.service';

@Component({
  selector: 'app-termini-pregled-sidebar',
  templateUrl: './termini-pregled-sidebar.component.html',
  styleUrls: ['./termini-pregled-sidebar.component.scss']
})
export class TerminiPregledSidebarComponent implements OnInit {

  constructor(
    public srvGrupe: GrupeService,
    public mod: ModulService,
    public srvTermini: TerminiService,
    public srvKalendar: KalendarService
  ) { }


  ngOnInit(): void {

    this.srvTermini.napraviFrmTerminFilter((val: TerminiFilter) => {
      this.srvTermini.prikazi();
    });

  }


  //Odabir poslovne jedinice
  odabirPoslovneJedinice() {
    this.srvTermini.frmTerminFilter.get('grpID').setValue('0');
    this.srvGrupe.objGrupe = null;
    this.srvGrupe.grupeByPosJed(this.srvTermini.frmTerminFilter.get('pjedID').value, 1, (rez: Grupa[]) => {
      this.srvGrupe.objGrupe = rez;
    });
  }

}
