import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModulService } from '../modul.service';
import * as validatori from '../validatori';
import { ClanoviService } from '../clanovi.service';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clan-odjava',
  templateUrl: './clan-odjava.component.html',
  styleUrl: './clan-odjava.component.scss'
})

export class ClanOdjavaComponent implements OnInit {

  constructor(
    public mod: ModulService,
    public frmBuilder: FormBuilder,
    public srvClanovi: ClanoviService,
    private http: HttpClient,
    private router: Router
  ) { }

  minDate = moment().format('YYYY-MM-DD');
  datTest;

  ngOnInit(): void {



  }

  odjaviClana(trenID: number) {

    this.srvClanovi.frmOdjava.markAllAsTouched();

    if (this.srvClanovi.frmOdjava.valid) {

      //Formatiranje Datum/Vrijeme za slanje http
      let d1Tmp: Date = new Date(this.srvClanovi.frmOdjava.controls['odjDat'].value);

      let godTmp: number = d1Tmp.getFullYear();
      let mjTmp: number = d1Tmp.getMonth();
      let danTmp: number = d1Tmp.getDate();
      let hTmp: number = this.srvClanovi.frmOdjava.controls['odjH'].value;
      let minTmp: number = this.srvClanovi.frmOdjava.controls['odjMin'].value;

      let dTmp: string = formatDate(new Date(godTmp, mjTmp, danTmp, hTmp, minTmp, 0), 'yyyy-MM-dd HH:mm:ss', 'en-US');

      //JSON body
      let phpBody = {
        trenID: this.srvClanovi.frmOdjava.controls['trenID'].value,
        vrijemeOdjava: dTmp,
        fingerprint: this.mod.fingerprint()
      }

      //www. url
      let urlTmp: string = this.mod.webUrl + '/php/prisutni.php?token=' + this.mod.objKor.token + '&funk=odjava';

      //http.get
      this.http.post(urlTmp, phpBody).subscribe(rez => {

        if (rez == 'ok') {

          document.getElementById('btnMdlOdjavaQrClose').click();
          document.getElementById('btnOdjavaClose').click();

          if (this.mod.session && this.mod.session.trenID == this.srvClanovi.frmOdjava.controls['trenID'].value) {
            this.mod.session = null;
            this.mod.objPosJedSelected = null;
            localStorage.removeItem('objPosJedSelected');
            localStorage.removeItem('session');

            this.router.navigate(['/cpanel1']);

          }

          this.mod.msgBox(1, "Član je uspješno odjavljen!", 2000);

        } else {

          this.mod.msgBox(2, "Greška prilikom odjave!", 3000);

        }

      });

    }

  }

  trajanjePosjeta() {

    /* //Angular / Javascript
     let d1 = +new Date(this.frmOdjava.controls['vrijemePrijava'].value);
     let d2 = +new Date(this.frmOdjava.controls['odjDat'].value + 'T' + this.frmOdjava.controls['odjH'].value + ':' + this.frmOdjava.controls['odjMin'].value + ':00');
     let diff: number = -(d1 - d2) / 1000 / 60; //Ukupno min
    */

    //Moment.js
    let dd1 = moment(this.srvClanovi.frmOdjava.controls['vrijemePrijava'].value);
    let d012 = moment(this.srvClanovi.frmOdjava.controls['odjDat'].value).format('YYYY-MM-DD');
    let dd2 = moment(d012 + 'T' + this.srvClanovi.frmOdjava.controls['odjH'].value + ':' + this.srvClanovi.frmOdjava.controls['odjMin'].value + ':00');

    this.datTest = dd2;

    //alert(dd2);

    let diff: number = -dd1.diff(dd2, 'minutes');

    let rezDana: number = Math.floor(diff / 1440); //Dana

    let rezH: number = Math.floor((diff - (rezDana * 1440)) / 60); //Sati

    let rezMin: number = diff - (rezDana * 1440) - (rezH * 60); //Minuta

    if (isNaN(rezDana) || isNaN(rezH) || isNaN(rezMin)) {
      return

    } else {

      if (rezDana <= 0) {

        if (rezH <= 0) {
          return rezMin + 'min';
        } else {
          return rezH + 'h ' + rezMin + 'min';
        }

      } else {

        if (rezDana == 1) {
          return rezDana + 'dan ' + rezH + 'h ' + rezMin + 'min';
        } else {
          return rezDana + 'dana ' + rezH + 'h ' + rezMin + 'min';
        }

      }

    }
  }

  trajanjePosjetaParams(d1: Moment) {

    //Moment.js
    let dd1 = moment(d1);
    let dd2 = moment();

    let diff: number = -dd1.diff(dd2, 'minutes');


    let rezDana: number = Math.floor(diff / 1440); //Dana

    let rezH: number = Math.floor((diff - (rezDana * 1440)) / 60); //Sati

    let rezMin: number = diff - (rezDana * 1440) - (rezH * 60); //Minuta

    if (isNaN(rezDana) || isNaN(rezH) || isNaN(rezMin)) {
      return

    } else {

      if (rezDana <= 0) {

        if (rezH <= 0) {
          return rezMin + ' min';
        } else {
          return rezH + ' h ' + rezMin + ' min';
        }

      } else {

        if (rezDana == 1) {
          return rezDana + ' dan ' + rezH + ' h ' + rezMin + ' min';
        } else {
          return rezDana + ' dana ' + rezH + ' h ' + rezMin + ' min';
        }

      }

    }
  }

  //frmOdjava trener / operater




}
