import { Component } from '@angular/core';
import { ModulService } from '../modul.service';
import { NapredakService } from '../napredak.service';

@Component({
  selector: 'app-pn-veza-edit',
  templateUrl: './pn-veza-edit.component.html',
  styleUrl: './pn-veza-edit.component.scss'
})
export class PnVezaEditComponent {

  constructor(
    public mod: ModulService,
    public srvNapredak: NapredakService
  ) {}

  aktSwitch() {

    let aktTmp: number = this.srvNapredak.frmPnVeza.get('pnvAkt').value;

    if (aktTmp == 0) {
      this.srvNapredak.frmPnVeza.get('pnvAkt').setValue(1);
    }  else {
      this.srvNapredak.frmPnVeza.get('pnvAkt').setValue(0);
    }

  }

}
