<form *ngIf="srvNapredak.frmPnProgram" [formGroup]="srvNapredak.frmPnProgram">

    <!-- Naslov -->
    <div>
        <mat-form-field class="w-100">
            <input matInput type="text" id="pnNaziv" autocomplete="off" formControlName="pnNaziv" placeholder="naslov - max 100 znakova" required>
            <mat-error *ngIf="srvNapredak.frmPnProgram.controls['pnNaziv'].errors?.required">Polje je obavezno za unos
            </mat-error>
            <mat-error *ngIf="srvNapredak.frmPnProgram.controls['pnNaziv'].errors?.maxlength">Duljina poruke je maksimalno
                100 znakova
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Tekst -->
    <div>

        <mat-form-field class="w-100">
            <textarea matInput type="text" formControlName="pnNapomena" placeholder="napomena - max 750 znakova"
                style="height: 200px;"> </textarea>
            <mat-error *ngIf="srvNapredak.frmPnProgram.controls['pnNapomena'].errors?.maxlength">Duljina poruke je maksimalno
                750 znakova
            </mat-error>
        </mat-form-field>

    </div>

    <div *ngIf="mod.objKor.vrstaID == 2 || mod.session" class="d-flex align-items-center justify-content-center" style="cursor: pointer;" (click)="publicClick()">
        <div style="margin-right: 10px; font-size: 1.5rem;">
            <i *ngIf="srvNapredak.frmPnProgram.get('pnPublic').value == 1" class="fa fa-check-square-o" aria-hidden="true"></i>
            <i *ngIf="srvNapredak.frmPnProgram.get('pnPublic').value == 0" class="fa fa-square-o" aria-hidden="true"></i>
        </div>
        <div>
            Podijeli s drugima
        </div>
    </div>

</form>