<div class="zp-main" style="padding-top: 5px;">

    <!--QR code-->
    <!-- <div *ngIf="mod.objKor && mod.objKor.vrstaID == 1 && !mod.session" class="col col-btn" data-toggle="modal"
        data-target="#modalQR">
        <i class="material-icons cp-icon">
            filter_center_focus
        </i> <br />
        <span>fitNet ID</span>
    </div> -->

    <div class="btn mybtn-cancel w-100 d-flex align-items-center" style="font-size: 1.2rem;"><i class="material-icons cp-icon" style="font-size: 2rem;">
        filter_center_focus
    </i> <span style="margin-left: 10px;">fitNet ID</span> </div>


    <!--Grupe-->
    <!-- <div *ngIf="mod.objPosJedSelected && mod.objPosJedSelected?.PoslJedID" class="col col-btn" routerLink="/grupe">
        <i class="material-icons cp-icon">people_alt</i> <br />
        <span>Grupe</span>
    </div> -->

    <div class="btn mybtn-cancel w-100 d-flex align-items-center" style="font-size: 1.2rem;" routerLink="/grupe"><i class="material-icons cp-icon" style="font-size: 2rem;">
        people_alt
    </i> <span style="margin-left: 10px;">Grupe</span> </div>

    <!--Obavijesti-->
    <!-- <div *ngIf="true" class="col col-btn d-flex flex-column" style="position: relative;">
        <i class="material-icons cp-icon">contact_mail</i>
        <span>Obavijesti</span>
    </div> -->

    <div class="btn mybtn-cancel w-100 d-flex align-items-center" style="font-size: 1.2rem;" routerLink="/grupe"><i class="material-icons cp-icon" style="font-size: 2rem;">
        contact_mail
    </i> <span style="margin-left: 10px;">Obavijesti</span> </div>

    <!--Termini-->
    <!-- <div  class="col col-btn d-flex flex-column"
        style="position: relative;" routerLink="/termini-pregled">
        <i class="material-icons cp-icon" [ngClass]="{'new-msgs': mod.objAktSad}">access_time</i>
        <span>Termini</span>
    </div> -->

    <div class="btn mybtn-cancel w-100 d-flex align-items-center" style="font-size: 1.2rem;" routerLink="/termini-pregled"><i class="material-icons cp-icon" style="font-size: 2rem;">
        access_time
    </i> <span style="margin-left: 10px;">Termini</span> </div>

    <!--Članarine-->
    <!-- <div class="col col-btn d-flex flex-column"
        style="position: relative;" routerLink="/clanarine-pregled">
        <i class="material-icons cp-icon">payment</i>
        <span>Članarine</span>
    </div> -->

    <div class="btn mybtn-cancel w-100 d-flex align-items-center" style="font-size: 1.2rem;" routerLink="/clanarine-pregled"><i class="material-icons cp-icon" style="font-size: 2rem;">
        payment
    </i> <span style="margin-left: 10px;">Članarine</span> </div>

    <!--Statistike-->
    <!-- <div *ngIf="true" class="col col-btn" routerLink="/stat-klijent">
        <i class="material-icons cp-icon">show_chart</i> <br />
        <span>Statistike</span>
    </div> -->

    <div class="btn mybtn-cancel w-100 d-flex align-items-center" style="font-size: 1.2rem;" routerLink="/stat-klijent"><i class="material-icons cp-icon" style="font-size: 2rem;">
        show_chart
    </i> <span style="margin-left: 10px;">Statistike</span> </div>

    <!--Postavke-->
   <!--  <div *ngIf="true" class="col col-btn">
        <i class="material-icons cp-icon">perm_data_setting</i> <br />
        <span>Postavke</span>
    </div> -->

    <div class="btn mybtn-cancel w-100 d-flex align-items-center" style="font-size: 1.2rem;" routerLink="/"><i class="material-icons cp-icon" style="font-size: 2rem;">
        perm_data_setting
    </i> <span style="margin-left: 10px;">Postavke</span> </div>

</div>