import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sklanjanjeClan' })
export class SklanjanjeClan implements PipeTransform {
  transform(val: number): string {

    val = Number(val);

    let newStr: string;

    if (val == 0) {
      return '0 članova';
    } else if (val == 1) {
      return '1 član';
    } else if (val > 1 && val < 5) {
      return val + ' člana';
    } else {
      return val + ' članova';
    }

  }
}

@Pipe({ name: 'sklanjanjeOsoba' })
export class SklanjanjeOsoba implements PipeTransform {
  transform(val: number): string {

    val = Number(val);

    let newStr: string;

    if (val == 0) {
      return '0 osoba';
    } else if (val == 1) {
      return '1 osoba';
    } else if (val > 1 && val < 5) {
      return val + ' osobe';
    } else {
      return val + ' osoba';
    }

  }
}

@Pipe({ name: 'sklanjanjeDani' })
export class SklanjanjeDani implements PipeTransform {
  transform(val: number): string {

    val = Number(val);

    let ld: number = Number(val.toString().slice(-1));
    let ltd: number = Number(val.toString().slice(-2));

    if (val == 0) {
      return 'za 0 dana';
    } else if (val == 1) {
      return 'za 1 dan';
    } else if (val > 1 && val <= 20) {
      return 'u ' + val + ' dana';
    } else if (val > 20 && ld == 1 && ltd != 11) {
      return 'u ' + val + ' dan';
    } else {
      return 'u ' + val + ' dana';
    }

  }
}

@Pipe({ name: 'sklanjanjeDaniClean' })
export class SklanjanjeDaniClean implements PipeTransform {
  transform(val: number): string {

    val = Number(val);

    let ld: number = Number(val.toString().slice(-1));
    let ltd: number = Number(val.toString().slice(-2));

    if (val == 0) {
      return 'dana';
    } else if (val == 1) {
      return 'dan';
    } else if (val > 1 && val <= 20) {
      return 'dana';
    } else if (val > 20 && ld == 1 && ltd != 11) {
      return 'dan';
    } else {
      return 'dana';
    }

  }
}

@Pipe({ name: "shortName" })
export class ShortNamePipe implements PipeTransform {
  transform(fullName: string): any {
    return fullName
      .split(" ")
      .map(n => n[0])
      .join("");
  }
}

@Pipe({ name: "minToHHHmm" })
export class MinToHHHmmNamePipe implements PipeTransform {
  transform(minuta: number): any {

    minuta = Number(minuta);

    let hTmp: number;
    let mTmp: number;

    if (minuta < 60) {
      return minuta + ' min';
    } else {

      hTmp = Math.floor(minuta / 60);
      mTmp = minuta - (hTmp * 60);

      return hTmp + ' h'  + (mTmp > 0 ?  '  ' + mTmp + ' min' : '');

    }

  }
}

function sklanjajeSati(h: number) {

  if (h == 0) {
    return 'sati';
  } else if (h == 1) {
    return 'sat';
  } else if (h >= 2 && h <= 4) {
    return 'sata';
  } else if (h > 4) {
    return 'sati'
  }

}
