import { Injectable } from '@angular/core';
import { Kor, ModulService } from './modul.service';
import { Moment } from 'moment';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Clanarina } from './clanarine.service';
import { Clan } from './clanovi.service';

export interface ClanarinaClan {
  claID: number, 
  claClanID: number, 
  claVrstaID: number, 
  claGrupaID: number, 
  intPlacena: number, 
  claDatumUplate: Moment, 
  claVrijediOD: Moment, 
  claVrijediDO: Moment, 
  claIznos: number, 
  claKorClID: number,
  kIme: string,
  kPrezime: string,
  cIme: string,
  cPrezime: string,
  grpOpis: string,
  cvOpis: string,
  cvIznos: number,
  cvTjedno: number
}

export interface FrmFilter {
  vazeca: number,
  klijentID: number,
  placena: number,
  razdoblje: number,
  datOd: Moment,
  datDo: Moment
}

@Injectable({
  providedIn: 'root'
})
export class ClanarineClanService {

  constructor(
    private mod: ModulService,
    private http: HttpClient,
    private frmBuilder: FormBuilder
  ) { }

  frmFilter: FormGroup;
  objKlijenti: Kor[];

  objClanarine: ClanarinaClan[];

  napraviFrmFilter(val: FrmFilter, callback) {

    this.frmFilter = this.frmBuilder.group({
      vazeca: [1],
      klijentID: ['0'],
      placena: [-1],
      razdoblje: [0],
      datOd: [moment(moment().format("YYYY") + "-01-01")],
      datDo: [moment()],
      fingerprint: this.mod.fingerprint()
    });

    if (val) {
      this.frmFilter.patchValue(val);
    }

    callback(this.frmFilter.value);

  }

  //Članarine by član
  clanarineByFilter(val: FrmFilter, callback) {

    let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=clanarineByFilter';
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez: ClanarinaClan[]) => {
      callback(rez);
    });

  }

  //Klijenti by član
  klijentiByClan(callback) {

    let url: string = this.mod.webUrl + '/php/clanarine.php?token=' + this.mod.objKor.token + '&funk=klijentiByClan';
    let obj: string = JSON.stringify({ fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: Kor[]) => {
      callback(rez);
    });

  }

}
