<div>

    <div *ngFor="let x of srvVjezbe.objVjezbe" (click)="odabirVjezbe(x)" style="padding: 1px 0;">

        <button type="button" class="btn mybtn-info w-100">
            <div>
                {{x.vjNaziv}}
            </div>
        </button>

    </div>

</div>