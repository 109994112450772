<div class="container" style="padding: 0; height: 100%; max-height: 100%;">

    <div class="row" style="padding-top: 50px; max-height: 100%;">

        <!-- Lijeva kolona -->
        <div class="col-lg-4 col-md-4 d-none d-md-block card"
            style="margin: 0px !important; padding: 5px; padding-bottom: 30px; overflow: hidden; max-height: 100%; min-width: 300px; opacity: 0.9;">

            <app-zahtjevi-zp-sidebar></app-zahtjevi-zp-sidebar>

        </div>

        <!-- Desna kolona -->
        <div class="col" style="padding: 0px 0px 0px 0px; overflow: hidden; max-height: 100%;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">

                <div class="bgs-dark">

                    <app-pos-jed-hamburger></app-pos-jed-hamburger>

                </div>


                <div class="flex-grow-1 card" style="overflow-y: scroll; scrollbar-width: none; opacity: 0.9; ">

                    <div>

                        <!--Input-->
                        <div class="w-100 input-slim" style="text-align: center; padding: 0;">
                            <mat-form-field style="width: 100%;">
                                <input autocomplete="off" name="pretraga" [(ngModel)]="srvClanovi.strPretragaPosl"
                                    (ngModelChange)="srvClanovi.filtrirajPosKor(srvClanovi.strPretragaPosl)" matInput
                                    placeholder="pretraga..">
                                <button mat-button *ngIf="srvClanovi.strPretragaPosl" matSuffix aria-label="Clear"
                                    (click)="pretragaPoslClear()">X</button>
                            </mat-form-field>
                        </div>

                        <div class="card" *ngFor="let x of srvClanovi.fltPoslovniKor" style="margin:2px; padding: 5px;">

                            <div class="row">

                                <div class="col col-3 d-flex justify-content-center align-items-center"
                                    style="padding: 2px;">
                                    <img style="border-radius: 50%; max-height: 90px;"
                                        src="{{imgSrc(x.img, x.imgSize)}}"
                                        onerror="this.onerror=null; this.src='fitNet/assets/clanovi/0.jpg'"
                                        class="img-fluid p-slika" alt="{Slika nije dostupna}">
                                </div>

                                <div class="col">
                                    <div style="font-size: 1.2rem; font-weight: bold;">
                                        {{x.Ime}}
                                    </div>
                                    <div>
                                        {{(x.Adresa ? x.Adresa : '') + (x.Mjesto && x.Adresa ? ', ' + x.Mjesto :
                                        x.Mjesto ? x.Mjesto : '' )}}
                                    </div>

                                    <div style="margin-top: 10px;">
                                        <div *ngFor="let y of x.pjed">
                                            <div *ngIf="y.intIsto == 0 || x.pjed.length > 1">
                                                <div style="font-weight: bold;">
                                                    * {{y.NazivPos}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div *ngIf="x.povezani == 0 && !x.zahtjev" style="padding: 10px 0px;">
                                <button type="button" class="btn mybtn-success w-100"
                                    (click)="napraviFrmZahtjev(x)">Pošalji zahtjev za povezivanjem</button>
                            </div>

                            <div class="d-flex justify-content-end" *ngIf="x.povezani != 0"
                                style="padding: 10px 10px 5px;">
                                <div style="color: #03b58e;">
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                </div>
                                <div style="margin-left: 10px;">
                                    Povezani ste
                                </div>
                            </div>
                            
                            <div *ngIf="x.zahtjev" class="d-flex justify-content-end">
                                <div>
                                    {{'zahtjev poslan ' + (x.zahtjev.DatumVfc | amDateFormat:'DD.MM.YYYY.')}}
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>



    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document" style="font-size: 1.2rem;">
        <div class="modal-content">

            <!-- <div class="modal-header posjed-mod-header d-flex justify-content-center">
                Pregled termina
            </div> -->

            <div class="modal-body" style="padding: 5px;">

                <app-zahtjevi-zp-sidebar></app-zahtjevi-zp-sidebar>

            </div>

            <div class="modal-footer bgs-dark" style="padding: 5px; padding-bottom: 20px;">
                <button type="button" id="btnSidebarClose" class="btn mybtn-cancel" data-dismiss="modal"
                    style="width: 100%;">Zatvori</button>
            </div>
        </div>
    </div>
</div>


<!-- Modal zahtjev za povezivanje -->
<button type="button" hidden id="btnMdlZahtjevClFit" class="btn btn-primary" data-toggle="modal"
    data-target="#mdlZahtjevClFit">
    Modal zahtjev za povezivanje / član - fitNet
</button>

<div class="modal fade" id="mdlZahtjevClFit" tabindex="-1" role="dialog" aria-labelledby="mdlZahtjevClFitLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlZahtjevClFitLabel">Novo povezivanje</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div>
                    <form *ngIf="srvClanovi.frmZahtjevClFit" [formGroup]="srvClanovi.frmZahtjevClFit">

                        <div>

                            <div style="text-align: center;">
                                Želite li poslati zahtjev za povezivanjem centru
                            </div>

                            <div style="margin: 5px 0; font-size: 1.2rem; font-weight: bold; text-align: center;">
                                {{srvClanovi.frmZahtjevClFit.controls['naziv'].value}} ?
                            </div>

                            <div class="d-flex" style="color: #6c1d1d; text-align: center; padding: 5px;">
                                <div>
                                    <i class="material-icons">error_outline</i>
                                </div>
                                <div>
                                   <span>Molimo da ne šaljete zahtjeve za povezivanjem centrima u koje niste učlanjeni</span>  <span style="margin-left: 5px; color: black;">Hvala!</span>
                                </div>
                            </div>

                        </div>

                    </form>
                </div>

            </div>
            <div class="modal-footer d-block" style="padding: 2px;">
                <div class="row">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success" style="width: 100%;"
                            (click)="srvClanovi.posaljiZahtjev()"><i class="fa fa-paper-plane-o" aria-hidden="true"
                                style="color: white; margin-right: 10px;"></i> Pošalji zahtjev</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button id="btnZahtjevClose" type="button" class="btn mybtn-cancel" style="width: 100%;"
                            data-dismiss="modal"><i aria-hidden="true" style="margin-right: 10px; font-style: normal;">X</i> Otkaži</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>