import { Component } from '@angular/core';
import { ModulService } from '../modul.service';

@Component({
  selector: 'app-zahtjevi-zp-sidebar',
  templateUrl: './zahtjevi-zp-sidebar.component.html',
  styleUrl: './zahtjevi-zp-sidebar.component.scss'
})
export class ZahtjeviZpSidebarComponent {

  constructor(
    public mod: ModulService
  ) {}

}
