import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ModulService, Kor } from '../modul.service';
import { HttpClient } from '@angular/common/http';
import getBrowserFingerprint from 'get-browser-fingerprint';

@Component({
  selector: 'app-aktivacija',
  templateUrl: './aktivacija.component.html',
  styleUrls: ['./aktivacija.component.scss']
})
export class AktivacijaComponent implements OnInit {

  constructor(
    private router: Router,
    private actRoute: ActivatedRoute,
    public mod: ModulService,
    private http: HttpClient
  ) { }

  kor: string;
  token: string;

  ngOnInit() {

    //Čitanje url parametara
    /* this.actRoute.queryParams.subscribe(params => {
      this.kor = params['kor'];
      this.token = params['token'];
    }); */

    //this.mod.msgBox(1,"start",2000);

    this.kor = this.actRoute.snapshot.queryParamMap.get('kor');
    this.token = this.actRoute.snapshot.queryParamMap.get('token');

    console.log(this.kor);
    console.log(this.token);

    //Provjera url parametara
    if (this.kor && this.token) {

      let urlTmp: string = this.mod.webUrl + '/php/aktivacija.php';
      
      let fingerprint = this.mod.fingerprint();

      let objTmp = {
        kor: this.kor,
        token: this.token,
        fingerprint: fingerprint
      }

      let obj: string = JSON.stringify(objTmp);

      //alert('ima param');

      this.http.post(urlTmp, obj).subscribe((rez: Kor) => {
        console.log(rez);
        if (rez) {

          /* this.mod.objKor = null;
          this.mod.objLogin = null;
          localStorage.objKor = ''; */

          //this.mod.objKor = rez;
          /* localStorage.objKor = JSON.stringify(rez);
          localStorage.setItem('token', this.mod.objKor.token); */
          this.mod.prviLogin = true;
          //this.mod.msgBox(1, "valja", 2000);
          this.router.navigate(['naslovnica']); //this.router.navigate(['cpanel1']);
        } else {
          //this.mod.msgBox(2, "Greška 1!",3000);
          this.router.navigate(['naslovnica']);
        }

        //alert(JSON.stringify(rez));

      });

    } else {
      //alert('nema param');
      //this.mod.msgBox(2, "Greška!",3000);
      this.router.navigate(['naslovnica']);
    }

  }

}

