<form *ngIf="srvPosJed.frmPosJed" [formGroup]="srvPosJed.frmPosJed">

        <div class="d-flex align-items-center" style="padding: 0px 10px; cursor: pointer;"
            (click)="istoCheck()">
            <div style="font-size: 1.5rem;">
                <div *ngIf="srvPosJed.frmPosJed.controls['intIsto'].value == '0'" class="fa fa-square-o"
                    aria-hidden="true"></div>
                <div *ngIf="srvPosJed.frmPosJed.controls['intIsto'].value == '1'" class="fa fa-check-square-o"
                    aria-hidden="true"></div>
            </div>
            <div style="margin-left: 10px;">
                isti kao podaci tvrtke
            </div>
        </div>

        <div *ngIf="srvPosJed.frmPosJed.controls['intIsto'].value == '0'">
            <mat-form-field class="w-100">
                <mat-label>Naziv poslovne jedinice</mat-label>
                <input autocomplete="off" autocomplete="off" matInput type="text" formControlName="NazivPos"
                    placeholder="npr. Fitness centar Test" required>
                <mat-error *ngIf="srvPosJed.frmPosJed.controls['NazivPos'].errors?.required">Polje
                    je
                    obavezno za unos
                </mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="srvPosJed.frmPosJed.controls['intIsto'].value == '0'">
            <mat-form-field class="w-100">
                <mat-label>Adresa</mat-label>
                <input autocomplete="off" matInput type="text" formControlName="AdresaPos" placeholder="Adresa">
            </mat-form-field>
        </div>

        <div *ngIf="srvPosJed.frmPosJed.controls['intIsto'].value == '0'">
            <mat-form-field class="w-100">
                <mat-label>Mjesto</mat-label>
                <input autocomplete="off" type="text" placeholder="Upišite 3 slova iz naziva" aria-label="Mjesto"
                    matInput formControlName="Mjesto" [matAutocomplete]="auto1">
                <mat-autocomplete #auto1="matAutocomplete">
                    <mat-option *ngFor="let x of srvKorisnici.fltMjesta2" [value]="x.Mjesto"
                        (onSelectionChange)="postaviMjestoID2(x.Mjesto_ID)">
                        {{x.Mjesto}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field class="w-100">
                <mat-label>Kapacitet (broj osoba)</mat-label>
                <input autocomplete="off" matInput type="number" formControlName="kapacitet" placeholder="broj osoba"
                    required>
                <mat-error *ngIf="srvPosJed.frmPosJed.controls['kapacitet'].errors?.required">Polje
                    je obavezno za unos</mat-error>
                <mat-error *ngIf="srvPosJed.frmPosJed.controls['kapacitet'].errors?.min">Broj mora
                    biti veći od 0</mat-error>
                <mat-error *ngIf="srvPosJed.frmPosJed.controls['kapacitet'].errors?.max">Broj mora
                    biti veći od 1000</mat-error>
            </mat-form-field>
        </div>

        <div class="d-flex align-items-center" style="padding: 0px 10px; cursor: pointer;"
            (click)="aktivnaCheck()">
            <div style="font-size: 1.5rem;">
                <div *ngIf="srvPosJed.frmPosJed.controls['aktivna'].value == '0'" class="fa fa-square-o"
                    aria-hidden="true"></div>
                <div *ngIf="srvPosJed.frmPosJed.controls['aktivna'].value == '1'" class="fa fa-check-square-o"
                    aria-hidden="true"></div>
            </div>
            <div style="margin-left: 10px;">
                Aktivna
            </div>
        </div>

</form>