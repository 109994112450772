<form *ngIf="srvClanClan.frmFilter" [formGroup]="srvClanClan.frmFilter">

    <div style="padding: 5px; background-color: #ebebeb;">
        Filter
    </div>

    <!--Važeće / sve-->
    <div class="row" style="cursor: pointer;"
        (click)="srvClanClan.frmFilter.get('vazeca').value == 0 ? srvClanClan.frmFilter.get('vazeca').setValue(1) : srvClanClan.frmFilter.get('vazeca').setValue(0)">
        <div class="col col-2 d-flex justify-content-center align-items-center" style="padding: 0; color: cadetblue;">
            <mat-icon style="font-size: 2.2rem; width: 40px; height: 40px; text-align: center;"
                *ngIf="srvClanClan.frmFilter.get('vazeca').value == 1">
                check_box</mat-icon>
            <mat-icon style="font-size: 2.2rem; width: 40px; height: 40px; text-align: center;"
                *ngIf="srvClanClan.frmFilter.get('vazeca').value == 0">
                check_box_outline_blank</mat-icon>
        </div>
        <div class="col d-flex align-items-center" style="font-size: 1.3rem;">
            Samo važeće
        </div>
    </div>

    <!--Razdoblje-->
    <div class="row" style="cursor: pointer;"
        (click)="srvClanClan.frmFilter.get('razdoblje').value == 0 ? srvClanClan.frmFilter.get('razdoblje').setValue(1) : srvClanClan.frmFilter.get('razdoblje').setValue(0)">
        <div class="col col-2 d-flex justify-content-center align-items-center" style="padding: 0; color: cadetblue;">
            <mat-icon style="font-size: 2.2rem; width: 40px; height: 40px; text-align: center;"
                *ngIf="srvClanClan.frmFilter.get('razdoblje').value == 1">
                check_box</mat-icon>
            <mat-icon style="font-size: 2.2rem; width: 40px; height: 40px; text-align: center;"
                *ngIf="srvClanClan.frmFilter.get('razdoblje').value == 0">
                check_box_outline_blank</mat-icon>
        </div>
        <div class="col d-flex align-items-center" style="font-size: 1.3rem;">
            Određeno razdoblje
        </div>
    </div>

    <div *ngIf="srvClanClan.frmFilter.get('razdoblje').value == 1" style="padding: 10px;">

        <!--Datum Od-->
        <mat-form-field style="width: 100%; text-align: center;">
            <input autocomplete="off" matInput formControlName="datOd" [matDatepicker]="picker1" placeholder="Datum od">
            <mat-error *ngIf="srvClanClan.frmFilter.get('datOd').errors?.required" style="font-size: 1rem;">
                Obavezan unos
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker1></mat-datepicker>
        </mat-form-field>

        <!--Datum Do-->
        <mat-form-field style="width: 100%; text-align: center;">
            <input autocomplete="off" matInput formControlName="datDo" [matDatepicker]="picker2" placeholder="Datum do">
            <mat-error *ngIf="srvClanClan.frmFilter.get('datDo').errors?.required" style="font-size: 1rem;">
                Obavezan unos
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker2></mat-datepicker>
        </mat-form-field>

    </div>

    <!-- Plaćena -->
    <mat-form-field class="w-100" style="text-align: center; padding: 10px;">
        <mat-label>Plaćanje</mat-label>
        <mat-select formControlName="placena">
            <mat-option [value]="-1">Plaćene i neplaćene</mat-option>
            <mat-option [value]="0">Neplaćene</mat-option>
            <mat-option [value]="1">Plaćene</mat-option>
        </mat-select>
    </mat-form-field>


    <!-- Klijent -->
    <mat-form-field class="w-100" style="text-align: center; padding: 10px;">
        <mat-label>Fitness centar</mat-label>
        <mat-select formControlName="klijentID">
            <mat-option [value]="'0'">Svi centri</mat-option>
            <mat-option *ngFor="let x of srvClanClan.objKlijenti" [value]="x.korisnikID">{{x.Ime}}</mat-option>
        </mat-select>
    </mat-form-field>

    <div style="padding: 5px;">
        <button type="button" class="btn mybtn-cancel w-100" (click)="prikazi()">Prikaži</button>
    </div>


</form>