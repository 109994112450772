import { Injectable } from '@angular/core';
import { ModulService } from './modul.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Dani, DobaDana, FrmFilter, Grp, Pjed, StatKlijentService } from './stat-klijent.service';
import { VrstaClanstva } from './clanovi.service';

//Statistika by FrmFilter
export interface StatClanByFrm {
  posjeta: number,
  vrste: Vrsta[];
  pJed: Pjed[],
  grupe: Grp[],
  dani: Dani,
  dobaD: DobaDana[]
}

export interface Vrsta extends VrstaClanstva {
  count: number;
}

@Injectable({
  providedIn: 'root'
})
export class StatClanService {

  constructor(
    private mod: ModulService,
    private http: HttpClient,
    private frmBuilder: FormBuilder,
    private srvStatKlijent: StatKlijentService
  ) { }

  /* frmFilter: FormGroup; */
  /* objStatClanByFrm: StatClanByFrm; */

  //Prikaži
  prikazi() {

    this.statClanByFrm(this.srvStatKlijent.frmFilter.value, (rez) => {

      this.srvStatKlijent.objStatByFrm = rez;

      console.log(this.srvStatKlijent.objStatByFrm);

      //Pie chart Poslovne jedinice
      rez.pJed.forEach((val, ind) => {
        val['data'] = this.srvStatKlijent.pcData(val.posjeta, rez.posjeta);
        val['options'] = this.srvStatKlijent.options(val.posjeta, rez.posjeta, 80, 80);
      });

      if (rez.dani) {
        this.srvStatKlijent.napuniChartDani(rez.posjeta);
      }

      if (rez.dobaD) {
        this.srvStatKlijent.napuniChartDd(rez.dobaD);
      }

    });

  }

  //Statistika by frmFilter
  statClanByFrm(val: FrmFilter, callback) {

    let url: string = this.mod.webUrl + '/php/stat-clan.php?token=' + this.mod.objKor.token + '&funk=statClanByFrm';

    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez) => {
      callback(rez);
    });

  }

}
