import { Injectable } from '@angular/core';
import { ModulService } from './modul.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface Dvorana {
  dvoID: number,
  pjedIDdvo: number,
  opisDvo: string,
  napomenaDvo: string,
  kapacitetDvo: number,
  aktivnaDvo: number
}

@Injectable({
  providedIn: 'root'
})
export class DvoraneService {

  constructor(
    private mod: ModulService,
    private http: HttpClient,
    private frmBuilder: FormBuilder
  ) { }

  frmDvorana: FormGroup;

  //Napravi frm dvorana
  napraviFrmDvorana(val: Dvorana, callback) {

    this.frmDvorana = this.frmBuilder.group({
      dvoID: [0],
      pjedIDdvo: [0],
      opisDvo: [, Validators.required],
      napomenaDvo: [],
      kapacitetDvo: [, [Validators.required, Validators.min(1), Validators.max(1000)]],
      aktivnaDvo: [1]
    });

    if (val) {
      this.frmDvorana.patchValue(val);
    }

    callback(this.frmDvorana.value);

  }

  //Unos / uređivanje
  dvoInsertEdit(val: Dvorana, callback) {

    let urlTmp: string = this.mod.webUrl + '/php/dvorane.php?token=' + this.mod.objKor.token + '&funk=dvoInsertEdit';
    val['fingerprint'] = this.mod.fingerprint();
    let obj: string = JSON.stringify(val);

    this.http.post(urlTmp, obj).subscribe((rez: Dvorana[]) => {
      callback(rez);
    });

  }

  //Dvorane by pos Jed ID
  //aktivna: -1 = dve, 0 = neaktivne, 1 = aktivne
  dvoraneByPosJedId(posJedID: number, aktivna: number, callback: Function) {

    let urlTmp: string = this.mod.webUrl + '/php/dvorane.php?token=' + this.mod.objKor.token + '&funk=dvoraneByPosJedId';

    let obj0 = { posJedID: posJedID, aktivna: aktivna, fingerprint: this.mod.fingerprint() };
    let obj: string = JSON.stringify(obj0);

    this.http.post(urlTmp, obj).subscribe((rez: Dvorana[]) => {
      callback(rez);
    });

  }

}
