<form *ngIf="srvNapredak.frmVePnCilj" [formGroup]="srvNapredak.frmVePnCilj">

    <div formArrayName="stavke" *ngFor="let x of srvNapredak.stavkeVePnCilj.controls; let i=index">

        <div *ngIf="x.controls.ppsAkt.value == 1" class="stavka" [ngClass]="{'stavka-sel': intFocus == i, 'stavka-neakt': !x.controls.pcVrijednost.value}">

            <div [formGroupName]="i" class="row input-slim">

                <div class="col col-7 mycol align-self-center">
                    <div class="naslov" style="font-size: 1.2rem;">
                        {{x.controls.ppsNaziv.value + ' (' + x.controls.ppsJedMj.value + ')'}}
                    </div>
                </div>

                <div class="col col-2 mycol align-self-center" style="text-align: center;">

                    <div style="color: blue;">
                        {{x.controls.prvoMj.value | number}}
                    </div>

                </div>
    
                <div class="col col-3 d-flex align-items-center mycol">
    
                    <mat-form-field class="w-100" style="text-align: center;">
                        <mat-label>{{x.controls.ppsJedMj.value}}</mat-label>
                        <input matInput type="tel" formControlName="pcVrijednost" ariaLabel="pcVrijednost" (focus)="intFocus = i"
                            (blur)="srvNapredak.decFormatVrCilj(i, $event); intFocus = null" placeholder="{{x.controls.ppsJedMj.value}}"
                            style="text-align: center;">
                    </mat-form-field>
    
                </div>
    
            </div>

        </div>

    </div>

</form>